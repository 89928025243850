import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { ZmienHasloMailModel } from 'src/app/modele/zmianaHasla/zmienHasloMailModel';
import { ZmienHasloService } from 'src/app/serwisy/konto/zmien-haslo.service';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { BazowyComponent } from '../../bazowy.component';

@Component({
  selector: 'app-zmiana-hasla-email',
  templateUrl: './zmiana-hasla-email.component.html'
})
export class ZmianaHaslaEmailComponent extends BazowyComponent implements OnInit {

  public zmienHasloModel = new ZmienHasloMailModel();

  constructor(
    public authService: AuthService,
    public router: Router,
    private zmienHasloSerwis: ZmienHasloService,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public translate: TranslateService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translate);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.inicjujJezyk();
    });
  }

  public zmienHaslo() {
    try {
      this.zmienHasloSerwis.zmienHasloMail(this.zmienHasloModel).subscribe(
        (rezultat) => {
          if (this.czyRezultatOk(rezultat)) {
            this.bledyWalidacji = null;
            this.wyswietlInformacje(this.pobierzTlumaczenie("zmianaHasla.wyslanoEmailKomunikat"));
            return;
          }
          if (this.bladModelu(rezultat)) {
            this.bledyWalidacji = rezultat.bledyModelu;
            return;
          }
          this.wyswietlKomunikat(rezultat.wiadomosc, true);
        }
      );
    } catch (blad) {

    }
  }
}
