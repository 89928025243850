import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { KonfiguracjaPomieszczen } from 'src/app/modele/projekty/konfiguracjaPomieszczen/konfiguracjaPomieszczen';
import { DodajPoleSpisuModel } from 'src/app/modele/projekty/polaSpisu/dodajPoleSpisuModel';
import { EdytujPoleSpisuModel } from 'src/app/modele/projekty/polaSpisu/edytujPoleSpisuModel';
import { PolaSpisuProjektu } from 'src/app/modele/projekty/polaSpisu/polaSpisuProjektu';
import { PoleSpisu } from 'src/app/modele/projekty/polaSpisu/poleSpisu';
import { ZmienKolejnoscPolSpisuModel } from 'src/app/modele/projekty/polaSpisu/zmienKolejnoscPolSpisuModel';
import { ComboBox } from 'src/app/modele/wspolne/comboBox';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { ListaWynikowResponse } from 'src/app/modele/wspolne/listaWynikowResponse';
import { PojedynczyElementResponse } from 'src/app/modele/wspolne/pojedynczyElement';
import { ResponseBazowy } from 'src/app/modele/wspolne/responseBazowy';
import { SerwisBazowy } from '../bazowy.service';
import { AuthService } from '../ogolne/auth.service';
import { ErrorService } from '../ogolne/error.service';
import { LoadingService } from '../ogolne/loading.service';

@Injectable({
  providedIn: 'root'
})
export class PolaSpisuService extends SerwisBazowy {

  constructor(
    httpClient: HttpClient,
    authService: AuthService,
    errorService: ErrorService,
    loaderService: LoadingService,
    translate: TranslateService) {
    super(httpClient, authService, errorService, loaderService, translate);
  }

  public pobierzPola(model: IdModel) {
    return this.wykonajAkcje<IdModel, PojedynczyElementResponse<PolaSpisuProjektu>>
      (this.AdresApi + "PolaSpisu/PobierzPola", model);
  }

  public zmienKolejnosc(model: ZmienKolejnoscPolSpisuModel) {
    return this.wykonajAkcje<ZmienKolejnoscPolSpisuModel, ResponseBazowy>
      (this.AdresApi + "PolaSpisu/ZmienKolejnosc", model);
  }

  public dodajPole(model: DodajPoleSpisuModel) {
    return this.wykonajAkcje<DodajPoleSpisuModel, ResponseBazowy>
      (this.AdresApi + "PolaSpisu/DodajPole", model);
  }

  public edytujPole(model: EdytujPoleSpisuModel) {
    return this.wykonajAkcje<EdytujPoleSpisuModel, ResponseBazowy>
      (this.AdresApi + "PolaSpisu/EdytujPole", model);
  }

  public usunPole(model: IdModel) {
    return this.wykonajAkcje<IdModel, ResponseBazowy>
      (this.AdresApi + "PolaSpisu/UsunPole", model);
  }

  public pobierzPoleDoEdycji(model: IdModel) {
    return this.wykonajAkcje<IdModel, PojedynczyElementResponse<EdytujPoleSpisuModel>>
      (this.AdresApi + "PolaSpisu/PobierzPoleDoEdycji", model);
  }

  public pobierzKolumnySlownika(model: IdModel) {
    return this.wykonajAkcje<IdModel, ListaWynikowResponse<ComboBox>>
      (this.AdresApi + "PolaSpisu/PobierzKolumnySlownika", model);
  }
}
