import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { ImportujEwidencjeRezultat } from 'src/app/modele/projekty/ewidencja/importujEwidencjeRezultat';
import { KolumnaImportuEwidencji } from 'src/app/modele/projekty/ewidencja/kolumnaImportuEwidencji';
import { MapujEwidencjeModel } from 'src/app/modele/projekty/ewidencja/mapujEwidencjeModel';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { EwidencjaService } from 'src/app/serwisy/projekty/ewidencja.service';
import { threadId } from 'worker_threads';

@Component({
  selector: 'app-mapuj-import-ewidencji-modal',
  templateUrl: './mapuj-import-ewidencji-modal.component.html',
  styleUrls: ['./mapuj-import-ewidencji-modal.component.css']
})
export class MapujImportEwidencjiModalComponent extends BazowyComponent implements OnInit {

  idProjektu: string;
  dane: ImportujEwidencjeRezultat = new ImportujEwidencjeRezultat();
  model: MapujEwidencjeModel = new MapujEwidencjeModel();


  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public ewidencjaSerwis: EwidencjaService,
    public translateSerwis: TranslateService,
    public activeModal: NgbActiveModal,
    private activatedRoute: ActivatedRoute) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translateSerwis);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.przygotujModel();
    });
  }

  przygotujModel() {
    this.model.projektId = this.idProjektu;
    this.model.kolumnyImportu = new Array();

    this.dane.listaKolumn.forEach(kolumna => {
      var kolumnaImportu = new KolumnaImportuEwidencji();
      kolumnaImportu.czyImportowac = true;
      kolumnaImportu.kolumnaEwidencji = kolumna;

      this.model.kolumnyImportu.push(kolumnaImportu);
    });
  }

  importuj() {

    this.ewidencjaSerwis.mapujEwidencje(this.model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.wyswietlKomunikat("Ewidencja została zaimportowane");
          this.activeModal.close(true);
          return;
        }
        if (this.bladModelu(rezultat)) {
          this.bledyWalidacji = rezultat.bledyModelu;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }


}
