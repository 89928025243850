<div class="modal-content" *ngIf="czyZaladowanoDane">
    <div class="modal-body">
        <button aria-label="Close" class="close" data-dismiss="modal" type="button"
            (click)="activeModal.dismiss()"></button>
        <div class="ft__01 text-center pt-0 pb-3 ">Mapowanie ewidencji</div>
        <div class="mb-2 mt-2">Kod lokalizacji</div>


        <div class="input_container mb-3 " [ngClass]="czyBladWalidacyjny('lokalizacjaKolumnaEwidencjiId')?'error':''">
            <select [(ngModel)]="model.lokalizacjaKolumnaEwidencjiId" name="lokalizacjaKolumnaEwidencjiId">
                <option [ngValue]="undefined" [disabled]="true">Kolumna z ewidencji</option>
                <option [ngValue]="kolumna.id" *ngFor="let kolumna of dane.listaKolumnEwidencji">{{kolumna.nazwa}}
                </option>
            </select>
            <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('lokalizacjaKolumnaEwidencjiId')">
                {{pobierzBladWalidacyjny("lokalizacjaKolumnaEwidencjiId") }}</div>
        </div>
        <div class="input_container mb-3" [ngClass]="czyBladWalidacyjny('lokalizacjaKolumnaLokalizacja')?'error':''">
            <select [(ngModel)]="model.lokalizacjaKolumnaLokalizacja" name="lokalizacjaKolumnaLokalizacja">
                <option [ngValue]="undefined" [disabled]="true">Kolumna lokalizacji</option>
                <option [ngValue]="kolumna" *ngFor="let kolumna of dane.listaKolumnLokalizacji">{{kolumna}}</option>
            </select>
            <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('lokalizacjaKolumnaLokalizacja')">
                {{pobierzBladWalidacyjny("lokalizacjaKolumnaLokalizacja") }}</div>
        </div>


        <div *ngFor="let kolumna of model.mapowania">
            <div class="mb-2 mt-4">{{kolumna.nazwaKolumny}}</div>
            <div class="input_container mb-3">
                <div class="custom_dropdown_container">
                    <select [(ngModel)]="kolumna.poleSpisuId">
                        <option [ngValue]="null">Pole spisu</option>
                        <option [ngValue]="pole.id" *ngFor="let pole of dane.listaPolSpisu">{{pole.nazwa}}</option>
                    </select>
                </div>
            </div>
            <div class="input_container pb-3" *ngIf="kolumna.poleSpisuId">
                <input type="number" placeholder="Priorytet parowania" [(ngModel)]="kolumna.priorytetParowania"
                    name="priorytetParowania" appTylkoPelneLiczby>
            </div>
        </div>


        <div class="input_container pb-3 pt-3">
            <button class="btn__primary " (click)="zapisz()">Zapisz</button>
        </div>
        <div class="input_container pb-3">
            <button class="btn__link" (click)="activeModal.dismiss()">Anuluj</button>
        </div>

    </div>
</div>