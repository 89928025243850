import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';

@Component({
  selector: 'app-znalezione-rekordy-ewidencji-modal',
  templateUrl: './znalezione-rekordy-ewidencji-modal.component.html',
  styleUrls: ['./znalezione-rekordy-ewidencji-modal.component.css']
})
export class ZnalezioneRekordyEwidencjiModalComponent extends BazowyComponent implements OnInit {

  listaKolumn: Array<string>;
  dane: Array<Array<string>>;
  kolumnaId: number;

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    public activeModal: NgbActiveModal,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translateSerwis);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.kolumnaId = this.listaKolumn.indexOf("id");
    });
  }
  wybierzRekord(indeks: number) {
    var id = this.dane[indeks][this.kolumnaId]

    this.activeModal.close(id);
  }
}
