import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CzyIstniejeImportEwidencjiRezultat } from 'src/app/modele/projekty/ewidencja/czyIstniejeImportEwidencjiRezultat';
import { DodajKomentarzDoEwidencji } from 'src/app/modele/projekty/ewidencja/dodajKomentarzDoEwidencji';
import { EdytujMapowaniaRezultat } from 'src/app/modele/projekty/ewidencja/edytujMapowaniaRezultat';
import { EdytujMapowanieModel } from 'src/app/modele/projekty/ewidencja/edytujMapowanieModel';
import { ImportujEwidencjeModel } from 'src/app/modele/projekty/ewidencja/importujEwidencjeModel';
import { ImportujEwidencjeRezultat } from 'src/app/modele/projekty/ewidencja/importujEwidencjeRezultat';
import { MapowaniaDoEdycji } from 'src/app/modele/projekty/ewidencja/mapowaniaDoEdycji';
import { MapujEwidencjeModel } from 'src/app/modele/projekty/ewidencja/mapujEwidencjeModel';
import { PobierzEwidencjeFiltry } from 'src/app/modele/projekty/ewidencja/pobierzEwidencjeFiltry';
import { PobierzEwidencjeRezultat } from 'src/app/modele/projekty/ewidencja/pobierzEwidencjeRezultat';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { PojedynczyElementResponse } from 'src/app/modele/wspolne/pojedynczyElement';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { ResponseBazowy } from 'src/app/modele/wspolne/responseBazowy';
import { SerwisBazowy } from '../bazowy.service';
import { AuthService } from '../ogolne/auth.service';
import { ErrorService } from '../ogolne/error.service';
import { LoadingService } from '../ogolne/loading.service';

@Injectable({
  providedIn: 'root',
})
export class EwidencjaService extends SerwisBazowy {
  constructor(
    httpClient: HttpClient,
    authService: AuthService,
    errorService: ErrorService,
    loaderService: LoadingService,
    translate: TranslateService
  ) {
    super(httpClient, authService, errorService, loaderService, translate);
  }

  public czyIstniejeImport(model: IdModel) {
    return this.wykonajAkcje<
      IdModel,
      PojedynczyElementResponse<CzyIstniejeImportEwidencjiRezultat>
    >(this.AdresApi + 'Ewidencja/CzyIstniejeImport', model);
  }

  public importuj(model: ImportujEwidencjeModel) {
    return this.wykonajAkcje<
      ImportujEwidencjeModel,
      PojedynczyElementResponse<ImportujEwidencjeRezultat>
    >(this.AdresApi + 'Ewidencja/Importuj', model, 15 * 60 * 1000);
  }

  public mapujEwidencje(model: MapujEwidencjeModel) {
    return this.wykonajAkcje<MapujEwidencjeModel, ResponseBazowy>(
      this.AdresApi + 'Ewidencja/MapujEwidencje',
      model,
      15 * 60 * 1000
    );
  }

  public pobierzEwidencje(
    model: RejestrFiltrowanyRequest<PobierzEwidencjeFiltry>,
    zLadowaczem: boolean
  ) {
    if (zLadowaczem) {
      return this.wykonajAkcje<
        RejestrFiltrowanyRequest<PobierzEwidencjeFiltry>,
        PojedynczyElementResponse<PobierzEwidencjeRezultat>
      >(this.AdresApi + 'Ewidencja/PobierzEwidencje', model);
    } else {
      return this.wykonajAkcjeBezLadowacza<
        RejestrFiltrowanyRequest<PobierzEwidencjeFiltry>,
        PojedynczyElementResponse<PobierzEwidencjeRezultat>
      >(this.AdresApi + 'Ewidencja/PobierzEwidencje', model);
    }
  }

  public edytujMapowanie(model: EdytujMapowanieModel) {
    return this.wykonajAkcje<
      EdytujMapowanieModel,
      PojedynczyElementResponse<EdytujMapowaniaRezultat>
    >(this.AdresApi + 'Ewidencja/EdytujMapowanie', model);
  }

  public pobierzMapowaniaDoEdycji(model: IdModel) {
    return this.wykonajAkcje<
      IdModel,
      PojedynczyElementResponse<MapowaniaDoEdycji>
    >(this.AdresApi + 'Ewidencja/PobierzMapowaniaDoEdycji', model);
  }

  public wyczyscEwidencje(model: IdModel) {
    return this.wykonajAkcje<IdModel, ResponseBazowy>(
      this.AdresApi + 'Ewidencja/WyczyscEwidencje',
      model
    );
  }

  public dodajKomentarzDoRekorduEwidencji(model: DodajKomentarzDoEwidencji) {
    return this.wykonajAkcje<DodajKomentarzDoEwidencji, ResponseBazowy>(
      this.AdresApi + 'Ewidencja/DodajKomentarzDoRekorduEwidencji',
      model
    );
  }
}
