import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { PobierzDaneDoWyboruRaportuModel } from 'src/app/modele/projekty/arkuszSpisu/pobierzDaneDoWyboruRaportuModel';
import { PobierzDaneDoWyboruRaportuRezultat } from 'src/app/modele/projekty/arkuszSpisu/pobierzDaneDoWyboruRaportuRezultat';
import { PomieszczenieDoArkusza } from 'src/app/modele/projekty/arkuszSpisu/pomieszczenieDoArkusza';
import { RodzajRaportu } from 'src/app/modele/projekty/arkuszSpisu/rodzajRaportu';
import { WygenerujArkuszModel } from 'src/app/modele/projekty/arkuszSpisu/wygenerujArkuszModel';
import { Routing } from 'src/app/modele/wspolne/routing';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { ArkuszSpisuService } from 'src/app/serwisy/projekty/arkusz-spisu.service';

@Component({
  selector: 'app-wybor-raportu',
  templateUrl: './wybor-raportu.component.html',
  styleUrls: ['./wybor-raportu.component.css']
})
export class WyborRaportuComponent extends BazowyComponent implements OnInit {

  idProjektu: string;
  idLokalizacji: string;
  idPomieszczenia: string;
  miejsceWywolania: string;
  czyZaladowanoDane: boolean;
  adresLokalizacji: string;
  daneDoEkranu: PobierzDaneDoWyboruRaportuRezultat = new PobierzDaneDoWyboruRaportuRezultat();
  model: WygenerujArkuszModel = new WygenerujArkuszModel();
  czyCalaLokalizacja: boolean;
  czyPokazanoPodmenu: boolean;
  pomieszczenia: Array<PomieszczenieDoArkusza> = new Array();

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public arkuszSpisuSerwis: ArkuszSpisuService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translateSerwis);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.idLokalizacji = this.activatedRoute.snapshot.paramMap.get('idLokalizacji');
      this.idProjektu = this.activatedRoute.snapshot.paramMap.get('idProjektu');
      this.idPomieszczenia = this.activatedRoute.snapshot.paramMap.get('idPomieszczenia');
      this.miejsceWywolania = this.activatedRoute.snapshot.paramMap.get('miejsceWywolania');


      this.pobierzDaneDoEkranu();
    });
  }

  pobierzDaneDoEkranu() {
    var model = new PobierzDaneDoWyboruRaportuModel();
    model.idLokalizacji = this.idLokalizacji;
    model.idProjektu = this.idProjektu;
    model.idPomieszczenia = this.idPomieszczenia;

    this.arkuszSpisuSerwis.pobierzDaneDoWyboruRaportu(model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.daneDoEkranu = rezultat.element;

          this.model.idLokalizacji = this.idLokalizacji;
          this.model.idProjektu = this.idProjektu;

          this.daneDoEkranu.pomieszczenia.forEach(p => {
            var pomieszczenie = new PomieszczenieDoArkusza();
            pomieszczenie.czyWybrano = p.id == this.idPomieszczenia;
            pomieszczenie.id = p.id;
            pomieszczenie.nazwa = p.nazwa;
            pomieszczenie.pietro = p.pietro;

            this.pomieszczenia.push(pomieszczenie);

            if (this.miejsceWywolania == "3" || this.miejsceWywolania == "6") {
              this.model.rodzajRaportu = RodzajRaportu.nadwyzki;
            }
            else if (this.miejsceWywolania == "4") {
              this.model.rodzajRaportu = RodzajRaportu.niedobory;
            }
            else {
              this.model.rodzajRaportu = RodzajRaportu.arkuszSpisu;
            }

            this.wybierzRodzajRaportu(this.model.rodzajRaportu);
            this.model.idPomieszczenia = this.idPomieszczenia;
          });
          this.czyZaladowanoDane = true;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }

  calaLokalizacja(e) {
    if (e.target.checked) {
      this.pomieszczenia.forEach(p => {
        p.czyWybrano = true;
      });
    }
    else {
      this.pomieszczenia.forEach(p => {
        p.czyWybrano = false;
      });
    }
  }

  pomieszczenieZmiana(e) {
    if (!e.target.checked) {
      this.czyCalaLokalizacja = false;
    }
  }

  pokazPodmenu() {
    this.czyPokazanoPodmenu = !this.czyPokazanoPodmenu;
  }

  wybierzRodzajRaportu(rodzaj: RodzajRaportu) {
    if (rodzaj == RodzajRaportu.niedobory) {
      this.czyCalaLokalizacja = true;
    }
    else {
      if (this.pomieszczenia.filter(m => !m.czyWybrano).length != 0) {
        this.czyCalaLokalizacja = false;
      }
    }
    this.model.rodzajRaportu = rodzaj;
  }

  dalej() {
    if (this.model.rodzajRaportu != RodzajRaportu.niedobory && this.pomieszczenia.filter(m => m.czyWybrano).length == 0) {
      this.wyswietlKomunikat("Nie wybrano pomieszczeń do raportu", true);
      return;
    }

    this.model.idPomieszczen = this.pomieszczenia.filter(m => m.czyWybrano).map(m => m.id);
    if (this.idPomieszczenia) {
      this.router.navigate([this.miejsceWywolania, Routing.ArkuszSpisu, this.idProjektu, this.idLokalizacji, this.idPomieszczenia], { state: { data: this.model } });
    }
    else {
      this.router.navigate([this.miejsceWywolania, Routing.ArkuszSpisu, this.idProjektu, this.idLokalizacji], { state: { data: this.model } });
    }

  }

  cofnij() {
    if (this.miejsceWywolania == "1") {
      this.router.navigate([Routing.PomieszczeniaLokalizacji, this.idProjektu, this.idLokalizacji]);
    }
    if (this.miejsceWywolania == "2") {
      this.router.navigate([Routing.SpisaneSrodkiLokalizacji, this.idProjektu, this.idLokalizacji]);
    }
    if (this.miejsceWywolania == "3") {
      this.router.navigate([Routing.NadwyzkiLokalizacji, this.idProjektu, this.idLokalizacji]);
    }
    if (this.miejsceWywolania == "4") {
      this.router.navigate([Routing.NiedoboryLokalizacji, this.idProjektu, this.idLokalizacji]);
    }
    if (this.miejsceWywolania == "5") {
      this.router.navigate([Routing.SpisaneSrodkiPomieszczenia, this.idProjektu, this.idLokalizacji, this.idPomieszczenia]);
    }
    if (this.miejsceWywolania == "6") {
      this.router.navigate([Routing.NadwyzkiPomieszczenia, this.idProjektu, this.idLokalizacji, this.idPomieszczenia]);
    }

  }
}
