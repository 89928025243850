import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { KonfiguracjaPomieszczen } from 'src/app/modele/projekty/konfiguracjaPomieszczen/konfiguracjaPomieszczen';
import { DodajSlownikModel } from 'src/app/modele/projekty/slowniki/dodajSlownikModel';
import { EdytujSlownikModel } from 'src/app/modele/projekty/slowniki/edytujSlownikModel';
import { FiltrSlownikowProjektu } from 'src/app/modele/projekty/slowniki/filtrSlownikowProjektu';
import { SlownikiProjektu } from 'src/app/modele/projekty/slowniki/slownikiProjektu';
import { SlownikNaLiscie } from 'src/app/modele/projekty/slowniki/slownikNaLiscie';
import { SzczegolySlownika } from 'src/app/modele/projekty/slowniki/szczegolySlownika';
import { UsunSlownikRezultat } from 'src/app/modele/projekty/slowniki/usunSlownikRezultat';
import { ComboBox } from 'src/app/modele/wspolne/comboBox';
import { FiltrSzukaj } from 'src/app/modele/wspolne/filtrSzukaj';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { ListaWynikowResponse } from 'src/app/modele/wspolne/listaWynikowResponse';
import { PojedynczyElementResponse } from 'src/app/modele/wspolne/pojedynczyElement';
import { ResponseBazowy } from 'src/app/modele/wspolne/responseBazowy';
import { SerwisBazowy } from '../bazowy.service';
import { AuthService } from '../ogolne/auth.service';
import { ErrorService } from '../ogolne/error.service';
import { LoadingService } from '../ogolne/loading.service';

@Injectable({
  providedIn: 'root'
})
export class SlownikiService extends SerwisBazowy {

  constructor(
    httpClient: HttpClient,
    authService: AuthService,
    errorService: ErrorService,
    loaderService: LoadingService,
    translate: TranslateService) {
    super(httpClient, authService, errorService, loaderService, translate);
  }

  public pobierzSlownikiJednokolumnowe(model: IdModel) {
    return this.wykonajAkcje<IdModel, ListaWynikowResponse<ComboBox>>
      (this.AdresApi + "Slowniki/PobierzSlownikiJednokolumnowe", model);
  }

  public pobierzSlownikiWielokolumnowe(model: IdModel) {
    return this.wykonajAkcje<IdModel, ListaWynikowResponse<ComboBox>>
      (this.AdresApi + "Slowniki/PobierzSlownikiWielokolumnowe", model);
  }

  public dodajSlownik(model: DodajSlownikModel) {
    return this.wykonajAkcje<DodajSlownikModel, ResponseBazowy>
      (this.AdresApi + "Slowniki/DodajSlownik", model);
  }

  public edytujSlownik(model: EdytujSlownikModel) {
    return this.wykonajAkcje<EdytujSlownikModel, ResponseBazowy>
      (this.AdresApi + "Slowniki/EdytujSlownik", model);
  }

  public pobierzSzczegolySlownika(model: IdModel) {
    return this.wykonajAkcje<IdModel, PojedynczyElementResponse<SzczegolySlownika>>
      (this.AdresApi + "Slowniki/PobierzSzczegolySlownika", model);
  }

  public pobierzSlownikiDomyslne(model: FiltrSzukaj, zLadowaczem: boolean) {
    if (zLadowaczem) {
      return this.wykonajAkcje<FiltrSzukaj, ListaWynikowResponse<SlownikNaLiscie>>
        (this.AdresApi + "Slowniki/PobierzSlownikiDomyslne", model);
    }
    else {
      return this.wykonajAkcjeBezLadowacza<FiltrSzukaj, ListaWynikowResponse<SlownikNaLiscie>>
        (this.AdresApi + "Slowniki/PobierzSlownikiDomyslne", model);
    }

  }

  public pobierzSlownikiProjektu(model: FiltrSlownikowProjektu) {
    return this.wykonajAkcje<FiltrSlownikowProjektu, PojedynczyElementResponse<SlownikiProjektu>>
      (this.AdresApi + "Slowniki/PobierzSlownikiProjektu", model);
  }

  public usunSlownik(model: IdModel) {
    return this.wykonajAkcje<IdModel, PojedynczyElementResponse<UsunSlownikRezultat>>
      (this.AdresApi + "Slowniki/UsunSlownik", model);
  }

  public sprawdzCzyWielokolumnowy(model: DodajSlownikModel) {
    return this.wykonajAkcje<DodajSlownikModel, PojedynczyElementResponse<boolean>>
      (this.AdresApi + "Slowniki/SprawdzCzyWielokolumnowy", model);
  }
}
