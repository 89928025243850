import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { PobierzDaneDoGenerowaniaRaportuModel } from 'src/app/modele/projekty/arkuszSpisu/pobierzDaneDoGenerowaniaRaportuModel';
import { PobierzDaneDoWyboruRaportuModel } from 'src/app/modele/projekty/arkuszSpisu/pobierzDaneDoWyboruRaportuModel';
import { PobierzDaneDoWyboruRaportuRezultat } from 'src/app/modele/projekty/arkuszSpisu/pobierzDaneDoWyboruRaportuRezultat';
import { PomieszczenieDoArkusza } from 'src/app/modele/projekty/arkuszSpisu/pomieszczenieDoArkusza';
import { RodzajRaportu } from 'src/app/modele/projekty/arkuszSpisu/rodzajRaportu';
import { WygenerujArkuszModel } from 'src/app/modele/projekty/arkuszSpisu/wygenerujArkuszModel';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { Routing } from 'src/app/modele/wspolne/routing';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { ArkuszSpisuService } from 'src/app/serwisy/projekty/arkusz-spisu.service';

@Component({
  selector: 'app-generowanie-arkusza-spisu',
  templateUrl: './generowanie-arkusza-spisu.component.html',
  styleUrls: ['./generowanie-arkusza-spisu.component.css'],
})
export class GenerowanieArkuszaSpisuComponent
  extends BazowyComponent
  implements OnInit
{
  idProjektu: string;
  idLokalizacji: string;
  idPomieszczenia: string;
  miejsceWywolania: string;
  czyZaladowanoDane: boolean;
  adresLokalizacji: string;
  daneDoEkranu: PobierzDaneDoWyboruRaportuRezultat =
    new PobierzDaneDoWyboruRaportuRezultat();
  model: WygenerujArkuszModel = new WygenerujArkuszModel();
  czyCalaLokalizacja: boolean;
  czyPokazanoPodmenu: boolean;

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public arkuszSpisuSerwis: ArkuszSpisuService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translateSerwis
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.idLokalizacji =
        this.activatedRoute.snapshot.paramMap.get('idLokalizacji');
      this.idProjektu = this.activatedRoute.snapshot.paramMap.get('idProjektu');
      this.idPomieszczenia =
        this.activatedRoute.snapshot.paramMap.get('idPomieszczenia');
      this.miejsceWywolania =
        this.activatedRoute.snapshot.paramMap.get('miejsceWywolania');

      this.model = history.state.data;

      if (this.model == undefined) {
        if (this.idPomieszczenia) {
          this.router.navigate([
            Routing.WyborRaportu,
            this.idProjektu,
            this.idLokalizacji,
            this.idPomieszczenia,
          ]);
        } else {
          this.router.navigate([
            Routing.WyborRaportu,
            this.idProjektu,
            this.idLokalizacji,
          ]);
        }
        return;
      }

      this.pobierzDaneDoEkranu();
    });
  }

  pobierzDaneDoEkranu() {
    var model = new PobierzDaneDoGenerowaniaRaportuModel();
    model.idLokalizacji = this.idLokalizacji;
    model.idProjektu = this.idProjektu;
    model.idPomieszczen = this.model.idPomieszczen;
    model.rodzajRaportu = this.model.rodzajRaportu;

    this.arkuszSpisuSerwis
      .pobierzDaneDoGenerowaniaRaportu(model)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.adresLokalizacji = rezultat.element.adresLokalizacji;
          this.model.klient = rezultat.element.klient;
          this.model.lokalizacja = rezultat.element.adresLokalizacji;
          this.model.przewodniczacyZespolu =
            rezultat.element.przewodniczacyZespolu;
          this.model.spisRozpoczetoData = rezultat.element.spisRozpoczetoData;
          this.model.spisRozpoczetoGodzina =
            rezultat.element.spisRozpoczetoGodzina;
          this.model.spisZakonczonoData = rezultat.element.spisZakonczonoData;
          this.model.spisZakonczonoGodzina =
            rezultat.element.spisZakonczonoGodzina;
          this.model.uwagi = rezultat.element.uwagi;
          this.model.kolumny = rezultat.element.kolumny;

          if (this.idPomieszczenia) {
            this.model.czyWywolanoZPoziomuPomieszczenia = true;
          }

          this.model.czyDrukowacMpkLokalizacji = true;

          this.czyZaladowanoDane = true;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  pobierzNazweRaportu() {
    switch (this.model.rodzajRaportu) {
      case RodzajRaportu.arkuszSpisu:
        return 'spisu';
      case RodzajRaportu.niedobory:
        return 'niedoborów';
      case RodzajRaportu.nadwyzki:
        return 'nadwyżek';
    }
  }

  generuj() {
    this.arkuszSpisuSerwis.generujRaport(this.model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.bledyWalidacji = null;
        var a = document.createElement('a');
        a.target = '_blank';
        a.href = 'data:application/pdf;base64,' + rezultat.element.plik.plik;
        a.download = rezultat.element.plik.nazwaPliku;
        a.click();
        return;
      }
      if (this.bladModelu(rezultat)) {
        this.bledyWalidacji = rezultat.bledyModelu;
        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  anuluj() {
    if (this.idPomieszczenia) {
      this.router.navigate([
        this.miejsceWywolania,
        Routing.WyborRaportu,
        this.idProjektu,
        this.idLokalizacji,
        this.idPomieszczenia,
      ]);
    } else {
      this.router.navigate([
        this.miejsceWywolania,
        Routing.WyborRaportu,
        this.idProjektu,
        this.idLokalizacji,
      ]);
    }
  }
}
