import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DaneZalogowanegoUzytkownika } from 'src/app/modele/logowanie/daneZalogowanegoUzytkownika';
import { EdytujDaneKontaModel } from 'src/app/modele/profil/edytujDaneKontaModel';
import { PobierzDaneKontaRezultat } from 'src/app/modele/profil/pobierzDaneKontaRezultat';
import { UzupelnijDaneKontaModel } from 'src/app/modele/profil/uzupelnijDaneKontaModel';
import { WeryfikujKodSmsDoZmianyNumeruTelefonuFormularz } from 'src/app/modele/profil/weryfikuj-kod-sms-do-zmiany-numeru-telefonu-formularz.model';
import { WeryfikujZmianeAdresuEmailFormularz } from 'src/app/modele/profil/weryfikuj-zmiane-adresu-email-formularz.model';
import { ZmienAdresEmailFormularz } from 'src/app/modele/profil/zmien-adres-email-formularz.model';
import { ZmienNumerTelefonuFormularz } from 'src/app/modele/profil/zmien-numer-telefonu-formularz.model';
import { ZmienJezykModel } from 'src/app/modele/profil/zmienJezykModel';
import { PojedynczyElementResponse } from 'src/app/modele/wspolne/pojedynczyElement';
import { RequestBazowy } from 'src/app/modele/wspolne/requestBazowy';
import { ResponseBazowy } from 'src/app/modele/wspolne/responseBazowy';
import { SerwisBazowy } from 'src/app/serwisy/bazowy.service';
import { AuthService } from '../ogolne/auth.service';
import { ErrorService } from '../ogolne/error.service';
import { LoadingService } from '../ogolne/loading.service';

@Injectable({
  providedIn: 'root'
})
export class ProfilService extends SerwisBazowy {

  constructor(
    httpClient: HttpClient,
    authService: AuthService,
    errorService: ErrorService,
    loaderService: LoadingService,
    translate: TranslateService) {
    super(httpClient, authService, errorService, loaderService, translate);
  }

  public pobierzDaneKonta() {
    return this.wykonajAkcjeBezModelu<PojedynczyElementResponse<PobierzDaneKontaRezultat>>(`${this.AdresApi}${ProfilAdresy.Controller}/${ProfilAdresy.PobierzDaneKonta}`);
  }

  public edytujDaneKonta(model: EdytujDaneKontaModel) {
    return this.wykonajAkcje<EdytujDaneKontaModel, PojedynczyElementResponse<PobierzDaneKontaRezultat>>(`${this.AdresApi}${ProfilAdresy.Controller}/${ProfilAdresy.EdytujDaneKonta}`, model);
  }

  public zmienAdresEmailIWyslijPotwierdzenie(formularz: ZmienAdresEmailFormularz) {
    return this.wykonajAkcje<ZmienAdresEmailFormularz, ResponseBazowy>(`${this.AdresApi}${ProfilAdresy.Controller}/${ProfilAdresy.ZmienAdresEmailIWyslijPotwierdzenie}`, formularz);
  }

  public WeryfikujZmianeAdresuEmail(formularz: WeryfikujZmianeAdresuEmailFormularz) {
    return this.wykonajAkcje<WeryfikujZmianeAdresuEmailFormularz, PojedynczyElementResponse<DaneZalogowanegoUzytkownika>>(`${this.AdresApi}${ProfilAdresy.Controller}/${ProfilAdresy.WeryfikujZmianeAdresuEmail}`, formularz);
  }

  public ZmienNumerTelefonuIWyslijKodSms(formularz: ZmienNumerTelefonuFormularz) {
    return this.wykonajAkcje<ZmienNumerTelefonuFormularz, ResponseBazowy>(`${this.AdresApi}${ProfilAdresy.Controller}/${ProfilAdresy.ZmienNumerTelefonuIWyslijKodSms}`, formularz);
  }

  public WeryfikujKodSmsDoZmianyNumeruTelefonu(formularz: WeryfikujKodSmsDoZmianyNumeruTelefonuFormularz) {
    return this.wykonajAkcje<WeryfikujKodSmsDoZmianyNumeruTelefonuFormularz, ResponseBazowy>(`${this.AdresApi}${ProfilAdresy.Controller}/${ProfilAdresy.WeryfikujKodSmsDoZmianyNumeruTelefonu}`, formularz);
  }
  public uzupelnijDaneKonta(model: UzupelnijDaneKontaModel) {
    return this.wykonajAkcje<UzupelnijDaneKontaModel, ResponseBazowy>(`${this.AdresApi}${ProfilAdresy.Controller}/${ProfilAdresy.UzupelnijDaneKonta}`, model);
  }

  public usunKonto() {
    return this.wykonajAkcjeBezModelu<ResponseBazowy>(`${this.AdresApi}${ProfilAdresy.Controller}/${ProfilAdresy.UsunKonto}`);
  }

  public zmienJezyk(model: ZmienJezykModel) {
    return this.wykonajAkcje<ZmienJezykModel, PojedynczyElementResponse<DaneZalogowanegoUzytkownika>>
      (`${this.AdresApi}${ProfilAdresy.Controller}/${ProfilAdresy.ZmienJezyk}`, model);
  }
}

enum ProfilAdresy {
  Controller = "Profil",
  ZmienAdresEmailIWyslijPotwierdzenie = "ZmienAdresEmailIWyslijPotwierdzenie",
  WeryfikujZmianeAdresuEmail = "WeryfikujZmianeAdresuEmail",
  ZmienNumerTelefonuIWyslijKodSms = "ZmienNumerTelefonuIWyslijKodSms",
  WeryfikujKodSmsDoZmianyNumeruTelefonu = "WeryfikujKodSmsDoZmianyNumeruTelefonu",
  PobierzDaneKonta = "PobierzDaneKonta",
  EdytujDaneKonta = "EdytujDaneKonta",
  UzupelnijDaneKonta = "UzupelnijDaneKonta",
  UsunKonto = "UsunKonto",
  ZmienJezyk = "ZmienJezyk"
}