import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { DodajSlownikModel } from 'src/app/modele/projekty/slowniki/dodajSlownikModel';
import { Zalacznik } from 'src/app/modele/wspolne/zalacznik';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { SlownikiService } from 'src/app/serwisy/projekty/slowniki.service';

@Component({
  selector: 'app-dodaj-slownik-modal',
  templateUrl: './dodaj-slownik-modal.component.html',
  styleUrls: ['./dodaj-slownik-modal.component.css']
})
export class DodajSlownikModalComponent extends BazowyComponent implements OnInit {

  idProjektu: string;
  model: DodajSlownikModel = new DodajSlownikModel();
  czyWielokolumnowy: boolean;

  constructor(
    public router: Router,
    modalService: NgbModal,
    public activeModal: NgbActiveModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public slownikiSerwis: SlownikiService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translateSerwis);
  }

  ngOnInit(): void {
    setTimeout(() => {
    });
  }

  zapisz() {
    this.slownikiSerwis.dodajSlownik(this.model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.wyswietlKomunikat("Słownik został dodany");
          this.activeModal.close(true);
          return;
        }
        if (this.bladModelu(rezultat)) {
          this.bledyWalidacji = rezultat.bledyModelu;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }

  wybierzZalacznikZDysku(event: any) {
    var plik = event.target.files[0];
    this.wybierzZalacznik(plik);

    event.target.value = null;
    return false;
  }

  wybierzZalacznik(plik: File) {
    if (plik) {

      var reader = new FileReader();
      reader.readAsDataURL(plik);
      reader.onload = () => {
        var zalacznik = new Zalacznik();
        zalacznik.nazwaPliku = plik.name;
        zalacznik.plik = this.usunZbedneZnakiZPliku(reader.result.toString());
        if (zalacznik.plik == "data:") {
          return;
        }
        zalacznik.dataModyfikacji = new Date(plik.lastModified);
        zalacznik.format = plik.name.split('.').pop().toLowerCase();

        this.model.zalacznik = zalacznik;
        this.model.projektId = this.idProjektu;

        this.sprawdzCzySlownikWielokolumnowy();
      };
    }
  }

  usunZbedneZnakiZPliku(base64: string) {
    if (base64.includes(",")) {
      return base64.substring(base64.indexOf(",") + 1);
    }
    return base64;
  }

  usunZalacznik() {
    this.model.zalacznik = undefined;
    this.czyWielokolumnowy = false;
  }

  sprawdzCzySlownikWielokolumnowy() {
    this.czyWielokolumnowy = false;
    this.slownikiSerwis.sprawdzCzyWielokolumnowy(this.model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.czyWielokolumnowy = rezultat.element;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }

}
