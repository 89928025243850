<div class="container-fluid d-none d-md-block pt-4" *ngIf="czyZaladowanoDane">
    <div class="box__scroll">
        <div class="box__scroll--top d-flex justify-content-between align-items-center mb-4">
            <span class="ft__03">{{ 'projekty.naglowek' | translate }}</span>
            <div class="input_container" *ngIf="czyAdmin()">
                <input class="pr-4" type="text" placeholder="nazwa projektu lub klienta" style="width: 290px;"
                    [(ngModel)]="wyszukiwanie.filtry.wartoscSzukaj" (keyup)="filtrujPoTekscie()">
                <button class="btn__input--search"></button>
            </div>
        </div>
        <div class="tableFixHead tableFixHead--2" *ngIf="czySaWynikiNaLiscie(projekty)">
            <table class="table">
                <thead>
                    <tr>
                        <th>
                            Nazwa projektu
                            <button class="btn__input--sort--bottom--gray"
                                [ngClass]="pobierzKierunekSortowania('NazwaProjektu')"
                                (click)="zmienKierunekSortowania('NazwaProjektu')"></button>
                        </th>
                        <th>
                            Nazwa klienta
                            <button class="btn__input--sort--bottom--gray"
                                [ngClass]="pobierzKierunekSortowania('NazwaKlienta')"
                                (click)="zmienKierunekSortowania('NazwaKlienta')"></button>
                        </th>
                        <th>
                            Data utworzenia
                            <button class="btn__input--sort--bottom--gray"
                                [ngClass]="pobierzKierunekSortowania('DataUtworzenia')"
                                (click)="zmienKierunekSortowania('DataUtworzenia')"></button>
                        </th>
                        <th>
                            Ostatnia aktualizacja
                            <button class="btn__input--sort--bottom--gray"
                                [ngClass]="pobierzKierunekSortowania('DataAktualizacji')"
                                (click)="zmienKierunekSortowania('DataAktualizacji')"></button>
                        </th>
                        <th>
                            Status
                            <button class="btn__input--sort--bottom--gray"
                                [ngClass]="pobierzKierunekSortowania('Status')"
                                (click)="zmienKierunekSortowania('Status')"></button>
                        </th>
                        <th>
                            Akcje
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let projekt of projekty" class="pointer project-row"
                        (click)="przejdzDoSzczegolow(projekt.id)">
                        <td>
                            <div class="ico__project">{{projekt.nazwaProjektu}}</div>
                        </td>
                        <td>
                            {{projekt.nazwaKlienta}}
                        </td>
                        <td>
                            {{formatujDate(projekt.dataUtworzenia)}}
                        </td>
                        <td>
                            {{formatujDate(projekt.dataAktualizacji)}}
                        </td>
                        <td>
                            {{pobierzStatus(projekt)}}
                        </td>
                        <td>
                            <button class="btn__next"></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="no-results text-center ft__no-results" *ngIf="!czySaWynikiNaLiscie(projekty)">
            <div *ngIf="czyAdmin()">Brak projektów<br>dla wybranych filtrów</div>
            <div *ngIf="!czyAdmin()">Brak projektów</div>
        </div>
    </div>

    <div class="paginate float-right d-flex align-items-center pt-4 pb-2 pt-lg-4">
        <div *ngIf="czySaWynikiNaLiscie(projekty)" class="paginate float-right d-flex align-items-center">
            <div>Pokaż wyniki</div>
            <select class="paginate__select mx-2" name="" id="" (change)="zmienRozmiarTabeli()"
                [(ngModel)]="rozmiarTabeli">
                <option [ngValue]="10">10</option>
                <option [ngValue]="25">25</option>
                <option [ngValue]="50">50</option>
            </select>
            <div class="px-2">{{pobierzNumerRekorduPoczatek()}}-{{pobierzNumerRekorduKoniec()}} z
                {{calkowitaLiczbaElementow}}</div>
            <div class="d-flex">
                <button class="btn__left" *ngIf="this.wyszukiwanie.stronicowanie.strona>1"
                    (click)="zmienStrone(-1)"></button>
                <div class="px-2">{{this.wyszukiwanie.stronicowanie.strona}}</div>
                <button class="btn__right" *ngIf="czyJestWiecejRekordow" (click)="zmienStrone(1)"></button>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
    <div class="pb-4 text-right" *ngIf="czyAdmin()">
        <button class="btn__add" (click)="dodajProjekt()"></button>
    </div>
</div>


<div class="container-fluid d-md-none pb-5" *ngIf="czyZaladowanoDane">

    <div class="filter-box d-flex justify-content-between pt-3">
        <span class="ft__03">{{ 'projekty.naglowek' | translate }}</span>
        <div class="dropdown person_desktop text-right">
            <a class="btn__input--sort dropdown-toggle" [routerLink]="" (click)="pokazMenuSortowania()" role="button"
                id="dropdownSort" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                (clickOutside)="kliknijPozaMenuSortowania($event)">
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownSort"
                *ngIf="czyPokazacMenuSortowanie" style="display: block;position: absolute;">
                <a class="dropdown-item ico__project" [routerLink]="" style="margin-left: 23px;margin-right: 23px;"
                    (click)="zmienKierunekSortowania('NazwaProjektu')">Nazwa
                    projektu</a>
                <a class="dropdown-item ico__account" [routerLink]=""
                    (click)="zmienKierunekSortowania('NazwaKlienta')">Nazwa klienta</a>
                <a class="dropdown-item ico__data" [routerLink]=""
                    (click)="zmienKierunekSortowania('DataUtworzenia')">Data utworzenia</a>
                <a class="dropdown-item ico__update" [routerLink]=""
                    (click)="zmienKierunekSortowania('DataAktualizacji')">Ostatnia aktualizacja</a>
                <a class="dropdown-item ico__status" [routerLink]=""
                    (click)="zmienKierunekSortowania('Status')">Status</a>
            </div>
        </div>
    </div>

    <div class="filter-select pt-3 pb-2 d-flex flex-column" *ngIf="pobierzKolumneSortowania()">
        <div class="d-flex pb-2">
            <div class="filter_item">
                <button class="btn__x" (click)="wyczyscSortowanieMobile()"></button>
                <span class="fs__sort">Sortowanie: {{pobierzKolumneSortowania()}}</span>
            </div>
            <button class="btn__input--sort--bottom ml-3" [ngClass]="pobierzKierunekSortowaniaMobile()"
                (click)="zmienKierunekSortowaniaMobile()"></button>
        </div>

    </div>


    <div class="input_container mb-3" *ngIf="czyAdmin()">
        <input class="pr-4" type="text" placeholder="nazwa projektu lub klienta"
            [(ngModel)]="wyszukiwanie.filtry.wartoscSzukaj" (keyup)="filtrujPoTekscie()">
        <button class="btn__input--clear" (click)="wyczyscFiltr()" *ngIf="wyszukiwanie.filtry.wartoscSzukaj"></button>
    </div>

    <div class="no-results text-center ft__no-results" *ngIf="!czySaWynikiNaLiscie(projekty)">
        <div *ngIf="czyAdmin()">Brak projektów<br>dla wybranych filtrów</div>
        <div *ngIf="!czyAdmin()">Brak projektów</div>

    </div>

    <div class="box__mobile mb-3 pointer" *ngFor="let projekt of projekty" (click)="przejdzDoSzczegolow(projekt.id)">
        <div class="d-flex justify-content-between align-items-center pb-2">
            <span class="fs__pname">{{projekt.nazwaProjektu}}</span>
            <button class="btn__next"></button>
        </div>
        <div class="d-flex justify-content-between align-items-center pb-2 pr-3 mb-2">
            <span class="ico__account--fix fs__pname--2">Nazwa klienta</span>
            <span class="fs__pname--3">{{projekt.nazwaKlienta}}</span>
        </div>
        <div class="d-flex justify-content-between align-items-center pb-2 pr-3 mb-2">
            <span class="ico__data--fix fs__pname--2">Data utworzenia</span>
            <span class="fs__pname--3">{{formatujDate(projekt.dataUtworzenia)}}</span>
        </div>
        <div class="d-flex justify-content-between align-items-center pb-2 pr-3 mb-2">
            <span class="ico__update--fix fs__pname--2">Ostatnia aktualizacja</span>
            <span class="fs__pname--3">{{formatujDate(projekt.dataAktualizacji)}}</span>
        </div>
        <div class="d-flex justify-content-between align-items-center pb-2 pr-3 mb-2">
            <span class="ico__status--fix fs__pname--2">Status</span>
            <span class="fs__pname--3">{{pobierzStatus(projekt)}}</span>
        </div>
    </div>


    <div class="paginate float-right d-flex align-items-center pb-2 pt-lg-4">
        <div *ngIf="czySaWynikiNaLiscie(projekty)" class="paginate float-right d-flex align-items-center">
            <div>Pokaż wyniki</div>
            <select class="paginate__select mx-2" name="" id="" (change)="zmienRozmiarTabeli()"
                [(ngModel)]="rozmiarTabeli">
                <option [ngValue]="10">10</option>
                <option [ngValue]="25">25</option>
                <option [ngValue]="50">50</option>
            </select>
            <div class="px-2">{{pobierzNumerRekorduPoczatek()}}-{{pobierzNumerRekorduKoniec()}} z
                {{calkowitaLiczbaElementow}}</div>
            <div class="d-flex">
                <button class="btn__left" *ngIf="this.wyszukiwanie.stronicowanie.strona>1"
                    (click)="zmienStrone(-1)"></button>
                <div class="px-2">{{this.wyszukiwanie.stronicowanie.strona}}</div>
                <button class="btn__right" *ngIf="czyJestWiecejRekordow" (click)="zmienStrone(1)"></button>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
    <div class="pb-3 text-right" *ngIf="czyAdmin()">
        <button class="btn__add" (click)="dodajProjekt()"></button>
    </div>

</div>
<div class="fixed_tabs" *ngIf="czyZaladowanoDane && czyAdmin()">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
            <a class="nav-link fs__tab  ico__tab--active pointer" id="home-tab" data-toggle="tab" [routerLink]=""
                role="tab" aria-controls="home" aria-selected="true" [ngClass]="wyszukiwanie.filtry.aktywne?'active':''"
                (click)="wybierzAktywneProjekty(true)">Aktywne</a>
        </li>
        <li class="nav-item">
            <a class="nav-link fs__tab ico__tab--archive pointer" id="profile-tab" data-toggle="tab" [routerLink]=""
                role="tab" aria-controls="profile" aria-selected="false"
                [ngClass]="!wyszukiwanie.filtry.aktywne?'active':''"
                (click)="wybierzAktywneProjekty(false)">Archiwalne</a>
        </li>
    </ul>
</div>