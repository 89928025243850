<div class="modal-content" *ngIf="czyZaladowanoDane">
    <div class="modal-body">
        <button aria-label="Close" class="close" data-dismiss="modal" type="button"
            (click)="activeModal.dismiss()"></button>
        <div class="text-center mb-2"></div>

        <div class="fixed_table_container">
            <table>
                <tr>
                    <th *ngFor="let kolumna of kolumnyTabeli; index as i"
                        [ngClass]="i==kolumnaId||i==kolumnaLokalizacji?'nie_pokazuj_kolumny':''">{{kolumna}}
                    </th>
                </tr>
                <tr *ngFor="let wiersz of spisaneSrodki;index as r" class="pointer project-row"
                    (click)="przejdzDoSzczegolowSrodka(wiersz)">
                    <td *ngFor="let wartosc of wiersz; index as i"
                        [ngClass]="i==kolumnaId||i==kolumnaLokalizacji?'nie_pokazuj_kolumny':''">
                        {{wartosc}}
                    </td>
                </tr>
            </table>

        </div>

    </div>
</div>