import { KolumnaDoRaportu } from './kolumnaDoRaportu';
import { RodzajRaportu } from './rodzajRaportu';

export class WygenerujArkuszModel {
  idPomieszczen: Array<string> = new Array();
  idProjektu: string;
  idLokalizacji: string;
  idPomieszczenia: string;
  rodzajRaportu: RodzajRaportu;
  lokalizacja: string;
  przewodniczacyZespolu: string;
  czlonekZespolu: string;
  klient: string;
  przedstawicielKlienta: string;
  spisRozpoczetoData: string;
  spisRozpoczetoGodzina: string;
  spisZakonczonoData: string;
  spisZakonczonoGodzina: string;
  uwagi: string;
  kolumny: Array<KolumnaDoRaportu> = new Array();
  czyWywolanoZPoziomuPomieszczenia: boolean;
  czyDrukowacNazwePomieszczenia: boolean;
  czyDrukowacMpkLokalizacji: boolean;
}
