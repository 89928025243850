<div *ngIf="czyZaladowanoDane">
    <div class="container--main d-none d-md-block">
        <div class="ft__sort text-center pt-4 mt-1 pb-3 mb-1">Raporty - {{daneDoEkranu.adresLokalizacji}}</div>

        <div class="box__scroll py-3">
            <div class="d-flex py-4">
                <div class="chb por ml5px mt-1">
                    <input id="cb_00" type="checkbox" name="aa" value="1" (change)="calaLokalizacja($event)"
                        [(ngModel)]="czyCalaLokalizacja" [disabled]="model.rodzajRaportu==1">
                    <label class="fs__chb" for="cb_00">Cała lokalizacja</label>
                </div>
                <button class="btn__ch " [ngClass]="model.rodzajRaportu==0?'active':''"
                    (click)="wybierzRodzajRaportu(0)">Arkusz spisu</button>
                <button class="btn__ch" [ngClass]="model.rodzajRaportu==1?'active':''" *ngIf="!idPomieszczenia"
                    (click)="wybierzRodzajRaportu(1)">Raport niedoborów</button>
                <button class="btn__ch" [ngClass]="model.rodzajRaportu==2?'active':''"
                    (click)="wybierzRodzajRaportu(2)">Raport nadwyżek</button>
            </div>


            <div class="tableFixHead tableFixHead--3">

                <table class="table table--fix text-left" *ngIf="model.rodzajRaportu!=1">
                    <thead>
                        <tr>
                            <th width="100">
                            </th>
                            <th width="260" class="text-left">
                                Nazwa pomieszczenia
                            </th>
                            <th width="200" class="text-left">
                                Piętro
                            </th>
                            <th>

                            </th>
                        </tr>
                    </thead>
                    <tbody class="fz12">
                        <tr *ngFor="let pomieszczenie of pomieszczenia">
                            <td class="text-left">
                                <div class="chb por">
                                    <input id="cb_{{pomieszczenie.id}}" type="checkbox" name="{{pomieszczenie.id}}"
                                        (change)="pomieszczenieZmiana($event)" [(ngModel)]="pomieszczenie.czyWybrano">
                                    <label class="fs__chb mt-2 pr-0 pl-0" for="cb_{{pomieszczenie.id}}">&nbsp;</label>
                                </div>
                            </td>
                            <td class="text-left">{{pomieszczenie.nazwa}}</td>
                            <td class="text-left">{{pomieszczenie.pietro}}</td>
                            <td class="text-left"></td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>



    </div>
    <!-- mobile -->
    <div class="container-fluid d-md-none">

        <div class="pt-3 mt-1 pb-3">
            <div class="ft__sort text-center">Raporty - {{daneDoEkranu.adresLokalizacji}}</div>
        </div>

        <div class="d-flex justify-content-between pt-2 mb-2">
            <div class="chb por ml5px mt-1">
                <input id="cb_00" type="checkbox" name="aa" value="1" (change)="calaLokalizacja($event)"
                    [(ngModel)]="czyCalaLokalizacja">
                <label class="fs__04" for="cb_00">Cała lokalizacja</label>
            </div>
            <div class="searchBox d-flex flex-wrap por pb-5" *ngIf="czyPokazanoPodmenu">
                <button class="btn__input--clear" (click)="pokazPodmenu(false)"></button>
            </div>
            <div class="input_container mb-4 text-right" *ngIf="!czyPokazanoPodmenu">
                <button class="btn__hamburger" (click)="pokazPodmenu()"></button>
            </div>
        </div>


        <div class="box__mobile box__mobile--form mb-3 pt-4 pb-3 d-flex flex-column" *ngIf="czyPokazanoPodmenu">

            <button class="btn__ch mb-4 py-2 mt-2 " [ngClass]="model.rodzajRaportu==0?'active':''"
                (click)="wybierzRodzajRaportu(0)">Arkusz spisu</button>
            <button class="btn__ch mb-4 py-2" [ngClass]="model.rodzajRaportu==1?'active':''" *ngIf="!idPomieszczenia"
                (click)="wybierzRodzajRaportu(1)">Raport niedoborów</button>
            <button class="btn__ch mb-4 py-2" [ngClass]="model.rodzajRaportu==2?'active':''"
                (click)="wybierzRodzajRaportu(2)">Raport nadwyżek</button>

        </div>

        <hr class="mb-3 mt-1">


        <div *ngIf="model.rodzajRaportu!=1">
            <div class="box__mobile mb-3 pt-4 d-flex" *ngFor="let pomieszczenie of pomieszczenia">
                <div class="chb por fix_w">
                    <input id="cb_{{pomieszczenie.id}}" type="checkbox" name="{{pomieszczenie.id}}"
                        (change)="pomieszczenieZmiana($event)" [(ngModel)]="pomieszczenie.czyWybrano">
                    <label class="fs__chb mt-2 pr-0 pl-0" for="cb_{{pomieszczenie.id}}">&nbsp;</label>
                </div>
                <div style="width: 100%;">
                    <div class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border">
                        <span class="fs__pname--2">Nazwa pomieszczenia</span>
                        <span class="fs__pname--3 text-lg2">{{pomieszczenie.nazwa}}</span>
                    </div>
                    <div class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-1 m_border">
                        <span class="fs__pname--2">Piętro</span>
                        <span class="fs__pname--3 text-lg2">{{pomieszczenie.pietro}}</span>
                    </div>
                </div>
            </div>
        </div>


    </div>
    <div class="bootom__buttons px-4">
        <div class="container--mw600 flex-column flex-sm-row d-flex justify-content-between align-items-center">
            <button class="btn__link order-1 order-sm-0" (click)="cofnij()">Cofnij</button>
            <button class="btn__blue order-0 order-sm-1 px-5" (click)="dalej()">Dalej</button>
        </div>
    </div>
</div>