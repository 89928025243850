import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ResponseBazowy } from 'src/app/modele/wspolne/responseBazowy';
import { ZmienHasloMailModel } from 'src/app/modele/zmianaHasla/zmienHasloMailModel';
import { SerwisBazowy } from '../bazowy.service';
import { AuthService } from '../ogolne/auth.service';
import { ErrorService } from '../ogolne/error.service';
import { LoadingService } from '../ogolne/loading.service';
import { PojedynczyElementResponse } from 'src/app/modele/wspolne/pojedynczyElement';
import { DanePodstawoweDoOswiadczenia } from 'src/app/modele/oswiadczenia/danePodstawoweDoOswiadczenia';
import { AutoryzujEmailDoOswiadczenia } from 'src/app/modele/oswiadczenia/autoryzujEmailDoOswiadczenia';
import { AutoryzujDoOswiadczeniaModel } from 'src/app/modele/oswiadczenia/autoryzujDoOswiadczeniaModel';
import { AutoryzujDoOswiadczeniaRezultat } from 'src/app/modele/oswiadczenia/autoryzujDoOswiadczeniaRezultat';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { Zalacznik } from 'src/app/modele/wspolne/zalacznik';
import { PobierzDaneDoUzupelnieniaDanychPodstawowychUzytkownikaRezultat } from 'src/app/modele/oswiadczenia/pobierzDaneDoUzupelnieniaDanychPodstawowychUzytkownikaRezultat';
import { ZapiszDanePodstawoweUzytkownikaDoOswiadczenia } from 'src/app/modele/oswiadczenia/zapiszDanePodstawoweUzytkownikaDoOswiadczenia';
import { PobierzDaneDoTabeliSrodkowOswiadczenia } from 'src/app/modele/oswiadczenia/pobierzDaneDoTabeliSrodkowOswiadczenia';
import { DaneDoSpisuSrodka } from 'src/app/modele/projekty/spis/daneDoSpisuSrodka';
import { EdytujSrodekModel } from 'src/app/modele/projekty/spis/edytujSrodekModel';
import { EdytujSrodekOswiadczeniaModel } from 'src/app/modele/oswiadczenia/edytujSrodekOswiadczeniaModel';
import { WyslijOswiadczenieModel } from 'src/app/modele/oswiadczenia/wyslijOswiadczenieModel';
import { SzukajWEwidencjiKrzyzowoModel } from 'src/app/modele/projekty/spis/szukajWEwidencjiKrzyzowoModel';
import { SzukajWEwidencjiRezultat } from 'src/app/modele/projekty/spis/szukajWEwidencjiRezultat';

@Injectable({
  providedIn: 'root',
})
export class OswiadczeniaService extends SerwisBazowy {
  constructor(
    httpClient: HttpClient,
    authService: AuthService,
    errorService: ErrorService,
    loaderService: LoadingService,
    translate: TranslateService
  ) {
    super(httpClient, authService, errorService, loaderService, translate);
    this.CustomoweNaglowki = true;
  }

  pobierzDanePodstawoweDoOswiadczenia(id: string) {
    this.ustawCustomoweNaglowki();
    return this.get<PojedynczyElementResponse<DanePodstawoweDoOswiadczenia>>(
      this.AdresApi +
        'Oswiadczenia/PobierzDanePodstawoweDoOswiadczenia?id=' +
        id
    );
  }

  autoryzujEmail(model: AutoryzujEmailDoOswiadczenia) {
    this.ustawCustomoweNaglowki();
    return this.wykonajAkcje<AutoryzujEmailDoOswiadczenia, ResponseBazowy>(
      this.AdresApi + 'Oswiadczenia/AutoryzujEmail',
      model
    );
  }

  autoryzuj(model: AutoryzujDoOswiadczeniaModel) {
    this.ustawCustomoweNaglowki();
    return this.wykonajAkcje<
      AutoryzujDoOswiadczeniaModel,
      PojedynczyElementResponse<AutoryzujDoOswiadczeniaRezultat>
    >(this.AdresApi + 'Oswiadczenia/Autoryzuj', model);
  }

  public pobierzInstrukcje() {
    this.ustawCustomoweNaglowki();
    return this.wykonajAkcjeBezModelu<PojedynczyElementResponse<Zalacznik>>(
      this.AdresApi + 'Oswiadczenia/PobierzInstrukcjeWypelnianiaOswiadczen'
    );
  }

  pobierzDaneDoUzupelnieniaDanychPodstawowychUzytkownika() {
    this.ustawCustomoweNaglowki();
    return this.wykonajAkcjeBezModelu<
      PojedynczyElementResponse<PobierzDaneDoUzupelnieniaDanychPodstawowychUzytkownikaRezultat>
    >(
      this.AdresApi +
        'Oswiadczenia/PobierzDaneDoUzupelnieniaDanychPodstawowychUzytkownika'
    );
  }

  zapiszDanePodstawoweUzytkownika(
    model: ZapiszDanePodstawoweUzytkownikaDoOswiadczenia
  ) {
    this.ustawCustomoweNaglowki();
    return this.wykonajAkcje<
      ZapiszDanePodstawoweUzytkownikaDoOswiadczenia,
      ResponseBazowy
    >(
      this.AdresApi +
        'Oswiadczenia/ZapiszDanePodstawoweUzytkownikaDoOswiadczenia',
      model
    );
  }

  pobierzDaneDoTabeliSrodkow() {
    this.ustawCustomoweNaglowki();
    return this.wykonajAkcjeBezModelu<
      PojedynczyElementResponse<PobierzDaneDoTabeliSrodkowOswiadczenia>
    >(this.AdresApi + 'Oswiadczenia/PobierzDaneDoTabeliSrodkow');
  }

  pobierzDaneDoSpisuSrodka() {
    this.ustawCustomoweNaglowki();
    return this.wykonajAkcjeBezModelu<
      PojedynczyElementResponse<DaneDoSpisuSrodka>
    >(this.AdresApi + 'Oswiadczenia/PobierzDaneDoSpisuSrodka');
  }

  walidujZapisSrodka(model: EdytujSrodekModel) {
    this.ustawCustomoweNaglowki();
    return this.wykonajAkcje<EdytujSrodekModel, ResponseBazowy>(
      this.AdresApi + 'Oswiadczenia/WalidujZapisSrodka',
      model
    );
  }

  wyslijOswiadczenie(model: WyslijOswiadczenieModel) {
    this.ustawCustomoweNaglowki();
    return this.wykonajAkcje<WyslijOswiadczenieModel, ResponseBazowy>(
      this.AdresApi + 'Oswiadczenia/WyslijOswiadczenie',
      model
    );
  }

  szukajWEwidencjiKrzyzowo(model: SzukajWEwidencjiKrzyzowoModel) {
    this.ustawCustomoweNaglowki();
    return this.wykonajAkcje<
      SzukajWEwidencjiKrzyzowoModel,
      PojedynczyElementResponse<SzukajWEwidencjiRezultat>
    >(this.AdresApi + 'Oswiadczenia/SzukajWEwidencjiKrzyzowo', model);
  }

  private ustawCustomoweNaglowki() {
    this.CustomoweNaglowki = true;
    var adresStrony = location.origin;
    var jezyk = this.pobierzJezyk();
    var token = '';
    var idUzytkownika = '';

    var uzytkownik = JSON.parse(localStorage.getItem('uzytkownik'));

    if (uzytkownik != null) {
      token = uzytkownik.token;
      idUzytkownika = uzytkownik.idUzytkownika;
    }

    this.HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'adres-strony': adresStrony,
      jezyk: jezyk,
      token: token,
      'id-uzytkownika': idUzytkownika,
    });
  }
}
