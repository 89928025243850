import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { TakNieModalComponent } from 'src/app/komponenty/ogolne/tak-nie-modal/tak-nie-modal.component';
import { EdytujMapowanieModel } from 'src/app/modele/projekty/ewidencja/edytujMapowanieModel';
import { MapowaniaDoEdycji } from 'src/app/modele/projekty/ewidencja/mapowaniaDoEdycji';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { EwidencjaService } from 'src/app/serwisy/projekty/ewidencja.service';

@Component({
  selector: 'app-edycja-mapowan-ewidencji-modal',
  templateUrl: './edycja-mapowan-ewidencji-modal.component.html',
  styleUrls: ['./edycja-mapowan-ewidencji-modal.component.css']
})
export class EdycjaMapowanEwidencjiModalComponent extends BazowyComponent implements OnInit {

  idProjektu: string;
  dane: MapowaniaDoEdycji = new MapowaniaDoEdycji();
  model: EdytujMapowanieModel = new EdytujMapowanieModel();
  czyZaladowanoDane: boolean;


  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public ewidencjaSerwis: EwidencjaService,
    public translateSerwis: TranslateService,
    public activeModal: NgbActiveModal,
    private activatedRoute: ActivatedRoute) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translateSerwis);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.pobierzDaneDoEkranu();
    });
  }

  pobierzDaneDoEkranu() {
    var model = new IdModel();
    model.id = this.idProjektu;

    this.ewidencjaSerwis.pobierzMapowaniaDoEdycji(model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.dane = rezultat.element;
          this.przygotujModel(rezultat.element);

          this.czyZaladowanoDane = true;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        this.activeModal.close();
        return;
      }
    );
  }


  przygotujModel(dane: MapowaniaDoEdycji) {
    this.model.projektId = this.idProjektu;
    this.model.lokalizacjaKolumnaEwidencjiId = dane.lokalizacjaKolumnaEwidencjiId;
    this.model.lokalizacjaKolumnaLokalizacja = dane.lokalizacjaKolumnaLokalizacja;
    this.model.mapowania = dane.mapowania;

    this.czyZaladowanoDane = true;
  }

  zapisz() {
    this.ewidencjaSerwis.edytujMapowanie(this.model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {

          if (rezultat.element.wymaganePonowneParowanie) {
            this.modalReference = this.modalService.open(TakNieModalComponent, new ModalOpcje(true, 'static'));
            this.modalReference.componentInstance.pytanie = "Wymagane będzię ponowne parowanie środków. Czy chcesz wykonać ponowne parowanie?";

            this.modalReference.result.then((result) => {
              if (result) {
                this.model.zgodaNaPonowneParowanie = true;
                this.zapisz();
              }
            }, onRejected => { });
            return;
          }

          this.wyswietlKomunikat("Mapowania zostały zapisane");
          this.activeModal.close(true);
          return;
        }
        if (this.bladModelu(rezultat)) {
          this.bledyWalidacji = rezultat.bledyModelu;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }

}
