import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { ImportujEwidencjeRezultat } from 'src/app/modele/projekty/ewidencja/importujEwidencjeRezultat';
import { KolumnaImportuEwidencji } from 'src/app/modele/projekty/ewidencja/kolumnaImportuEwidencji';
import { MapujEwidencjeModel } from 'src/app/modele/projekty/ewidencja/mapujEwidencjeModel';
import { PobierzSparowaneSrodkiRekorduEwidencjiModel } from 'src/app/modele/projekty/spis/pobierzSparowaneSrodkiRekorduEwidencjiModel';
import { Routing } from 'src/app/modele/wspolne/routing';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { EwidencjaService } from 'src/app/serwisy/projekty/ewidencja.service';
import { SpisService } from 'src/app/serwisy/projekty/spis.service';

@Component({
  selector: 'app-sparowane-srodki-modal',
  templateUrl: './sparowane-srodki-modal.component.html',
  styleUrls: ['./sparowane-srodki-modal.component.css']
})
export class SparowaneSrodkiModalComponent extends BazowyComponent implements OnInit {

  id: string;
  idProjektu: string;
  dane: ImportujEwidencjeRezultat = new ImportujEwidencjeRezultat();
  model: MapujEwidencjeModel = new MapujEwidencjeModel();
  czyZaladowanoDane: boolean;
  kolumnaId: number;
  kolumnaLokalizacji: number;
  spisaneSrodki: Array<Array<string>> = new Array();
  kolumnyTabeli: Array<string> = new Array();


  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public spisSerwis: SpisService,
    public translateSerwis: TranslateService,
    public activeModal: NgbActiveModal,
    private activatedRoute: ActivatedRoute) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translateSerwis);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.pobierzDane();
    });
  }

  pobierzDane() {
    var model = new PobierzSparowaneSrodkiRekorduEwidencjiModel();
    model.idRekordu = this.id;
    model.idProjektu = this.idProjektu;

    this.spisSerwis.pobierzSparowaneSrodkiRekorduEwidencji(model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {

          this.spisaneSrodki = rezultat.element.dane;
          this.kolumnyTabeli = rezultat.element.kolumny;
          this.kolumnaId = this.kolumnyTabeli.indexOf("id");
          this.kolumnaLokalizacji = this.kolumnyTabeli.indexOf("lokalizacja_id");

          // if (this.spisaneSrodki.length == 1) {
          //   this.przejdzDoSzczegolowSrodka(this.spisaneSrodki[0]);
          //   this.activeModal.close();
          // }
          this.czyZaladowanoDane = true;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        this.activeModal.close();
        return;
      }
    );
  }

  przejdzDoSzczegolowSrodka(rekord: Array<string>) {
    var id = rekord[this.kolumnaId];
    var idLokalizacj = rekord[this.kolumnaLokalizacji];

    const url = this.router.createUrlTree([Routing.EdycjaSrodka, this.idProjektu, idLokalizacj, id])
    window.open(url.toString(), '_blank')

    // this.router.navigate([Routing.EdycjaSrodka, this.idProjektu, idLokalizacj, id]);

    //this.activeModal.close();
  }

}
