import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FiltrSlownikowProjektu } from 'src/app/modele/projekty/slowniki/filtrSlownikowProjektu';
import { SlownikiProjektu } from 'src/app/modele/projekty/slowniki/slownikiProjektu';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { Routing } from 'src/app/modele/wspolne/routing';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { SlownikiService } from 'src/app/serwisy/projekty/slowniki.service';
import { BazowyComponent } from '../../bazowy.component';
import { DodajSlownikModalComponent } from './dodaj-slownik-modal/dodaj-slownik-modal.component';
import { EdytujSlownikModalComponent } from './edytuj-slownik-modal/edytuj-slownik-modal.component';

@Component({
  selector: 'app-slowniki',
  templateUrl: './slowniki.component.html',
  styleUrls: ['./slowniki.component.css']
})
export class SlownikiComponent extends BazowyComponent implements OnInit {

  idProjektu: string;
  czyZaladowanoDane: boolean;
  wyszukiwanie: FiltrSlownikowProjektu = new FiltrSlownikowProjektu();
  dane: SlownikiProjektu;
  slideConfig = {
    "slidesToShow": 2,
    "slidesToScroll": 1,
    "prevArrow": document.getElementsByClassName('btn__left'),
    "nextArrow": document.getElementsByClassName('btn__right'),
    "infinite": false,
    "variableWidth": true,
  };
  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public slownikiSerwis: SlownikiService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translateSerwis);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.idProjektu = this.activatedRoute.snapshot.paramMap.get('id');
      this.wyszukiwanie.projektId = this.idProjektu;
      this.pobierzDaneDoEkranu();
    });
  }

  pobierzDaneDoEkranu() {
    this.slownikiSerwis.pobierzSlownikiProjektu(this.wyszukiwanie).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.dane = rezultat.element;

          this.czyZaladowanoDane = true;

          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }

  dodajSlownik() {
    this.modalReference = this.modalService.open(DodajSlownikModalComponent, new ModalOpcje(true, 'static'));
    this.modalReference.componentInstance.idProjektu = this.idProjektu;
    this.modalReference.result.then((result) => {
      if (result) {
        this.pobierzDaneDoEkranu();
      }
    }, onRejected => { });
  }

  cofnij() {
    this.router.navigate([Routing.Ewidencja, this.idProjektu]);
  }

  dalej() {
    this.router.navigate([Routing.Zespoly, this.idProjektu]);
  }

  pokazSzczegolySlownika(id: string) {
    this.modalReference = this.modalService.open(EdytujSlownikModalComponent, new ModalOpcje(true, 'static'));
    this.modalReference.componentInstance.idSlownika = id;
    this.modalReference.result.then((result) => {
      if (result) {
        this.pobierzDaneDoEkranu();
      }
    }, onRejected => { });
  }

}
