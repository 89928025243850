import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from '../../bazowy.component';
import { ZalogujModel } from 'src/app/modele/logowanie/zalogujModel';
import { ZalogujPrzezFbModel } from 'src/app/modele/logowanie/zalogujPrzezFbModel';
import { LogowanieService } from 'src/app/serwisy/konto/logowanie.service';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { WyslijKodWeryfikacyjnyPonownieModel } from 'src/app/modele/uzytkownik/wyslijKodWeryfikacyjnyPonownieModel';
import { DaneZalogowanegoUzytkownika } from 'src/app/modele/logowanie/daneZalogowanegoUzytkownika';
import { Ciasteczko } from 'src/app/modele/wspolne/ciasteczka';
import { TranslateService } from '@ngx-translate/core';
import { Jezyk } from 'src/app/modele/wspolne/jezyk';
import { UzytkownikService } from 'src/app/serwisy/konto/uzytkownik.service';

@Component({
  selector: 'app-logowanie',
  templateUrl: './logowanie.component.html'
})

export class LogowanieComponent extends BazowyComponent implements OnInit {
  public zalogujModel = new ZalogujModel();
  public dokonczLogowaniePrzezFbModel = new ZalogujPrzezFbModel();
  public czyPokazacHaslo = false;

  constructor(
    private logowanieSerwis: LogowanieService,
    public router: Router,
    private uzytkownikSerwis: UzytkownikService,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    private komunikatSerwis: ToastrService,
    public authService: AuthService,
    public translate: TranslateService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translate);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.inicjujJezyk();
    });
  }

  public zaloguj(): void {
    this.logowanieSerwis.zalogujPrzezEmail(this.zalogujModel).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.zapiszDaneOLogowaniu(rezultat.element);
          return;
        }
        else if (this.bladModelu(rezultat)) {
          this.bledyWalidacji = rezultat.bledyModelu;
          return;
        }
        this.wyswietlKomunikat(rezultat.wiadomosc, true);
      }
    );
  }

  public wyslijLinkPonownie() {
    try {
      var model = new WyslijKodWeryfikacyjnyPonownieModel();
      model.email = this.zalogujModel.email;

      this.uzytkownikSerwis.wyslijLinkPonownie(model).subscribe(
        (rezultat) => {
          if (this.czyRezultatOk(rezultat)) {
            this.wyswietlKomunikat(this.pobierzTlumaczenie("wyslanoLinkAktywacyjny"))
            return;
          }

          this.wyswietlKomunikat(rezultat.wiadomosc, true);
          return;
        }
      );
    } catch (blad) {
    }
  }

  private zapiszDaneOLogowaniu(dane: DaneZalogowanegoUzytkownika) {
    this.authService.logIn(Ciasteczko.DaneZalogowanegoUzytkownika, dane);
    if (dane.jezyk == Jezyk.pl) {
      this.translate.use('pl-PL');
    }
    if (dane.jezyk == Jezyk.en) {
      this.translate.use('en-US');
    }
  }

  pokazHaslo() {
    this.czyPokazacHaslo = !this.czyPokazacHaslo;
  }


}
