<div class="modal-content">
    <div class="modal-body">
        <button aria-label="Close" class="close" data-dismiss="modal" type="button"
            (click)="activeModal.dismiss()"></button>
        <div class="text-center mb-2"></div>

        <div class="fixed_table_container">
            <table>
                <tr>
                    <th *ngFor="let kolumna of listaKolumn; index as i" [ngClass]="i==kolumnaId?'id':''">{{kolumna}}
                    </th>
                </tr>
                <tr *ngFor="let wiersz of dane;index as r" class="pointer project-row" (click)="wybierzRekord(r)">
                    <td *ngFor="let wartosc of wiersz; index as i" [ngClass]="i==kolumnaId?'id':''">
                        {{wartosc}}
                    </td>
                </tr>
            </table>

        </div>

    </div>
</div>