import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';

@Component({
  selector: 'app-dezaktywuj-uzytkownika-modal',
  templateUrl: './dezaktywuj-uzytkownika-modal.component.html',
  styleUrls: ['./dezaktywuj-uzytkownika-modal.component.css']
})
export class DezaktywujUzytkownikaModalComponent implements OnInit {


  constructor(
    public router: Router,
    public activeModal: NgbActiveModal,
    public authService: AuthService) {
  }

  ngOnInit(): void {
  }

  tak() {
    this.activeModal.close(true);
  }
  nie() {
    this.activeModal.close();
  }
}
