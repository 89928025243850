import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { delay } from 'rxjs/operators';
import { LoaderService } from './serwisy/ogolne/loader.service';
import { Konfiguracja } from 'src/config';
import { BazowyComponent } from './komponenty/bazowy.component';
import { MenuService } from './serwisy/ogolne/menu.service';
import { AuthService } from './serwisy/ogolne/auth.service';
import { LoadingService } from './serwisy/ogolne/loading.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent extends BazowyComponent {
  title = 'IPRO';

  czyPokazLadowacz: boolean;

  constructor(
    public menuService: MenuService,
    public authService: AuthService,
    modalService: NgbModal,
    public loaderService: LoaderService,
    komunikatSerwis: ToastrService,
    router: Router,
    private loadingService: LoadingService,
    public translate: TranslateService) {
    super(authService, modalService, loaderService, komunikatSerwis, router, translate);
    translate.setDefaultLang('pl-PL');
    translate.use('pl-PL');
  }
  ngOnInit() {
    this.listenToLoading();
  }

  public isAuthorized(): boolean {
    return this.authService.isLoggedIn;
  }


  private listenToLoading(): void {
    this.loadingService.loadingSub
      .pipe(delay(0))
      .subscribe((loading) => {
        this.czyPokazLadowacz = loading;
      });
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
}
