<div class="modal-content" *ngIf="czyZaladowanoDane">
    <div class="modal-body">
        <button aria-label="Close" class="close" data-dismiss="modal" type="button"
            (click)="activeModal.dismiss()"></button>
        <div class="ft__01 text-center py-4 ">Dodaj zespół</div>
        <div class="text-center mb-2"></div>
        <form action="">
            <div class="input_container pb-3" [ngClass]="czyBladWalidacyjny('nazwaZespolu')?'error':''">
                <input type="text" placeholder="Numer zespołu" name="nazwaZespolu" [(ngModel)]="model.nazwaZespolu">
                <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('nazwaZespolu')">
                    {{pobierzBladWalidacyjny("nazwaZespolu") }}
                </div>
            </div>
            <div class="input_container pb-4 mb-3">
                <ng-select placeholder="Dodaj wykonawcę" [searchable]="true" [clearable]="false" [multiple]="false"
                    [closeOnSelect]="true" [items]="wykonawcyDoListy" [(ngModel)]="idWykonawcy" bindValue="id"
                    [ngModelOptions]="{standalone: true}" (change)="wybierzWykonawce()" [searchFn]="customSearchFn"
                    notFoundText="Brak wykonawców">
                    <ng-template ng-option-tmp let-item="item">
                        <div> <button class="btn__select__add">{{item.nazwisko}} {{item.imie}}</button></div>
                        <small style="padding-left: 14px;">{{item.telefon}}</small>
                    </ng-template>


                </ng-select>


            </div>

            <div class="pb-4">
                <div class="d-flex py-2" *ngFor="let wykonawca of pobierzWybranychWykonawcow()">
                    <button class="btn__x btn__x--por" (click)="usunWybranegoWykonawce(wykonawca.id)"></button>
                    <div>
                        <div class="fs__dz">{{wykonawca.imie}} {{wykonawca.nazwisko}}</div>
                        <div class="fs__dz2" style="word-break:break-word;">{{wykonawca.telefon}}</div>
                        <div class="fs__dz2" style="word-break:break-word;">{{wykonawca.email}}</div>
                    </div>
                </div>

            </div>
            <div class="input_container pb-3">
                <button class="btn__primary" (click)="zapisz()">Zapisz zespół</button>
            </div>
            <div class="input_container pb-3">
                <button class="btn__link" (click)="activeModal.dismiss()">Anuluj</button>
            </div>
        </form>
    </div>
</div>