import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NadwyzkiDoParowania } from 'src/app/modele/projekty/parowanie/nadwyzkiDoParowania';
import { NiedoboryDoParowania } from 'src/app/modele/projekty/parowanie/niedoboryDoParowania';
import { ParujRecznieModel } from 'src/app/modele/projekty/parowanie/parujRecznieModel';
import { PobierzKomentarzParowaniaModel } from 'src/app/modele/projekty/parowanie/pobierzKomentarzParowaniaModel';
import { PobierzNadwyzkiDoParowaniaFiltry } from 'src/app/modele/projekty/parowanie/pobierzNadwyzkiDoParowaniaFiltry';
import { PobierzNiedoboryDoParowaniaFiltry } from 'src/app/modele/projekty/parowanie/pobierzNiedoboryDoParowaniaFiltry';
import { RozlaczSrodkiModel } from 'src/app/modele/projekty/parowanie/rozlaczSrodkiModel';
import { NiedoboryLokalizacji } from 'src/app/modele/projekty/spis/niedoboryLokalizacji';
import { PobierzSpisaneSrodkiLokalizacjiFiltry } from 'src/app/modele/projekty/spis/pobierzSpisaneSrodkiLokalizacjiFiltry';
import { SpisaneSrodkiLokalizacji } from 'src/app/modele/projekty/spis/spisaneSrodkiLokalizacji';
import { PojedynczyElementResponse } from 'src/app/modele/wspolne/pojedynczyElement';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { ResponseBazowy } from 'src/app/modele/wspolne/responseBazowy';
import { SerwisBazowy } from '../bazowy.service';
import { AuthService } from '../ogolne/auth.service';
import { ErrorService } from '../ogolne/error.service';
import { LoadingService } from '../ogolne/loading.service';

@Injectable({
  providedIn: 'root'
})
export class ParowanieService extends SerwisBazowy {

  constructor(
    httpClient: HttpClient,
    authService: AuthService,
    errorService: ErrorService,
    loaderService: LoadingService,
    translate: TranslateService) {
    super(httpClient, authService, errorService, loaderService, translate);
  }

  public pobierzNadwyzkiDoParowania(model: RejestrFiltrowanyRequest<PobierzNadwyzkiDoParowaniaFiltry>, zLadowaczem: boolean) {
    if (zLadowaczem) {
      return this.wykonajAkcje<RejestrFiltrowanyRequest<PobierzNadwyzkiDoParowaniaFiltry>, PojedynczyElementResponse<NadwyzkiDoParowania>>
        (this.AdresApi + "Parowanie/PobierzNadwyzkiDoParowania", model);
    }
    else {
      return this.wykonajAkcjeBezLadowacza<RejestrFiltrowanyRequest<PobierzNadwyzkiDoParowaniaFiltry>, PojedynczyElementResponse<NadwyzkiDoParowania>>
        (this.AdresApi + "Parowanie/PobierzNadwyzkiDoParowania", model);
    }
  }

  public pobierzNiedoboryDoParowania(model: RejestrFiltrowanyRequest<PobierzNiedoboryDoParowaniaFiltry>, zLadowaczem: boolean) {
    if (zLadowaczem) {
      return this.wykonajAkcje<RejestrFiltrowanyRequest<PobierzNiedoboryDoParowaniaFiltry>, PojedynczyElementResponse<NiedoboryDoParowania>>
        (this.AdresApi + "Parowanie/PobierzNiedoboryDoParowania", model);
    }
    else {
      return this.wykonajAkcjeBezLadowacza<RejestrFiltrowanyRequest<PobierzNiedoboryDoParowaniaFiltry>, PojedynczyElementResponse<NiedoboryDoParowania>>
        (this.AdresApi + "Parowanie/PobierzNiedoboryDoParowania", model);
    }
  }

  public parujRecznie(model: ParujRecznieModel) {
    return this.wykonajAkcje<ParujRecznieModel, ResponseBazowy>
      (this.AdresApi + "Parowanie/ParujRecznie", model);
  }
  public rozlaczSrodki(model: RozlaczSrodkiModel) {
    return this.wykonajAkcje<RozlaczSrodkiModel, ResponseBazowy>
      (this.AdresApi + "Parowanie/RozlaczSrodki", model);
  }

  public pobierzKomentarzParowania(model: PobierzKomentarzParowaniaModel) {
    return this.wykonajAkcje<PobierzKomentarzParowaniaModel, PojedynczyElementResponse<string>>
      (this.AdresApi + "Parowanie/PobierzKomentarzParowania", model);
  }
}
