import { ComboBox } from "../../wspolne/comboBox";
import { KolumnaEdycjiMapowania } from "./kolumnaEdycjiMapowania";

export class MapowaniaDoEdycji {
    projektId: string;
    lokalizacjaKolumnaLokalizacja: string;
    lokalizacjaKolumnaEwidencjiId: string;
    mapowania: Array<KolumnaEdycjiMapowania> = new Array();
    listaKolumnEwidencji: Array<ComboBox> = new Array();
    listaKolumnLokalizacji: Array<string> = new Array();
    listaPolSpisu: Array<ComboBox> = new Array();
}