import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AutoryzujDoOswiadczeniaRezultat } from 'src/app/modele/oswiadczenia/autoryzujDoOswiadczeniaRezultat';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { Routing } from 'src/app/modele/wspolne/routing';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { MenuService } from 'src/app/serwisy/ogolne/menu.service';
import { OswiadczeniaService } from 'src/app/serwisy/oswiadczenia/oswiadczenia.service';
import { BazowyComponent } from '../../bazowy.component';
import { OswiadczeniaSesjaService } from 'src/app/serwisy/oswiadczenia/oswiadczenia-sesja.service';

@Component({
  selector: 'app-instrukcja-wypelniania-oswiadczenia',
  templateUrl: './instrukcja-wypelniania-oswiadczenia.component.html',
  styleUrls: ['./instrukcja-wypelniania-oswiadczenia.component.css'],
})
export class InstrukcjaWypelnianiaOswiadczeniaComponent
  extends BazowyComponent
  implements OnInit
{
  id: string;
  nazwaKlienta: string;
  czyMoznaDodawacOswiadczenia: boolean = false;
  uzytkownik: AutoryzujDoOswiadczeniaRezultat;
  zapoznalemSieZInstrukcja: boolean = false;
  zaladowanoDane: boolean;

  constructor(
    public authService: AuthService,
    public router: Router,
    private oswiadczeniaSerwis: OswiadczeniaService,
    modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public translate: TranslateService,
    public menuService: MenuService,
    private sesjaSerwis: OswiadczeniaSesjaService
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translate
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.menuService.schowajMenu();
      this.id = this.activatedRoute.snapshot.paramMap.get('id');

      this.inicjujJezyk();
      this.uzytkownik = JSON.parse(localStorage.getItem('uzytkownik'));
      if (!this.uzytkownik || this.uzytkownik.idProjektu != this.id) {
        this.sesjaSerwis.wyczyscSesjeBezZmianyStrony();
        this.router.navigate([Routing.OswiadczeniaAutoryzacja, this.id]);
      }

      this.pobierzDaneDoEkranu();
    });
  }

  public pobierzDaneDoEkranu() {
    try {
      this.oswiadczeniaSerwis
        .pobierzDanePodstawoweDoOswiadczenia(this.id)
        .subscribe((rezultat) => {
          if (this.czyRezultatOk(rezultat)) {
            this.nazwaKlienta = rezultat.element.nazwaKlienta;
            this.czyMoznaDodawacOswiadczenia = rezultat.element.czyWlaczone;
            this.zaladowanoDane = true;
            return;
          }

          this.wyswietlKomunikat(rezultat.wiadomosc, true);
        });
    } catch (blad) {}
  }

  pobierzInstrukcje() {
    this.oswiadczeniaSerwis.pobierzInstrukcje().subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        var a = document.createElement('a');
        a.target = '_blank';
        a.href = 'data: pdf;base64,' + rezultat.element.plik;
        a.download = 'Instrukcja.pdf';
        a.click();

        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  potwierdz() {
    this.uzytkownik.potwierdzonoZapoznanieZInstrukcja =
      this.zapoznalemSieZInstrukcja;
    localStorage.removeItem('uzytkownik');
    localStorage.setItem('uzytkownik', JSON.stringify(this.uzytkownik));
    this.router.navigate([Routing.OswiadczeniaDanePodstawowe, this.id]);
  }
}
