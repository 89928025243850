import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { BazowyComponent } from '../../bazowy.component';
import { DaneDoSpisuSrodka } from 'src/app/modele/projekty/spis/daneDoSpisuSrodka';
import { EdytujSrodekModel } from 'src/app/modele/projekty/spis/edytujSrodekModel';
import { SzukajWEwidencjiRezultat } from 'src/app/modele/projekty/spis/szukajWEwidencjiRezultat';
import * as IMask from 'imask';
import {
  DOC_ORIENTATION,
  DataUrl,
  NgxImageCompressService,
} from 'ngx-image-compress';
import { AutoryzujDoOswiadczeniaRezultat } from 'src/app/modele/oswiadczenia/autoryzujDoOswiadczeniaRezultat';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { OswiadczeniaService } from 'src/app/serwisy/oswiadczenia/oswiadczenia.service';
import { Zalacznik } from 'src/app/modele/wspolne/zalacznik';
import { ZnalezioneRekordyEwidencjiModalComponent } from '../../projekty/spis/znalezione-rekordy-ewidencji-modal/znalezione-rekordy-ewidencji-modal.component';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { KolumnaEwidencjiFiltrowanie } from 'src/app/modele/projekty/ewidencja/kolumnaEwidencjiFiltrowanie';
import {
  SzukajWEwidencjiKrzyzowoModel,
  WartoscDoSzukaniaKrzyzowego,
} from 'src/app/modele/projekty/spis/szukajWEwidencjiKrzyzowoModel';
import { SzukajWEwidencjiModel } from 'src/app/modele/projekty/spis/szukajWEwidencjiModel';
import { TakNieModalComponent } from '../../ogolne/tak-nie-modal/tak-nie-modal.component';
import { PoleSpisuSrodkaDoZapisu } from 'src/app/modele/projekty/spis/poleSpisuSrodkaDoZapisu';
import { RodzajPolaSpisu } from 'src/app/modele/projekty/polaSpisu/rodzajPolaSpisu';
import { PobierzSrodekRezultat } from 'src/app/modele/projekty/spis/pobierzSrodekRezultat';
import { EdytujSrodekOswiadczeniaModel } from 'src/app/modele/oswiadczenia/edytujSrodekOswiadczeniaModel';

@Component({
  selector: 'app-edytuj-oswiadczenie-modal',
  templateUrl: './edytuj-oswiadczenie-modal.component.html',
  styleUrls: ['./edytuj-oswiadczenie-modal.component.css'],
})
export class EdytujOswiadczenieModalComponent
  extends BazowyComponent
  implements OnInit
{
  id: string;
  idProjektu: string;
  uzytkownik: AutoryzujDoOswiadczeniaRezultat;
  czyMoznaEdytowac = true;

  daneDoSpisu: DaneDoSpisuSrodka;
  model: EdytujSrodekModel = new EdytujSrodekModel();
  znalezioneRekordyWEwidencji: SzukajWEwidencjiRezultat;
  czyZaladowanoDane: boolean;

  fullScrennZdjecia: Array<object> = new Array();
  pokazGalerieZdjec: boolean = false;
  wybraneZdjecie: number = -1;

  dateMask = {
    mask: 'dd-MM-yyyy',
    pattern: 'dd-MM-yyyy',
    blocks: {
      dd: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
        maxLength: 2,
      },
      MM: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
        maxLength: 2,
      },
      yyyy: {
        mask: IMask.MaskedRange,
        from: 1900,
        to: 9999,
        maxLength: 4,
      },
    },
  };

  constructor(
    public router: Router,
    modalService: NgbModal,
    public activeModal: NgbActiveModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public translate: TranslateService,
    private imageCompress: NgxImageCompressService,
    private oswiadczeniaSerwis: OswiadczeniaService
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translate
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.pobierzDaneDoEkranu();
    });
  }

  pobierzDaneDoEkranu() {
    this.oswiadczeniaSerwis.pobierzDaneDoSpisuSrodka().subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.daneDoSpisu = rezultat.element;

        this.pobierzDaneSrodka();

        this.czyZaladowanoDane = true;
        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  pobierzDaneSrodka() {
    if (!this.id) {
      this.uzupelnijDaneNowySrodek();
    } else {
      this.uzupelnijDaneEdycja(
        JSON.parse(localStorage.getItem('spisaneSrodkiOswiadczenia')).find(
          (m) => m.id == this.id
        )
      );
    }

    this.model.idProjektu = this.idProjektu;
    this.model.id = this.id || this.generujId();
  }

  usun() {
    this.modalReference = this.modalService.open(
      TakNieModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.pytanie =
      'Czy na pewno chcesz usunąć środek?';

    this.modalReference.result.then(
      (result) => {
        if (result) {
          var srodki =
            (JSON.parse(
              localStorage.getItem('spisaneSrodkiOswiadczenia')
            ) as Array<EdytujSrodekModel>) || [];

          srodki = srodki.filter((m) => m.id != this.id);

          localStorage.removeItem('spisaneSrodkiOswiadczenia');
          localStorage.setItem(
            'spisaneSrodkiOswiadczenia',
            JSON.stringify(srodki)
          );
          this.wyswietlKomunikat('Środek został usunięty');
          this.activeModal.close(true);
        }
      },
      (onRejected) => {}
    );
  }

  czyCheckboxZaznaczonyDomyslnie(wartoscDomyslna: string) {
    return wartoscDomyslna.toLowerCase() == 'tak';
  }

  pobierzMaksymalnaDlugoscPola(maxDlugosc?: number) {
    return maxDlugosc == null || maxDlugosc == undefined || maxDlugosc <= 1
      ? 99
      : maxDlugosc;
  }

  uzupelnijDaneNowySrodek() {
    this.model = new EdytujSrodekOswiadczeniaModel();
    this.fullScrennZdjecia = new Array();

    this.daneDoSpisu.polaSpisu.forEach((p) => {
      var pole = new PoleSpisuSrodkaDoZapisu();
      pole.poleSpisu = p;
      pole.wartosc =
        p.wartoscDomyslna && p.wartoscDomyslna.length > 0
          ? p.wartoscDomyslna
          : '';

      if (p.rodzajPolaSpisu == RodzajPolaSpisu.slownikJednokolumnowy) {
        pole.wartosciSlownika = this.daneDoSpisu.slowniki
          .find((m) => m.id == p.slownikId)
          .dane.map((m) => m.kolumna1);
      }

      if (p.rodzajPolaSpisu == RodzajPolaSpisu.slownikWielokolumnowy) {
        var slownik = this.daneDoSpisu.slowniki.find(
          (m) => m.id == p.slownikId
        );
        var wartosciSlownika = new Array();
        if (slownik.listaKolumn.indexOf(p.kolumnaSlownika) == 0) {
          wartosciSlownika = [
            ...new Set(
              slownik.dane
                .map((m) => m.kolumna1)
                .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1))
            ),
          ];
        }
        pole.wartosciSlownika = wartosciSlownika;
      }

      this.model.pola.push(pole);
    });
  }

  uzupelnijDaneEdycja(daneSrodka: EdytujSrodekModel) {
    this.daneDoSpisu.polaSpisu.forEach((p) => {
      var danePole = daneSrodka.pola.find(
        (m) => m.poleSpisu.nazwaPola == p.nazwaPola
      );
      var pole = new PoleSpisuSrodkaDoZapisu();
      pole.poleSpisu = p;
      if (danePole && danePole.wartosc && danePole.wartosc.length > 0) {
        pole.wartosc = danePole.wartosc;
      } else {
        pole.wartosc =
          p.wartoscDomyslna && p.wartoscDomyslna.length > 0
            ? p.wartoscDomyslna
            : '';
      }

      if (p.rodzajPolaSpisu == RodzajPolaSpisu.slownikJednokolumnowy) {
        pole.wartosciSlownika = this.daneDoSpisu.slowniki
          .find((m) => m.id == p.slownikId)
          .dane.map((m) => m.kolumna1);
      }

      if (p.rodzajPolaSpisu == RodzajPolaSpisu.slownikWielokolumnowy) {
        var slownik = this.daneDoSpisu.slowniki.find(
          (m) => m.id == p.slownikId
        );
        var wartosciSlownika = new Array();
        if (slownik.listaKolumn.indexOf(p.kolumnaSlownika) == 0) {
          wartosciSlownika = [
            ...new Set(
              slownik.dane
                .map((m) => m.kolumna1)
                .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1))
            ),
          ];
        }
        pole.wartosciSlownika = wartosciSlownika;
      }

      this.model.pola.push(pole);
    });
    this.model.zdjecia = daneSrodka.zdjecia;
    daneSrodka.zdjecia.forEach((m) => {
      this.fullScrennZdjecia.push({
        image: this.budujZdjecieDoWyswietlenia(m),
      });
    });
  }

  onFocusOutEvent($event: any, idPola: string) {
    var pole = this.model.pola.find((m) => m.poleSpisu.id == idPola);
    this.dodajObslugeDopisywaniaZer(pole);
    this.dodajObslugeSlownikowWielokolumnowych(pole);
  }

  selectEvent(wartosc: string, idPola: string) {
    var pole = this.model.pola.find((m) => m.poleSpisu.id == idPola);
    pole.wartosc = wartosc;
    this.dodajObslugeSlownikowWielokolumnowych(pole);
  }
  clearedEvent($event: any, idPola: string) {
    var pole = this.model.pola.find((m) => m.poleSpisu.id == idPola);
    pole.wartosc = '';
    this.dodajObslugeSlownikowWielokolumnowych(pole);
  }

  dodajObslugeDopisywaniaZer(pole: PoleSpisuSrodkaDoZapisu) {
    if (!pole.wartosc || pole.wartosc.length == 0) {
      return;
    }

    if (pole.poleSpisu.czyDopisacZeraNaPoczatku) {
      pole.wartosc = pole.wartosc.padStart(
        pole.poleSpisu.liczbaZerDoUzupelnienia,
        '0'
      );
    }
    if (pole.poleSpisu.czyDopisacZeraNaKoncu) {
      pole.wartosc = pole.wartosc.padEnd(
        pole.poleSpisu.liczbaZerDoUzupelnienia,
        '0'
      );
    }
  }

  dodajObslugeSlownikowWielokolumnowych(pole: PoleSpisuSrodkaDoZapisu) {
    if (
      pole.poleSpisu.rodzajPolaSpisu != RodzajPolaSpisu.slownikWielokolumnowy
    ) {
      return;
    }
    var slownik = this.daneDoSpisu.slowniki.find(
      (m) => m.id == pole.poleSpisu.slownikId
    );
    var polaSpisuTegoSamegoSlownika = this.model.pola.filter(
      (p) => p.poleSpisu.slownikId == pole.poleSpisu.slownikId
    );
    var bazowyIndeksKolumny = slownik.listaKolumn.indexOf(
      pole.poleSpisu.kolumnaSlownika
    );

    var pole1 = polaSpisuTegoSamegoSlownika.find(
      (m) => slownik.listaKolumn.indexOf(m.poleSpisu.kolumnaSlownika) == 0
    );
    var pole2 = polaSpisuTegoSamegoSlownika.find(
      (m) => slownik.listaKolumn.indexOf(m.poleSpisu.kolumnaSlownika) == 1
    );
    var pole3 = polaSpisuTegoSamegoSlownika.find(
      (m) => slownik.listaKolumn.indexOf(m.poleSpisu.kolumnaSlownika) == 2
    );
    var pole4 = polaSpisuTegoSamegoSlownika.find(
      (m) => slownik.listaKolumn.indexOf(m.poleSpisu.kolumnaSlownika) == 3
    );
    var pole5 = polaSpisuTegoSamegoSlownika.find(
      (m) => slownik.listaKolumn.indexOf(m.poleSpisu.kolumnaSlownika) == 4
    );

    switch (bazowyIndeksKolumny) {
      case 0: {
        if (pole2) {
          var wartosciSlownika = [
            ...new Set(
              slownik.dane
                .filter(
                  (m) =>
                    m.kolumna1.toLowerCase().trim() ==
                      pole1.wartosc.toLowerCase().trim() && !!m.kolumna2
                )
                .map((m) => m.kolumna2)
            ),
          ];
          this.aktualizujWartosciSlownikaWielokolumnowego(
            pole2,
            wartosciSlownika
          );
        }
        if (pole3) {
          var wartosciSlownika = [
            ...new Set(
              slownik.dane
                .filter(
                  (m) =>
                    m.kolumna1.toLowerCase().trim() ==
                      pole1.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna2) ==
                      pole2.wartosc.toLowerCase().trim() &&
                    !!m.kolumna3
                )
                .map((m) => m.kolumna3)
            ),
          ];
          this.aktualizujWartosciSlownikaWielokolumnowego(
            pole3,
            wartosciSlownika
          );
        }
        if (pole4) {
          var wartosciSlownika = [
            ...new Set(
              slownik.dane
                .filter(
                  (m) =>
                    m.kolumna1.toLowerCase().trim() ==
                      pole1.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna2) ==
                      pole2.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna3) ==
                      pole3.wartosc.toLowerCase().trim() &&
                    !!m.kolumna4
                )
                .map((m) => m.kolumna4)
            ),
          ];
          this.aktualizujWartosciSlownikaWielokolumnowego(
            pole4,
            wartosciSlownika
          );
        }
        if (pole5) {
          var wartosciSlownika = [
            ...new Set(
              slownik.dane
                .filter(
                  (m) =>
                    m.kolumna1.toLowerCase().trim() ==
                      pole1.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna2) ==
                      pole2.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna3) ==
                      pole3.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna4) ==
                      pole4.wartosc.toLowerCase().trim() &&
                    !!m.kolumna5
                )
                .map((m) => m.kolumna5)
            ),
          ];
          this.aktualizujWartosciSlownikaWielokolumnowego(
            pole5,
            wartosciSlownika
          );
        }

        break;
      }
      case 1: {
        if (pole3) {
          var wartosciSlownika = [
            ...new Set(
              slownik.dane
                .filter(
                  (m) =>
                    m.kolumna1.toLowerCase().trim() ==
                      pole1.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna2) ==
                      pole2.wartosc.toLowerCase().trim() &&
                    !!m.kolumna3
                )
                .map((m) => m.kolumna3)
            ),
          ];
          this.aktualizujWartosciSlownikaWielokolumnowego(
            pole3,
            wartosciSlownika
          );
        }
        if (pole4) {
          var wartosciSlownika = [
            ...new Set(
              slownik.dane
                .filter(
                  (m) =>
                    m.kolumna1.toLowerCase().trim() ==
                      pole1.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna2) ==
                      pole2.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna3) ==
                      pole3.wartosc.toLowerCase().trim() &&
                    !!m.kolumna4
                )
                .map((m) => m.kolumna4)
            ),
          ];
          this.aktualizujWartosciSlownikaWielokolumnowego(
            pole4,
            wartosciSlownika
          );
        }
        if (pole5) {
          var wartosciSlownika = [
            ...new Set(
              slownik.dane
                .filter(
                  (m) =>
                    m.kolumna1.toLowerCase().trim() ==
                      pole1.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna2) ==
                      pole2.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna3) ==
                      pole3.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna4) ==
                      pole4.wartosc.toLowerCase().trim() &&
                    !!m.kolumna5
                )
                .map((m) => m.kolumna5)
            ),
          ];
          this.aktualizujWartosciSlownikaWielokolumnowego(
            pole5,
            wartosciSlownika
          );
        }
        break;
      }
      case 2: {
        if (pole4) {
          var wartosciSlownika = [
            ...new Set(
              slownik.dane
                .filter(
                  (m) =>
                    m.kolumna1.toLowerCase().trim() ==
                      pole1.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna2) ==
                      pole2.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna3) ==
                      pole3.wartosc.toLowerCase().trim() &&
                    !!m.kolumna4
                )
                .map((m) => m.kolumna4)
            ),
          ];
          this.aktualizujWartosciSlownikaWielokolumnowego(
            pole4,
            wartosciSlownika
          );
        }
        if (pole5) {
          var wartosciSlownika = [
            ...new Set(
              slownik.dane
                .filter(
                  (m) =>
                    m.kolumna1.toLowerCase().trim() ==
                      pole1.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna2) ==
                      pole2.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna3) ==
                      pole3.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna4) ==
                      pole4.wartosc.toLowerCase().trim() &&
                    !!m.kolumna5
                )
                .map((m) => m.kolumna5)
            ),
          ];
          this.aktualizujWartosciSlownikaWielokolumnowego(
            pole5,
            wartosciSlownika
          );
        }
        break;
      }
      case 3: {
        if (pole5) {
          var wartosciSlownika = [
            ...new Set(
              slownik.dane
                .filter(
                  (m) =>
                    m.kolumna1.toLowerCase().trim() ==
                      pole1.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna2) ==
                      pole2.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna3) ==
                      pole3.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna4) ==
                      pole4.wartosc.toLowerCase().trim() &&
                    !!m.kolumna5
                )
                .map((m) => m.kolumna5)
            ),
          ];
          this.aktualizujWartosciSlownikaWielokolumnowego(
            pole5,
            wartosciSlownika
          );
        }
        break;
      }
    }
  }

  aktualizujWartosciSlownikaWielokolumnowego(
    pole: PoleSpisuSrodkaDoZapisu,
    wartosci: Array<string>
  ) {
    if (!pole) {
      return;
    }

    pole.wartosciSlownika = wartosci.sort((a, b) =>
      a.toLowerCase() > b.toLowerCase() ? 1 : -1
    );
  }

  wybierzZalacznikZDysku(event: any) {
    var plik = event.target.files[0];
    if (plik) {
      this.pokazLadowacz();

      var reader = new FileReader();
      reader.readAsDataURL(plik);
      reader.onload = () => {
        var zdjecie = new Zalacznik();
        zdjecie.nazwaPliku = plik.name;
        zdjecie.dataModyfikacji = new Date(plik.lastModified);
        zdjecie.format = plik.name.split('.').pop().toLowerCase();

        this.imageCompress
          .compressFile(
            reader.result.toString(),
            DOC_ORIENTATION.NotDefined,
            30,
            60
          )
          .then((plik: DataUrl) => {
            this.schowajLadowacz();
            zdjecie.plik = this.usunZbedneZnakiZPliku(plik);
            this.model.zdjecia.push(zdjecie);
            this.fullScrennZdjecia.push({
              image: plik,
            });
          });
      };
    }
    event.target.value = null;
    return false;
  }

  usunZbedneZnakiZPliku(base64: string) {
    if (base64.includes(',')) {
      return base64.substring(base64.indexOf(',') + 1);
    }
    return base64;
  }

  usunZdjecie(indeks: number) {
    this.model.zdjecia.splice(indeks, 1);
    this.fullScrennZdjecia.splice(indeks, 1);
  }

  pokazGalerie(index) {
    this.wybraneZdjecie = index;
    this.pokazGalerieZdjec = true;
  }
  zamknijGalerie() {
    this.pokazGalerieZdjec = false;
    this.wybraneZdjecie = -1;
  }

  szukajWEwidencjiKrzyzowo() {
    var model = new SzukajWEwidencjiKrzyzowoModel();
    model.projektId = this.idProjektu;
    this.model.pola.forEach((pole) => {
      if (!pole.poleSpisu.czySzukacWEwidencji || !pole.wartosc.length) {
        return;
      }
      var mapowanie = this.daneDoSpisu.mapowania.find(
        (m) => m.poleSpisuId == pole.poleSpisu.id
      );
      if (!mapowanie) {
        return;
      }
      // var wartosc = new KolumnaEwidencjiFiltrowanie();
      // wartosc.kolumna = mapowanie.kolumnaEwidencji;
      var wartosc = new WartoscDoSzukaniaKrzyzowego();
      wartosc.poleSpisuId = pole.poleSpisu.id;
      wartosc.wartoscSzukaj = pole.wartosc;
      model.wartosci.push(wartosc);
    });
    if (model.wartosci.length == 0) {
      return;
    }
    this.oswiadczeniaSerwis
      .szukajWEwidencjiKrzyzowo(model)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.znalezioneRekordyWEwidencji = rezultat.element;
          if (this.znalezioneRekordyWEwidencji.czyZaDuzoRekordow) {
            this.wyswietlKomunikat(
              'Znaleziono za dużo rekordów. Popraw wartość i wyszukaj ponownie',
              true
            );
            return;
          }
          var kolumnaId = this.pobierzIndeksKolumnyEwidencji('id');
          if (this.znalezioneRekordyWEwidencji.dane.length == 0) {
            this.wyswietlKomunikat(
              'Nie odnaleziono rekordów w ewidencji',
              true
            );
            this.przywrocWartosciDomyslnePolTylkoDoOdczytu();
            return;
          }
          if (this.znalezioneRekordyWEwidencji.dane.length == 1) {
            this.uzupelnijPolaSpisuRekordemZEwidencji(
              this.znalezioneRekordyWEwidencji.dane[0][kolumnaId]
            );
            return;
          }
          this.wyswietlModalZRekordamiEwidencji(rezultat.element);
          return;
        }
        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  wyswietlModalZRekordamiEwidencji(element: SzukajWEwidencjiRezultat) {
    this.modalReference = this.modalService.open(
      ZnalezioneRekordyEwidencjiModalComponent,
      new ModalOpcje(true, 'static', null, 'modal-dialog--fluid')
    );
    this.modalReference.componentInstance.listaKolumn = element.kolumny;
    this.modalReference.componentInstance.dane = element.dane;
    this.modalReference.result.then(
      (id) => {
        if (id) {
          this.uzupelnijPolaSpisuRekordemZEwidencji(id);
        }
      },
      (onRejected) => {}
    );
  }

  uzupelnijPolaSpisuRekordemZEwidencji(idRekordu: string) {
    var kolumnaId = this.pobierzIndeksKolumnyEwidencji('id');
    var rekordEwidencji = this.znalezioneRekordyWEwidencji.dane.find(
      (m) => m[kolumnaId] == idRekordu
    );

    this.model.pola.forEach((p) => {
      var mapowanie = this.daneDoSpisu.mapowania.find(
        (m) => m.poleSpisuId == p.poleSpisu.id
      );
      if (!mapowanie || !p.poleSpisu.czyUzupelnicAutomatycznieZEwidencji) {
        return;
      }
      var wartoscZEwidencji =
        rekordEwidencji[
          this.pobierzIndeksKolumnyEwidencji(mapowanie.kolumnaEwidencji)
        ];

      p.wartosc = wartoscZEwidencji;
    });
  }

  pobierzIndeksKolumnyEwidencji(kolumna: string) {
    return this.znalezioneRekordyWEwidencji.kolumny.indexOf(kolumna);
  }

  zapisz() {
    this.oswiadczeniaSerwis
      .walidujZapisSrodka(this.model)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.wyswietlKomunikat('Środek został zapisany');
          this.zapiszSrodekWPamieci();
          this.bledyWalidacji = null;
          this.activeModal.close(true);
          return;
        }
        if (this.bladModelu(rezultat)) {
          this.bledyWalidacji = rezultat.bledyModelu;
          return;
        }
        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  zapiszSrodekWPamieci() {
    var srodki =
      (JSON.parse(
        localStorage.getItem('spisaneSrodkiOswiadczenia')
      ) as Array<EdytujSrodekModel>) || [];

    if (this.id) {
      srodki = srodki.filter((m) => m.id != this.id);
    }
    srodki.push(this.model);
    localStorage.removeItem('spisaneSrodkiOswiadczenia');
    localStorage.setItem('spisaneSrodkiOswiadczenia', JSON.stringify(srodki));
  }

  zaznaczCheckbox($event: any, idPola: string) {
    var pole = this.model.pola.find((m) => m.poleSpisu.id == idPola);
    pole.wartosc = $event.currentTarget.checked ? 'Tak' : 'Nie';
  }

  czyMoznaWyszukiwacWEwidencji(poleId: string) {
    var pole = this.model.pola.find((m) => m.poleSpisu.id == poleId);
    var mapowanie = this.daneDoSpisu.mapowania.find(
      (m) => m.poleSpisuId == poleId
    );

    return pole.poleSpisu.czySzukacWEwidencji && mapowanie;
  }

  czyJakiekolwiekPoleMaWyszukiwanieWEwidencji() {
    return this.model.pola.find(
      (m) =>
        m.poleSpisu.czySzukacWEwidencji &&
        this.daneDoSpisu.mapowania.find((z) => z.poleSpisuId == m.poleSpisu.id)
    );
  }

  budujZdjecieDoWyswietlenia(zdjecie: Zalacznik) {
    var format = '';
    switch (zdjecie.format) {
      case 'jpg':
        format = 'jpeg';
        break;
      case 'png':
        format = 'png';
        break;
      default:
        format = zdjecie.format;
        break;
    }
    return 'data:image/' + format + ';base64,' + zdjecie.plik;
  }

  przywrocWartosciDomyslnePolTylkoDoOdczytu() {
    this.model.pola.forEach((pole) => {
      if (!pole.poleSpisu.tylkoDoOdczytu) {
        return;
      }
      pole.wartosc = pole.poleSpisu.wartoscDomyslna;
    });
  }

  nullNaString(wartosc: string): string {
    return wartosc == null || wartosc == undefined
      ? ''
      : wartosc.toLowerCase().trim();
  }

  generujId(): string {
    const znaki: string[] = [...Array(26)]
      .map((_, i) => String.fromCharCode(i + 97)) // 'a' to 'z'
      .concat([...Array(10)].map((_, i) => String(i))); // '0' to '9'
    let id: string = '';
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * znaki.length);
      id += znaki[randomIndex];
    }
    return id;
  }
}
