import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DodajSlownikModel } from 'src/app/modele/projekty/slowniki/dodajSlownikModel';
import { FiltrSlownikowProjektu } from 'src/app/modele/projekty/slowniki/filtrSlownikowProjektu';
import { SlownikNaLiscie } from 'src/app/modele/projekty/slowniki/slownikNaLiscie';
import { FiltrSzukaj } from 'src/app/modele/wspolne/filtrSzukaj';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { Zalacznik } from 'src/app/modele/wspolne/zalacznik';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { SlownikiService } from 'src/app/serwisy/projekty/slowniki.service';
import { BazowyComponent } from '../../bazowy.component';
import { DodajSlownikModalComponent } from '../../projekty/slowniki/dodaj-slownik-modal/dodaj-slownik-modal.component';
import { EdytujSlownikModalComponent } from '../../projekty/slowniki/edytuj-slownik-modal/edytuj-slownik-modal.component';

@Component({
  selector: 'app-slowniki-domyslne',
  templateUrl: './slowniki-domyslne.component.html',
  styleUrls: ['./slowniki-domyslne.component.css'],
  providers: [DatePipe]
})
export class SlownikiDomyslneComponent extends BazowyComponent implements OnInit {

  czyZaladowanoDane: boolean;
  wyszukiwanie: FiltrSzukaj = new FiltrSzukaj();
  model: DodajSlownikModel = new DodajSlownikModel();
  slowniki: Array<SlownikNaLiscie> = new Array();

  slideConfig = {
    "slidesToShow": 2,
    "slidesToScroll": 1,
    "prevArrow": document.getElementsByClassName('btn__left'),
    "nextArrow": document.getElementsByClassName('btn__right'),
    "infinite": false,
    "variableWidth": true,
  };

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    private datePipe: DatePipe,
    public slownikiSerwis: SlownikiService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translateSerwis);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.pobierzDaneDoEkranu(true);
    });
  }

  pobierzDaneDoEkranu(zLadowaczem: boolean) {
    this.slownikiSerwis.pobierzSlownikiDomyslne(this.wyszukiwanie, zLadowaczem).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.slowniki = rezultat.listaElementow;

          this.czyZaladowanoDane = true;

          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }

  dodajSlownik() {
    this.modalReference = this.modalService.open(DodajSlownikModalComponent, new ModalOpcje(true, 'static'));
    this.modalReference.result.then((result) => {
      if (result) {
        this.pobierzDaneDoEkranu(true);
      }
    }, onRejected => { });
  }

  pokazSzczegolySlownika(id: string) {
    this.modalReference = this.modalService.open(EdytujSlownikModalComponent, new ModalOpcje(true, 'static'));
    this.modalReference.componentInstance.idSlownika = id;
    this.modalReference.result.then((result) => {
      if (result) {
        this.pobierzDaneDoEkranu(true);
      }
    }, onRejected => { });
  }


  filtrujPoTekscie() {
    if (this.wyszukiwanie.wartoscSzukaj.length == 0) {
      this.pobierzDaneDoEkranu(false);
    }

    if (this.wyszukiwanie.wartoscSzukaj.length >= 3) {
      this.pobierzDaneDoEkranu(false);
    }
  }

}
