<div *ngIf="czyZaladowanoDane">

    <div class="container--main d-none d-md-block">

        <div class="d-flex py-4">
            <div class="project__menu">
                <a routerLink='/users'>Użytkownicy</a>
                <a routerLink='/default-dictionaries'>Słowniki</a>
                <a class="active" routerLink='/admin/settings'>Ustawienia</a>
            </div>
        </div>
    </div>
    <div class="container ">

        <div class="d-fledx py-3 d-md-none">
            <div class="project__menu">
                <button class="btn__left"></button>
                <ngx-slick-carousel class="carousel menu_slider" #slickModal="slick-carousel" [config]="slideConfig">

                    <div ngxSlickItem class="slide"><a routerLink="/users">Użytkownicy</a></div>
                    <div ngxSlickItem class="slide"><a routerLink="/default-dictionaries">Słowniki</a></div>
                    <div ngxSlickItem class="slide"><a class="active" routerLink="/admin/settings">Ustawienia</a> </div>
                </ngx-slick-carousel>
                <button class="btn__right"></button>
            </div>
        </div>

        <div class="input_container pb-3" [ngClass]="czyBladWalidacyjny('adresEmail')?'error':''">
            <div class="f-input mb-1">
                <label class="ft__label mb-1 text-left w-100">E-mail do powiadomień</label>
            </div>
            <input type="text" name="adresEmail" [(ngModel)]="model.adresEmail">
            <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('adresEmail')">{{
                pobierzBladWalidacyjny("adresEmail")
                }}
            </div>
        </div>
        <div class="input_container pb-3" [ngClass]="czyBladWalidacyjny('serwerPocztowy')?'error':''">
            <div class="f-input mb-1">
                <label class="ft__label mb-1 text-left w-100">Serwer pocztowy do powiadomień</label>
            </div>
            <input type="text" name="serwerPocztowy" [(ngModel)]="model.serwerPocztowy">
            <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('serwerPocztowy')">{{
                pobierzBladWalidacyjny("serwerPocztowy")
                }}
            </div>
        </div>
        <div class="input_container pb-3" [ngClass]="czyBladWalidacyjny('port')?'error':''">
            <div class="f-input mb-1">
                <label class="ft__label mb-1 text-left w-100">Port serwera pocztowego</label>
            </div>
            <input type="text" name="port" [(ngModel)]="model.port">
            <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('port')">{{ pobierzBladWalidacyjny("port")
                }}
            </div>
        </div>

        <div class="f-input mb-1">
            <label class="ft__label mb-1 text-left w-100">Hasło do skrzynki e-mail</label>
        </div>
        <div class="input_container pb-5">

            <input type="text" value="***************" readonly style="padding-right: 35px;">
            <button class="btn__input--edit" (click)="zmienHaslo()"></button>
        </div>

        <div class="input_container pb-3">
            <button class="btn__primary" (click)="zapisz()">Zapisz</button>
        </div>
    </div>
</div>