import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { ParujRecznieModel } from 'src/app/modele/projekty/parowanie/parujRecznieModel';
import { PobierzKomentarzParowaniaModel } from 'src/app/modele/projekty/parowanie/pobierzKomentarzParowaniaModel';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { EwidencjaService } from 'src/app/serwisy/projekty/ewidencja.service';
import { ParowanieService } from 'src/app/serwisy/projekty/parowanie.service';

@Component({
  selector: 'app-komentarz-parowania-modal',
  templateUrl: './komentarz-parowania-modal.component.html',
  styleUrls: ['./komentarz-parowania-modal.component.css']
})
export class KomentarzParowaniaModalComponent extends BazowyComponent implements OnInit {

  model: ParujRecznieModel;
  czyZaladowanoDane: boolean;

  constructor(
    public router: Router,
    modalService: NgbModal,
    public activeModal: NgbActiveModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public parowanieSerwis: ParowanieService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translateSerwis);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.pobierzKomentarzParowania()
    });
  }


  pobierzKomentarzParowania() {
    var model = new PobierzKomentarzParowaniaModel();
    model.idLokalizacji = this.model.idLokalizacji;
    model.idProjektu = this.model.idProjektu;
    model.idSrodkaEwidencji = this.model.idSrodkaEwidencji;

    this.parowanieSerwis.pobierzKomentarzParowania(model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.model.komentarzParowania = rezultat.element;
          this.czyZaladowanoDane = true;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        this.activeModal.close(false);
        return;
      }
    );
  }

  zapisz() {
    this.parowanieSerwis.parujRecznie(this.model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.activeModal.close(true);
          return;
        }
        if (this.bladModelu(rezultat)) {
          this.bledyWalidacji = rezultat.bledyModelu;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }

}
