import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ImportujEwidencjeModel } from 'src/app/modele/projekty/ewidencja/importujEwidencjeModel';
import { KolumnaEwidencjiFiltrowanie } from 'src/app/modele/projekty/ewidencja/kolumnaEwidencjiFiltrowanie';
import { PobierzEwidencjeFiltry } from 'src/app/modele/projekty/ewidencja/pobierzEwidencjeFiltry';
import { StatusProjektu } from 'src/app/modele/projekty/statusProjektu';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { Routing } from 'src/app/modele/wspolne/routing';
import {
  KierunekSortowania,
  Sortowanie,
} from 'src/app/modele/wspolne/sortowanie';
import { Stronicowanie } from 'src/app/modele/wspolne/stronicowanie';
import { Zalacznik } from 'src/app/modele/wspolne/zalacznik';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { EwidencjaService } from 'src/app/serwisy/projekty/ewidencja.service';
import { BazowyComponent } from '../../bazowy.component';
import { TakNieModalComponent } from '../../ogolne/tak-nie-modal/tak-nie-modal.component';
import { DodajKomentarzDoEwidencjiModalComponent } from './dodaj-komentarz-do-ewidencji-modal/dodaj-komentarz-do-ewidencji-modal.component';
import { ImportEwidencjiBledyModalComponent } from './import-ewidencji-bledy-modal/import-ewidencji-bledy-modal.component';
import { MapujImportEwidencjiModalComponent } from './mapuj-import-ewidencji-modal/mapuj-import-ewidencji-modal.component';
import { SparowaneSrodkiModalComponent } from './sparowane-srodki-modal/sparowane-srodki-modal.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ewidencja',
  templateUrl: './ewidencja.component.html',
  styleUrls: ['./ewidencja.component.css'],
})
export class EwidencjaComponent extends BazowyComponent implements OnInit {
  idProjektu: string;
  czyZaladowanoDane: boolean;
  calkowitaLiczbaElementow: BigInteger;
  rozmiarTabeli: number = 25;
  czyJestWiecejRekordow: boolean = true;
  czyPokazacMenuSortowanie: boolean;
  importEwidencjiModel: ImportujEwidencjeModel = new ImportujEwidencjeModel();
  wyszukiwanie: RejestrFiltrowanyRequest<PobierzEwidencjeFiltry> =
    new RejestrFiltrowanyRequest<PobierzEwidencjeFiltry>();
  rekordyEwidencji: Array<Array<string>> = new Array();
  kolumnyTabeli: Array<string> = new Array();
  czyPierwszeUruchomienie: boolean = true;
  nazwaProjektu: string;
  czyZaimportowanoEwidencje: boolean;
  czyPokazanoWyszukiwarkeMobile: boolean;
  slideConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: document.getElementsByClassName('btn__left'),
    nextArrow: document.getElementsByClassName('btn__right'),
    infinite: false,
    variableWidth: true,
    initialSlide: 3,
  };
  subsription: Subscription | undefined;

  kolumnaKomentarza: number;
  kolumnaId: number;
  kolumnaStatusu: number;
  statusProjektu: StatusProjektu;

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public ewidencjaSerwis: EwidencjaService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translateSerwis
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.idProjektu = this.activatedRoute.snapshot.paramMap.get('id');
      this.wyszukiwanie.filtry = new PobierzEwidencjeFiltry();
      this.wyszukiwanie.sortowanie = new Sortowanie(
        'lp',
        KierunekSortowania.asc
      );
      this.wyszukiwanie.stronicowanie = new Stronicowanie(false);
      this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
      this.wyszukiwanie.stronicowanie.strona = 1;

      this.pobierzDaneDoEkranu(true);
    });
  }

  pobierzDaneDoEkranu(zLadowaczem: boolean) {
    this.wyszukiwanie.filtry.idProjektu = this.idProjektu;
    this.subsription = this.ewidencjaSerwis
      .pobierzEwidencje(this.wyszukiwanie, zLadowaczem)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.rekordyEwidencji = rezultat.element.dane;
          this.calkowitaLiczbaElementow =
            rezultat.element.calkowitaIloscRekordow;
          this.czyJestWiecejRekordow = rezultat.element.czyJestWiecejRekordow;
          this.kolumnyTabeli = rezultat.element.kolumny;
          this.nazwaProjektu = rezultat.element.nazwaProjektu;
          this.czyZaimportowanoEwidencje =
            rezultat.element.czyZaimportowanoEwidencje;

          this.kolumnaKomentarza = this.kolumnyTabeli.indexOf('Komentarz');
          this.kolumnaId = this.kolumnyTabeli.indexOf('id');
          this.kolumnaStatusu = this.kolumnyTabeli.indexOf('Czy sparowany');
          this.statusProjektu = rezultat.element.statusProjektu;

          if (this.czyPierwszeUruchomienie) {
            this.przygotujFiltry();
            this.czyPierwszeUruchomienie = false;
          }

          this.czyZaladowanoDane = true;

          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  zmienRozmiarTabeli() {
    this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
    this.pobierzDaneDoEkranu(true);
  }

  pobierzNumerRekorduPoczatek() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return this.wyszukiwanie.stronicowanie.rekordPoczatkowy + 1;
  }
  pobierzNumerRekorduKoniec() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return (
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy +
      this.rekordyEwidencji.length
    );
  }

  zmienStrone(oIle: number) {
    this.wyszukiwanie.stronicowanie.strona += oIle;
    this.wyszukiwanie.stronicowanie.rekordPoczatkowy =
      (this.wyszukiwanie.stronicowanie.strona - 1) *
      this.wyszukiwanie.stronicowanie.iloscRekordow;
    this.pobierzDaneDoEkranu(true);
  }

  importujEwidencje() {
    var model = new IdModel();
    model.id = this.idProjektu;
    this.ewidencjaSerwis.czyIstniejeImport(model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        if (rezultat.element.czyJestImport) {
          this.modalReference = this.modalService.open(
            TakNieModalComponent,
            new ModalOpcje(true, 'static')
          );
          this.modalReference.componentInstance.pytanie =
            'Istnieje już zaimportowana ewidencja. Czy na pewno chcesz nadpisać istniejącą?';
          this.modalReference.result.then(
            (result) => {
              if (result) {
                document.getElementById('file-upload').click();
              }
            },
            (onRejected) => {}
          );
          return;
        }

        document.getElementById('file-upload').click();
        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  wybierzZalacznikZDysku(event: any) {
    var plik = event.target.files[0];
    this.wybierzZalacznik(plik);

    event.target.value = null;
    return false;
  }

  wybierzZalacznik(plik: File) {
    if (plik) {
      var reader = new FileReader();
      reader.readAsDataURL(plik);
      reader.onload = () => {
        var zalacznik = new Zalacznik();
        zalacznik.nazwaPliku = plik.name;
        zalacznik.plik = this.usunZbedneZnakiZPliku(reader.result.toString());
        if (zalacznik.plik == 'data:') {
          return;
        }
        zalacznik.dataModyfikacji = new Date(plik.lastModified);
        zalacznik.format = plik.name.split('.').pop().toLowerCase();

        this.importEwidencjiModel.zalacznik = zalacznik;
        this.importEwidencjiModel.projektId = this.idProjektu;
        this.importuj();
      };
    }
  }

  usunZbedneZnakiZPliku(base64: string) {
    if (base64.includes(',')) {
      return base64.substring(base64.indexOf(',') + 1);
    }
    return base64;
  }

  importuj() {
    this.ewidencjaSerwis
      .importuj(this.importEwidencjiModel)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          if (this.czySaWynikiNaLiscie(rezultat.element.listaBledow)) {
            this.modalReference = this.modalService.open(
              ImportEwidencjiBledyModalComponent,
              new ModalOpcje(true, 'static')
            );
            this.modalReference.componentInstance.listaBledow =
              rezultat.element.listaBledow;

            return;
          }

          this.modalReference = this.modalService.open(
            MapujImportEwidencjiModalComponent,
            new ModalOpcje(true, 'static')
          );
          this.modalReference.componentInstance.dane = rezultat.element;
          this.modalReference.componentInstance.idProjektu = this.idProjektu;
          this.modalReference.result.then(
            (result) => {
              if (result) {
                this.czyPierwszeUruchomienie = true;
                this.pobierzDaneDoEkranu(true);
              }
            },
            (onRejected) => {}
          );
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  cofnij() {
    this.router.navigate([Routing.Lokalizacje, this.idProjektu]);
  }

  pobierzKierunekSortowania(kolumna: string) {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      return '';
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      return 'up';
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      return 'down';
    }
  }

  zmienKierunekSortowania(kolumna: string) {
    this.czyPokazacMenuSortowanie = false;
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.desc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        'id',
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
  }

  wyczyscFiltry() {
    this.wyszukiwanie.filtry.kolumnyFiltrowania.forEach((k) => {
      k.wartoscSzukaj = '';
    });
    this.pobierzDaneDoEkranu(true);
  }

  filtrujPoTekscie(event: any, kolumna: string) {
    this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
    this.wyszukiwanie.stronicowanie.strona = 1;
    var wartosc = event.target.value.toString();

    this.ustawWartoscFiltrowania(kolumna, wartosc);
    this.subsription?.unsubscribe();
    this.pobierzDaneDoEkranu(false);
  }

  ustawWartoscFiltrowania(kolumna: string, wartosc: string) {
    var kolumnaFiltrowania = this.wyszukiwanie.filtry.kolumnyFiltrowania.find(
      (m) => m.kolumna == kolumna
    );

    if (kolumnaFiltrowania == undefined) {
      kolumnaFiltrowania = new KolumnaEwidencjiFiltrowanie();
      kolumnaFiltrowania.kolumna = kolumna;
      kolumnaFiltrowania.wartoscSzukaj = wartosc;
      this.wyszukiwanie.filtry.kolumnyFiltrowania.push(kolumnaFiltrowania);
    } else {
      this.wyszukiwanie.filtry.kolumnyFiltrowania.forEach((k) => {
        if (k.kolumna == kolumna) {
          k.wartoscSzukaj = wartosc;
        }
      });
    }
  }

  czyPokazacPrzyciskCzyszczeniaFiltru(kolumna: string) {
    var kolumnaFiltrowania = this.wyszukiwanie.filtry.kolumnyFiltrowania.find(
      (m) => m.kolumna == kolumna
    );
    return kolumnaFiltrowania != undefined && kolumnaFiltrowania.wartoscSzukaj;
  }
  wyczyscFiltrKolumny(kolumna: string) {
    this.wyszukiwanie.filtry.kolumnyFiltrowania.forEach((k) => {
      if (k.kolumna == kolumna) {
        k.wartoscSzukaj = '';
      }
    });
    this.pobierzDaneDoEkranu(false);
  }

  przygotujFiltry() {
    this.wyszukiwanie.filtry.kolumnyFiltrowania = new Array();
    this.kolumnyTabeli.forEach((kolumna) => {
      if (kolumna == 'id') {
        return;
      }
      var kolumnaFiltrowania = new KolumnaEwidencjiFiltrowanie();
      kolumnaFiltrowania.kolumna = kolumna;
      kolumnaFiltrowania.wartoscSzukaj = '';
      this.wyszukiwanie.filtry.kolumnyFiltrowania.push(kolumnaFiltrowania);
    });
  }

  czyPokazacWyczyscFiltry() {
    return (
      this.wyszukiwanie.filtry.kolumnyFiltrowania.filter((m) => m.wartoscSzukaj)
        .length > 0
    );
  }
  pokazWyszukiwarkeMobile(czyPokazac: boolean) {
    this.czyPokazanoWyszukiwarkeMobile = czyPokazac;
  }

  czyKolumnaLp(kolumna: string) {
    return kolumna.toLowerCase().includes('lp');
  }

  wyczyscEwidencje() {
    this.modalReference = this.modalService.open(
      TakNieModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.pytanie =
      'Czy na pewno chcesz wyczyścić ewidencję?';
    this.modalReference.result.then(
      (result) => {
        if (result) {
          var model = new IdModel();
          model.id = this.idProjektu;
          this.ewidencjaSerwis.wyczyscEwidencje(model).subscribe((rezultat) => {
            if (this.czyRezultatOk(rezultat)) {
              this.wyswietlKomunikat('Ewidencja została wyczyszczona');
              this.czyPierwszeUruchomienie = false;
              this.wyszukiwanie.filtry.kolumnyFiltrowania = new Array();
              this.pobierzDaneDoEkranu(true);

              return;
            }

            this.wyswietlKomunikat(rezultat.wiadomosc, true);
            return;
          });
        }
      },
      (onRejected) => {}
    );
  }

  dodajKomentarz(rekord: Array<string>, tresc: string) {
    var id = rekord[this.kolumnaId];

    this.modalReference = this.modalService.open(
      DodajKomentarzDoEwidencjiModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.idProjektu = this.idProjektu;
    this.modalReference.componentInstance.id = id;
    this.modalReference.componentInstance.tresc = tresc;
    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.pobierzDaneDoEkranu(true);
        }
      },
      (onRejected) => {}
    );
  }

  czySrodekSparowany(wartosc: string) {
    return wartosc == 'Tak';
  }

  pokazPowiazanySrodek(rekord: Array<string>) {
    var id = rekord[this.kolumnaId];

    this.modalReference = this.modalService.open(
      SparowaneSrodkiModalComponent,
      new ModalOpcje(true, 'static', null, 'modal-dialog--fluid')
    );
    this.modalReference.componentInstance.idProjektu = this.idProjektu;
    this.modalReference.componentInstance.id = id;
    this.modalReference.result.then(
      (result) => {},
      (onRejected) => {}
    );
  }

  czyMoznaEdytowacEwidencji() {
    return this.statusProjektu != StatusProjektu.zarchiwizowany;
  }
}
