import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { PobierzPomieszczeniaFiltr } from 'src/app/modele/projekty/pomieszczenia/pobierzPomieszczeniaFiltr';
import { Pomieszczenie } from 'src/app/modele/projekty/pomieszczenia/pomieszczenie';
import { EksportujDaneModel } from 'src/app/modele/projekty/spis/eksportujDaneModel';
import { StatusProjektu } from 'src/app/modele/projekty/statusProjektu';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { Routing } from 'src/app/modele/wspolne/routing';
import {
  Sortowanie,
  KierunekSortowania,
} from 'src/app/modele/wspolne/sortowanie';
import { Stronicowanie } from 'src/app/modele/wspolne/stronicowanie';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { EksportDanychService } from 'src/app/serwisy/projekty/eksport-danych.service';
import { PomieszczeniaService } from 'src/app/serwisy/projekty/pomieszczenia.service';
import { DodajPomieszczenieModalComponent } from '../dodaj-pomieszczenie-modal/dodaj-pomieszczenie-modal.component';
import { EdytujPomieszczenieModalComponent } from '../edytuj-pomieszczenie-modal/edytuj-pomieszczenie-modal.component';
import { PrzeniesPomieszczeniaLokalizacjeModalComponent } from '../przenies-pomieszczenia-lokalizacje-modal/przenies-pomieszczenia-lokalizacje-modal.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pomieszczenia-lokalizacji',
  templateUrl: './pomieszczenia-lokalizacji.component.html',
  styleUrls: ['./pomieszczenia-lokalizacji.component.css'],
})
export class PomieszczeniaLokalizacjiComponent
  extends BazowyComponent
  implements OnInit
{
  idProjektu: string;
  idLokalizacji: string;
  czyZaladowanoDane: boolean;
  calkowitaLiczbaElementow: BigInteger;
  rozmiarTabeli: number = 25;
  czyJestWiecejRekordow: boolean = true;
  czyPokazacMenuSortowanie: boolean;
  wyszukiwanie: RejestrFiltrowanyRequest<PobierzPomieszczeniaFiltr> =
    new RejestrFiltrowanyRequest<PobierzPomieszczeniaFiltr>();
  pomieszczenia: Array<Pomieszczenie> = new Array();
  adresLokalizacji: string;
  czyPokazanoWyszukiwarkeMobile: boolean;
  nazwaProjektu: string;
  czyPokazanoPodmenu: boolean;
  statusProjektu: StatusProjektu;
  czyPrzenoszeniePomieszczen: boolean;
  czyZaznaczonoWszystko: boolean;
  subsription: Subscription | undefined;

  slideConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: document.getElementsByClassName('btn__left'),
    nextArrow: document.getElementsByClassName('btn__right'),
    infinite: false,
    variableWidth: true,
  };
  modalReference: any;
  modalService: any;

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public pomieszczeniaSerwis: PomieszczeniaService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute,
    public eksportDanychSerwis: EksportDanychService
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translateSerwis
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.idLokalizacji =
        this.activatedRoute.snapshot.paramMap.get('idLokalizacji');
      this.idProjektu = this.activatedRoute.snapshot.paramMap.get('idProjektu');
      this.wyszukiwanie.filtry = new PobierzPomieszczeniaFiltr();
      this.wyszukiwanie.filtry.lokalizacjaId = this.idLokalizacji;
      this.wyszukiwanie.sortowanie = new Sortowanie(
        'nazwa',
        KierunekSortowania.asc
      );
      this.wyszukiwanie.stronicowanie = new Stronicowanie(false);
      this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
      this.wyszukiwanie.stronicowanie.strona = 1;

      this.pobierzDaneDoEkranu(true);
    });
  }

  pobierzDaneDoEkranu(zLadowaczem: boolean) {
    this.subsription = this.pomieszczeniaSerwis
      .pobierzPomieszczeniaLokalizacji(this.wyszukiwanie, zLadowaczem)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.pomieszczenia = rezultat.element.pomieszczenia;
          this.calkowitaLiczbaElementow =
            rezultat.element.calkowitaIloscRekordow;
          this.czyJestWiecejRekordow = rezultat.element.czyJestWiecejRekordow;
          this.adresLokalizacji = rezultat.element.adresLokalizacji;
          this.nazwaProjektu = rezultat.element.nazwaProjektu;
          this.statusProjektu = rezultat.element.statusProjektu;

          this.czyZaladowanoDane = true;
          this.anulujPrzenoszeniePomieszczen();
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  eksportujDane() {
    var model = new EksportujDaneModel();
    model.idLokalizacji = this.idLokalizacji;
    model.idProjektu = this.idProjektu;

    this.eksportDanychSerwis
      .eksportujSpisaneSrodkiLokalizacji(model)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.bledyWalidacji = null;
          var a = document.createElement('a');
          a.target = '_blank';
          a.href =
            'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
            rezultat.element.plik;
          a.download = rezultat.element.nazwaPliku;
          a.click();
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  zmienRozmiarTabeli() {
    this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
    this.pobierzDaneDoEkranu(true);
  }

  pobierzNumerRekorduPoczatek() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return this.wyszukiwanie.stronicowanie.rekordPoczatkowy + 1;
  }
  pobierzNumerRekorduKoniec() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return (
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy +
      this.pomieszczenia.length
    );
  }

  zmienStrone(oIle: number) {
    this.wyszukiwanie.stronicowanie.strona += oIle;
    this.wyszukiwanie.stronicowanie.rekordPoczatkowy =
      (this.wyszukiwanie.stronicowanie.strona - 1) *
      this.wyszukiwanie.stronicowanie.iloscRekordow;
    this.pobierzDaneDoEkranu(true);
  }

  pokazMenuSortowania() {
    this.czyPokazacMenuSortowanie = !this.czyPokazacMenuSortowanie;
  }

  pobierzKierunekSortowania(kolumna: string) {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      return '';
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      return 'up';
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      return 'down';
    }
  }

  zmienKierunekSortowania(kolumna: string) {
    this.czyPokazacMenuSortowanie = false;
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.desc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        'Id',
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
  }

  pobierzKolumneSortowania() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    switch (kierunki[0].nazwaKolumny) {
      case 'nazwa':
        return 'Nazwa';
      case 'pietro':
        return 'Piętro';

      case 'mpk':
        return 'MPK';
      case 'opis':
        return 'Opis';
      case 'osoba':
        return 'Osoba';
      case 'inne':
        return 'Inne';

      case 'sumaSpisanychSrodkow':
        return 'Spisane środki';
      case 'sparowaneSrodki':
        return 'Sparowane';
      case 'utworzyl':
        return 'Utworzył';
    }
  }

  pobierzKierunekSortowaniaMobile() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    if (kierunki[0].kierunekSortowania == KierunekSortowania.asc) {
      return 'up';
    }
    if (kierunki[0].kierunekSortowania == KierunekSortowania.desc) {
      return '';
    }
  }

  zmienKierunekSortowaniaMobile() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki[0];

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kierunek.nazwaKolumny,
        KierunekSortowania.desc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kierunek.nazwaKolumny,
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
  }
  wyczyscSortowanieMobile() {
    this.wyszukiwanie.sortowanie = new Sortowanie('Id', KierunekSortowania.asc);
    this.pobierzDaneDoEkranu(true);
  }

  cofnij() {
    this.router.navigate([Routing.Spis, this.idProjektu]);
  }

  filtrujPoTekscie() {
    this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
    this.wyszukiwanie.stronicowanie.strona = 1;
    this.subsription?.unsubscribe();
    this.pobierzDaneDoEkranu(false);
  }

  czyPokazacWyczyscFiltry() {
    return (
      this.wyszukiwanie.filtry.nazwa ||
      this.wyszukiwanie.filtry.pietro ||
      this.wyszukiwanie.filtry.sparowaneSrodki ||
      this.wyszukiwanie.filtry.sumaSpisanychSrodkow ||
      this.wyszukiwanie.filtry.utworzyl
    );
  }

  wyczyscFiltry() {
    this.wyszukiwanie.filtry.nazwa = '';
    this.wyszukiwanie.filtry.pietro = '';
    this.wyszukiwanie.filtry.sparowaneSrodki = null;
    this.wyszukiwanie.filtry.sumaSpisanychSrodkow = null;
    this.wyszukiwanie.filtry.utworzyl = '';

    this.pobierzDaneDoEkranu(true);
  }

  pokazWyszukiwarkeMobile(czyPokazac: boolean) {
    this.czyPokazanoWyszukiwarkeMobile = czyPokazac;
    this.czyPokazanoPodmenu = false;
  }

  dodajPomieszczenie() {
    this.modalReference = this.modalService.open(
      DodajPomieszczenieModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.idLokalizacji = this.idLokalizacji;
    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.pobierzDaneDoEkranu(true);
        }
      },
      (onRejected) => {}
    );
  }

  edytujPomieszczenie(id: string) {
    this.modalReference = this.modalService.open(
      EdytujPomieszczenieModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.idLokalizacji = this.idLokalizacji;
    this.modalReference.componentInstance.idPomieszczenia = id;
    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.pobierzDaneDoEkranu(true);
        }
      },
      (onRejected) => {}
    );
  }

  pokazEkranPomieszczen() {
    this.router.navigate([
      Routing.PomieszczeniaLokalizacji,
      this.idProjektu,
      this.idLokalizacji,
    ]);
  }

  pokazEkranNadwyzek() {
    this.router.navigate([
      Routing.NadwyzkiLokalizacji,
      this.idProjektu,
      this.idLokalizacji,
    ]);
  }

  pokazEkranSpisanychSrodkow() {
    this.router.navigate([
      Routing.SpisaneSrodkiLokalizacji,
      this.idProjektu,
      this.idLokalizacji,
    ]);
  }

  pokazEkranNiedoborow() {
    this.router.navigate([
      Routing.NiedoboryLokalizacji,
      this.idProjektu,
      this.idLokalizacji,
    ]);
  }

  pokazPodmenu() {
    this.czyPokazanoPodmenu = !this.czyPokazanoPodmenu;
    this.czyPokazanoWyszukiwarkeMobile = false;
  }

  wybierzPomieszczenie(id: string) {
    if (this.czyPrzenoszeniePomieszczen) {
      var pomieszczenie = this.pomieszczenia.filter((m) => m.id == id)[0];
      if (pomieszczenie.czyWybrano) {
        this.czyZaznaczonoWszystko = false;
        pomieszczenie.czyWybrano = false;
      } else {
        pomieszczenie.czyWybrano = true;
      }
      return;
    }

    this.router.navigate([
      Routing.SpisaneSrodkiPomieszczenia,
      this.idProjektu,
      this.idLokalizacji,
      id,
    ]);
  }

  wygenerujArkusz() {
    this.router.navigate([
      '1',
      Routing.WyborRaportu,
      this.idProjektu,
      this.idLokalizacji,
    ]);
  }

  czyProjektZarchiwizowany() {
    return this.statusProjektu == StatusProjektu.zarchiwizowany;
  }

  przeniesPomieszczenia() {
    if (this.pomieszczenia.filter((m) => m.czyWybrano).length == 0) {
      this.wyswietlKomunikat('Wybierz pomieszczenia do przeniesienia', true);
      return;
    }

    this.modalReference = this.modalService.open(
      PrzeniesPomieszczeniaLokalizacjeModalComponent,
      new ModalOpcje(true, 'static', null, 'full-screen-width-modal')
    );
    this.modalReference.componentInstance.idProjektu = this.idProjektu;
    this.modalReference.componentInstance.idPomieszczen = this.pomieszczenia
      .filter((m) => m.czyWybrano)
      .map((m) => m.id);
    this.modalReference.componentInstance.lokalizacjaAktualnaId =
      this.idLokalizacji;

    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.czyPrzenoszeniePomieszczen = false;
          this.pobierzDaneDoEkranu(true);
        }
      },
      (onRejected) => {}
    );
  }

  anulujPrzenoszeniePomieszczen() {
    this.czyPrzenoszeniePomieszczen = false;
  }
  rozpocznijPrzenoszeniePomieszczen() {
    this.czyPrzenoszeniePomieszczen = true;
    this.odznaczWszystko();
  }

  zaznaczWszystko() {
    this.czyZaznaczonoWszystko = true;
    this.pomieszczenia.forEach((p) => {
      p.czyWybrano = true;
    });
  }

  odznaczWszystko() {
    this.czyZaznaczonoWszystko = false;
    this.pomieszczenia.forEach((p) => {
      p.czyWybrano = false;
    });
  }

  pomieszczenieZmiana(e) {
    if (!e.target.checked) {
      this.czyZaznaczonoWszystko = false;
    }
  }
}
