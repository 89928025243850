export enum Routing {
  Zaloguj = 'login',
  RejestracjaPowodzenie = 'register-success',
  RejestracjaPotwierdzenie = 'confirm',
  ZmienHaslo = 'change-password',
  NoweHaslo = 'new-password',
  Profil = 'profile',
  ZmianaEmail = 'confirm-email',
  StronaGlowna = '/',
  Projekty = 'projects',
  Uzytkownicy = 'users',
  NowyProjekt = 'new-project',
  EdytujProjekt = 'edit-project',
  SzczegolyProjektu = 'project-details',
  Ewidencja = 'client-data',
  Lokalizacje = 'locations',
  SlownikiProjektu = 'project-dictionaries',
  SlownikiDomyslne = 'default-dictionaries',
  Zespoly = 'teams',
  PolaSpisu = 'inventory-fields',
  Spis = 'inventory',
  KonfiguracjaPomieszczen = 'room-configuration',
  PomieszczeniaLokalizacji = 'rooms',
  SpisaneSrodkiLokalizacji = 'location-inventory-all',
  NadwyzkiLokalizacji = 'location-inventory-surplus',
  NiedoboryLokalizacji = 'location-inventory-shortages',
  SpisaneSrodkiPomieszczenia = 'room-inventory-all',
  NadwyzkiPomieszczenia = 'room-inventory-surplus',
  SpisSrodka = 'inventory-new',
  EdycjaSrodka = 'inventory-edit',
  WyborRaportu = 'report-selection',
  ArkuszSpisu = 'report-generate',
  Parowanie = 'inventory-connection',
  Ustawienia = 'admin/settings',
  OswiadczeniaAutoryzacja = 'external/statements-authorize',
  OswiadczeniaAutoryzacjaKod = 'external/statements-authorize-code',
  OswiadczeniaInstrukcja = 'external/statements-instruction',
  OswiadczeniaDanePodstawowe = 'external/statements-user-data',
  Oswiadczenia = 'external/statements',
}
