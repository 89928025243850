import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appTylkoRegex]'
})
export class TylkoRegexDirective {

  public text;

  constructor(private el: ElementRef) {
  }
  @Input('wyrazenie-regularne') wyrazenieRegularne: string;

  @HostListener('paste', ['$event'])
  @HostListener('keypress', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);

    if ((next && (!next.match(this.wyrazenieRegularne) || next.match(this.wyrazenieRegularne)[0] != next))) {
      event.preventDefault();
    }
  }

}
