<div class="modal-content" *ngIf="czyZaladowanoDane">
    <div class="modal-body">
        <button aria-label="Close" class="close" data-dismiss="modal" type="button"
            (click)="activeModal.dismiss()"></button>
        <div class="ft__01 text-center py-4 ">Komentarz parowania</div>
        <div class="text-center mb-2"></div>
        <form action="">

            <div class="input_container pb-3" [ngClass]="czyBladWalidacyjny('tresc')?'error':''">
                <textarea class="big_textarea" placeholder="Komentarz parowania" name="tresc" maxlength="500"
                    [(ngModel)]="model.komentarzParowania" cols="30" rows="10"></textarea>

                <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('komentarzParowania')">
                    {{pobierzBladWalidacyjny("komentarzParowania") }}
                </div>
            </div>


            <div class="input_container pb-3">
                <button class="btn__primary" (click)="zapisz()">Zapisz</button>
            </div>
            <div class="input_container pb-3">
                <button class="btn__link" (click)="activeModal.dismiss()">Anuluj</button>
            </div>
        </form>
    </div>
</div>