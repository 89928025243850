import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LokalizacjeProjektu } from 'src/app/modele/projekty/lokalizacje/lokalizacjeProjektu';
import { PobierzLokalizacjeFiltr } from 'src/app/modele/projekty/lokalizacje/pobierzLokalizacjeFiltr';
import { DaneKonfiguracyjneDoEdycjiPomieszczenia } from 'src/app/modele/projekty/pomieszczenia/daneKonfiguracyjneDoEdycjiPomieszczenia';
import { DodajPomieszczenieModel } from 'src/app/modele/projekty/pomieszczenia/dodajPomieszczenieModel';
import { EdytujPomieszczenieModel } from 'src/app/modele/projekty/pomieszczenia/edytujPomieszczenieModel';
import { PobierzPomieszczeniaFiltr } from 'src/app/modele/projekty/pomieszczenia/pobierzPomieszczeniaFiltr';
import { PomieszczeniaLokalizacji } from 'src/app/modele/projekty/pomieszczenia/pomieszczeniaLokalizacji';
import { SzczegolyPomieszczenia } from 'src/app/modele/projekty/pomieszczenia/szczegolyPomieszczenia';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { PojedynczyElementResponse } from 'src/app/modele/wspolne/pojedynczyElement';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { ResponseBazowy } from 'src/app/modele/wspolne/responseBazowy';
import { SerwisBazowy } from '../bazowy.service';
import { AuthService } from '../ogolne/auth.service';
import { ErrorService } from '../ogolne/error.service';
import { LoadingService } from '../ogolne/loading.service';
import { PrzeniesPomieszczeniaModel } from 'src/app/modele/projekty/pomieszczenia/przeniesPomieszczeniaModel';

@Injectable({
  providedIn: 'root'
})
export class PomieszczeniaService extends SerwisBazowy {


  constructor(
    httpClient: HttpClient,
    authService: AuthService,
    errorService: ErrorService,
    loaderService: LoadingService,
    translate: TranslateService) {
    super(httpClient, authService, errorService, loaderService, translate);
  }

  public pobierzPomieszczeniaLokalizacji(model: RejestrFiltrowanyRequest<PobierzPomieszczeniaFiltr>, zLadowaczem: boolean) {
    if (zLadowaczem) {
      return this.wykonajAkcje<RejestrFiltrowanyRequest<PobierzPomieszczeniaFiltr>, PojedynczyElementResponse<PomieszczeniaLokalizacji>>
        (this.AdresApi + "Pomieszczenia/PobierzPomieszczeniaLokalizacji", model);
    }
    else {
      return this.wykonajAkcjeBezLadowacza<RejestrFiltrowanyRequest<PobierzPomieszczeniaFiltr>, PojedynczyElementResponse<PomieszczeniaLokalizacji>>
        (this.AdresApi + "Pomieszczenia/PobierzPomieszczeniaLokalizacji", model);
    }
  }

  public dodajPomieszczenie(model: DodajPomieszczenieModel) {
    return this.wykonajAkcje<DodajPomieszczenieModel, PojedynczyElementResponse<string>>
      (this.AdresApi + "Pomieszczenia/DodajPomieszczenie", model);
  }

  public edytujPomieszczenie(model: EdytujPomieszczenieModel) {
    return this.wykonajAkcje<EdytujPomieszczenieModel, ResponseBazowy>
      (this.AdresApi + "Pomieszczenia/EdytujPomieszczenie", model);
  }

  public pobierzDaneKonfiguracyjneDoEdycjiPomieszczenia(model: IdModel) {
    return this.wykonajAkcje<IdModel, PojedynczyElementResponse<DaneKonfiguracyjneDoEdycjiPomieszczenia>>
      (this.AdresApi + "Pomieszczenia/PobierzDaneKonfiguracyjneDoEdycjiPomieszczenia", model);
  }

  public pobierzSzczegoly(model: IdModel) {
    return this.wykonajAkcje<IdModel, PojedynczyElementResponse<SzczegolyPomieszczenia>>
      (this.AdresApi + "Pomieszczenia/PobierzSzczegoly", model);
  }

  usun(model: IdModel) {
    return this.wykonajAkcje<IdModel, ResponseBazowy>
      (this.AdresApi + "Pomieszczenia/UsunPomieszczenie", model);
  }

  public przeniesPomieszczenia(model: PrzeniesPomieszczeniaModel) {
    return this.wykonajAkcje<PrzeniesPomieszczeniaModel, ResponseBazowy>
      (this.AdresApi + "Pomieszczenia/PrzeniesPomieszczenia", model);
  }
}
