import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { TakNieModalComponent } from 'src/app/komponenty/ogolne/tak-nie-modal/tak-nie-modal.component';
import { EdytujPoleSpisuModel } from 'src/app/modele/projekty/polaSpisu/edytujPoleSpisuModel';
import { RodzajPolaSpisu } from 'src/app/modele/projekty/polaSpisu/rodzajPolaSpisu';
import { WymagalnoscPolaSpisu } from 'src/app/modele/projekty/polaSpisu/wymagalnoscPolaSpisu';
import { ComboBox } from 'src/app/modele/wspolne/comboBox';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { PolaSpisuService } from 'src/app/serwisy/projekty/pola-spisu.service';
import { SlownikiService } from 'src/app/serwisy/projekty/slowniki.service';

@Component({
  selector: 'app-edytuj-pole-modal',
  templateUrl: './edytuj-pole-modal.component.html',
  styleUrls: ['./edytuj-pole-modal.component.css'],
})
export class EdytujPoleModalComponent
  extends BazowyComponent
  implements OnInit
{
  idPola: string;
  model: EdytujPoleSpisuModel = new EdytujPoleSpisuModel();
  slowniki: Array<ComboBox> = new Array();
  slownikiWymagalnosci: Array<ComboBox> = new Array();
  polaSpisuWymagalnosci: Array<ComboBox> = new Array();
  kolumny: Array<ComboBox> = new Array();
  czyZaladowanoDane: boolean;
  separatoryKoduQr = [',', ';', '.', '-', '/'];
  //@ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

  constructor(
    public router: Router,
    modalService: NgbModal,
    public activeModal: NgbActiveModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public polaSpisuSerwis: PolaSpisuService,
    public slownikiSerwis: SlownikiService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translateSerwis
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.model.id = this.idPola;
      this.pobierzDaneDoEkranu();
    });
  }

  pobierzDaneDoEkranu() {
    var model = new IdModel();
    model.id = this.idPola;
    this.polaSpisuSerwis.pobierzPoleDoEdycji(model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.model = rezultat.element;

        if (
          rezultat.element.rodzajPolaSpisu ==
          RodzajPolaSpisu.slownikJednokolumnowy
        ) {
          this.pobierzSlownikiJednokolumnowe();
        }
        if (
          rezultat.element.rodzajPolaSpisu ==
          RodzajPolaSpisu.slownikWielokolumnowy
        ) {
          this.pobierzSlownikiWielokolumnowe();
          this.pobierzKolumnySlownika();
        }
        if (
          [
            WymagalnoscPolaSpisu.wymaganeJezeliInnePoleNieJestPuste,
            WymagalnoscPolaSpisu.wymaganeJezeliInnePolePuste,
            WymagalnoscPolaSpisu.wymaganeJezeliWInnymPoluWartoscZeSlownika,
          ].includes(rezultat.element.wymagalnosc)
        ) {
          this.pobierzPolaSpisuDoWymagalnosci();
          if (
            rezultat.element.wymagalnosc ==
            WymagalnoscPolaSpisu.wymaganeJezeliWInnymPoluWartoscZeSlownika
          ) {
            this.pobierzSlownikiJednokolumnowe(true);
          }
        }
        this.czyZaladowanoDane = true;
        return;
      }
      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  zapisz() {
    this.polaSpisuSerwis.edytujPole(this.model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.wyswietlKomunikat('Pole zostało zapisane');
        this.activeModal.close(true);
        return;
      }
      if (this.bladModelu(rezultat)) {
        this.bledyWalidacji = rezultat.bledyModelu;
        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  wybranoRodzajPola() {
    this.model.slownikId = undefined;
    this.model.kolumnaSlownika = undefined;
    if (this.model.rodzajPolaSpisu == RodzajPolaSpisu.slownikJednokolumnowy) {
      this.pobierzSlownikiJednokolumnowe();
    }
    if (this.model.rodzajPolaSpisu == RodzajPolaSpisu.slownikWielokolumnowy) {
      this.pobierzSlownikiWielokolumnowe();
    }
    if (this.model.rodzajPolaSpisu == RodzajPolaSpisu.checkbox) {
      this.model.wymagalnosc = WymagalnoscPolaSpisu.niewymagane;
      this.model.wymagalnoscPolaSpisuId = Array();
      this.model.wymagalnoscSlownikId = undefined;
      this.model.wymagalnoscPolaSpisuOperator = 0;
    }
    if (this.model.rodzajPolaSpisu == RodzajPolaSpisu.inne) {
      this.model.czyMaskaWlaczonaNaOswiadczeniu = true;
    }
  }

  pobierzSlownikiJednokolumnowe(czyDoWymagalnosci: boolean = false) {
    var model = new IdModel();
    model.id = this.model.projektId;
    this.slownikiSerwis
      .pobierzSlownikiJednokolumnowe(model)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          if (czyDoWymagalnosci) {
            this.slownikiWymagalnosci = rezultat.listaElementow;
          } else {
            this.slowniki = rezultat.listaElementow;
          }
          return;
        }
        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  pobierzSlownikiWielokolumnowe() {
    var model = new IdModel();
    model.id = this.model.projektId;
    this.slownikiSerwis
      .pobierzSlownikiWielokolumnowe(model)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.slowniki = rezultat.listaElementow;
          return;
        }
        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  pobierzKolumnySlownika() {
    var model = new IdModel();
    model.id = this.model.slownikId;
    this.polaSpisuSerwis.pobierzKolumnySlownika(model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.kolumny = rezultat.listaElementow;
        return;
      }
      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  wybranoSlownik() {
    this.model.kolumnaSlownika = undefined;
    if (this.model.rodzajPolaSpisu != RodzajPolaSpisu.slownikWielokolumnowy) {
      return;
    }
    this.pobierzKolumnySlownika();
  }

  usun() {
    var model = new IdModel();
    model.id = this.idPola;

    this.modalReference = this.modalService.open(
      TakNieModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.pytanie =
      'Czy na pewno chcesz usunąć pole spisu?';

    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.polaSpisuSerwis.usunPole(model).subscribe((rezultat) => {
            if (this.czyRezultatOk(rezultat)) {
              this.wyswietlKomunikat('Pole zostało usunięte');
              this.activeModal.close(true);
              return;
            }

            this.wyswietlKomunikat(rezultat.wiadomosc, true);
            return;
          });
        }
      },
      (onRejected) => {}
    );
  }

  wybranoWymagalnosc() {
    if ([0, 1].includes(this.model.wymagalnosc)) {
      return;
    }
    if ([2, 3, 4].includes(this.model.wymagalnosc)) {
      this.model.wymagalnoscPolaSpisuId = Array();
      this.model.wymagalnoscPolaSpisuOperator = 0;
      this.pobierzPolaSpisuDoWymagalnosci();
    }
    if (this.model.wymagalnosc == 3) {
      this.model.wymagalnoscSlownikId = undefined;
      this.pobierzSlownikiJednokolumnowe(true);
    }
  }

  pobierzPolaSpisuDoWymagalnosci() {
    var model = new IdModel();
    model.id = this.model.projektId;
    this.polaSpisuSerwis.pobierzPola(model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.polaSpisuWymagalnosci = rezultat.element.pola
          .filter((m) => ![0, 7].includes(m.rodzajPola) && m.id != this.idPola)
          .map((m) => ({
            id: m.id,
            nazwa: m.nazwaPola,
          }));
        return;
      }
      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }
}
