<div class="modal-content" *ngIf="czyZaladowanoDane">
    <div class="modal-body">
        <button aria-label="Close" class="close" data-dismiss="modal" type="button"
            (click)="activeModal.dismiss()"></button>
        <div class="ft__01 text-center py-4 ">Dodaj pomieszczenie</div>
        <div class="text-center mb-2"></div>
        <form action="">
            <div class="input_container pb-3" [ngClass]="czyBladWalidacyjny('nazwa')?'error':''">
                <mat-form-field class="example-full-width " appearance="fill">
                    <input matInput [formControl]="nazwaFC" placeholder="Nazwa" [matAutocomplete]="nazwa"
                        aria-label="Nazwa" [(ngModel)]="model.nazwa" [ngModelOptions]="{standalone: true}">
                    <mat-autocomplete autoActiveFirstOption #nazwa="matAutocomplete">
                        <mat-option *ngFor="let wartosc of nazwaSlownikObs | async" [value]="wartosc">
                            {{wartosc}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('nazwa')">
                    {{pobierzBladWalidacyjny("nazwa") }}
                </div>
            </div>

            <div class="input_container pb-3" [ngClass]="czyBladWalidacyjny('pietro')?'error':''">
                <mat-form-field class="example-full-width " appearance="fill">
                    <input matInput [formControl]="pietroFC" placeholder="Piętro" [matAutocomplete]="pietro"
                        aria-label="Piętro" [(ngModel)]="model.pietro" [ngModelOptions]="{standalone: true}">
                    <mat-autocomplete autoActiveFirstOption #pietro="matAutocomplete">
                        <mat-option *ngFor="let wartosc of pietroSlownikObs | async" [value]="wartosc">
                            {{wartosc}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('pietro')">
                    {{pobierzBladWalidacyjny("pietro") }}
                </div>
            </div>

            <div class="input_container pb-3" [ngClass]="czyBladWalidacyjny('mpk')?'error':''">
                <mat-form-field class="example-full-width " appearance="fill">
                    <input matInput [formControl]="mpkFC" placeholder="MPK" [matAutocomplete]="mpk"
                        [(ngModel)]="model.mpk" [ngModelOptions]="{standalone: true}">
                    <mat-autocomplete autoActiveFirstOption #mpk="matAutocomplete">
                        <mat-option *ngFor="let wartosc of mpkSlownikObs | async" [value]="wartosc">
                            {{wartosc}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('mpk')">
                    {{pobierzBladWalidacyjny("mpk") }}
                </div>
            </div>

            <div class="input_container pb-3" [ngClass]="czyBladWalidacyjny('opisDodatkowy')?'error':''">
                <mat-form-field class="example-full-width " appearance="fill">
                    <input matInput [formControl]="opisDodatkowyFC" placeholder="Opis dodatkowy"
                        [matAutocomplete]="opisDodatkowy" [(ngModel)]="model.opisDodatkowy"
                        [ngModelOptions]="{standalone: true}">
                    <mat-autocomplete autoActiveFirstOption #opisDodatkowy="matAutocomplete">
                        <mat-option *ngFor="let wartosc of opisDodatkowySlownikObs | async" [value]="wartosc">
                            {{wartosc}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('opisDodatkowy')">
                    {{pobierzBladWalidacyjny("opisDodatkowy") }}
                </div>
            </div>

            <div class="input_container pb-3" [ngClass]="czyBladWalidacyjny('osoba')?'error':''">
                <mat-form-field class="example-full-width " appearance="fill">
                    <input matInput [formControl]="osobaFC" placeholder="Osoba" [matAutocomplete]="osoba"
                        [(ngModel)]="model.osoba" [ngModelOptions]="{standalone: true}">
                    <mat-autocomplete autoActiveFirstOption #osoba="matAutocomplete">
                        <mat-option *ngFor="let wartosc of osobaSlownikObs | async" [value]="wartosc">
                            {{wartosc}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('osoba')">
                    {{pobierzBladWalidacyjny("osoba") }}
                </div>
            </div>

            <div class="input_container pb-3" [ngClass]="czyBladWalidacyjny('inne')?'error':''">
                <mat-form-field class="example-full-width " appearance="fill">
                    <input matInput [formControl]="inneFC" placeholder="Inne" [matAutocomplete]="inne"
                        [(ngModel)]="model.inne" [ngModelOptions]="{standalone: true}">
                    <mat-autocomplete autoActiveFirstOption #inne="matAutocomplete">
                        <mat-option *ngFor="let wartosc of inneSlownikObs | async" [value]="wartosc">
                            {{wartosc}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('inne')">
                    {{pobierzBladWalidacyjny("inne") }}
                </div>
            </div>


            <div class="input_container pb-3">
                <button class="btn__primary" (click)="zapisz()">Zapisz</button>
            </div>
            <div class="input_container pb-3">
                <button class="btn__link" (click)="activeModal.dismiss()">Anuluj</button>
            </div>
        </form>
    </div>
</div>