export class Sortowanie {
    listaSortowania: SortowanieElement[] = new Array();
    constructor(nazwaKolumny: string, kierunekSortowania: KierunekSortowania) {
        var element = new SortowanieElement(nazwaKolumny, kierunekSortowania);

        this.listaSortowania.push(element);
    }
}

export class SortowanieElement {
    kierunekSortowania: KierunekSortowania;
    nazwaKolumny: string;
    constructor(nazwaKolumny: string, kierunekSortowania: KierunekSortowania) {
        this.nazwaKolumny = nazwaKolumny;
        this.kierunekSortowania = kierunekSortowania;
    }
}

export enum KierunekSortowania {
    asc = 0,
    desc = 1
}