import { Component, OnInit } from '@angular/core';
import {
  Router,
  ActivatedRoute,
  NavigationExtras,
  QueryParamsHandling,
} from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
  DOC_ORIENTATION,
  DataUrl,
  NgxImageCompressService,
} from 'ngx-image-compress';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { TakNieModalComponent } from 'src/app/komponenty/ogolne/tak-nie-modal/tak-nie-modal.component';
import { RodzajPolaSpisu } from 'src/app/modele/projekty/polaSpisu/rodzajPolaSpisu';
import { DaneDoSpisuSrodka } from 'src/app/modele/projekty/spis/daneDoSpisuSrodka';
import { EdytujSrodekModel } from 'src/app/modele/projekty/spis/edytujSrodekModel';
import { PobierzSrodekModel } from 'src/app/modele/projekty/spis/pobierzSrodekModel';
import { PobierzSrodekRezultat } from 'src/app/modele/projekty/spis/pobierzSrodekRezultat';
import { PoleSpisuSrodkaDoZapisu } from 'src/app/modele/projekty/spis/poleSpisuSrodkaDoZapisu';
import { SzukajWEwidencjiModel } from 'src/app/modele/projekty/spis/szukajWEwidencjiModel';
import { SzukajWEwidencjiRezultat } from 'src/app/modele/projekty/spis/szukajWEwidencjiRezultat';
import { UsunSrodekModel } from 'src/app/modele/projekty/spis/usunSrodekModel';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { Routing } from 'src/app/modele/wspolne/routing';
import { Zalacznik } from 'src/app/modele/wspolne/zalacznik';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { SpisService } from 'src/app/serwisy/projekty/spis.service';
import { ZnalezioneRekordyEwidencjiModalComponent } from '../znalezione-rekordy-ewidencji-modal/znalezione-rekordy-ewidencji-modal.component';
import IMask from 'imask';
import {
  SzukajWEwidencjiKrzyzowoModel,
  WartoscDoSzukaniaKrzyzowego,
} from 'src/app/modele/projekty/spis/szukajWEwidencjiKrzyzowoModel';
import { KolumnaEwidencjiFiltrowanie } from 'src/app/modele/projekty/ewidencja/kolumnaEwidencjiFiltrowanie';
import { KolumnaSpisuFiltrowanie } from 'src/app/modele/projekty/spis/kolumnaSpisuFiltrowanie';
import {
  HistoriaZmianSrodka,
  PobierzHistorieZmianSrodkaModel,
} from 'src/app/modele/projekty/spis/historiaZmianSrodka';
import { HistoriaZmianSrodkaModalComponent } from '../historia-zmian-srodka-modal/historia-zmian-srodka-modal.component';
import { PobierzDaneDoSpisuSrodkaModel } from 'src/app/modele/projekty/spis/pobierzDaneDoSpisuSrodkaModel';

@Component({
  selector: 'app-edycja-srodka',
  templateUrl: './edycja-srodka.component.html',
  styleUrls: ['./edycja-srodka.component.css'],
})
export class EdycjaSrodkaComponent extends BazowyComponent implements OnInit {
  idLokalizacji: string;
  idProjektu: string;
  idPomieszczenia: string;
  id: string;
  czyZaladowanoDane: boolean;
  daneDoSpisu: DaneDoSpisuSrodka;
  model: EdytujSrodekModel = new EdytujSrodekModel();
  znalezioneRekordyWEwidencji: SzukajWEwidencjiRezultat;
  czyMoznaEdytowac: boolean;

  pokazGalerieZdjec: boolean = false;
  wybraneZdjecie: number = -1;

  fullScrennZdjecia: Array<object> = new Array();

  dateMask = {
    mask: 'dd-MM-yyyy',
    pattern: 'dd-MM-yyyy',
    blocks: {
      dd: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
        maxLength: 2,
      },
      MM: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
        maxLength: 2,
      },
      yyyy: {
        mask: IMask.MaskedRange,
        from: 1900,
        to: 9999,
        maxLength: 4,
      },
    },
  };
  filtryZUrl: KolumnaSpisuFiltrowanie[] = new Array();

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public spisSerwis: SpisService,
    public translateSerwis: TranslateService,
    public activeModal: NgbActiveModal,
    private activatedRoute: ActivatedRoute,
    private imageCompress: NgxImageCompressService
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translateSerwis
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.idLokalizacji =
        this.activatedRoute.snapshot.paramMap.get('idLokalizacji');
      this.idProjektu = this.activatedRoute.snapshot.paramMap.get('idProjektu');
      this.idPomieszczenia =
        this.activatedRoute.snapshot.paramMap.get('idPomieszczenia');
      this.id = this.activatedRoute.snapshot.paramMap.get('id');

      this.model.idProjektu = this.idProjektu;
      this.model.id = this.id;

      this.pobierzFiltryZUrl();
      this.pobierzDaneDoEkranu();
    });
  }

  pobierzDaneDoEkranu() {
    var model = new PobierzDaneDoSpisuSrodkaModel();
    model.idProjektu = this.idProjektu;
    model.idSrodka = this.id;
    model.idPomieszczenia = this.idPomieszczenia;

    this.spisSerwis.pobierzDaneDoSpisuSrodka(model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.daneDoSpisu = rezultat.element;

        this.pobierzDaneSrodka();

        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  pobierzDaneSrodka() {
    var model = new PobierzSrodekModel();
    model.idSrodka = this.id;
    model.idProjektu = this.idProjektu;

    this.spisSerwis.pobierzSrodek(model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.czyMoznaEdytowac = rezultat.element.czyMoznaEdytowac;
        this.uzupelnijDane(rezultat.element);

        this.czyZaladowanoDane = true;

        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  usun() {
    var model = new UsunSrodekModel();
    model.id = this.id;
    model.idLokalizacji = this.idLokalizacji;
    model.idProjektu = this.idProjektu;
    model.idPomieszczenia = this.idPomieszczenia;

    this.modalReference = this.modalService.open(
      TakNieModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.pytanie =
      'Czy na pewno chcesz usunąć środek?';

    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.spisSerwis.usunSrodek(model).subscribe((rezultat) => {
            if (this.czyRezultatOk(rezultat)) {
              this.wyswietlKomunikat('Środek został usunięty');
              this.cofnij();
              return;
            }

            this.wyswietlKomunikat(rezultat.wiadomosc, true);
            return;
          });
        }
      },
      (onRejected) => {}
    );
  }

  cofnij() {
    var nadwyzki =
      this.filtryZUrl?.find((m) => m.kolumna == 'poprzedniEkran')
        ?.wartoscSzukaj == 'nadwyzki';
    var filtry = this.filtryZUrl.filter((m) => m.kolumna != 'poprzedniEkran');
    var qp =
      filtry.length > 0
        ? {
            queryParams: Object.assign(
              {},
              ...filtry.map((m) => ({
                [m.kolumna]: m.wartoscSzukaj,
              }))
            ),
          }
        : {};

    if (this.idPomieszczenia) {
      this.router.navigate(
        [
          nadwyzki
            ? Routing.NadwyzkiPomieszczenia
            : Routing.SpisaneSrodkiPomieszczenia,
          this.idProjektu,
          this.idLokalizacji,
          this.idPomieszczenia,
        ],
        { ...qp }
      );
    } else {
      this.router.navigate(
        [
          nadwyzki
            ? Routing.NadwyzkiLokalizacji
            : Routing.SpisaneSrodkiLokalizacji,
          this.idProjektu,
          this.idLokalizacji,
        ],

        { ...qp }
      );
    }
  }

  czyCheckboxZaznaczonyDomyslnie(wartoscDomyslna: string) {
    return wartoscDomyslna.toLowerCase() == 'tak';
  }

  pobierzMaksymalnaDlugoscPola(maxDlugosc?: number) {
    return maxDlugosc == null || maxDlugosc == undefined || maxDlugosc <= 1
      ? 99
      : maxDlugosc;
  }

  uzupelnijDane(daneSrodka: PobierzSrodekRezultat) {
    this.daneDoSpisu.polaSpisu.forEach((p) => {
      var danePole = daneSrodka.wartosci.find(
        (m) => m.nazwaPola == p.nazwaPola
      );
      var pole = new PoleSpisuSrodkaDoZapisu();
      pole.poleSpisu = p;
      if (danePole && danePole.wartosc && danePole.wartosc.length > 0) {
        pole.wartosc = danePole.wartosc;
      } else {
        pole.wartosc =
          p.wartoscDomyslna && p.wartoscDomyslna.length > 0
            ? p.wartoscDomyslna
            : '';
      }

      if (p.rodzajPolaSpisu == RodzajPolaSpisu.slownikJednokolumnowy) {
        pole.wartosciSlownika = this.daneDoSpisu.slowniki
          .find((m) => m.id == p.slownikId)
          .dane.map((m) => m.kolumna1);
      }

      if (p.rodzajPolaSpisu == RodzajPolaSpisu.slownikWielokolumnowy) {
        var slownik = this.daneDoSpisu.slowniki.find(
          (m) => m.id == p.slownikId
        );
        var wartosciSlownika = new Array();
        if (slownik.listaKolumn.indexOf(p.kolumnaSlownika) == 0) {
          wartosciSlownika = [
            ...new Set(
              slownik.dane
                .map((m) => m.kolumna1)
                .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1))
            ),
          ];
        }
        pole.wartosciSlownika = wartosciSlownika;
      }

      this.model.pola.push(pole);
    });
    this.model.zdjecia = daneSrodka.zdjecia;
    daneSrodka.zdjecia.forEach((m) => {
      this.fullScrennZdjecia.push({
        image: this.budujZdjecieDoWyswietlenia(m),
      });
    });
  }

  onFocusOutEvent($event: any, idPola: string) {
    var pole = this.model.pola.find((m) => m.poleSpisu.id == idPola);
    this.dodajObslugeDopisywaniaZer(pole);
    this.dodajObslugeSlownikowWielokolumnowych(pole);
  }

  selectEvent(wartosc: string, idPola: string) {
    var pole = this.model.pola.find((m) => m.poleSpisu.id == idPola);
    pole.wartosc = wartosc;
    this.dodajObslugeSlownikowWielokolumnowych(pole);
  }
  clearedEvent($event: any, idPola: string) {
    var pole = this.model.pola.find((m) => m.poleSpisu.id == idPola);
    pole.wartosc = '';
    this.dodajObslugeSlownikowWielokolumnowych(pole);
  }

  dodajObslugeDopisywaniaZer(pole: PoleSpisuSrodkaDoZapisu) {
    if (!pole.wartosc || pole.wartosc.length == 0) {
      return;
    }

    if (pole.poleSpisu.czyDopisacZeraNaPoczatku) {
      pole.wartosc = pole.wartosc.padStart(
        pole.poleSpisu.liczbaZerDoUzupelnienia,
        '0'
      );
    }
    if (pole.poleSpisu.czyDopisacZeraNaKoncu) {
      pole.wartosc = pole.wartosc.padEnd(
        pole.poleSpisu.liczbaZerDoUzupelnienia,
        '0'
      );
    }
  }

  dodajObslugeSlownikowWielokolumnowych(pole: PoleSpisuSrodkaDoZapisu) {
    if (
      pole.poleSpisu.rodzajPolaSpisu != RodzajPolaSpisu.slownikWielokolumnowy
    ) {
      return;
    }
    var slownik = this.daneDoSpisu.slowniki.find(
      (m) => m.id == pole.poleSpisu.slownikId
    );
    var polaSpisuTegoSamegoSlownika = this.model.pola.filter(
      (p) => p.poleSpisu.slownikId == pole.poleSpisu.slownikId
    );
    var bazowyIndeksKolumny = slownik.listaKolumn.indexOf(
      pole.poleSpisu.kolumnaSlownika
    );

    var pole1 = polaSpisuTegoSamegoSlownika.find(
      (m) => slownik.listaKolumn.indexOf(m.poleSpisu.kolumnaSlownika) == 0
    );
    var pole2 = polaSpisuTegoSamegoSlownika.find(
      (m) => slownik.listaKolumn.indexOf(m.poleSpisu.kolumnaSlownika) == 1
    );
    var pole3 = polaSpisuTegoSamegoSlownika.find(
      (m) => slownik.listaKolumn.indexOf(m.poleSpisu.kolumnaSlownika) == 2
    );
    var pole4 = polaSpisuTegoSamegoSlownika.find(
      (m) => slownik.listaKolumn.indexOf(m.poleSpisu.kolumnaSlownika) == 3
    );
    var pole5 = polaSpisuTegoSamegoSlownika.find(
      (m) => slownik.listaKolumn.indexOf(m.poleSpisu.kolumnaSlownika) == 4
    );

    switch (bazowyIndeksKolumny) {
      case 0: {
        if (pole2) {
          var wartosciSlownika = [
            ...new Set(
              slownik.dane
                .filter(
                  (m) =>
                    m.kolumna1.toLowerCase().trim() ==
                      pole1.wartosc.toLowerCase().trim() && !!m.kolumna2
                )
                .map((m) => m.kolumna2)
            ),
          ];
          this.aktualizujWartosciSlownikaWielokolumnowego(
            pole2,
            wartosciSlownika
          );
        }
        if (pole3) {
          var wartosciSlownika = [
            ...new Set(
              slownik.dane
                .filter(
                  (m) =>
                    m.kolumna1.toLowerCase().trim() ==
                      pole1.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna2) ==
                      pole2.wartosc.toLowerCase().trim() &&
                    !!m.kolumna3
                )
                .map((m) => m.kolumna3)
            ),
          ];
          this.aktualizujWartosciSlownikaWielokolumnowego(
            pole3,
            wartosciSlownika
          );
        }
        if (pole4) {
          var wartosciSlownika = [
            ...new Set(
              slownik.dane
                .filter(
                  (m) =>
                    m.kolumna1.toLowerCase().trim() ==
                      pole1.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna2) ==
                      pole2.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna3) ==
                      pole3.wartosc.toLowerCase().trim() &&
                    !!m.kolumna4
                )
                .map((m) => m.kolumna4)
            ),
          ];
          this.aktualizujWartosciSlownikaWielokolumnowego(
            pole4,
            wartosciSlownika
          );
        }
        if (pole5) {
          var wartosciSlownika = [
            ...new Set(
              slownik.dane
                .filter(
                  (m) =>
                    m.kolumna1.toLowerCase().trim() ==
                      pole1.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna2) ==
                      pole2.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna3) ==
                      pole3.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna4) ==
                      pole4.wartosc.toLowerCase().trim() &&
                    !!m.kolumna5
                )
                .map((m) => m.kolumna5)
            ),
          ];
          this.aktualizujWartosciSlownikaWielokolumnowego(
            pole5,
            wartosciSlownika
          );
        }

        break;
      }
      case 1: {
        if (pole3) {
          var wartosciSlownika = [
            ...new Set(
              slownik.dane
                .filter(
                  (m) =>
                    m.kolumna1.toLowerCase().trim() ==
                      pole1.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna2) ==
                      pole2.wartosc.toLowerCase().trim() &&
                    !!m.kolumna3
                )
                .map((m) => m.kolumna3)
            ),
          ];
          this.aktualizujWartosciSlownikaWielokolumnowego(
            pole3,
            wartosciSlownika
          );
        }
        if (pole4) {
          var wartosciSlownika = [
            ...new Set(
              slownik.dane
                .filter(
                  (m) =>
                    m.kolumna1.toLowerCase().trim() ==
                      pole1.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna2) ==
                      pole2.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna3) ==
                      pole3.wartosc.toLowerCase().trim() &&
                    !!m.kolumna4
                )
                .map((m) => m.kolumna4)
            ),
          ];
          this.aktualizujWartosciSlownikaWielokolumnowego(
            pole4,
            wartosciSlownika
          );
        }
        if (pole5) {
          var wartosciSlownika = [
            ...new Set(
              slownik.dane
                .filter(
                  (m) =>
                    m.kolumna1.toLowerCase().trim() ==
                      pole1.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna2) ==
                      pole2.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna3) ==
                      pole3.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna4) ==
                      pole4.wartosc.toLowerCase().trim() &&
                    !!m.kolumna5
                )
                .map((m) => m.kolumna5)
            ),
          ];
          this.aktualizujWartosciSlownikaWielokolumnowego(
            pole5,
            wartosciSlownika
          );
        }
        break;
      }
      case 2: {
        if (pole4) {
          var wartosciSlownika = [
            ...new Set(
              slownik.dane
                .filter(
                  (m) =>
                    m.kolumna1.toLowerCase().trim() ==
                      pole1.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna2) ==
                      pole2.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna3) ==
                      pole3.wartosc.toLowerCase().trim() &&
                    !!m.kolumna4
                )
                .map((m) => m.kolumna4)
            ),
          ];
          this.aktualizujWartosciSlownikaWielokolumnowego(
            pole4,
            wartosciSlownika
          );
        }
        if (pole5) {
          var wartosciSlownika = [
            ...new Set(
              slownik.dane
                .filter(
                  (m) =>
                    m.kolumna1.toLowerCase().trim() ==
                      pole1.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna2) ==
                      pole2.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna3) ==
                      pole3.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna4) ==
                      pole4.wartosc.toLowerCase().trim() &&
                    !!m.kolumna5
                )
                .map((m) => m.kolumna5)
            ),
          ];
          this.aktualizujWartosciSlownikaWielokolumnowego(
            pole5,
            wartosciSlownika
          );
        }
        break;
      }
      case 3: {
        if (pole5) {
          var wartosciSlownika = [
            ...new Set(
              slownik.dane
                .filter(
                  (m) =>
                    m.kolumna1.toLowerCase().trim() ==
                      pole1.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna2) ==
                      pole2.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna3) ==
                      pole3.wartosc.toLowerCase().trim() &&
                    this.nullNaString(m.kolumna4) ==
                      pole4.wartosc.toLowerCase().trim() &&
                    !!m.kolumna5
                )
                .map((m) => m.kolumna5)
            ),
          ];
          this.aktualizujWartosciSlownikaWielokolumnowego(
            pole5,
            wartosciSlownika
          );
        }
        break;
      }
    }
  }

  aktualizujWartosciSlownikaWielokolumnowego(
    pole: PoleSpisuSrodkaDoZapisu,
    wartosci: Array<string>
  ) {
    if (!pole) {
      return;
    }

    pole.wartosciSlownika = wartosci.sort((a, b) =>
      a.toLowerCase() > b.toLowerCase() ? 1 : -1
    );
  }

  wybierzZalacznikZDysku(event: any) {
    var plik = event.target.files[0];
    if (plik) {
      this.pokazLadowacz();

      var reader = new FileReader();
      reader.readAsDataURL(plik);
      reader.onload = () => {
        var zdjecie = new Zalacznik();
        zdjecie.nazwaPliku = plik.name;
        zdjecie.dataModyfikacji = new Date(plik.lastModified);
        zdjecie.format = plik.name.split('.').pop().toLowerCase();

        this.imageCompress
          .compressFile(
            reader.result.toString(),
            DOC_ORIENTATION.NotDefined,
            30,
            60
          )
          .then((plik: DataUrl) => {
            this.schowajLadowacz();
            zdjecie.plik = this.usunZbedneZnakiZPliku(plik);
            this.model.zdjecia.push(zdjecie);
            this.fullScrennZdjecia.push({
              image: plik,
            });
          });
      };
    }
    event.target.value = null;
    return false;
  }

  usunZbedneZnakiZPliku(base64: string) {
    if (base64.includes(',')) {
      return base64.substring(base64.indexOf(',') + 1);
    }
    return base64;
  }

  usunZdjecie(indeks: number) {
    this.model.zdjecia.splice(indeks, 1);
    this.fullScrennZdjecia.splice(indeks, 1);
  }

  pokazGalerie(index) {
    this.wybraneZdjecie = index;
    this.pokazGalerieZdjec = true;
  }
  zamknijGalerie() {
    this.pokazGalerieZdjec = false;
    this.wybraneZdjecie = -1;
  }

  szukajWEwidencji(idPola: string) {
    var pole = this.model.pola.find((m) => m.poleSpisu.id == idPola);
    var mapowanie = this.daneDoSpisu.mapowania.find(
      (m) => m.poleSpisuId == idPola
    );

    if (!mapowanie) {
      return;
    }
    var model = new SzukajWEwidencjiModel();
    model.projektId = this.idProjektu;
    model.kolumna = mapowanie.kolumnaEwidencji;
    model.wartosc = pole.wartosc;

    if (!model.wartosc.length) {
      return;
    }
    this.spisSerwis.szukajWEwidencji(model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.znalezioneRekordyWEwidencji = rezultat.element;
        if (this.znalezioneRekordyWEwidencji.czyZaDuzoRekordow) {
          this.wyswietlKomunikat(
            'Znaleziono za dużo rekordów. Popraw wartość i wyszukaj ponownie',
            true
          );
          return;
        }
        var kolumnaId = this.pobierzIndeksKolumnyEwidencji('id');
        if (this.znalezioneRekordyWEwidencji.dane.length == 0) {
          this.wyswietlKomunikat('Nie odnaleziono rekordów w ewidencji', true);
          return;
        }
        if (this.znalezioneRekordyWEwidencji.dane.length == 1) {
          this.uzupelnijPolaSpisuRekordemZEwidencji(
            this.znalezioneRekordyWEwidencji.dane[0][kolumnaId]
          );
          return;
        }

        this.wyswietlModalZRekordamiEwidencji(rezultat.element);

        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  szukajWEwidencjiKrzyzowo() {
    var model = new SzukajWEwidencjiKrzyzowoModel();
    model.projektId = this.idProjektu;

    this.model.pola.forEach((pole) => {
      if (!pole.poleSpisu.czySzukacWEwidencji || !pole.wartosc.length) {
        return;
      }
      var mapowanie = this.daneDoSpisu.mapowania.find(
        (m) => m.poleSpisuId == pole.poleSpisu.id
      );
      if (!mapowanie) {
        return;
      }

      // var wartosc = new KolumnaEwidencjiFiltrowanie();
      // wartosc.kolumna = mapowanie.kolumnaEwidencji;
      var wartosc = new WartoscDoSzukaniaKrzyzowego();
      wartosc.poleSpisuId = pole.poleSpisu.id;
      wartosc.wartoscSzukaj = pole.wartosc;
      model.wartosci.push(wartosc);
    });

    if (model.wartosci.length == 0) {
      return;
    }

    this.spisSerwis.szukajWEwidencjiKrzyzowo(model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.znalezioneRekordyWEwidencji = rezultat.element;
        if (this.znalezioneRekordyWEwidencji.czyZaDuzoRekordow) {
          this.wyswietlKomunikat(
            'Znaleziono za dużo rekordów. Popraw wartość i wyszukaj ponownie',
            true
          );
          return;
        }
        var kolumnaId = this.pobierzIndeksKolumnyEwidencji('id');
        if (this.znalezioneRekordyWEwidencji.dane.length == 0) {
          this.wyswietlKomunikat('Nie odnaleziono rekordów w ewidencji', true);
          this.przywrocWartosciDomyslnePolTylkoDoOdczytu();
          return;
        }
        if (this.znalezioneRekordyWEwidencji.dane.length == 1) {
          this.uzupelnijPolaSpisuRekordemZEwidencji(
            this.znalezioneRekordyWEwidencji.dane[0][kolumnaId]
          );
          return;
        }

        this.wyswietlModalZRekordamiEwidencji(rezultat.element);

        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  wyswietlModalZRekordamiEwidencji(element: SzukajWEwidencjiRezultat) {
    this.modalReference = this.modalService.open(
      ZnalezioneRekordyEwidencjiModalComponent,
      new ModalOpcje(true, 'static', null, 'modal-dialog--fluid')
    );
    this.modalReference.componentInstance.listaKolumn = element.kolumny;
    this.modalReference.componentInstance.dane = element.dane;
    this.modalReference.result.then(
      (id) => {
        if (id) {
          this.uzupelnijPolaSpisuRekordemZEwidencji(id);
        }
      },
      (onRejected) => {}
    );
  }

  uzupelnijPolaSpisuRekordemZEwidencji(idRekordu: string) {
    var kolumnaId = this.pobierzIndeksKolumnyEwidencji('id');
    var rekordEwidencji = this.znalezioneRekordyWEwidencji.dane.find(
      (m) => m[kolumnaId] == idRekordu
    );

    this.model.pola.forEach((p) => {
      var mapowanie = this.daneDoSpisu.mapowania.find(
        (m) => m.poleSpisuId == p.poleSpisu.id
      );
      if (!mapowanie || !p.poleSpisu.czyUzupelnicAutomatycznieZEwidencji) {
        return;
      }
      var wartoscZEwidencji =
        rekordEwidencji[
          this.pobierzIndeksKolumnyEwidencji(mapowanie.kolumnaEwidencji)
        ];

      p.wartosc = wartoscZEwidencji;
    });
  }

  pobierzIndeksKolumnyEwidencji(kolumna: string) {
    return this.znalezioneRekordyWEwidencji.kolumny.indexOf(kolumna);
  }

  zapisz() {
    this.spisSerwis.edytujSrodek(this.model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.wyswietlKomunikat('Środek został zapisany');
        this.bledyWalidacji = null;
        return;
      }
      if (this.bladModelu(rezultat)) {
        this.bledyWalidacji = rezultat.bledyModelu;
        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  zaznaczCheckbox($event: any, idPola: string) {
    var pole = this.model.pola.find((m) => m.poleSpisu.id == idPola);
    pole.wartosc = $event.currentTarget.checked ? 'Tak' : 'Nie';
  }

  czyMoznaWyszukiwacWEwidencji(poleId: string) {
    var pole = this.model.pola.find((m) => m.poleSpisu.id == poleId);
    var mapowanie = this.daneDoSpisu.mapowania.find(
      (m) => m.poleSpisuId == poleId
    );

    return (
      pole.poleSpisu.czySzukacWEwidencji && mapowanie && this.czyMoznaEdytowac
    );
  }

  budujZdjecieDoWyswietlenia(zdjecie: Zalacznik) {
    var format = '';
    switch (zdjecie.format) {
      case 'jpg':
        format = 'jpeg';
        break;
      case 'png':
        format = 'png';
        break;
      default:
        format = zdjecie.format;
        break;
    }
    return 'data:image/' + format + ';base64,' + zdjecie.plik;
  }

  przywrocWartosciDomyslnePolTylkoDoOdczytu() {
    this.model.pola.forEach((pole) => {
      if (!pole.poleSpisu.tylkoDoOdczytu) {
        return;
      }
      pole.wartosc = pole.poleSpisu.wartoscDomyslna;
    });
  }

  nullNaString(wartosc: string): string {
    return wartosc == null || wartosc == undefined
      ? ''
      : wartosc.toLowerCase().trim();
  }

  pobierzFiltryZUrl() {
    var filtry = this.activatedRoute.snapshot.queryParamMap;

    filtry.keys.forEach((k) => {
      var filtr = {
        kolumna: k,
        wartoscSzukaj: filtry.get(k),
      } as KolumnaSpisuFiltrowanie;
      this.filtryZUrl.push(filtr);
    });
  }

  pobierzHistorieZmianSrodka() {
    var model = new PobierzHistorieZmianSrodkaModel();
    model.idProjektu = this.idProjektu;
    model.idSrodka = this.id;

    this.spisSerwis.pobierzHistorieZmianSrodka(model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.wyswietlModalZHistoriaZmian(rezultat.element.historia);

        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  wyswietlModalZHistoriaZmian(historia: Array<HistoriaZmianSrodka>) {
    this.modalReference = this.modalService.open(
      HistoriaZmianSrodkaModalComponent,
      new ModalOpcje(true, 'static', null, 'modal-dialog--fluid')
    );
    this.modalReference.componentInstance.historia = historia;
  }
}
