<div class="modal-content">
    <div class="modal-body">
        <button aria-label="Close" class="close" data-dismiss="modal" type="button"
            (click)="activeModal.dismiss()"></button>
        <div class="ft__01 text-center py-4 ">{{ 'zmienEmail.naglowekModal' | translate}}</div>
        <div class="text-center pb-4 mb-2">{{ 'zmienEmail.naglowekModal2' | translate}}</div>
        <form action="">
            <div class="input_container pb-3" [ngClass]="czyBladWalidacyjny('adresEmail')?'error':''">
                <input placeholder="E-mail" type="text" name="email"
                    [(ngModel)]="zmienAdresEmailFormularz.adresEmail" />
                <div class="error-info fs__error pt-2" [hidden]="!czyBladWalidacyjny('adresEmail')">
                    {{pobierzBladWalidacyjny("adresEmail")}}</div>
            </div>
            <div class="input_container pb-3">
                <button class="btn__primary" (click)="onZapiszNowyEmailClick()">{{ 'zapisz' | translate}}</button>
            </div>
            <div class="input_container pb-3">
                <button class="btn__link" (click)="activeModal.dismiss()">{{ 'zamknij' | translate}}</button>
            </div>
        </form>
    </div>
</div>