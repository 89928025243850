<div class="container container--login pb-4" *ngIf="czyZaladowanoDane">
    <div class="ft__01 text-center py-4 mt-2">{{authService.getUserName()}} {{authService.getUserLastName()}}</div>

    <div class="d-flex justify-content-center mb-4">
        <div class="person_avatar ft__avatar">
            {{pobierzInicjalyUzytkownika()}}
        </div>

    </div>

    <div class="input_container pb-3" [ngClass]="czyBladWalidacyjny('imie')?'error':''">
        <div class="f-input mb-1">
            <label class="ft__label mb-1 text-left w-100">Imię</label>
        </div>
        <input placeholder="{{ 'imie' | translate}}" type="text" name="imie" [(ngModel)]="model.imie">
        <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('imie')">{{ pobierzBladWalidacyjny("imie")
            }}
        </div>
    </div>
    <div class="input_container pb-3" [ngClass]="czyBladWalidacyjny('nazwisko')?'error':''">
        <div class="f-input mb-1">
            <label class="ft__label mb-1 text-left w-100">Nazwisko</label>
        </div>
        <input placeholder="{{ 'nazwisko' | translate}}" type="text" name="nazwisko" [(ngModel)]="model.nazwisko">
        <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('nazwisko')">{{
            pobierzBladWalidacyjny("nazwisko") }}
        </div>
    </div>
    <div class="input_container pb-3" [ngClass]="czyBladWalidacyjny('numerTelefonu')?'error':''">
        <div class="f-input mb-1">
            <label class="ft__label mb-1 text-left w-100">Numer telefonu</label>
        </div>
        <input placeholder="{{ 'numerTelefonu' | translate}}" type="text" name="numerTelefonu"
            [(ngModel)]="model.numerTelefonu">
        <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('numerTelefonu')">{{
            pobierzBladWalidacyjny("numerTelefonu") }}
        </div>
    </div>
    <div class="input_container pb-3">
        <div class="f-input mb-1">
            <label class="ft__label mb-1 text-left w-100">Rola</label>
        </div>
        <input type="text" placeholder="Rola" [value]="pobierzRole()" readonly>
    </div>
    <div class="f-input mb-1">
        <label class="ft__label mb-1 text-left w-100">E-mail</label>
    </div>
    <div class="input_container pb-2">

        <input type="text" placeholder="E-mail" [value]="daneKonta.email" readonly style="padding-right: 35px;">
        <button class="btn__input--edit" (click)="onZmienEmailBtnClick()"></button>
    </div>
    <div class="input_container pb-3 mt-3">
        <button class="btn__link" (click)="onZmienHasloClick()">{{ 'profil.zmienHaslo' |
            translate}}</button>
    </div>
    <div class="input_container pb-3">
        <button class="btn__primary" (click)="zapisz()">{{ 'profil.zapiszDane' |
            translate}}</button>
    </div>

</div>