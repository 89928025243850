import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { WyslijKodWeryfikacyjnyPonownieModel } from 'src/app/modele/uzytkownik/wyslijKodWeryfikacyjnyPonownieModel';
import { Uzytkownik } from 'src/app/modele/uzytkownik/uzytkownik';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { Uprawnienie } from 'src/app/modele/wspolne/uprawnienie';
import { UzytkownikService } from 'src/app/serwisy/konto/uzytkownik.service';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { BazowyComponent } from '../../bazowy.component';
import { EdytujUzytkownikaModalComponent } from '../edytuj-uzytkownika-modal/edytuj-uzytkownika-modal.component';
import { NowyUzytkownikModalComponent } from '../nowy-uzytkownik-modal/nowy-uzytkownik-modal.component';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { PobierzUzytkownikowFiltr } from 'src/app/modele/uzytkownik/pobierzUzytkownikowFiltr';
import { Stronicowanie } from 'src/app/modele/wspolne/stronicowanie';
import {
  KierunekSortowania,
  Sortowanie,
} from 'src/app/modele/wspolne/sortowanie';

@Component({
  selector: 'app-uzytkownicy',
  templateUrl: './uzytkownicy.component.html',
  styleUrls: ['./uzytkownicy.component.css'],
})
export class UzytkownicyComponent extends BazowyComponent implements OnInit {
  public czyZaladowanoDane: boolean = false;
  wyszukiwanie: RejestrFiltrowanyRequest<PobierzUzytkownikowFiltr> =
    new RejestrFiltrowanyRequest<PobierzUzytkownikowFiltr>();
  calkowitaLiczbaElementow: BigInteger;
  rozmiarTabeli: number = 25;
  czyJestWiecejRekordow: boolean = true;
  uzytkownicy: Array<Uzytkownik> = new Array();
  czyPokazacMenuSortowanie: boolean;
  slideConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: document.getElementsByClassName('btn__left'),
    nextArrow: document.getElementsByClassName('btn__right'),
    infinite: false,
    variableWidth: true,
  };

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    private uzytkownikSerwis: UzytkownikService,
    public translate: TranslateService
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translate
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.wyszukiwanie.stronicowanie = new Stronicowanie(false);
      this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
      this.wyszukiwanie.stronicowanie.strona = 1;
      this.wyszukiwanie.sortowanie = new Sortowanie(
        'Nazwisko',
        KierunekSortowania.asc
      );
      this.wyszukiwanie.filtry = new PobierzUzytkownikowFiltr();
      this.wyszukiwanie.filtry.aktywni = true;

      this.pobierzDaneDoEkranu(true);
    });
  }

  pobierzDaneDoEkranu(zLadowaczem: boolean) {
    this.uzytkownikSerwis
      .pobierzUzytkownikow(this.wyszukiwanie, zLadowaczem)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.uzytkownicy = rezultat.listaElementow;
          this.czyJestWiecejRekordow = rezultat.czyJestWiecejRekordow;
          this.calkowitaLiczbaElementow = rezultat.calkowitaIloscRekordow;
          this.czyZaladowanoDane = true;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  pobierzInicjalyUzytkownika(uzytkownik: Uzytkownik) {
    return uzytkownik.imie.slice(0, 1) + uzytkownik.nazwisko.slice(0, 1);
  }

  public dodajUzytkownika(): void {
    this.modalReference = this.modalService.open(
      NowyUzytkownikModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.pobierzDaneDoEkranu(true);
        }
      },
      (onRejected) => {}
    );
  }

  public wyslijLinkAktywacyjny(uzytkownik: Uzytkownik) {
    var model = new WyslijKodWeryfikacyjnyPonownieModel();
    model.email = uzytkownik.email;

    this.uzytkownikSerwis.wyslijLinkPonownie(model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.wyswietlKomunikat(
          this.pobierzTlumaczenie('wyslanoLinkAktywacyjny')
        );
        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  public edytujUzytkownika(uzytkownik: Uzytkownik) {
    this.modalReference = this.modalService.open(
      EdytujUzytkownikaModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.id = uzytkownik.id;

    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.pobierzDaneDoEkranu(true);
        }
      },
      (onRejected) => {}
    );
  }

  pobierzRole(uzytkownik: Uzytkownik) {
    switch (uzytkownik.uprawnienie) {
      case Uprawnienie.administrator:
        return this.pobierzTlumaczenie('administrator');
      case Uprawnienie.wykonawca:
        return this.pobierzTlumaczenie('wykonawca');
      case Uprawnienie.pracownikKlienta:
        return 'Pracownik klienta';
    }
  }

  zmienRozmiarTabeli() {
    this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
    this.pobierzDaneDoEkranu(true);
  }

  pobierzNumerRekorduPoczatek() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return this.wyszukiwanie.stronicowanie.rekordPoczatkowy + 1;
  }
  pobierzNumerRekorduKoniec() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return (
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy + this.uzytkownicy.length
    );
  }

  zmienStrone(oIle: number) {
    this.wyszukiwanie.stronicowanie.strona += oIle;
    this.wyszukiwanie.stronicowanie.rekordPoczatkowy =
      (this.wyszukiwanie.stronicowanie.strona - 1) *
      this.wyszukiwanie.stronicowanie.iloscRekordow;
    this.pobierzDaneDoEkranu(true);
  }

  pokazMenuSortowania() {
    this.czyPokazacMenuSortowanie = !this.czyPokazacMenuSortowanie;
  }
  wybierzAktywnychUzytkownikow(czyAktywni: boolean) {
    this.wyszukiwanie.filtry.aktywni = czyAktywni;
    this.pobierzDaneDoEkranu(true);
  }

  filtrujPoTekscie() {
    if (this.wyszukiwanie.filtry.wartoscSzukaj.length == 0) {
      this.pobierzDaneDoEkranu(false);
    }

    if (this.wyszukiwanie.filtry.wartoscSzukaj.length >= 3) {
      this.pobierzDaneDoEkranu(false);
    }
  }

  wyczyscFiltr() {
    this.wyszukiwanie.filtry.wartoscSzukaj = '';
    this.pobierzDaneDoEkranu(false);
  }

  pobierzKierunekSortowania(kolumna: string) {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      return '';
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      return 'up';
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      return 'down';
    }
  }

  zmienKierunekSortowania(kolumna: string) {
    this.czyPokazacMenuSortowanie = false;
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.desc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        'Id',
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
  }

  pobierzKolumneSortowania() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    switch (kierunki[0].nazwaKolumny) {
      case 'Imie':
        return 'Imię';
      case 'Nazwisko':
        return 'Nazwisko';
      case 'Email':
        return 'E-mail';
      case 'NumerTelefonu':
        return 'Telefon';
      case 'Rola':
        return 'Rola';
    }
  }

  pobierzKierunekSortowaniaMobile() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    if (kierunki[0].kierunekSortowania == KierunekSortowania.asc) {
      return 'up';
    }
    if (kierunki[0].kierunekSortowania == KierunekSortowania.desc) {
      return '';
    }
  }

  zmienKierunekSortowaniaMobile() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki[0];

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kierunek.nazwaKolumny,
        KierunekSortowania.desc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kierunek.nazwaKolumny,
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
  }
  wyczyscSortowanieMobile() {
    this.wyszukiwanie.sortowanie = new Sortowanie('Id', KierunekSortowania.asc);
    this.pobierzDaneDoEkranu(true);
  }
}
