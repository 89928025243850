import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DodajProjektModel } from 'src/app/modele/projekty/dodajProjektModel';
import { PobierzProjektyFiltr } from 'src/app/modele/projekty/pobierzProjektyFiltr';
import { Projekt } from 'src/app/modele/projekty/projekt';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { Routing } from 'src/app/modele/wspolne/routing';
import {
  Sortowanie,
  KierunekSortowania,
} from 'src/app/modele/wspolne/sortowanie';
import { Stronicowanie } from 'src/app/modele/wspolne/stronicowanie';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { ProjektyService } from 'src/app/serwisy/projekty/projekty.service';
import { BazowyComponent } from '../../bazowy.component';
import { KalendarzModalContent } from '../../kalendarz/kalendarz-modal';
import { Zalacznik } from 'src/app/modele/wspolne/zalacznik';

@Component({
  selector: 'app-nowy-projekt',
  templateUrl: './nowy-projekt.component.html',
  styleUrls: ['./nowy-projekt.component.css'],
  providers: [DatePipe],
})
export class NowyProjektComponent extends BazowyComponent implements OnInit {
  czyZaladowanoDane: boolean;
  model: DodajProjektModel = new DodajProjektModel();
  sformatowanaDataKoniecSpisuDo: string = '';
  sformatowanaDataRozliczenieJednoznaczneDo: string = '';
  sformatowanaDataRozliczenieAnalityczneDo: string = '';
  sformatowanaDataKoniecProjektu: string = '';

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    private datePipe: DatePipe,
    public projektySerwis: ProjektyService,
    public translateSerwis: TranslateService
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translateSerwis
    );
  }

  ngOnInit(): void {
    setTimeout(() => {});
  }

  zapisz() {
    this.projektySerwis.dodajProjekt(this.model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        var idProjektu = rezultat.element;
        this.wyswietlKomunikat(
          this.pobierzTlumaczenie('projekty.dodanoProjektKomunikat')
        );
        this.router.navigate([Routing.SzczegolyProjektu, idProjektu]);
        return;
      }
      if (this.bladModelu(rezultat)) {
        this.bledyWalidacji = rezultat.bledyModelu;
        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  anuluj() {
    this.router.navigate([Routing.Projekty]);
  }

  wybierzDateKoniecSpisuDo() {
    this.modalReference = this.modalService.open(KalendarzModalContent, {
      centered: true,
    });
    this.modalReference.componentInstance.data = this.model.koniecSpisuDo;

    this.modalReference.result.then(
      (rezultat) => {
        if (rezultat != undefined) {
          this.model.koniecSpisuDo = rezultat.data;

          this.sformatowanaDataKoniecSpisuDo = this.datePipe.transform(
            rezultat.data,
            'dd.MM.yyyy'
          );
        }
      },
      (onRejected) => {}
    );
  }

  wybierzDateRozliczenieJednoznaczneDo() {
    this.modalReference = this.modalService.open(KalendarzModalContent, {
      centered: true,
    });
    this.modalReference.componentInstance.data =
      this.model.rozliczenieJednoznaczneDo;

    this.modalReference.result.then(
      (rezultat) => {
        if (rezultat != undefined) {
          this.model.rozliczenieJednoznaczneDo = rezultat.data;

          this.sformatowanaDataRozliczenieJednoznaczneDo =
            this.datePipe.transform(rezultat.data, 'dd.MM.yyyy');
        }
      },
      (onRejected) => {}
    );
  }

  wybierzDateRozliczenieAnalityczneDo() {
    this.modalReference = this.modalService.open(KalendarzModalContent, {
      centered: true,
    });
    this.modalReference.componentInstance.data =
      this.model.rozliczenieAnalityczneDo;

    this.modalReference.result.then(
      (rezultat) => {
        if (rezultat != undefined) {
          this.model.rozliczenieAnalityczneDo = rezultat.data;

          this.sformatowanaDataRozliczenieAnalityczneDo =
            this.datePipe.transform(rezultat.data, 'dd.MM.yyyy');
        }
      },
      (onRejected) => {}
    );
  }

  wybierzDateKoniecProjektu() {
    this.modalReference = this.modalService.open(KalendarzModalContent, {
      centered: true,
    });
    this.modalReference.componentInstance.data = this.model.koniecProjektu;

    this.modalReference.result.then(
      (rezultat) => {
        if (rezultat != undefined) {
          this.model.koniecProjektu = rezultat.data;

          this.sformatowanaDataKoniecProjektu = this.datePipe.transform(
            rezultat.data,
            'dd.MM.yyyy'
          );
        }
      },
      (onRejected) => {}
    );
  }

  wyczyscDate(rodzaj: string) {
    switch (rodzaj) {
      case 'KoniecSpisuDo':
        this.sformatowanaDataKoniecSpisuDo = '';
        this.model.koniecSpisuDo = null;
        break;
      case 'RozliczenieJednoznaczneDo':
        this.sformatowanaDataRozliczenieJednoznaczneDo = '';
        this.model.rozliczenieJednoznaczneDo = null;
        break;
      case 'RozliczenieAnalityczneDo':
        this.sformatowanaDataRozliczenieAnalityczneDo = '';
        this.model.rozliczenieAnalityczneDo = null;
        break;
      case 'KoniecProjektu':
        this.sformatowanaDataKoniecProjektu = '';
        this.model.koniecProjektu = null;
        break;
    }
  }

  wybierzInstrukcjeZDysku(event: any) {
    var plik = event.target.files[0];
    this.wybierzInstrukcje(plik);

    event.target.value = null;
    return false;
  }

  wybierzInstrukcje(plik: File) {
    if (plik) {
      var reader = new FileReader();
      reader.readAsDataURL(plik);
      reader.onload = () => {
        var zalacznik = new Zalacznik();
        zalacznik.nazwaPliku = plik.name;
        zalacznik.plik = this.usunZbedneZnakiZPliku(reader.result.toString());
        if (zalacznik.plik == 'data:') {
          return;
        }
        zalacznik.dataModyfikacji = new Date(plik.lastModified);
        zalacznik.format = plik.name.split('.').pop().toLowerCase();

        this.model.instrukcjaWypelnieniaOswiadczen = zalacznik;
      };
    }
  }

  usunZbedneZnakiZPliku(base64: string) {
    if (base64.includes(',')) {
      return base64.substring(base64.indexOf(',') + 1);
    }
    return base64;
  }

  usunInstrukcje() {
    this.model.instrukcjaWypelnieniaOswiadczen = undefined;
  }
}
