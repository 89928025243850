import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { WeryfikujZmianeAdresuEmailFormularz } from 'src/app/modele/profil/weryfikuj-zmiane-adresu-email-formularz.model';
import { Routing } from 'src/app/modele/wspolne/routing';
import { ProfilService } from 'src/app/serwisy/konto/profil.service';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';

@Component({
  selector: 'app-potwierdz-email',
  templateUrl: './potwierdz-email.component.html'
})
export class PotwierdzEmailComponent extends BazowyComponent implements OnInit {

  czyZaladowanoDane: boolean;

  constructor(
    private profilService: ProfilService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    private komunikatSerwis: ToastrService,
    public authService: AuthService,
    public translate: TranslateService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translate);

  }

  ngOnInit(): void {
    setTimeout(() => {
      this._potwierdzZmianeEmaila();
    });
  }

  private _potwierdzZmianeEmaila(): void {
    var zmianaAdresuEmailFormularz = new WeryfikujZmianeAdresuEmailFormularz();
    zmianaAdresuEmailFormularz.tokenPotwierdzajacy = this.activatedRoute.snapshot.paramMap.get('token');
    this.profilService.WeryfikujZmianeAdresuEmail(zmianaAdresuEmailFormularz)
      .subscribe((result) => {
        if (this.czyRezultatOk(result)) {
          this.czyZaladowanoDane = true;
        } else {
          this.wyswietlKomunikat(result.wiadomosc, true);
        }
      });
  }

  public onPrzejdzDoAplikacjiClick(): void {
    this.router.navigate([Routing.Zaloguj]);
  }

}
