<nav class="navbar">
    <div class="navbar__container">
        <div class="navbrand d-flex align-items-center justify-content-center">
            <a class="brand" href="#">
                <img src="assets/img/logo.svg" alt="">
            </a>
        </div>
    </div>
</nav>

<div class="container container--login" *ngIf="czyZaladowanoDane">
    <div class="pt-5 pb-2 text-center">
        <img src="assets/img/info_check.svg" alt="">
    </div>
    <div class="ft__02 text-center py-5">{{ 'zmienEmail.naglowekPotwierdz' | translate}}</div>
    <div class="input_container pb-3">
        <button class="btn__primary" (click)="onPrzejdzDoAplikacjiClick()">{{ 'zmienEmail.przejdzDoAplikacji' |
            translate}}</button>
    </div>
</div>