import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { HistoriaZmianSrodka } from 'src/app/modele/projekty/spis/historiaZmianSrodka';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';

@Component({
  selector: 'app-historia-zmian-srodka-modal',
  templateUrl: './historia-zmian-srodka-modal.component.html',
  styleUrls: ['./historia-zmian-srodka-modal.component.css'],
})
export class HistoriaZmianSrodkaModalComponent
  extends BazowyComponent
  implements OnInit
{
  historia: Array<HistoriaZmianSrodka>;

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    public activeModal: NgbActiveModal,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translateSerwis
    );
  }

  ngOnInit(): void {
    setTimeout(() => {});
  }

  formatuj(dane) {
    return JSON.stringify(dane, null, 2);
  }
}
