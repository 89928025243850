import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { Konfiguracja } from 'src/config';
import { AuthService } from './ogolne/auth.service';
import { ErrorService } from './ogolne/error.service';
import { LoadingService } from './ogolne/loading.service';

export class SerwisBazowy {
  AdresApi: string;
  HttpHeaders: HttpHeaders;
  CustomoweNaglowki: boolean = false;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private errorService: ErrorService,
    private loaderService: LoadingService,
    private translate: TranslateService
  ) {
    var config = new Konfiguracja();
    this.AdresApi = config.AdresApi;
  }

  public wykonajAkcje<T, TR>(
    adres: string,
    model: T,
    timeoutMs: number = 60000
  ): Observable<TR> {
    this.loaderService.zablokujLoader(false);
    this.ustawNaglowek();

    return this.httpClient
      .post<TR>(adres, model, {
        headers: this.HttpHeaders,
      })
      .pipe(timeout(timeoutMs), catchError(this.errorService.handleError));
  }
  public wykonajAkcjeBezLadowacza<T, TR>(
    adres: string,
    model: T
  ): Observable<TR> {
    this.loaderService.zablokujLoader(true);
    this.ustawNaglowek();

    return this.httpClient
      .post<TR>(adres, model, {
        headers: this.HttpHeaders,
      })
      .pipe(catchError(this.errorService.handleError));
  }
  public wykonajAkcjeBezModelu<TR>(adres: string): Observable<TR> {
    this.loaderService.zablokujLoader(false);
    this.ustawNaglowek();

    return this.httpClient
      .post<TR>(adres, null, {
        headers: this.HttpHeaders,
      })
      .pipe(catchError(this.errorService.handleError));
  }

  public get<TR>(adres: string): Observable<TR> {
    this.loaderService.zablokujLoader(false);
    this.ustawNaglowek();

    return this.httpClient
      .get<TR>(adres, {
        headers: this.HttpHeaders,
      })
      .pipe(catchError(this.errorService.handleError));
  }
  public getBezLoadera<TR>(adres: string): Observable<TR> {
    this.loaderService.zablokujLoader(true);
    this.ustawNaglowek();

    return this.httpClient
      .get<TR>(adres, {
        headers: this.HttpHeaders,
      })
      .pipe(catchError(this.errorService.handleError));
  }

  public pobierzStream(adres: string) {
    this.loaderService.zablokujLoader(false);
    this.ustawNaglowek();

    return this.httpClient
      .get(adres, {
        responseType: 'blob',
        headers: this.HttpHeaders,
      })
      .pipe(catchError(this.errorService.handleError));
  }

  private ustawNaglowek() {
    if (this.CustomoweNaglowki) {
      return;
    }
    var adresStrony = location.origin;
    var jezyk = this.pobierzJezyk();
    var token = '';
    var idUzytkownika = '';

    var daneUzytkownika = this.authService.pobierzDaneZalogowanegoUzytkownika();

    if (daneUzytkownika != null) {
      token = daneUzytkownika.token;
      idUzytkownika = daneUzytkownika.idUzytkownika;
    }

    this.HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'adres-strony': adresStrony,
      jezyk: jezyk,
      token: token,
      'id-uzytkownika': idUzytkownika,
    });
  }

  pobierzJezyk() {
    return 'pl-PL';
    // var jezyk = navigator.language;
    // if (jezyk == undefined) {
    //     jezyk = this.translate.getBrowserLang();
    // }

    // if (jezyk == undefined) {
    //     jezyk = "pl-PL";
    // }
    // if (jezyk.toLowerCase().startsWith("en")) {
    //     return "en-US";
    // }
    // else {
    //     return "pl-PL";
    // }
  }
}
