import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DaneDoSpisuSrodka } from 'src/app/modele/projekty/spis/daneDoSpisuSrodka';
import { DodajSrodekModel } from 'src/app/modele/projekty/spis/dodajSrodekModel';
import { EdytujSrodekModel } from 'src/app/modele/projekty/spis/edytujSrodekModel';
import { EksportujDaneModel } from 'src/app/modele/projekty/spis/eksportujDaneModel';
import { NiedoboryLokalizacji } from 'src/app/modele/projekty/spis/niedoboryLokalizacji';
import { PobierzNiedoboryLokalizacjiFiltry } from 'src/app/modele/projekty/spis/pobierzNiedoboryLokalizacjiFiltry';
import { PobierzSparowaneSrodkiRekorduEwidencjiModel } from 'src/app/modele/projekty/spis/pobierzSparowaneSrodkiRekorduEwidencjiModel';
import { PobierzSpisaneSrodkiLokalizacjiFiltry } from 'src/app/modele/projekty/spis/pobierzSpisaneSrodkiLokalizacjiFiltry';
import { PobierzSpisaneSrodkiPomieszczeniaFiltry } from 'src/app/modele/projekty/spis/pobierzSpisaneSrodkiPomieszczeniaFiltry';
import { PobierzSrodekModel } from 'src/app/modele/projekty/spis/pobierzSrodekModel';
import { PobierzSrodekRezultat } from 'src/app/modele/projekty/spis/pobierzSrodekRezultat';
import { SparowaneSrodkiRekorduEwidencji } from 'src/app/modele/projekty/spis/sparowaneSrodkiRekorduEwidencji';
import { SpisaneSrodkiLokalizacji } from 'src/app/modele/projekty/spis/spisaneSrodkiLokalizacji';
import { SpisaneSrodkiPomieszczenia } from 'src/app/modele/projekty/spis/spisaneSrodkiPomieszczenia';
import { SzukajWEwidencjiKrzyzowoModel } from 'src/app/modele/projekty/spis/szukajWEwidencjiKrzyzowoModel';
import { SzukajWEwidencjiModel } from 'src/app/modele/projekty/spis/szukajWEwidencjiModel';
import { SzukajWEwidencjiRezultat } from 'src/app/modele/projekty/spis/szukajWEwidencjiRezultat';
import { UsunSrodekModel } from 'src/app/modele/projekty/spis/usunSrodekModel';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { PojedynczyElementResponse } from 'src/app/modele/wspolne/pojedynczyElement';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { ResponseBazowy } from 'src/app/modele/wspolne/responseBazowy';
import { Zalacznik } from 'src/app/modele/wspolne/zalacznik';
import { SerwisBazowy } from '../bazowy.service';
import { AuthService } from '../ogolne/auth.service';
import { ErrorService } from '../ogolne/error.service';
import { LoadingService } from '../ogolne/loading.service';
import { PrzeniesSrodkiModel } from 'src/app/modele/projekty/spis/przeniesSrodkiModel';
import { UsunSrodkiModel } from 'src/app/modele/projekty/spis/usunSrodkiModel';
import {
  HistoriaZmianSrodkaRezultat,
  PobierzHistorieZmianSrodkaModel,
} from 'src/app/modele/projekty/spis/historiaZmianSrodka';
import { PobierzDaneDoSpisuSrodkaModel } from 'src/app/modele/projekty/spis/pobierzDaneDoSpisuSrodkaModel';

@Injectable({
  providedIn: 'root',
})
export class SpisService extends SerwisBazowy {
  constructor(
    httpClient: HttpClient,
    authService: AuthService,
    errorService: ErrorService,
    loaderService: LoadingService,
    translate: TranslateService
  ) {
    super(httpClient, authService, errorService, loaderService, translate);
  }

  public pobierzSpisaneSrodkiLokalizacji(
    model: RejestrFiltrowanyRequest<PobierzSpisaneSrodkiLokalizacjiFiltry>,
    zLadowaczem: boolean
  ) {
    if (zLadowaczem) {
      return this.wykonajAkcje<
        RejestrFiltrowanyRequest<PobierzSpisaneSrodkiLokalizacjiFiltry>,
        PojedynczyElementResponse<SpisaneSrodkiLokalizacji>
      >(this.AdresApi + 'Spis/PobierzSpisaneSrodkiLokalizacji', model);
    } else {
      return this.wykonajAkcjeBezLadowacza<
        RejestrFiltrowanyRequest<PobierzSpisaneSrodkiLokalizacjiFiltry>,
        PojedynczyElementResponse<SpisaneSrodkiLokalizacji>
      >(this.AdresApi + 'Spis/PobierzSpisaneSrodkiLokalizacji', model);
    }
  }

  public pobierzNadwyzkiLokalizacji(
    model: RejestrFiltrowanyRequest<PobierzSpisaneSrodkiLokalizacjiFiltry>,
    zLadowaczem: boolean
  ) {
    if (zLadowaczem) {
      return this.wykonajAkcje<
        RejestrFiltrowanyRequest<PobierzSpisaneSrodkiLokalizacjiFiltry>,
        PojedynczyElementResponse<SpisaneSrodkiLokalizacji>
      >(this.AdresApi + 'Spis/PobierzNadwyzkiLokalizacji', model);
    } else {
      return this.wykonajAkcjeBezLadowacza<
        RejestrFiltrowanyRequest<PobierzSpisaneSrodkiLokalizacjiFiltry>,
        PojedynczyElementResponse<SpisaneSrodkiLokalizacji>
      >(this.AdresApi + 'Spis/PobierzNadwyzkiLokalizacji', model);
    }
  }

  public pobierzNiedoboryLokalizacji(
    model: RejestrFiltrowanyRequest<PobierzNiedoboryLokalizacjiFiltry>,
    zLadowaczem: boolean
  ) {
    if (zLadowaczem) {
      return this.wykonajAkcje<
        RejestrFiltrowanyRequest<PobierzNiedoboryLokalizacjiFiltry>,
        PojedynczyElementResponse<NiedoboryLokalizacji>
      >(this.AdresApi + 'Spis/PobierzNiedoboryLokalizacji', model);
    } else {
      return this.wykonajAkcjeBezLadowacza<
        RejestrFiltrowanyRequest<PobierzNiedoboryLokalizacjiFiltry>,
        PojedynczyElementResponse<NiedoboryLokalizacji>
      >(this.AdresApi + 'Spis/PobierzNiedoboryLokalizacji', model);
    }
  }

  public pobierzSpisaneSrodkiPomieszczenia(
    model: RejestrFiltrowanyRequest<PobierzSpisaneSrodkiPomieszczeniaFiltry>,
    zLadowaczem: boolean
  ) {
    if (zLadowaczem) {
      return this.wykonajAkcje<
        RejestrFiltrowanyRequest<PobierzSpisaneSrodkiPomieszczeniaFiltry>,
        PojedynczyElementResponse<SpisaneSrodkiPomieszczenia>
      >(this.AdresApi + 'Spis/PobierzSpisaneSrodkiPomieszczenia', model);
    } else {
      return this.wykonajAkcjeBezLadowacza<
        RejestrFiltrowanyRequest<PobierzSpisaneSrodkiPomieszczeniaFiltry>,
        PojedynczyElementResponse<SpisaneSrodkiPomieszczenia>
      >(this.AdresApi + 'Spis/PobierzSpisaneSrodkiPomieszczenia', model);
    }
  }

  public pobierzNadwyzkiPomieszczenia(
    model: RejestrFiltrowanyRequest<PobierzSpisaneSrodkiPomieszczeniaFiltry>,
    zLadowaczem: boolean
  ) {
    if (zLadowaczem) {
      return this.wykonajAkcje<
        RejestrFiltrowanyRequest<PobierzSpisaneSrodkiPomieszczeniaFiltry>,
        PojedynczyElementResponse<SpisaneSrodkiPomieszczenia>
      >(this.AdresApi + 'Spis/PobierzNadwyzkiPomieszczenia', model);
    } else {
      return this.wykonajAkcjeBezLadowacza<
        RejestrFiltrowanyRequest<PobierzSpisaneSrodkiPomieszczeniaFiltry>,
        PojedynczyElementResponse<SpisaneSrodkiPomieszczenia>
      >(this.AdresApi + 'Spis/PobierzNadwyzkiPomieszczenia', model);
    }
  }

  public pobierzDaneDoSpisuSrodka(model: PobierzDaneDoSpisuSrodkaModel) {
    return this.wykonajAkcje<
      PobierzDaneDoSpisuSrodkaModel,
      PojedynczyElementResponse<DaneDoSpisuSrodka>
    >(this.AdresApi + 'Spis/PobierzDaneDoSpisuSrodka', model);
  }

  public usunSrodek(model: UsunSrodekModel) {
    return this.wykonajAkcje<UsunSrodekModel, ResponseBazowy>(
      this.AdresApi + 'Spis/UsunSrodek',
      model
    );
  }

  public szukajWEwidencji(model: SzukajWEwidencjiModel) {
    return this.wykonajAkcje<
      SzukajWEwidencjiModel,
      PojedynczyElementResponse<SzukajWEwidencjiRezultat>
    >(this.AdresApi + 'Spis/SzukajWEwidencji', model);
  }

  public szukajWEwidencjiKrzyzowo(model: SzukajWEwidencjiKrzyzowoModel) {
    return this.wykonajAkcje<
      SzukajWEwidencjiKrzyzowoModel,
      PojedynczyElementResponse<SzukajWEwidencjiRezultat>
    >(this.AdresApi + 'Spis/SzukajWEwidencjiKrzyzowo', model);
  }

  public dodajSrodek(model: DodajSrodekModel) {
    return this.wykonajAkcje<DodajSrodekModel, ResponseBazowy>(
      this.AdresApi + 'Spis/DodajSrodek',
      model
    );
  }
  public edytujSrodek(model: EdytujSrodekModel) {
    return this.wykonajAkcje<EdytujSrodekModel, ResponseBazowy>(
      this.AdresApi + 'Spis/EdytujSrodek',
      model
    );
  }

  public pobierzSrodek(model: PobierzSrodekModel) {
    return this.wykonajAkcje<
      PobierzSrodekModel,
      PojedynczyElementResponse<PobierzSrodekRezultat>
    >(this.AdresApi + 'Spis/PobierzSrodek', model);
  }

  public pobierzSparowaneSrodkiRekorduEwidencji(
    model: PobierzSparowaneSrodkiRekorduEwidencjiModel
  ) {
    return this.wykonajAkcje<
      PobierzSparowaneSrodkiRekorduEwidencjiModel,
      PojedynczyElementResponse<SparowaneSrodkiRekorduEwidencji>
    >(this.AdresApi + 'Spis/PobierzSparowaneSrodkiRekorduEwidencji', model);
  }

  public przeniesSrodki(model: PrzeniesSrodkiModel) {
    return this.wykonajAkcje<PrzeniesSrodkiModel, ResponseBazowy>(
      this.AdresApi + 'Spis/PrzeniesSrodki',
      model
    );
  }

  public usunSrodki(model: UsunSrodkiModel) {
    return this.wykonajAkcje<UsunSrodkiModel, ResponseBazowy>(
      this.AdresApi + 'Spis/UsunSrodki',
      model
    );
  }

  public pobierzHistorieZmianSrodka(model: PobierzHistorieZmianSrodkaModel) {
    return this.wykonajAkcje<
      PobierzHistorieZmianSrodkaModel,
      PojedynczyElementResponse<HistoriaZmianSrodkaRezultat>
    >(this.AdresApi + 'Spis/PobierzHistorieZmianSrodka', model);
  }
}
