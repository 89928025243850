<div class="modal-content">
  <div class="modal-body">
    <button
      aria-label="Close"
      class="close"
      data-dismiss="modal"
      type="button"
      (click)="activeModal.dismiss()"
    ></button>
    <div class="ft__01 text-center py-4">Edytuj lokalizację</div>
    <div class="text-center mb-2"></div>
    <form action="">
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('ulica') ? 'error' : ''"
      >
        <input
          type="text"
          placeholder="Ulica"
          [(ngModel)]="model.ulica"
          name="ulica"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('ulica')"
        >
          {{ pobierzBladWalidacyjny("ulica") }}
        </div>
      </div>
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('miasto') ? 'error' : ''"
      >
        <input
          type="text"
          placeholder="Miasto"
          [(ngModel)]="model.miasto"
          name="miasto"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('miasto')"
        >
          {{ pobierzBladWalidacyjny("miasto") }}
        </div>
      </div>
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('kodPocztowy') ? 'error' : ''"
      >
        <input
          type="text"
          placeholder="Kod pocztowy"
          [(ngModel)]="model.kodPocztowy"
          name="kodPocztowy"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('kodPocztowy')"
        >
          {{ pobierzBladWalidacyjny("kodPocztowy") }}
        </div>
      </div>
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('rodzajLokalizacji') ? 'error' : ''"
      >
        <input
          type="text"
          placeholder="Rodzaj lokalizacji"
          [(ngModel)]="model.rodzajLokalizacji"
          name="rodzajLokalizacji"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('rodzajLokalizacji')"
        >
          {{ pobierzBladWalidacyjny("rodzajLokalizacji") }}
        </div>
      </div>
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('mpk') ? 'error' : ''"
      >
        <input
          type="text"
          placeholder="MPK"
          [(ngModel)]="model.mpk"
          name="mpk"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('mpk')"
        >
          {{ pobierzBladWalidacyjny("mpk") }}
        </div>
      </div>
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('osobaOdpowiedzialna') ? 'error' : ''"
      >
        <input
          type="text"
          placeholder="Osoba odpowiedzialna"
          [(ngModel)]="model.osobaOdpowiedzialna"
          name="osobaOdpowiedzialna"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('osobaOdpowiedzialna')"
        >
          {{ pobierzBladWalidacyjny("osobaOdpowiedzialna") }}
        </div>
      </div>
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('numerTelefonu') ? 'error' : ''"
      >
        <input
          type="text"
          placeholder="Numer telefonu"
          [(ngModel)]="model.numerTelefonu"
          name="numerTelefonu"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('numerTelefonu')"
        >
          {{ pobierzBladWalidacyjny("numerTelefonu") }}
        </div>
      </div>
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('numerZespolu') ? 'error' : ''"
      >
        <input
          type="text"
          placeholder="Numer zespołu"
          [(ngModel)]="model.numerZespolu"
          name="numerZespolu"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('numerZespolu')"
        >
          {{ pobierzBladWalidacyjny("numerZespolu") }}
        </div>
      </div>

      <div class="input_container pb-3">
        <button class="btn__primary" (click)="zapisz()">Zapisz</button>
      </div>

      <div class="input_container pb-3">
        <button class="btn__link btn__link--red" (click)="usun()">Usuń</button>
      </div>

      <div class="input_container pb-3">
        <button class="btn__link" (click)="activeModal.dismiss()">
          Anuluj
        </button>
      </div>
    </form>
  </div>
</div>
