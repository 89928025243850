import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { PobierzProjektyFiltr } from 'src/app/modele/projekty/pobierzProjektyFiltr';
import { Projekt } from 'src/app/modele/projekty/projekt';
import { StatusProjektu } from 'src/app/modele/projekty/statusProjektu';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { Routing } from 'src/app/modele/wspolne/routing';
import {
  KierunekSortowania,
  Sortowanie,
} from 'src/app/modele/wspolne/sortowanie';
import { Stronicowanie } from 'src/app/modele/wspolne/stronicowanie';
import { Uprawnienie } from 'src/app/modele/wspolne/uprawnienie';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { ProjektyService } from 'src/app/serwisy/projekty/projekty.service';
import { BazowyComponent } from '../../bazowy.component';

@Component({
  selector: 'app-lista-projektow',
  templateUrl: './lista-projektow.component.html',
  styleUrls: ['./lista-projektow.component.css'],
  providers: [DatePipe],
})
export class ListaProjektowComponent extends BazowyComponent implements OnInit {
  czyZaladowanoDane: boolean;
  wyszukiwanie: RejestrFiltrowanyRequest<PobierzProjektyFiltr> =
    new RejestrFiltrowanyRequest<PobierzProjektyFiltr>();
  projekty: Array<Projekt> = new Array();
  calkowitaLiczbaElementow: BigInteger;
  rozmiarTabeli: number = 10;
  czyJestWiecejRekordow: boolean = true;
  czyPokazacMenuSortowanie: boolean;

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    private datePipe: DatePipe,
    public projektySerwis: ProjektyService,
    public translateSerwis: TranslateService
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translateSerwis
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.wyszukiwanie.stronicowanie = new Stronicowanie(false);
      this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
      this.wyszukiwanie.stronicowanie.strona = 1;
      this.wyszukiwanie.sortowanie = new Sortowanie(
        this.czyAdmin() ? 'NazwaProjektu' : 'DataUtworzenia',
        this.czyAdmin() ? KierunekSortowania.asc : KierunekSortowania.desc
      );
      this.wyszukiwanie.filtry = new PobierzProjektyFiltr();
      this.wyszukiwanie.filtry.aktywne = true;

      this.pobierzDaneDoEkranu(true);
    });
  }

  pobierzDaneDoEkranu(zLadowaczem: boolean) {
    this.projektySerwis
      .PobierzProjekty(this.wyszukiwanie, zLadowaczem)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.projekty = rezultat.listaElementow;
          this.calkowitaLiczbaElementow = rezultat.calkowitaIloscRekordow;
          this.czyJestWiecejRekordow = rezultat.czyJestWiecejRekordow;

          if (
            this.czyRola(Uprawnienie.wykonawca) &&
            rezultat.listaElementow.length == 1
          ) {
            this.przejdzDoSzczegolow(rezultat.listaElementow[0].id);
            return;
          }
          if (this.czyRola(Uprawnienie.pracownikKlienta)) {
            this.przejdzDoSpisu(rezultat.listaElementow[0].id);
            return;
          }
          this.czyZaladowanoDane = true;

          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }
  przejdzDoSpisu(id: string) {
    this.router.navigate([Routing.Spis, id]);
  }

  zmienRozmiarTabeli() {
    this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
    this.pobierzDaneDoEkranu(true);
  }

  pobierzNumerRekorduPoczatek() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return this.wyszukiwanie.stronicowanie.rekordPoczatkowy + 1;
  }
  pobierzNumerRekorduKoniec() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return (
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy + this.projekty.length
    );
  }

  zmienStrone(oIle: number) {
    this.wyszukiwanie.stronicowanie.strona += oIle;
    this.wyszukiwanie.stronicowanie.rekordPoczatkowy =
      (this.wyszukiwanie.stronicowanie.strona - 1) *
      this.wyszukiwanie.stronicowanie.iloscRekordow;
    this.pobierzDaneDoEkranu(true);
  }

  kliknijPozaMenuSortowania(event) {
    if (this.czyPokazacMenuSortowanie) {
      this.czyPokazacMenuSortowanie = false;
    }
  }
  pokazMenuSortowania() {
    this.czyPokazacMenuSortowanie = !this.czyPokazacMenuSortowanie;
  }
  wybierzAktywneProjekty(czyAktywne: boolean) {
    this.wyszukiwanie.filtry.aktywne = czyAktywne;
    this.pobierzDaneDoEkranu(true);
  }

  filtrujPoTekscie() {
    if (this.wyszukiwanie.filtry.wartoscSzukaj.length == 0) {
      this.pobierzDaneDoEkranu(false);
    }

    if (this.wyszukiwanie.filtry.wartoscSzukaj.length >= 3) {
      this.pobierzDaneDoEkranu(false);
    }
  }

  wyczyscFiltr() {
    this.wyszukiwanie.filtry.wartoscSzukaj = '';
    this.pobierzDaneDoEkranu(false);
  }

  pobierzKierunekSortowania(kolumna: string) {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      return '';
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      return 'up';
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      return 'down';
    }
  }

  zmienKierunekSortowania(kolumna: string) {
    this.czyPokazacMenuSortowanie = false;
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.desc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        'Id',
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
  }

  pobierzKolumneSortowania() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    switch (kierunki[0].nazwaKolumny) {
      case 'NazwaProjektu':
        return 'Nazwa projektu';
      case 'NazwaKlienta':
        return 'Nazwa klienta';
      case 'DataUtworzenia':
        return 'Data utworzenia';
      case 'DataAktualizacji':
        return 'Ostatnia aktualizacja';
      case 'Status':
        return 'Status';
    }
  }

  pobierzKierunekSortowaniaMobile() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    if (kierunki[0].kierunekSortowania == KierunekSortowania.asc) {
      return 'up';
    }
    if (kierunki[0].kierunekSortowania == KierunekSortowania.desc) {
      return '';
    }
  }

  zmienKierunekSortowaniaMobile() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki[0];

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kierunek.nazwaKolumny,
        KierunekSortowania.desc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kierunek.nazwaKolumny,
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
  }
  wyczyscSortowanieMobile() {
    this.wyszukiwanie.sortowanie = new Sortowanie('Id', KierunekSortowania.asc);
    this.pobierzDaneDoEkranu(true);
  }

  formatujDate(data: Date) {
    return this.datePipe.transform(data, 'yyyy-MM-dd, HH:mm');
  }

  pobierzStatus(projekt: Projekt) {
    switch (projekt.statusProjektu) {
      case StatusProjektu.przygotowanie:
        return this.pobierzTlumaczenie('projekty.status.przygotowanie');
      case StatusProjektu.realizacja:
        return this.pobierzTlumaczenie('projekty.status.realizacja');
      case StatusProjektu.zakonczony:
        return this.pobierzTlumaczenie('projekty.status.zakonczony');
      case StatusProjektu.zarchiwizowany:
        return this.pobierzTlumaczenie('projekty.status.zarchiwizowany');
    }
  }

  dodajProjekt() {
    this.router.navigate([Routing.NowyProjekt]);
  }

  przejdzDoSzczegolow(id: string) {
    this.router.navigate([Routing.SzczegolyProjektu, id]);
  }
}
