export class EdytujLokalizacjeModel {
  id: string;
  ulica: string;
  miasto: string;
  kodPocztowy: string;
  mpk: string;
  osobaOdpowiedzialna: string;
  numerTelefonu: string;
  rodzajLokalizacji: string;
  numerZespolu: string;
}
