import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { TakNieModalComponent } from 'src/app/komponenty/ogolne/tak-nie-modal/tak-nie-modal.component';
import { KonfiguracjaPomieszczen } from 'src/app/modele/projekty/konfiguracjaPomieszczen/konfiguracjaPomieszczen';
import { EdytujPomieszczenieModel } from 'src/app/modele/projekty/pomieszczenia/edytujPomieszczenieModel';
import { SlownikPolaPomieszczenia } from 'src/app/modele/projekty/pomieszczenia/slownikPolaPomieszczenia';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { PomieszczeniaService } from 'src/app/serwisy/projekty/pomieszczenia.service';

@Component({
  selector: 'app-edytuj-pomieszczenie-modal',
  templateUrl: './edytuj-pomieszczenie-modal.component.html',
  styleUrls: ['./edytuj-pomieszczenie-modal.component.css']
})
export class EdytujPomieszczenieModalComponent extends BazowyComponent implements OnInit {

  idLokalizacji: string;
  idPomieszczenia: string;
  model: EdytujPomieszczenieModel = new EdytujPomieszczenieModel();
  czyZaladowanoDane: boolean;
  konfiguracja: KonfiguracjaPomieszczen;
  slowniki: Array<SlownikPolaPomieszczenia> = new Array();
  czyMoznaEdytowac: boolean;
  czyMoznaUsunac: boolean;

  nazwaSlownik: Array<string> = new Array();
  pietroSlownik: Array<string> = new Array();
  mpkSlownik: Array<string> = new Array();
  opisDodatkowySlownik: Array<string> = new Array();
  osobaSlownik: Array<string> = new Array();
  inneSlownik: Array<string> = new Array();

  nazwaSlownikObs: Observable<string[]>;
  pietroSlownikObs: Observable<string[]>;
  mpkSlownikObs: Observable<string[]>;
  opisDodatkowySlownikObs: Observable<string[]>;
  osobaSlownikObs: Observable<string[]>;
  inneSlownikObs: Observable<string[]>;

  nazwaFC = new UntypedFormControl();
  pietroFC = new UntypedFormControl();
  mpkFC = new UntypedFormControl();
  opisDodatkowyFC = new UntypedFormControl();
  osobaFC = new UntypedFormControl();
  inneFC = new UntypedFormControl();

  constructor(
    public router: Router,
    modalService: NgbModal,
    public activeModal: NgbActiveModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public pomieszczeniaSerwis: PomieszczeniaService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translateSerwis);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.pobierzSzczegoly();
    });
  }

  pobierzDaneDoEkranu() {
    var model = new IdModel();
    model.id = this.idLokalizacji;

    this.pomieszczeniaSerwis.pobierzDaneKonfiguracyjneDoEdycjiPomieszczenia(model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.konfiguracja = rezultat.element.konfiguracjaPomieszczen;
          this.slowniki = rezultat.element.slowniki;

          if (this.konfiguracja.nazwaSlownikId) {
            this.nazwaSlownik = this.slowniki.find(m => m.id == this.konfiguracja.nazwaSlownikId).wartosci;
            this.nazwaSlownikObs = this.nazwaFC.valueChanges.pipe(startWith(this.model.nazwa), map(m => this.filtrujSlownikNazw(m)));
          }
          if (this.konfiguracja.pietroSlownikId) {
            this.pietroSlownik = this.slowniki.find(m => m.id == this.konfiguracja.pietroSlownikId).wartosci;
            this.pietroSlownikObs = this.pietroFC.valueChanges.pipe(startWith(this.model.pietro), map(m => this.filtrujSlownikPietro(m)));
          }
          if (this.konfiguracja.mpkSlownikId) {
            this.mpkSlownik = this.slowniki.find(m => m.id == this.konfiguracja.mpkSlownikId).wartosci;
            this.mpkSlownikObs = this.mpkFC.valueChanges.pipe(startWith(this.model.mpk), map(m => this.filtrujSlownikMpk(m)));
          }
          if (this.konfiguracja.opisDodatkowySlownikId) {
            this.opisDodatkowySlownik = this.slowniki.find(m => m.id == this.konfiguracja.opisDodatkowySlownikId).wartosci;
            this.opisDodatkowySlownikObs = this.opisDodatkowyFC.valueChanges.pipe(startWith(this.model.opisDodatkowy), map(m => this.filtrujSlownikOpis(m)));
          }
          if (this.konfiguracja.osobaSlownikId) {
            this.osobaSlownik = this.slowniki.find(m => m.id == this.konfiguracja.osobaSlownikId).wartosci;
            this.osobaSlownikObs = this.osobaFC.valueChanges.pipe(startWith(this.model.osoba), map(m => this.filtrujSlownikOsoba(m)));
          }
          if (this.konfiguracja.inneSlownikId) {
            this.inneSlownik = this.slowniki.find(m => m.id == this.konfiguracja.inneSlownikId).wartosci;
            this.inneSlownikObs = this.inneFC.valueChanges.pipe(startWith(this.model.inne), map(m => this.filtrujSlownikInne(m)));
          }

          this.czyZaladowanoDane = true;

          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }

  pobierzSzczegoly() {
    var model = new IdModel();
    model.id = this.idPomieszczenia;

    this.pomieszczeniaSerwis.pobierzSzczegoly(model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {

          this.model.idLokalizacji = this.idLokalizacji;
          this.model.id = rezultat.element.id;
          this.model.nazwa = rezultat.element.nazwa;
          this.model.pietro = rezultat.element.pietro;
          this.model.osoba = rezultat.element.osoba;
          this.model.opisDodatkowy = rezultat.element.opisDodatkowy;
          this.model.inne = rezultat.element.inne;
          this.model.mpk = rezultat.element.mpk;
          this.czyMoznaEdytowac = rezultat.element.czyMoznaEdytowac;
          this.czyMoznaUsunac = rezultat.element.czyMoznaUsunac;

          this.pobierzDaneDoEkranu();

          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }

  usun() {
    var model = new IdModel();
    model.id = this.idPomieszczenia;

    this.modalReference = this.modalService.open(TakNieModalComponent, new ModalOpcje(true, 'static'));
    this.modalReference.componentInstance.pytanie = "Czy na pewno chcesz usunąć pomieszczenie?";

    this.modalReference.result.then((result) => {
      if (result) {
        this.pomieszczeniaSerwis.usun(model).subscribe(
          (rezultat) => {
            if (this.czyRezultatOk(rezultat)) {
              this.wyswietlKomunikat("Pomieszczenie zostało usunięte");
              this.activeModal.close(true);
              return;
            }

            this.wyswietlKomunikat(rezultat.wiadomosc, true);
            return;
          }
        );
      }
    }, onRejected => { });
  }


  zapisz() {
    this.pomieszczeniaSerwis.edytujPomieszczenie(this.model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.wyswietlKomunikat("Pomieszczenie zostało zapisane");
          this.activeModal.close(true);
          return;
        }
        if (this.bladModelu(rezultat)) {
          this.bledyWalidacji = rezultat.bledyModelu;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }


  private filtrujSlownikNazw(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.nazwaSlownik.filter(option => option.toLowerCase().includes(filterValue)).slice(0, 200);
  }

  private filtrujSlownikPietro(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.pietroSlownik.filter(option => option.toLowerCase().includes(filterValue)).slice(0, 200);
  }

  private filtrujSlownikMpk(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.mpkSlownik.filter(option => option.toLowerCase().includes(filterValue)).slice(0, 200);
  }

  private filtrujSlownikOpis(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.opisDodatkowySlownik.filter(option => option.toLowerCase().includes(filterValue)).slice(0, 200);
  }

  private filtrujSlownikOsoba(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.osobaSlownik.filter(option => option.toLowerCase().includes(filterValue)).slice(0, 200);
  }

  private filtrujSlownikInne(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.inneSlownik.filter(option => option.toLowerCase().includes(filterValue)).slice(0, 200);
  }
}
