import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { BazowyComponent } from '../../bazowy.component';
import { OswiadczeniaService } from 'src/app/serwisy/oswiadczenia/oswiadczenia.service';
import { AutoryzujEmailDoOswiadczenia } from 'src/app/modele/oswiadczenia/autoryzujEmailDoOswiadczenia';
import { MenuService } from 'src/app/serwisy/ogolne/menu.service';
import { Routing } from 'src/app/modele/wspolne/routing';

@Component({
  selector: 'app-autoryzacja-email',
  templateUrl: './autoryzacja-email.component.html',
  styleUrls: ['./autoryzacja-email.component.css'],
})
export class AutoryzacjaEmailComponent
  extends BazowyComponent
  implements OnInit
{
  id: string;
  nazwaKlienta: string;
  czyMoznaDodawacOswiadczenia: boolean = false;
  model = new AutoryzujEmailDoOswiadczenia();
  zaladowanoDane: boolean;

  constructor(
    public authService: AuthService,
    public router: Router,
    private oswiadczeniaSerwis: OswiadczeniaService,
    modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public translate: TranslateService,
    public menuService: MenuService
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translate
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.menuService.schowajMenu();
      this.id = this.activatedRoute.snapshot.paramMap.get('id');

      this.inicjujJezyk();
      this.pobierzDaneDoEkranu();
    });
  }

  public pobierzDaneDoEkranu() {
    try {
      this.oswiadczeniaSerwis
        .pobierzDanePodstawoweDoOswiadczenia(this.id)
        .subscribe((rezultat) => {
          if (this.czyRezultatOk(rezultat)) {
            this.nazwaKlienta = rezultat.element.nazwaKlienta;
            this.czyMoznaDodawacOswiadczenia = rezultat.element.czyWlaczone;
            this.zaladowanoDane = true;
            return;
          }

          this.wyswietlKomunikat(rezultat.wiadomosc, true);
        });
    } catch (blad) {}
  }

  public potwierdz() {
    try {
      this.model.id = this.id;

      this.oswiadczeniaSerwis
        .autoryzujEmail(this.model)
        .subscribe((rezultat) => {
          if (this.czyRezultatOk(rezultat)) {
            localStorage.setItem('email', this.model.email);
            this.router.navigate([Routing.OswiadczeniaAutoryzacjaKod, this.id]);
            return;
          }
          if (this.bladModelu(rezultat)) {
            this.bledyWalidacji = rezultat.bledyModelu;
            return;
          }
          this.wyswietlKomunikat(rezultat.wiadomosc, true);
        });
    } catch (blad) {}
  }
}
