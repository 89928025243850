import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AutoryzujEmailDoOswiadczenia } from 'src/app/modele/oswiadczenia/autoryzujEmailDoOswiadczenia';
import { Routing } from 'src/app/modele/wspolne/routing';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { MenuService } from 'src/app/serwisy/ogolne/menu.service';
import { OswiadczeniaService } from 'src/app/serwisy/oswiadczenia/oswiadczenia.service';
import { BazowyComponent } from '../../bazowy.component';
import { AutoryzujDoOswiadczeniaModel } from 'src/app/modele/oswiadczenia/autoryzujDoOswiadczeniaModel';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-autoryzacja-kod',
  templateUrl: './autoryzacja-kod.component.html',
  styleUrls: ['./autoryzacja-kod.component.css'],
})
export class AutoryzacjaKodComponent extends BazowyComponent implements OnInit {
  id: string;
  nazwaKlienta: string;
  czyMoznaDodawacOswiadczenia: boolean = false;
  model = new AutoryzujDoOswiadczeniaModel();
  email: string;
  zaladowanoDane: boolean;

  constructor(
    public authService: AuthService,
    public router: Router,
    private oswiadczeniaSerwis: OswiadczeniaService,
    modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public translate: TranslateService,
    public menuService: MenuService
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translate
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.menuService.schowajMenu();
      this.id = this.activatedRoute.snapshot.paramMap.get('id');
      this.email = localStorage.getItem('email');
      if (!this.email) {
        this.router.navigate([Routing.OswiadczeniaAutoryzacja, this.id]);
        return;
      }

      this.inicjujJezyk();
      this.pobierzDaneDoEkranu();
    });
  }

  public pobierzDaneDoEkranu() {
    try {
      this.oswiadczeniaSerwis
        .pobierzDanePodstawoweDoOswiadczenia(this.id)
        .subscribe((rezultat) => {
          if (this.czyRezultatOk(rezultat)) {
            this.nazwaKlienta = rezultat.element.nazwaKlienta;
            this.czyMoznaDodawacOswiadczenia = rezultat.element.czyWlaczone;
            this.zaladowanoDane = true;
            return;
          }

          this.wyswietlKomunikat(rezultat.wiadomosc, true);
        });
    } catch (blad) {}
  }

  public potwierdz() {
    try {
      this.model.kod = this.model.kod.trim();
      this.oswiadczeniaSerwis.autoryzuj(this.model).subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          localStorage.setItem('uzytkownik', JSON.stringify(rezultat.element));
          this.router.navigate([Routing.OswiadczeniaInstrukcja, this.id]);
          return;
        }
        if (this.bladModelu(rezultat)) {
          this.bledyWalidacji = rezultat.bledyModelu;
          return;
        }
        this.wyswietlKomunikat(rezultat.wiadomosc, true);
      });
    } catch (blad) {}
  }

  public wyslijKodPonownie() {
    try {
      var model = new AutoryzujEmailDoOswiadczenia();
      model.email = this.email;
      model.id = this.id;
      this.oswiadczeniaSerwis.autoryzujEmail(model).subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.wyswietlKomunikat('Kod został wysłany ponownie');
          return;
        }
        this.wyswietlKomunikat(rezultat.wiadomosc, true);
      });
    } catch (blad) {}
  }
}
