import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DezaktywujUzytkownikaModel } from 'src/app/modele/uzytkownik/dezaktywujUzytkownikaModel';
import { DezaktywujUzytkownikaRezultat } from 'src/app/modele/uzytkownik/dezaktywujUzytkownikaRezultat';
import { DodajUzytkownikaModel } from 'src/app/modele/uzytkownik/dodajUzytkownikaModel';
import { EdytujUzytkownikaModel } from 'src/app/modele/uzytkownik/edytujUzytkownikaModel';
import { PobierzUzytkownikowFiltr } from 'src/app/modele/uzytkownik/pobierzUzytkownikowFiltr';
import { PotwierdzKontoModel } from 'src/app/modele/uzytkownik/potwierdzKontoModel';
import { Uzytkownik } from 'src/app/modele/uzytkownik/uzytkownik';
import { WyslijKodWeryfikacyjnyPonownieModel } from 'src/app/modele/uzytkownik/wyslijKodWeryfikacyjnyPonownieModel';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { ListaWynikowResponse } from 'src/app/modele/wspolne/listaWynikowResponse';
import { PojedynczyElementResponse } from 'src/app/modele/wspolne/pojedynczyElement';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { ResponseBazowy } from 'src/app/modele/wspolne/responseBazowy';
import { StronicowanaListaWynikowResponse } from 'src/app/modele/wspolne/stronicowanaListaWynikowResponse';
import { ZmienHasloMailModel } from 'src/app/modele/zmianaHasla/zmienHasloMailModel';
import { SerwisBazowy } from '../bazowy.service';
import { AuthService } from '../ogolne/auth.service';
import { ErrorService } from '../ogolne/error.service';
import { LoadingService } from '../ogolne/loading.service';

@Injectable({
  providedIn: 'root'
})
export class UzytkownikService extends SerwisBazowy {


  constructor(
    httpClient: HttpClient,
    authService: AuthService,
    errorService: ErrorService,
    loaderService: LoadingService,
    translate: TranslateService) {
    super(httpClient, authService, errorService, loaderService, translate);
  }

  pobierzUzytkownikow(model: RejestrFiltrowanyRequest<PobierzUzytkownikowFiltr>, zLadowaczem: boolean) {
    if (zLadowaczem) {
      return this.wykonajAkcje<RejestrFiltrowanyRequest<PobierzUzytkownikowFiltr>, StronicowanaListaWynikowResponse<Uzytkownik>>
        (this.AdresApi + "Uzytkownik/PobierzUzytkownikow", model);
    }
    else {
      return this.wykonajAkcjeBezLadowacza<RejestrFiltrowanyRequest<PobierzUzytkownikowFiltr>, StronicowanaListaWynikowResponse<Uzytkownik>>
        (this.AdresApi + "Uzytkownik/PobierzUzytkownikow", model);
    }


  }
  dodajUzytkownika(model: DodajUzytkownikaModel) {
    return this.wykonajAkcje<DodajUzytkownikaModel, ResponseBazowy>(this.AdresApi + "Uzytkownik/DodajUzytkownika", model);

  }
  edytujUzytkownika(model: EdytujUzytkownikaModel) {
    return this.wykonajAkcje<EdytujUzytkownikaModel, ResponseBazowy>(this.AdresApi + "Uzytkownik/EdytujUzytkownika", model);

  }

  pobierzSzczegoly(model: IdModel) {
    return this.wykonajAkcje<IdModel, PojedynczyElementResponse<Uzytkownik>>(this.AdresApi + "Uzytkownik/PobierzUzytkownika", model);

  }

  dezaktywuj(model: DezaktywujUzytkownikaModel) {
    return this.wykonajAkcje<DezaktywujUzytkownikaModel, PojedynczyElementResponse<DezaktywujUzytkownikaRezultat>>
      (this.AdresApi + "Uzytkownik/Dezaktywuj", model);

  }

  aktywuj(model: IdModel) {
    return this.wykonajAkcje<IdModel, ResponseBazowy>(this.AdresApi + "Uzytkownik/Aktywuj", model);

  }

  usun(model: IdModel) {
    return this.wykonajAkcje<IdModel, ResponseBazowy>(this.AdresApi + "Uzytkownik/Usun", model);

  }

  public potwierdzKonto(model: PotwierdzKontoModel) {
    return this.wykonajAkcje<PotwierdzKontoModel, PojedynczyElementResponse<string>>(this.AdresApi + "Uzytkownik/PotwierdzKonto", model);
  }
  public wyslijLinkPonownie(model: WyslijKodWeryfikacyjnyPonownieModel) {
    return this.wykonajAkcje<WyslijKodWeryfikacyjnyPonownieModel, ResponseBazowy>(this.AdresApi + "Uzytkownik/WyslijKodWeryfikacyjnyPonownie", model);
  }
}
