import { HostListener, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, fromEvent, timer } from 'rxjs';
import { switchMap, tap, startWith, throttleTime } from 'rxjs/operators';
import { Routing } from 'src/app/modele/wspolne/routing';

@Injectable({
  providedIn: 'root',
})
export class OswiadczeniaSesjaService {
  timeout = 900000;

  private pozostalyCzasSubject = new BehaviorSubject<number>(this.timeout);
  pozostalyCzas$ = this.pozostalyCzasSubject.asObservable();
  private ostatniaAktywnosc = new Date().getTime();

  constructor(private router: Router) {
    this.uruchomOdliczanie();
  }

  uruchomOdliczanie(): void {
    fromEvent(document, 'mousemove')
      .pipe(throttleTime(5000)) // 5 seconds interval
      .subscribe(() => {
        this.zapiszOstatniaAktywnosc();
      });

    timer(0, 1000)
      .pipe(
        tap(() => {
          const now = new Date().getTime();
          const timeSinceLastActivity = now - this.ostatniaAktywnosc;
          const remainingTime = this.timeout - timeSinceLastActivity;

          if (remainingTime <= 0) {
            this.wyczyscSesje();
          } else {
            this.pozostalyCzasSubject.next(remainingTime);
          }
        })
      )
      .subscribe();
  }

  @HostListener('window:beforeunload', ['$event'])
  zapiszOstatniaAktywnoscPrzedZamknieciem(): void {
    this.zapiszOstatniaAktywnosc();
  }

  wyczyscSesjeBezZmianyStrony() {
    localStorage.removeItem('uzytkownik');
    localStorage.removeItem('spisaneSrodkiOswiadczenia');
  }

  wyczyscSesje(): void {
    var uzytkownik = JSON.parse(localStorage.getItem('uzytkownik'));
    localStorage.removeItem('uzytkownik');
    localStorage.removeItem('spisaneSrodkiOswiadczenia');
    this.router.navigate([
      Routing.OswiadczeniaAutoryzacja,
      uzytkownik.danePodstawowe.idProjektu,
    ]);
  }

  zapiszOstatniaAktywnosc(): void {
    this.ostatniaAktywnosc = new Date().getTime();
    localStorage.setItem(
      'ostatniaAktywnosc',
      JSON.stringify(this.ostatniaAktywnosc)
    );
  }

  sprawdzDateOstatniejAktywnosci(): boolean {
    const ostatniaAktywnosc = JSON.parse(
      localStorage.getItem('ostatniaAktywnosc')
    );
    const now = new Date().getTime();
    return now - ostatniaAktywnosc > this.timeout;
  }
}
