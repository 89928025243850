import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { EdytujSlownikModel } from 'src/app/modele/projekty/slowniki/edytujSlownikModel';
import { RodzajSlownika } from 'src/app/modele/projekty/slowniki/rodzajSlownika';
import { SzczegolySlownika } from 'src/app/modele/projekty/slowniki/szczegolySlownika';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { Zalacznik } from 'src/app/modele/wspolne/zalacznik';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { SlownikiService } from 'src/app/serwisy/projekty/slowniki.service';
import { TabelaSlownikaModalComponent } from '../tabela-slownika-modal/tabela-slownika-modal.component';

@Component({
  selector: 'app-edytuj-slownik-modal',
  templateUrl: './edytuj-slownik-modal.component.html',
  styleUrls: ['./edytuj-slownik-modal.component.css']
})
export class EdytujSlownikModalComponent extends BazowyComponent implements OnInit {

  idSlownika: string;
  czyZaladowanoDane: boolean;
  szczegolySlownika: SzczegolySlownika = new SzczegolySlownika();
  edytujSlownikModel: EdytujSlownikModel = new EdytujSlownikModel();
  czyWielokolumnowy: boolean;

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    public activeModal: NgbActiveModal,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public slownikiSerwis: SlownikiService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translateSerwis);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.pobierzDaneDoEkranu();
    });
  }

  pobierzDaneDoEkranu() {
    var model = new IdModel();
    model.id = this.idSlownika;
    this.slownikiSerwis.pobierzSzczegolySlownika(model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.szczegolySlownika = rezultat.element;
          this.edytujSlownikModel.id = this.idSlownika;
          this.edytujSlownikModel.nazwa = rezultat.element.nazwa;
          this.edytujSlownikModel.projektId = rezultat.element.projektId;
          this.edytujSlownikModel.zalacznik = rezultat.element.zalacznik;
          this.edytujSlownikModel.czyMoznaEdytowac = rezultat.element.czyMoznaEdytowac;

          this.czyWielokolumnowy = rezultat.element.rodzajSlownika == RodzajSlownika.wielokolumnowy;

          this.czyZaladowanoDane = true;

          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }

  zapisz() {
    this.slownikiSerwis.edytujSlownik(this.edytujSlownikModel).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.wyswietlKomunikat("Słownik został zapisany");
          this.activeModal.close(true);
          return;
        }
        if (this.bladModelu(rezultat)) {
          this.bledyWalidacji = rezultat.bledyModelu;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }


  pobierzPlik() {
    var a = document.createElement("a");
    a.target = '_blank';
    a.href = "data:" + this.szczegolySlownika.zalacznik.format + ";base64," + this.szczegolySlownika.zalacznik.plik;
    a.download = this.szczegolySlownika.zalacznik.nazwaPliku;
    a.click();
  }

  pokazTabeleSlownika() {
    if (this.edytujSlownikModel.czyNowyPlik) {
      return;
    }
    this.modalReference = this.modalService.open(TabelaSlownikaModalComponent, new ModalOpcje(true, 'static', null, "modal-dialog--fluid"));
    this.modalReference.componentInstance.szczegolySlownika = this.szczegolySlownika;
  }

  usunZalacznik() {
    this.edytujSlownikModel.zalacznik = undefined;
    this.edytujSlownikModel.czyNowyPlik = true;
    this.edytujSlownikModel.czyMoznaEdytowac = false;
    this.czyWielokolumnowy = false;
  }

  wybierzZalacznikZDysku(event: any) {
    var plik = event.target.files[0];
    this.wybierzZalacznik(plik);

    event.target.value = null;
    return false;
  }

  wybierzZalacznik(plik: File) {
    if (plik) {

      var reader = new FileReader();
      reader.readAsDataURL(plik);
      reader.onload = () => {
        var zalacznik = new Zalacznik();
        zalacznik.nazwaPliku = plik.name;
        zalacznik.plik = this.usunZbedneZnakiZPliku(reader.result.toString());
        if (zalacznik.plik == "data:") {
          return;
        }
        zalacznik.dataModyfikacji = new Date(plik.lastModified);
        zalacznik.format = plik.name.split('.').pop().toLowerCase();

        this.edytujSlownikModel.zalacznik = zalacznik;
        this.edytujSlownikModel.czyNowyPlik = true;

        this.sprawdzCzySlownikWielokolumnowy();
      };
    }
  }

  usunZbedneZnakiZPliku(base64: string) {
    if (base64.includes(",")) {
      return base64.substring(base64.indexOf(",") + 1);
    }
    return base64;
  }

  usun() {
    var model = new IdModel();
    model.id = this.idSlownika;

    this.slownikiSerwis.usunSlownik(model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {

          if (rezultat.element.slownikWUzyciu) {
            this.wyswietlKomunikat("Brak możliwości usunięcia słownika w użyciu", true);
            return;
          }

          this.wyswietlKomunikat("Słownik został usunięty");
          this.activeModal.close(true);
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }

  sprawdzCzySlownikWielokolumnowy() {
    this.czyWielokolumnowy = false;
    this.slownikiSerwis.sprawdzCzyWielokolumnowy(this.edytujSlownikModel).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.czyWielokolumnowy = rezultat.element;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }
}
