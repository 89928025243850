import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { UstawieniaSystemu } from 'src/app/modele/administracja/ustawieniaSystemu';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { Routing } from 'src/app/modele/wspolne/routing';
import { UstawieniaService } from 'src/app/serwisy/administracja/ustawienia.service';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { BazowyComponent } from '../../bazowy.component';
import { ZmienHasloDoSkrzynkiModalComponent } from './zmien-haslo-do-skrzynki-modal/zmien-haslo-do-skrzynki-modal.component';

@Component({
  selector: 'app-ustawienia',
  templateUrl: './ustawienia.component.html',
  styleUrls: ['./ustawienia.component.css']
})
export class UstawieniaComponent extends BazowyComponent implements OnInit {

  czyZaladowanoDane: boolean;
  model: UstawieniaSystemu = new UstawieniaSystemu();

  slideConfig = {
    "slidesToShow": 2,
    "slidesToScroll": 1,
    "prevArrow": document.getElementsByClassName('btn__left'),
    "nextArrow": document.getElementsByClassName('btn__right'),
    "infinite": false,
    "variableWidth": true,
    "initialSlide": 2
  };

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public translateSerwis: TranslateService,
    private ustawieniaSerwis: UstawieniaService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translateSerwis);
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (!this.czyAdmin()) {
        this.router.navigate([Routing.StronaGlowna]);
        return;
      }
      this.pobierzDaneDoEkranu();
    });
  }

  pobierzDaneDoEkranu() {
    this.ustawieniaSerwis.pobierzUstawienia().subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {

          this.model = rezultat.element;
          this.czyZaladowanoDane = true;

          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }

  public zapisz() {
    this.ustawieniaSerwis.zapiszUstawienia(this.model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.wyswietlKomunikat(this.pobierzTlumaczenie("daneZostalyZapisane"));
        this.bledyWalidacji = null;
        return;
      } else if (this.bladModelu(rezultat)) {
        this.bledyWalidacji = rezultat.bledyModelu;
        return;
      }
      this.wyswietlKomunikat(rezultat.wiadomosc, true);
    }
    );
  }


  public zmienHaslo(): void {
    const ref = this.modalService.open(ZmienHasloDoSkrzynkiModalComponent, new ModalOpcje(true, 'static'));
    ref.result.then((result) => {
      this.wyswietlKomunikat("Hasło zostało zmienione");
    }, onRejected => { });

  }

}
