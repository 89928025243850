import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { KolumnaEwidencjiFiltrowanie } from 'src/app/modele/projekty/ewidencja/kolumnaEwidencjiFiltrowanie';
import { ParujRecznieModel } from 'src/app/modele/projekty/parowanie/parujRecznieModel';
import { PobierzNadwyzkiDoParowaniaFiltry } from 'src/app/modele/projekty/parowanie/pobierzNadwyzkiDoParowaniaFiltry';
import { PobierzNiedoboryDoParowaniaFiltry } from 'src/app/modele/projekty/parowanie/pobierzNiedoboryDoParowaniaFiltry';
import { RozlaczSrodkiModel } from 'src/app/modele/projekty/parowanie/rozlaczSrodkiModel';
import { WierszSrodka } from 'src/app/modele/projekty/parowanie/wierszSrodka';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { Routing } from 'src/app/modele/wspolne/routing';
import {
  Sortowanie,
  KierunekSortowania,
} from 'src/app/modele/wspolne/sortowanie';
import { Stronicowanie } from 'src/app/modele/wspolne/stronicowanie';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { ParowanieService } from 'src/app/serwisy/projekty/parowanie.service';
import { DodajKomentarzDoEwidencjiModalComponent } from '../../ewidencja/dodaj-komentarz-do-ewidencji-modal/dodaj-komentarz-do-ewidencji-modal.component';
import { KomentarzParowaniaModalComponent } from './komentarz-parowania-modal/komentarz-parowania-modal.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-parowanie-srodkow',
  templateUrl: './parowanie-srodkow.component.html',
  styleUrls: ['./parowanie-srodkow.component.css'],
})
export class ParowanieSrodkowComponent
  extends BazowyComponent
  implements OnInit
{
  idLokalizacji: string;
  idProjektu: string;
  czyZaladowanoDane: boolean;
  czyPokazanoWyszukiwarkeNadwyzekMobile: boolean;
  czyPokazanoWyszukiwarkeNiedoborowMobile: boolean;
  czyPokazanoPodmenu: boolean;

  miejsceWywolania: string;

  czySugerowacPodobne: boolean;
  czyPokazacSparowane: boolean;
  czySzukacWSparowanych: boolean;
  przefiltrowanePrzezNadwyzki: boolean;
  przefiltrowanePrzezNiedobory: boolean;

  calkowitaLiczbaElementowNadwyzki: BigInteger;
  rozmiarTabeliNadwyzki: number = 25;
  czyJestWiecejRekordowNadwyzki: boolean = true;
  czyPokazacMenuSortowanieNadwyzki: boolean;
  wyszukiwanieNadwyzki: RejestrFiltrowanyRequest<PobierzNadwyzkiDoParowaniaFiltry> =
    new RejestrFiltrowanyRequest<PobierzNadwyzkiDoParowaniaFiltry>();
  nadwyzki: Array<WierszSrodka> = new Array();
  kolumnyTabeliNadwyzki: Array<string> = new Array();
  czyPierwszeUruchomienieNadwyzki: boolean = true;
  czyPokazanoPodmenuNadwyzki: boolean;
  kolumnaIdNadwyzki: number;

  calkowitaLiczbaElementowNiedobory: BigInteger;
  rozmiarTabeliNiedobory: number = 25;
  czyJestWiecejRekordowNiedobory: boolean = true;
  czyPokazacMenuSortowanieNiedobory: boolean;
  wyszukiwanieNiedobory: RejestrFiltrowanyRequest<PobierzNiedoboryDoParowaniaFiltry> =
    new RejestrFiltrowanyRequest<PobierzNiedoboryDoParowaniaFiltry>();
  niedobory: Array<WierszSrodka> = new Array();
  kolumnyTabeliNiedobory: Array<string> = new Array();
  czyPierwszeUruchomienieNiedobory: boolean = true;
  czyPokazanoPodmenuNiedobory: boolean;
  kolumnaIdNiedobory: number;
  kolumnaKomentarza: number;

  subsriptionNadwyzki: Subscription | undefined;
  subsriptionNiedobory: Subscription | undefined;

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public parowanieSerwis: ParowanieService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translateSerwis
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.idLokalizacji =
        this.activatedRoute.snapshot.paramMap.get('idLokalizacji');
      this.idProjektu = this.activatedRoute.snapshot.paramMap.get('idProjektu');
      this.miejsceWywolania =
        this.activatedRoute.snapshot.paramMap.get('miejsceWywolania');

      this.wyszukiwanieNadwyzki.filtry = new PobierzNadwyzkiDoParowaniaFiltry();
      this.wyszukiwanieNadwyzki.sortowanie = new Sortowanie(
        'data_utworzenia',
        KierunekSortowania.desc
      );
      this.wyszukiwanieNadwyzki.stronicowanie = new Stronicowanie(false);
      this.wyszukiwanieNadwyzki.stronicowanie.iloscRekordow =
        this.rozmiarTabeliNadwyzki;
      this.wyszukiwanieNadwyzki.stronicowanie.rekordPoczatkowy = 0;
      this.wyszukiwanieNadwyzki.stronicowanie.strona = 1;

      this.wyszukiwanieNiedobory.filtry =
        new PobierzNiedoboryDoParowaniaFiltry();
      this.wyszukiwanieNiedobory.sortowanie = new Sortowanie(
        'lp',
        KierunekSortowania.asc
      );
      this.wyszukiwanieNiedobory.stronicowanie = new Stronicowanie(false);
      this.wyszukiwanieNiedobory.stronicowanie.iloscRekordow =
        this.rozmiarTabeliNiedobory;
      this.wyszukiwanieNiedobory.stronicowanie.rekordPoczatkowy = 0;
      this.wyszukiwanieNiedobory.stronicowanie.strona = 1;

      this.pobierzNadwyzki(true);
      this.pobierzNiedobory(true);
    });
  }

  pobierzNadwyzki(zLadowaczem: boolean) {
    this.wyszukiwanieNadwyzki.filtry.idLokalizacji = this.idLokalizacji;
    this.wyszukiwanieNadwyzki.filtry.idProjektu = this.idProjektu;

    this.wyszukiwanieNadwyzki.filtry.czyPokazacSparowaneSrodki =
      this.czyPokazacSparowane;
    this.wyszukiwanieNadwyzki.filtry.czySugerowacPodobne =
      this.czySugerowacPodobne;

    this.subsriptionNadwyzki = this.parowanieSerwis
      .pobierzNadwyzkiDoParowania(this.wyszukiwanieNadwyzki, zLadowaczem)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.nadwyzki = rezultat.element.dane;
          this.calkowitaLiczbaElementowNadwyzki =
            rezultat.element.calkowitaIloscRekordow;
          this.czyJestWiecejRekordowNadwyzki =
            rezultat.element.czyJestWiecejRekordow;
          this.kolumnyTabeliNadwyzki = rezultat.element.kolumny;

          this.kolumnaIdNadwyzki = this.kolumnyTabeliNadwyzki.indexOf('id');

          if (this.czyPierwszeUruchomienieNadwyzki) {
            this.przygotujFiltryNadwyzki();
            this.czyPierwszeUruchomienieNadwyzki = false;
          }

          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  pobierzNiedobory(zLadowaczem: boolean) {
    this.wyszukiwanieNiedobory.filtry.idLokalizacji = this.idLokalizacji;
    this.wyszukiwanieNiedobory.filtry.idProjektu = this.idProjektu;

    this.wyszukiwanieNiedobory.filtry.czyPokazacSparowaneSrodki =
      this.czyPokazacSparowane;
    this.wyszukiwanieNiedobory.filtry.czySugerowacPodobne =
      this.czySugerowacPodobne;
    this.wyszukiwanieNiedobory.filtry.czySzukacWSparowanych =
      this.czySzukacWSparowanych;

    this.subsriptionNiedobory = this.parowanieSerwis
      .pobierzNiedoboryDoParowania(this.wyszukiwanieNiedobory, zLadowaczem)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.niedobory = rezultat.element.dane;
          this.calkowitaLiczbaElementowNiedobory =
            rezultat.element.calkowitaIloscRekordow;
          this.czyJestWiecejRekordowNiedobory =
            rezultat.element.czyJestWiecejRekordow;
          this.kolumnyTabeliNiedobory = rezultat.element.kolumny;

          this.kolumnaIdNiedobory = this.kolumnyTabeliNiedobory.indexOf('id');
          this.kolumnaKomentarza =
            this.kolumnyTabeliNiedobory.indexOf('Komentarz');

          if (this.czyPierwszeUruchomienieNiedobory) {
            this.przygotujFiltryNiedobory();
            this.czyPierwszeUruchomienieNiedobory = false;
          }

          this.czyZaladowanoDane = true;

          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  zmienRozmiarTabeliNadwyzki() {
    this.wyszukiwanieNadwyzki.stronicowanie.iloscRekordow =
      this.rozmiarTabeliNadwyzki;
    this.pobierzNadwyzki(true);
  }

  pobierzNumerRekorduPoczatekNadwyzki() {
    if (this.wyszukiwanieNadwyzki.stronicowanie == undefined) {
      return 1;
    }
    return this.wyszukiwanieNadwyzki.stronicowanie.rekordPoczatkowy + 1;
  }
  pobierzNumerRekorduKoniecNadwyzki() {
    if (this.wyszukiwanieNadwyzki.stronicowanie == undefined) {
      return 1;
    }
    return (
      this.wyszukiwanieNadwyzki.stronicowanie.rekordPoczatkowy +
      this.nadwyzki.length
    );
  }

  zmienStroneNadwyzki(oIle: number) {
    this.wyszukiwanieNadwyzki.stronicowanie.strona += oIle;
    this.wyszukiwanieNadwyzki.stronicowanie.rekordPoczatkowy =
      (this.wyszukiwanieNadwyzki.stronicowanie.strona - 1) *
      this.wyszukiwanieNadwyzki.stronicowanie.iloscRekordow;
    this.pobierzNadwyzki(true);
  }

  pobierzKierunekSortowaniaNadwyzki(kolumna: string) {
    var kierunki = this.wyszukiwanieNadwyzki.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      return '';
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      return 'up';
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      return 'down';
    }
  }

  zmienKierunekSortowaniaNadwyzki(kolumna: string) {
    this.czyPokazacMenuSortowanieNadwyzki = false;
    var kierunki = this.wyszukiwanieNadwyzki.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      this.wyszukiwanieNadwyzki.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.asc
      );
      this.pobierzNadwyzki(true);
      return;
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanieNadwyzki.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.desc
      );
      this.pobierzNadwyzki(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanieNadwyzki.sortowanie = new Sortowanie(
        'id',
        KierunekSortowania.asc
      );
      this.pobierzNadwyzki(true);
      return;
    }
  }

  wyczyscFiltryNadwyzki() {
    this.wyszukiwanieNadwyzki.filtry.kolumnyFiltrowania.forEach((k) => {
      k.wartoscSzukaj = '';
    });
    this.pobierzNadwyzki(true);
  }

  filtrujPoTekscieNadwyzki(event: any, kolumna: string) {
    this.wyszukiwanieNadwyzki.stronicowanie.rekordPoczatkowy = 0;
    this.wyszukiwanieNadwyzki.stronicowanie.strona = 1;
    var wartosc = event.target.value.toString();

    this.ustawWartoscFiltrowaniaNadwyzki(kolumna, wartosc);
    this.subsriptionNadwyzki?.unsubscribe();
    this.pobierzNadwyzki(false);
  }

  ustawWartoscFiltrowaniaNadwyzki(kolumna: string, wartosc: string) {
    var kolumnaFiltrowania =
      this.wyszukiwanieNadwyzki.filtry.kolumnyFiltrowania.find(
        (m) => m.kolumna == kolumna
      );

    if (kolumnaFiltrowania == undefined) {
      kolumnaFiltrowania = new KolumnaEwidencjiFiltrowanie();
      kolumnaFiltrowania.kolumna = kolumna;
      kolumnaFiltrowania.wartoscSzukaj = wartosc;
      this.wyszukiwanieNadwyzki.filtry.kolumnyFiltrowania.push(
        kolumnaFiltrowania
      );
    } else {
      this.wyszukiwanieNadwyzki.filtry.kolumnyFiltrowania.forEach((k) => {
        if (k.kolumna == kolumna) {
          k.wartoscSzukaj = wartosc;
        }
      });
    }
  }

  czyPokazacPrzyciskCzyszczeniaFiltruNadwyzki(kolumna: string) {
    var kolumnaFiltrowania =
      this.wyszukiwanieNadwyzki.filtry.kolumnyFiltrowania.find(
        (m) => m.kolumna == kolumna
      );
    return kolumnaFiltrowania != undefined && kolumnaFiltrowania.wartoscSzukaj;
  }
  wyczyscFiltrKolumnyNadwyzki(kolumna: string) {
    this.wyszukiwanieNadwyzki.filtry.kolumnyFiltrowania.forEach((k) => {
      if (k.kolumna == kolumna) {
        k.wartoscSzukaj = '';
      }
    });
    this.pobierzNadwyzki(false);
  }

  przygotujFiltryNadwyzki() {
    this.wyszukiwanieNadwyzki.filtry.kolumnyFiltrowania = new Array();
    this.kolumnyTabeliNadwyzki.forEach((kolumna) => {
      if (kolumna == 'id') {
        return;
      }
      var kolumnaFiltrowania = new KolumnaEwidencjiFiltrowanie();
      kolumnaFiltrowania.kolumna = kolumna;
      kolumnaFiltrowania.wartoscSzukaj = '';
      this.wyszukiwanieNadwyzki.filtry.kolumnyFiltrowania.push(
        kolumnaFiltrowania
      );
    });
  }

  czyPokazacWyczyscFiltryNadwyzki() {
    return (
      this.wyszukiwanieNadwyzki.filtry.kolumnyFiltrowania.filter(
        (m) => m.wartoscSzukaj
      ).length > 0
    );
  }

  zmienRozmiarTabeliNiedobory() {
    this.wyszukiwanieNiedobory.stronicowanie.iloscRekordow =
      this.rozmiarTabeliNiedobory;
    this.pobierzNiedobory(true);
  }

  pobierzNumerRekorduPoczatekNiedobory() {
    if (this.wyszukiwanieNiedobory.stronicowanie == undefined) {
      return 1;
    }
    return this.wyszukiwanieNiedobory.stronicowanie.rekordPoczatkowy + 1;
  }
  pobierzNumerRekorduKoniecNiedobory() {
    if (this.wyszukiwanieNiedobory.stronicowanie == undefined) {
      return 1;
    }
    return (
      this.wyszukiwanieNiedobory.stronicowanie.rekordPoczatkowy +
      this.niedobory.length
    );
  }

  zmienStroneNiedobory(oIle: number) {
    this.wyszukiwanieNiedobory.stronicowanie.strona += oIle;
    this.wyszukiwanieNiedobory.stronicowanie.rekordPoczatkowy =
      (this.wyszukiwanieNiedobory.stronicowanie.strona - 1) *
      this.wyszukiwanieNiedobory.stronicowanie.iloscRekordow;
    this.pobierzNiedobory(true);
  }

  pobierzKierunekSortowaniaNiedobory(kolumna: string) {
    var kierunki = this.wyszukiwanieNiedobory.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      return '';
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      return 'up';
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      return 'down';
    }
  }

  zmienKierunekSortowaniaNiedobory(kolumna: string) {
    this.czyPokazacMenuSortowanieNiedobory = false;
    var kierunki = this.wyszukiwanieNiedobory.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      this.wyszukiwanieNiedobory.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.asc
      );
      this.pobierzNiedobory(true);
      return;
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanieNiedobory.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.desc
      );
      this.pobierzNiedobory(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanieNiedobory.sortowanie = new Sortowanie(
        'id',
        KierunekSortowania.asc
      );
      this.pobierzNiedobory(true);
      return;
    }
  }

  wyczyscFiltryNiedobory() {
    this.wyszukiwanieNiedobory.filtry.kolumnyFiltrowania.forEach((k) => {
      k.wartoscSzukaj = '';
    });
    this.pobierzNiedobory(true);
  }

  filtrujPoTekscieNiedobory(event: any, kolumna: string) {
    this.wyszukiwanieNiedobory.stronicowanie.rekordPoczatkowy = 0;
    this.wyszukiwanieNiedobory.stronicowanie.strona = 1;
    var wartosc = event.target.value.toString();

    this.ustawWartoscFiltrowaniaNiedobory(kolumna, wartosc);
    this.subsriptionNiedobory?.unsubscribe();
    this.pobierzNiedobory(false);
  }

  ustawWartoscFiltrowaniaNiedobory(kolumna: string, wartosc: string) {
    var kolumnaFiltrowania =
      this.wyszukiwanieNiedobory.filtry.kolumnyFiltrowania.find(
        (m) => m.kolumna == kolumna
      );

    if (kolumnaFiltrowania == undefined) {
      kolumnaFiltrowania = new KolumnaEwidencjiFiltrowanie();
      kolumnaFiltrowania.kolumna = kolumna;
      kolumnaFiltrowania.wartoscSzukaj = wartosc;
      this.wyszukiwanieNiedobory.filtry.kolumnyFiltrowania.push(
        kolumnaFiltrowania
      );
    } else {
      this.wyszukiwanieNiedobory.filtry.kolumnyFiltrowania.forEach((k) => {
        if (k.kolumna == kolumna) {
          k.wartoscSzukaj = wartosc;
        }
      });
    }
  }

  czyPokazacPrzyciskCzyszczeniaFiltruNiedobory(kolumna: string) {
    var kolumnaFiltrowania =
      this.wyszukiwanieNiedobory.filtry.kolumnyFiltrowania.find(
        (m) => m.kolumna == kolumna
      );
    return kolumnaFiltrowania != undefined && kolumnaFiltrowania.wartoscSzukaj;
  }
  wyczyscFiltrKolumnyNiedobory(kolumna: string) {
    this.wyszukiwanieNiedobory.filtry.kolumnyFiltrowania.forEach((k) => {
      if (k.kolumna == kolumna) {
        k.wartoscSzukaj = '';
      }
    });
    this.pobierzNiedobory(false);
  }

  przygotujFiltryNiedobory() {
    this.wyszukiwanieNiedobory.filtry.kolumnyFiltrowania = new Array();
    this.kolumnyTabeliNiedobory.forEach((kolumna) => {
      if (kolumna == 'id') {
        return;
      }
      var kolumnaFiltrowania = new KolumnaEwidencjiFiltrowanie();
      kolumnaFiltrowania.kolumna = kolumna;
      kolumnaFiltrowania.wartoscSzukaj = '';
      this.wyszukiwanieNiedobory.filtry.kolumnyFiltrowania.push(
        kolumnaFiltrowania
      );
    });
  }

  czyPokazacWyczyscFiltryNiedobory() {
    return (
      this.wyszukiwanieNiedobory.filtry.kolumnyFiltrowania.filter(
        (m) => m.wartoscSzukaj
      ).length > 0
    );
  }

  czyKolumnaLp(kolumna: string) {
    return kolumna.toLowerCase().includes('lp');
  }

  dodajKomentarz(rekord: WierszSrodka, tresc: string) {
    var id = rekord.id;

    this.modalReference = this.modalService.open(
      DodajKomentarzDoEwidencjiModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.idProjektu = this.idProjektu;
    this.modalReference.componentInstance.id = id;
    this.modalReference.componentInstance.tresc = tresc;
    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.pobierzNiedobory(true);
        }
      },
      (onRejected) => {}
    );
  }

  zaznaczCheckboxNiedoborow($event: any, id: string) {
    if ($event.currentTarget.checked) {
      this.niedobory.forEach((m) => {
        m.czyWybrany = m.id == id;
      });
      if (
        (!this.czySugerowacPodobne && !this.czyPokazacSparowane) ||
        this.nadwyzki.filter((m) => m.czyWybrany).length != 0
      ) {
        return;
      }
      this.przefiltrowanePrzezNiedobory = true;
      this.przefiltrowanePrzezNadwyzki = false;
      this.wyszukiwanieNadwyzki.filtry.rekordEwidencjiDoSparowaniaId = id;
      this.pobierzNadwyzki(true);
    } else {
      this.niedobory.forEach((m) => {
        m.czyWybrany = false;
      });

      if (
        this.czySugerowacPodobne ||
        this.czyPokazacSparowane
        //  && this.nadwyzki.filter(m => m.czyWybrany).length == 0
      ) {
        if (
          this.nadwyzki.filter((m) => m.czyWybrany).length == 0 ||
          this.przefiltrowanePrzezNiedobory
        ) {
          this.wyszukiwanieNadwyzki.filtry.rekordEwidencjiDoSparowaniaId = null;
          this.pobierzNadwyzki(true);
        } else {
        }
      }
    }
  }

  zaznaczCheckboxNadwyzek($event: any, id: string) {
    if ($event.currentTarget.checked) {
      this.nadwyzki.forEach((m) => {
        m.czyWybrany = m.id == id;
      });
      if (
        (!this.czySugerowacPodobne && !this.czyPokazacSparowane) ||
        this.niedobory.filter((m) => m.czyWybrany).length != 0
      ) {
        return;
      }
      this.przefiltrowanePrzezNiedobory = false;
      this.przefiltrowanePrzezNadwyzki = true;
      this.wyszukiwanieNiedobory.filtry.spisanySrodekDoSparowaniaId = id;
      this.pobierzNiedobory(true);
    } else {
      this.nadwyzki.forEach((m) => {
        m.czyWybrany = false;
      });

      if (this.czySugerowacPodobne || this.czyPokazacSparowane) {
        if (
          this.niedobory.filter((m) => m.czyWybrany).length == 0 ||
          this.przefiltrowanePrzezNadwyzki
        ) {
          this.wyszukiwanieNiedobory.filtry.spisanySrodekDoSparowaniaId = null;
          this.pobierzNiedobory(true);
        }
      }
    }
  }

  czyPokazacPrzyciskParuj() {
    if (
      !this.czyPokazacSparowane &&
      this.nadwyzki.filter((m) => m.czyWybrany).length == 1 &&
      this.niedobory.filter((m) => m.czyWybrany).length == 1
    ) {
      return true;
    }

    return false;
  }
  czyPokazacPrzyciskRozlacz() {
    if (
      this.czyPokazacSparowane &&
      this.nadwyzki.filter((m) => m.czyWybrany).length == 1 &&
      this.niedobory.filter((m) => m.czyWybrany).length == 1
    ) {
      return true;
    }

    return false;
  }

  zaznaczSugerujPodobne($event: any) {
    this.czySugerowacPodobne = $event.currentTarget.checked;

    if (this.czyPokazacSparowane) {
      this.czyPokazacSparowane = false;
      this.pobierzNadwyzki(true);
      this.pobierzNiedobory(true);
    }
    if (this.nadwyzki.filter((m) => m.czyWybrany).length == 1) {
      this.wyszukiwanieNiedobory.filtry.spisanySrodekDoSparowaniaId =
        this.nadwyzki.find((m) => m.czyWybrany).id;
      this.pobierzNiedobory(true);
    }
    if (this.niedobory.filter((m) => m.czyWybrany).length == 1) {
      this.wyszukiwanieNadwyzki.filtry.rekordEwidencjiDoSparowaniaId =
        this.niedobory.find((m) => m.czyWybrany).id;
      this.pobierzNadwyzki(true);
    }
  }

  zaznaczPokazSparowane($event: any) {
    this.czyPokazacSparowane = $event.currentTarget.checked;

    if (this.czyPokazacSparowane) {
      this.czySugerowacPodobne = false;
      this.czySzukacWSparowanych = false;
    }

    this.pobierzNadwyzki(true);
    this.pobierzNiedobory(true);
  }

  zaznaczSzukajWSparowanych($event: any) {
    this.czySzukacWSparowanych = $event.currentTarget.checked;

    if (this.czyPokazacSparowane) {
      this.czyPokazacSparowane = false;
      this.pobierzNadwyzki(true);
    }
    this.pobierzNiedobory(true);
  }

  anuluj() {
    if (this.miejsceWywolania == '1') {
      this.router.navigate([
        Routing.NadwyzkiLokalizacji,
        this.idProjektu,
        this.idLokalizacji,
      ]);
    } else {
      this.router.navigate([
        Routing.NiedoboryLokalizacji,
        this.idProjektu,
        this.idLokalizacji,
      ]);
    }
  }

  rozpocznijParowanie() {
    var model = new ParujRecznieModel();
    model.idLokalizacji = this.idLokalizacji;
    model.idProjektu = this.idProjektu;
    model.idSpisanegoSrodka = this.nadwyzki.find((m) => m.czyWybrany).id;
    model.idSrodkaEwidencji = this.niedobory.find((m) => m.czyWybrany).id;

    this.modalReference = this.modalService.open(
      KomentarzParowaniaModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.model = model;
    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.nadwyzki.forEach((m) => {
            m.czyWybrany = false;
          });
          this.niedobory.forEach((m) => {
            m.czyWybrany = false;
          });
          this.wyszukiwanieNiedobory.filtry.spisanySrodekDoSparowaniaId = null;
          this.wyszukiwanieNadwyzki.filtry.rekordEwidencjiDoSparowaniaId = null;
          this.pobierzNiedobory(true);
          this.pobierzNadwyzki(true);
          this.wyswietlKomunikat('Środki zostały sparowane');
        }
      },
      (onRejected) => {}
    );
  }

  rozlacz() {
    var model = new RozlaczSrodkiModel();
    model.idLokalizacji = this.idLokalizacji;
    model.idProjektu = this.idProjektu;
    model.idSpisanegoSrodka = this.nadwyzki.find((m) => m.czyWybrany).id;
    model.idSrodkaEwidencji = this.niedobory.find((m) => m.czyWybrany).id;

    this.parowanieSerwis.rozlaczSrodki(model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.nadwyzki.forEach((m) => {
          m.czyWybrany = false;
        });
        this.niedobory.forEach((m) => {
          m.czyWybrany = false;
        });
        this.wyszukiwanieNiedobory.filtry.spisanySrodekDoSparowaniaId = null;
        this.wyszukiwanieNadwyzki.filtry.rekordEwidencjiDoSparowaniaId = null;
        this.pobierzNiedobory(true);
        this.pobierzNadwyzki(true);
        this.wyswietlKomunikat('Środki zostały rozłączone');
        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  pokazWyszukiwarkeNadwyzekMobile(czyPokazac: boolean) {
    this.czyPokazanoWyszukiwarkeNadwyzekMobile = czyPokazac;
    this.czyPokazanoWyszukiwarkeNiedoborowMobile = false;
    this.czyPokazanoPodmenu = false;
  }

  pokazWyszukiwarkeNiedoborowMobile(czyPokazac: boolean) {
    this.czyPokazanoWyszukiwarkeNadwyzekMobile = false;
    this.czyPokazanoWyszukiwarkeNiedoborowMobile = czyPokazac;
    this.czyPokazanoPodmenu = false;
  }

  pokazPodmenu() {
    this.czyPokazanoPodmenu = !this.czyPokazanoPodmenu;
    this.czyPokazanoWyszukiwarkeNadwyzekMobile = false;
    this.czyPokazanoWyszukiwarkeNiedoborowMobile = false;
  }

  kliknijWWierszNadwyzek(id: string) {
    var czyWybrany = !this.nadwyzki.find((m) => m.id == id).czyWybrany;

    if (czyWybrany) {
      this.nadwyzki.forEach((m) => {
        m.czyWybrany = m.id == id;
      });
      if (
        (!this.czySugerowacPodobne && !this.czyPokazacSparowane) ||
        this.niedobory.filter((m) => m.czyWybrany).length != 0
      ) {
        return;
      }
      this.przefiltrowanePrzezNiedobory = false;
      this.przefiltrowanePrzezNadwyzki = true;
      this.wyszukiwanieNiedobory.filtry.spisanySrodekDoSparowaniaId = id;
      this.pobierzNiedobory(true);
    } else {
      this.nadwyzki.forEach((m) => {
        m.czyWybrany = false;
      });

      if (this.czySugerowacPodobne || this.czyPokazacSparowane) {
        if (
          this.niedobory.filter((m) => m.czyWybrany).length == 0 ||
          this.przefiltrowanePrzezNadwyzki
        ) {
          this.wyszukiwanieNiedobory.filtry.spisanySrodekDoSparowaniaId = null;
          this.pobierzNiedobory(true);
        }
      }
    }
  }

  kliknijWWierszNiedoborow(id: string) {
    var czyWybrany = !this.niedobory.find((m) => m.id == id).czyWybrany;

    if (czyWybrany) {
      this.niedobory.forEach((m) => {
        m.czyWybrany = m.id == id;
      });
      if (
        (!this.czySugerowacPodobne && !this.czyPokazacSparowane) ||
        this.nadwyzki.filter((m) => m.czyWybrany).length != 0
      ) {
        return;
      }
      this.przefiltrowanePrzezNiedobory = true;
      this.przefiltrowanePrzezNadwyzki = false;
      this.wyszukiwanieNadwyzki.filtry.rekordEwidencjiDoSparowaniaId = id;
      this.pobierzNadwyzki(true);
    } else {
      this.niedobory.forEach((m) => {
        m.czyWybrany = false;
      });

      if (this.czySugerowacPodobne || this.czyPokazacSparowane) {
        if (
          this.nadwyzki.filter((m) => m.czyWybrany).length == 0 ||
          this.przefiltrowanePrzezNiedobory
        ) {
          this.wyszukiwanieNadwyzki.filtry.rekordEwidencjiDoSparowaniaId = null;
          this.pobierzNadwyzki(true);
        }
      }
    }
  }
}
