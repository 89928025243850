export class PobierzLokalizacjeFiltr {
    lp: string;
    ulica: string;
    miasto: string;
    kodPocztowy: string;
    mPK: string;
    osobaOdpowiedzialna: string;
    numerTelefonu: string;
    rodzajLokalizacji: string;
    projektId: string;
    numerZespolu: string;
}