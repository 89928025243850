<div class="modal-content">
    <div class="modal-body">
        <button aria-label="Close" class="close" data-dismiss="modal" type="button"
            (click)="activeModal.dismiss()"></button>

        <div class="ft__03--2 text-center mt-3 pb-1 pt-5 pt-md-2">{{'dezaktywacjaUzytkownika.naglowek' | translate}}
        </div>

        <div class="ft__03--2 text-center pb-4 pt-0 pt-md-2">{{'dezaktywacjaUzytkownika.naglowek2' | translate}}</div>

        <div class="row text-center yesno-container pb-3">
            <div class="col"><a [routerLink]="" (click)="nie()" class="btn__link" style="cursor: pointer;">{{'nie' |
                    translate}}</a></div>
            <div class="col"><a [routerLink]="" (click)="tak()" class="btn__link" style="cursor: pointer;">{{'tak' |
                    translate}}</a></div>
        </div>

    </div>
</div>