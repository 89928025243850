<div *ngIf="czyZaladowanoDane">
    <div class="container--main d-none d-md-block">
        <div class="ft__sort text-center pt-4">{{dane.nazwaProjektu}}</div>

        <div class="d-flex py-3">
            <div class="project__menu">
                <a routerLink="/project-details/{{idProjektu}}">Projekt</a>
                <a routerLink="/inventory/{{idProjektu}}">Spis</a>
                <a routerLink="/locations/{{idProjektu}}">Lokalizacje</a>
                <a routerLink="/client-data/{{idProjektu}}">Ewidencja klienta</a>
                <a routerLink="/project-dictionaries/{{idProjektu}}" *ngIf="czyAdmin()">Słowniki</a>
                <a routerLink="/teams/{{idProjektu}}" *ngIf="czyAdmin()">Zespoły</a>
                <a class="active" routerLink="/inventory-fields/{{idProjektu}}" *ngIf="czyAdmin()">Pola spisu</a>
            </div>
        </div>
        <div class="d-flex py-3">
            <div class="project__menu project__menu--gray">
                <a class="active" routerLink="/inventory-fields/{{idProjektu}}">Pola spisu</a>
                <a routerLink="/room-configuration/{{idProjektu}}">Pola pomieszczenia</a>
            </div>
        </div>
    </div>
    <div class="container ">

        <div class="pt-3 mt-1 d-md-none">
            <div class="ft__sort text-center">{{dane.nazwaProjektu}}</div>
        </div>



        <div class="d-fledx py-3 d-md-none">
            <div class="project__menu">
                <button class="btn__left"></button>
                <ngx-slick-carousel class="carousel menu_slider" #slickModal="slick-carousel" [config]="slideConfig">

                    <div ngxSlickItem class="slide"><a routerLink="/project-details/{{idProjektu}}">Projekt</a></div>
                    <div ngxSlickItem class="slide"><a routerLink="/inventory/{{idProjektu}}">Spis</a></div>
                    <div ngxSlickItem class="slide"><a routerLink="/locations/{{idProjektu}}">Lokalizacje</a></div>
                    <div ngxSlickItem class="slide"><a routerLink="/client-data/{{idProjektu}}">Ewidencja klienta</a>
                    </div>
                    <div ngxSlickItem class="slide" *ngIf="czyAdmin()"><a
                            routerLink="/project-dictionaries/{{idProjektu}}">Słowniki</a></div>
                    <div ngxSlickItem class="slide" *ngIf="czyAdmin()"> <a
                            routerLink="/teams/{{idProjektu}}">Zespoły</a>
                    </div>
                    <div ngxSlickItem class="slide" *ngIf="czyAdmin()"><a class="active"
                            routerLink="/inventory-fields/{{idProjektu}}">Pola
                            spisu</a></div>
                </ngx-slick-carousel>
                <button class="btn__right"></button>
            </div>
        </div>
        <div class="d-flex py-1 d-md-none">
            <div class="project__menu project__menu--gray">
                <a class="active" routerLink="/inventory-fields/{{idProjektu}}">Pola spisu</a>
                <a routerLink="/room-configuration/{{idProjektu}}">Pola pomieszczenia</a>
            </div>
        </div>

        <div class="md-white-box" *ngIf="czySaWynikiNaLiscie(dane.pola)">

            <button class="btn__border mb-5" (click)="dodajPole()">Dodaj pole</button>

            <div cdkDropList [cdkDropListData]="dane.pola" (cdkDropListDropped)="zmienKolejnosc($event)">
                <div class="box__pole mb-3" *ngFor="let pole of dane.pola" cdkDrag style="cursor: grabbing;"
                    [cdkDragStartDelay]="20">
                    <div class="d-flex justify-content-between align-items-center fs__imported pb-2">
                        <div class="pt-1" style="font-weight: bold;">{{pole.nazwaPola}}</div>
                        <div>
                            <button class="btn__edit btn__edit--min" (click)="edytujPole(pole.id)"></button>
                            <button class="btn__delete ml-2" (click)="usunPole(pole.id)"></button>
                        </div>
                    </div>
                    <div class="fs__imported text-lg top_border">{{pobierzRodzajPola(pole.rodzajPola)}}</div>
                </div>
            </div>



        </div>

        <button class="btn__border mb-3 mt-3" (click)="dodajPole()" *ngIf="!czySaWynikiNaLiscie(dane.pola)">Dodaj
            pole</button>

        <div class="ft__03 text-gray text-center py-5" style="color: #757575 !important;"
            *ngIf="!czySaWynikiNaLiscie(dane.pola)">
            Brak zapisanych<br>pól spisu
        </div>



    </div>
    <div class="bootom__buttons px-4">
        <div class="container--mw600 flex-column flex-sm-row d-flex justify-content-between align-items-center">
            <button class="btn__link order-1 order-sm-0" (click)="cofnij()">Cofnij</button>
            <button class="btn__blue order-0 order-sm-1" (click)="edytujMapowania()"
                *ngIf="dane.czyJestImportEwidencji">Edytuj mapowania</button>
        </div>
    </div>
</div>