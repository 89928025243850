<nav class="navbar menu">
    <div class="navbar__container px-md-4">
        <div class="navbrand d-flex align-items-center align-items-center  justify-content-between">
            <div class="px-4 mx-1 d-md-none"></div>
            <a class="brand" href="#">
                <img src="assets/img/logo.svg" alt="">
            </a>
            <div class="d-none d-md-block menu_desktop">
                <ul class="d-flex mb-0">
                    <li class="" [routerLinkActive]="['active']"><a class="m1"
                            routerLink='projects'>{{'nawigacja.projekty' | translate}}</a></li>
                    <li [routerLinkActive]="['active']" *ngIf="czyPokazacZakladkeAdministracja()"><a class="m2"
                            routerLink='users'>{{'nawigacja.administracja' |
                            translate}}</a>
                        <a routerLink="/default-dictionaries" style="display: none"></a>
                    </li>
                </ul>
            </div>
            <div class="px-3 pt-md-1 pr-md-0">
                <div class="dropdown person_desktop text-right" (click)="pokazMenuKontekstowe()"
                    (clickOutside)="kliknijPozaMenu($event)">
                    <a class="btn__user dropdown-toggle" [routerLink]="" role="button" id="dropdownMenuLink"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="d-none d-md-block person_name">{{authService.getUserName()}}
                            {{authService.getUserLastName()}}</span>
                        <span class="inc">{{pobierzInicjalyUzytkownika()}}</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink"
                        style="display: block;z-index: 10000;" *ngIf="czyPokazacMenuKontekstowe">
                        <a class="dropdown-item ico__account" routerLink="/profile">{{'nawigacja.konto' |
                            translate}}</a>
                        <a class="dropdown-item ico__logout" [routerLink]="" (click)="wyloguj()">{{'nawigacja.wyloguj' |
                            translate}}</a>
                    </div>
                </div>
            </div>
        </div>
        <ul class="nav nav-tabs d-flex d-md-none" id="myTab" role="tablist">
            <li class="nav-item w-50">
                <a aria-controls="patient" aria-selected="true" class="nav-link  text-center" data-toggle="tab"
                    routerLink='projects' id="patient-tab" role="tab" [routerLinkActive]="['active']">
                    {{'nawigacja.projekty' | translate}}
                </a>
            </li>
            <li class="nav-item w-50" *ngIf="czyPokazacZakladkeAdministracja()">
                <a aria-controls="pump" aria-selected="false" class="nav-link text-center" data-toggle="tab"
                    routerLink='users' id="pump-tab" role="tab" [routerLinkActive]="['active']">
                    {{'nawigacja.administracja' | translate}}
                </a>
            </li>
        </ul>
    </div>
</nav>