<div class="modal-body">


    <div class="d-flex justify-content-between align-items-center mb-4">
        <a class="arr__left " [routerLink]="[]" (click)="zmienMiesiac(-1)">
            <img src="assets/img/arr__left.svg" alt="">
        </a>
        <div class="date text-center">
            <div class="date__month f_date__02">{{kalendarz.nazwaMiesiaca}} {{kalendarz.rok}}</div>
        </div>
        <a class="arr__right pr-2" [routerLink]="[]" (click)="zmienMiesiac(1)">
            <img src="assets/img/arr__right.svg" alt="">
        </a>
    </div>

    <ul class="weekdays">
        <li>P</li>
        <li>Wt</li>
        <li>Ś</li>
        <li>Cz</li>
        <li>Pt</li>
        <li>Sb</li>
        <li>Ndz</li>
    </ul>

    <ul class="days">
        <li *ngFor="let dzien of kalendarz.dniMiesiaca" (click)="wybierzDzien(dzien.dzienMiesiaca)"
            style="cursor: pointer;">
            <div *ngIf="!dzien.czyAktywnyDzien&&dzien.czyAktualnyMiesiac">
                {{dzien.czyAktualnyMiesiac ? dzien.dzienMiesiaca : ''}}</div>

            <span class="active" *ngIf="dzien.czyAktywnyDzien">{{dzien.dzienMiesiaca}}</span>
        </li>
    </ul>

    <button data-dismiss="modal" aria-label="Close" class="btn__link mb-3" (click)="zamknijModal()">Zamknij</button>

</div>