<nav class="navbar">
  <div class="navbar__container">
    <div class="navbrand d-flex align-items-center justify-content-center">
      <a class="brand" [routerLink]="">
        <img src="assets/img/logo.svg" alt="" />
      </a>
    </div>
  </div>
</nav>

<div
  class="container container--login"
  *ngIf="czyMoznaDodawacOswiadczenia && zaladowanoDane"
>
  <div class="ft__01 text-center py-4 mt-3">
    Oświadczenia {{ nazwaKlienta }}
  </div>
  <div class="text-center pb-4 mb-2">Zapoznaj się z instrukcją wypełnienia</div>
  <!-- <div
    class="d-flex justify-content-between align-items-center fs__imported mb-2 mt-2"
  >
    <div style="word-break: break-all">Instrukcja.pdf</div>
    <div>
      <button
        class="btn__download ml-2"
        (click)="pobierzInstrukcje()"
        title="Pobierz"
      ></button>
    </div>
  </div> -->

  <div class="input_container pb-3">
    <button (click)="pobierzInstrukcje()" class="btn__primary">
      Pobierz instrukcję
    </button>
  </div>

  <br />
  <div class="chb por mb-3">
    <input
      id="cb_1"
      type="checkbox"
      [(ngModel)]="zapoznalemSieZInstrukcja"
      name="zapoznalemSieZInstrukcja"
    />
    <label class="fs__chb" for="cb_1">Zapoznałem się z instrukcją</label>
  </div>
  <br />
  <div class="input_container pb-3">
    <button
      (click)="potwierdz()"
      class="btn__primary"
      [disabled]="!zapoznalemSieZInstrukcja ? 'disabled' : null"
    >
      Potwierdź
    </button>
  </div>
  <hr />
  <!-- </form> -->
</div>
<div
  class="container container--login"
  *ngIf="!czyMoznaDodawacOswiadczenia && zaladowanoDane"
>
  <div class="ft__01 text-center py-4 mt-3">
    Wypełnianie oświadczeń nie jest możliwe
  </div>
</div>
