import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GenerujRaportRezultat } from 'src/app/modele/projekty/arkuszSpisu/generujRaportRezultat';
import { PobierzDaneDoGenerowaniaRaportuModel } from 'src/app/modele/projekty/arkuszSpisu/pobierzDaneDoGenerowaniaRaportuModel';
import { PobierzDaneDoGenerowaniaRaportuRezultat } from 'src/app/modele/projekty/arkuszSpisu/pobierzDaneDoGenerowaniaRaportuRezultat';
import { PobierzDaneDoWyboruRaportuModel } from 'src/app/modele/projekty/arkuszSpisu/pobierzDaneDoWyboruRaportuModel';
import { PobierzDaneDoWyboruRaportuRezultat } from 'src/app/modele/projekty/arkuszSpisu/pobierzDaneDoWyboruRaportuRezultat';
import { WygenerujArkuszModel } from 'src/app/modele/projekty/arkuszSpisu/wygenerujArkuszModel';
import { PobierzSpisaneSrodkiLokalizacjiFiltry } from 'src/app/modele/projekty/spis/pobierzSpisaneSrodkiLokalizacjiFiltry';
import { SpisaneSrodkiLokalizacji } from 'src/app/modele/projekty/spis/spisaneSrodkiLokalizacji';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { PojedynczyElementResponse } from 'src/app/modele/wspolne/pojedynczyElement';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { Zalacznik } from 'src/app/modele/wspolne/zalacznik';
import { SerwisBazowy } from '../bazowy.service';
import { AuthService } from '../ogolne/auth.service';
import { ErrorService } from '../ogolne/error.service';
import { LoadingService } from '../ogolne/loading.service';

@Injectable({
  providedIn: 'root',
})
export class ArkuszSpisuService extends SerwisBazowy {
  constructor(
    httpClient: HttpClient,
    authService: AuthService,
    errorService: ErrorService,
    loaderService: LoadingService,
    translate: TranslateService
  ) {
    super(httpClient, authService, errorService, loaderService, translate);
  }

  public pobierzDaneDoWyboruRaportu(model: PobierzDaneDoWyboruRaportuModel) {
    return this.wykonajAkcje<
      PobierzDaneDoWyboruRaportuModel,
      PojedynczyElementResponse<PobierzDaneDoWyboruRaportuRezultat>
    >(this.AdresApi + 'ArkuszSpisu/PobierzDaneDoWyboruRaportu', model);
  }

  public pobierzDaneDoGenerowaniaRaportu(
    model: PobierzDaneDoGenerowaniaRaportuModel
  ) {
    return this.wykonajAkcje<
      PobierzDaneDoGenerowaniaRaportuModel,
      PojedynczyElementResponse<PobierzDaneDoGenerowaniaRaportuRezultat>
    >(this.AdresApi + 'ArkuszSpisu/PobierzDaneDoGenerowaniaRaportu', model);
  }

  public generujRaport(model: WygenerujArkuszModel) {
    return this.wykonajAkcje<
      WygenerujArkuszModel,
      PojedynczyElementResponse<GenerujRaportRezultat>
    >(this.AdresApi + 'ArkuszSpisu/GenerujRaport', model, 15 * 60 * 1000);
  }
}
