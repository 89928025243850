<div *ngIf="czyZaladowanoDane">
    <div class="container--main d-none d-md-block">
        <div class="ft__sort text-center pt-4">{{model.nazwaProjektu}}</div>

        <div class="d-flex py-3">
            <div class="project__menu">
                <a routerLink="/project-details/{{idProjektu}}">Projekt</a>
                <a routerLink="/inventory/{{idProjektu}}">Spis</a>
                <a routerLink="/locations/{{idProjektu}}">Lokalizacje</a>
                <a routerLink="/client-data/{{idProjektu}}">Ewidencja klienta</a>
                <a routerLink="/project-dictionaries/{{idProjektu}}" *ngIf="czyAdmin()">Słowniki</a>
                <a routerLink="/teams/{{idProjektu}}" *ngIf="czyAdmin()">Zespoły</a>
                <a class="active" routerLink="/inventory-fields/{{idProjektu}}" *ngIf="czyAdmin()">Pola spisu</a>
            </div>
        </div>

        <div class="d-flex py-3">
            <div class="project__menu project__menu--gray">
                <a routerLink="/inventory-fields/{{idProjektu}}">Pola spisu</a>
                <a class="active" routerLink="/room-configuration/{{idProjektu}}">Pola pomieszczenia</a>
            </div>
        </div>
    </div>
    <div class="container--main2 pb-5 mb-4 d-none d-md-block">
        <div class="box__scroll">
            <div class="row">
                <div class="col-md-6 py-2 px-4">
                    <div class="f-input mb-3 ">
                        <!-- <input placeholder="" type="text" value="Nazwa"> -->
                        <label _ngcontent-ajo-c128="" for="" class="ft__label mb-1 text-left w-100">Nazwa</label>
                    </div>
                    <div class="input_container mb-3">
                        <select [(ngModel)]="model.nazwaRodzajPola" name="nazwaRodzajPola">
                            <option [ngValue]="undefined" [disabled]="true">Rodzaj pola</option>
                            <option [ngValue]="0">Tekst</option>
                            <option [ngValue]="1">Słownik</option>
                        </select>
                    </div>
                    <div class="input_container mb-3" *ngIf="model.nazwaRodzajPola==1"
                        [ngClass]="czyBladWalidacyjny('nazwaSlownikId')?'error':''">
                        <select [(ngModel)]="model.nazwaSlownikId" name="nazwaSlownikId">
                            <option [ngValue]="null" [disabled]="true">Słownik</option>
                            <option [ngValue]="slownik.id" *ngFor="let slownik of slowniki">{{slownik.nazwa}}</option>
                        </select>
                        <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('nazwaSlownikId')">
                            {{pobierzBladWalidacyjny("nazwaSlownikId") }}</div>
                    </div>


                    <div class="f-input mb-3">
                        <!-- <input placeholder="" type="text" value="Piętro"> -->
                        <label _ngcontent-ajo-c128="" for="" class="ft__label mb-1 text-left w-100">Piętro</label>
                    </div>
                    <div class="input_container mb-3">
                        <select [(ngModel)]="model.pietroRodzajPola" name="pietroRodzajPola">
                            <option [ngValue]="undefined" [disabled]="true">Rodzaj pola</option>
                            <option [ngValue]="0">Tekst</option>
                            <option [ngValue]="1">Słownik</option>
                        </select>
                    </div>
                    <div class="input_container mb-3" *ngIf="model.pietroRodzajPola==1"
                        [ngClass]="czyBladWalidacyjny('pietroSlownikId')?'error':''">
                        <select [(ngModel)]="model.pietroSlownikId" name="pietroSlownikId">
                            <option [ngValue]="null" [disabled]="true">Słownik</option>
                            <option [ngValue]="slownik.id" *ngFor="let slownik of slowniki">{{slownik.nazwa}}</option>
                        </select>
                        <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('pietroSlownikId')">
                            {{pobierzBladWalidacyjny("pietroSlownikId") }}</div>
                    </div>

                    <div class="f-input mb-3">
                        <!-- <input placeholder="" type="text" value="MPK"> -->
                        <label _ngcontent-ajo-c128="" for="" class="ft__label mb-1 text-left w-100">MPK</label>
                    </div>
                    <div class="input_container mb-3">
                        <select [(ngModel)]="model.mpkRodzajPola" name="mpkRodzajPola">
                            <option [ngValue]="undefined" [disabled]="true">Rodzaj pola</option>
                            <option [ngValue]="0">Tekst</option>
                            <option [ngValue]="1">Słownik</option>
                        </select>
                    </div>
                    <div class="input_container mb-3" *ngIf="model.mpkRodzajPola==1"
                        [ngClass]="czyBladWalidacyjny('nazwaSlownikId')?'error':''">
                        <select [(ngModel)]="model.mpkSlownikId" name="mpkSlownikId">
                            <option [ngValue]="null" [disabled]="true">Słownik</option>
                            <option [ngValue]="slownik.id" *ngFor="let slownik of slowniki">{{slownik.nazwa}}</option>
                        </select>
                        <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('mpkSlownikId')">
                            {{pobierzBladWalidacyjny("mpkSlownikId") }}</div>
                    </div>


                </div>
                <div class="col-md-6 py-2 px-4">
                    <div class="f-input mb-3">
                        <!-- <input placeholder="" type="text" value="Nazwa"> -->
                        <label _ngcontent-ajo-c128="" for="" class="ft__label mb-1 text-left w-100">Opis
                            dodatkowy</label>
                    </div>
                    <div class="input_container mb-3">
                        <select [(ngModel)]="model.opisDodatkowyRodzajPola" name="opisDodatkowyRodzajPola">
                            <option [ngValue]="undefined" [disabled]="true">Rodzaj pola</option>
                            <option [ngValue]="0">Tekst</option>
                            <option [ngValue]="1">Słownik</option>
                        </select>
                    </div>
                    <div class="input_container mb-3" *ngIf="model.opisDodatkowyRodzajPola==1"
                        [ngClass]="czyBladWalidacyjny('nazwaSlownikId')?'error':''">
                        <select [(ngModel)]="model.opisDodatkowySlownikId" name="opisDodatkowySlownikId">
                            <option [ngValue]="null" [disabled]="true">Słownik</option>
                            <option [ngValue]="slownik.id" *ngFor="let slownik of slowniki">{{slownik.nazwa}}</option>
                        </select>
                        <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('opisDodatkowySlownikId')">
                            {{pobierzBladWalidacyjny("opisDodatkowySlownikId") }}</div>
                    </div>

                    <div class="f-input mb-3">
                        <!-- <input placeholder="" type="text" value="Piętro"> -->
                        <label _ngcontent-ajo-c128="" for="" class="ft__label mb-1 text-left w-100">Osoba</label>
                    </div>
                    <div class="input_container mb-3">
                        <select [(ngModel)]="model.osobaRodzajPola" name="osobaRodzajPola">
                            <option [ngValue]="undefined" [disabled]="true">Rodzaj pola</option>
                            <option [ngValue]="0">Tekst</option>
                            <option [ngValue]="1">Słownik</option>
                        </select>
                    </div>
                    <div class="input_container mb-3" *ngIf="model.osobaRodzajPola==1"
                        [ngClass]="czyBladWalidacyjny('osobaSlownikId')?'error':''">
                        <select [(ngModel)]="model.osobaSlownikId" name="osobaSlownikId">
                            <option [ngValue]="null" [disabled]="true">Słownik</option>
                            <option [ngValue]="slownik.id" *ngFor="let slownik of slowniki">{{slownik.nazwa}}</option>
                        </select>
                        <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('osobaSlownikId')">
                            {{pobierzBladWalidacyjny("osobaSlownikId") }}</div>
                    </div>

                    <div class="f-input mb-3">
                        <!-- <input placeholder="" type="text" value="MPK"> -->
                        <label _ngcontent-ajo-c128="" for="" class="ft__label mb-1 text-left w-100">Inne</label>
                    </div>
                    <div class="input_container mb-3">
                        <select [(ngModel)]="model.inneRodzajPola" name="inneRodzajPola">
                            <option [ngValue]="undefined" [disabled]="true">Rodzaj pola</option>
                            <option [ngValue]="0">Tekst</option>
                            <option [ngValue]="1">Słownik</option>
                        </select>
                    </div>
                    <div class="input_container mb-3" *ngIf="model.inneRodzajPola==1"
                        [ngClass]="czyBladWalidacyjny('inneSlownikId')?'error':''">
                        <select [(ngModel)]="model.inneSlownikId" name="inneSlownikId">
                            <option [ngValue]="null" [disabled]="true">Słownik</option>
                            <option [ngValue]="slownik.id" *ngFor="let slownik of slowniki">{{slownik.nazwa}}</option>
                        </select>
                        <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('inneSlownikId')">
                            {{pobierzBladWalidacyjny("inneSlownikId") }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- mobile -->
    <div class="container-fluid d-md-none">

        <div class="pt-3 mt-1">
            <div class="ft__sort text-center">{{model.nazwaProjektu}}</div>
        </div>

        <div class="d-fledx py-3">
            <div class="project__menu">
                <button class="btn__left"></button>
                <ngx-slick-carousel class="carousel menu_slider" #slickModal="slick-carousel" [config]="slideConfig">

                    <div ngxSlickItem class="slide"><a routerLink="/project-details/{{idProjektu}}">Projekt</a></div>
                    <div ngxSlickItem class="slide"><a routerLink="/inventory/{{idProjektu}}">Spis</a></div>
                    <div ngxSlickItem class="slide"><a routerLink="/locations/{{idProjektu}}">Lokalizacje</a></div>
                    <div ngxSlickItem class="slide"><a routerLink="/client-data/{{idProjektu}}">Ewidencja klienta</a>
                    </div>
                    <div ngxSlickItem class="slide" *ngIf="czyAdmin()"><a
                            routerLink="/project-dictionaries/{{idProjektu}}">Słowniki</a>
                    </div>
                    <div ngxSlickItem class="slide" *ngIf="czyAdmin()"> <a
                            routerLink="/teams/{{idProjektu}}">Zespoły</a>
                    </div>
                    <div ngxSlickItem class="slide" *ngIf="czyAdmin()"><a class="active"
                            routerLink="/inventory-fields/{{idProjektu}}">Pola
                            spisu</a></div>
                </ngx-slick-carousel>
                <button class="btn__right"></button>
            </div>
        </div>

        <div class="d-flex py-1">
            <div class="project__menu project__menu--gray">
                <a routerLink="/inventory-fields/{{idProjektu}}">Pola spisu</a>
                <a class="active" routerLink="/room-configuration/{{idProjektu}}">Pola pomieszczenia</a>
            </div>
        </div>

        <div class="box__mobiled mb-4">
            <div class="row">
                <div class="col-md-12">
                    <div class="f-input mb-3 ">
                        <label _ngcontent-ajo-c128="" for="" class="ft__label mb-1 text-left w-100">Nazwa</label>
                    </div>
                    <div class="input_container mb-3">
                        <select [(ngModel)]="model.nazwaRodzajPola" name="nazwaRodzajPola">
                            <option [ngValue]="undefined" [disabled]="true">Rodzaj pola</option>
                            <option [ngValue]="0">Tekst</option>
                            <option [ngValue]="1">Słownik</option>
                        </select>
                    </div>
                    <div class="input_container mb-3" *ngIf="model.nazwaRodzajPola==1"
                        [ngClass]="czyBladWalidacyjny('nazwaSlownikId')?'error':''">
                        <select [(ngModel)]="model.nazwaSlownikId" name="nazwaSlownikId">
                            <option [ngValue]="null" [disabled]="true">Słownik</option>
                            <option [ngValue]="slownik.id" *ngFor="let slownik of slowniki">{{slownik.nazwa}}</option>
                        </select>
                        <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('nazwaSlownikId')">
                            {{pobierzBladWalidacyjny("nazwaSlownikId") }}</div>
                    </div>

                    <div class="f-input mb-3">
                        <label _ngcontent-ajo-c128="" for="" class="ft__label mb-1 text-left w-100">Piętro</label>
                    </div>
                    <div class="input_container mb-3">
                        <select [(ngModel)]="model.pietroRodzajPola" name="pietroRodzajPola">
                            <option [ngValue]="undefined" [disabled]="true">Rodzaj pola</option>
                            <option [ngValue]="0">Tekst</option>
                            <option [ngValue]="1">Słownik</option>
                        </select>
                    </div>
                    <div class="input_container mb-3" *ngIf="model.pietroRodzajPola==1"
                        [ngClass]="czyBladWalidacyjny('pietroSlownikId')?'error':''">
                        <select [(ngModel)]="model.pietroSlownikId" name="pietroSlownikId">
                            <option [ngValue]="null" [disabled]="true">Słownik</option>
                            <option [ngValue]="slownik.id" *ngFor="let slownik of slowniki">{{slownik.nazwa}}</option>
                        </select>
                        <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('pietroSlownikId')">
                            {{pobierzBladWalidacyjny("pietroSlownikId") }}</div>
                    </div>

                    <div class="f-input mb-3">
                        <label _ngcontent-ajo-c128="" for="" class="ft__label mb-1 text-left w-100">MPK</label>
                    </div>
                    <div class="input_container mb-3">
                        <select [(ngModel)]="model.mpkRodzajPola" name="mpkRodzajPola">
                            <option [ngValue]="undefined" [disabled]="true">Rodzaj pola</option>
                            <option [ngValue]="0">Tekst</option>
                            <option [ngValue]="1">Słownik</option>
                        </select>
                    </div>
                    <div class="input_container mb-3" *ngIf="model.mpkRodzajPola==1"
                        [ngClass]="czyBladWalidacyjny('nazwaSlownikId')?'error':''">
                        <select [(ngModel)]="model.mpkSlownikId" name="mpkSlownikId">
                            <option [ngValue]="null" [disabled]="true">Słownik</option>
                            <option [ngValue]="slownik.id" *ngFor="let slownik of slowniki">{{slownik.nazwa}}</option>
                        </select>
                        <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('mpkSlownikId')">
                            {{pobierzBladWalidacyjny("mpkSlownikId") }}</div>
                    </div>


                    <div class="f-input mb-3">
                        <label _ngcontent-ajo-c128="" for="" class="ft__label mb-1 text-left w-100">Opis
                            dodatkowy</label>
                    </div>
                    <div class="input_container mb-3">
                        <select [(ngModel)]="model.opisDodatkowyRodzajPola" name="opisDodatkowyRodzajPola">
                            <option [ngValue]="undefined" [disabled]="true">Rodzaj pola</option>
                            <option [ngValue]="0">Tekst</option>
                            <option [ngValue]="1">Słownik</option>
                        </select>
                    </div>
                    <div class="input_container mb-3" *ngIf="model.opisDodatkowyRodzajPola==1"
                        [ngClass]="czyBladWalidacyjny('nazwaSlownikId')?'error':''">
                        <select [(ngModel)]="model.opisDodatkowySlownikId" name="opisDodatkowySlownikId">
                            <option [ngValue]="null" [disabled]="true">Słownik</option>
                            <option [ngValue]="slownik.id" *ngFor="let slownik of slowniki">{{slownik.nazwa}}</option>
                        </select>
                        <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('opisDodatkowySlownikId')">
                            {{pobierzBladWalidacyjny("opisDodatkowySlownikId") }}</div>
                    </div>


                    <div class="f-input mb-3">
                        <label _ngcontent-ajo-c128="" for="" class="ft__label mb-1 text-left w-100">Osoba</label>
                    </div>
                    <div class="input_container mb-3">
                        <select [(ngModel)]="model.osobaRodzajPola" name="osobaRodzajPola">
                            <option [ngValue]="undefined" [disabled]="true">Rodzaj pola</option>
                            <option [ngValue]="0">Tekst</option>
                            <option [ngValue]="1">Słownik</option>
                        </select>
                    </div>
                    <div class="input_container mb-3" *ngIf="model.osobaRodzajPola==1"
                        [ngClass]="czyBladWalidacyjny('osobaSlownikId')?'error':''">
                        <select [(ngModel)]="model.osobaSlownikId" name="osobaSlownikId">
                            <option [ngValue]="null" [disabled]="true">Słownik</option>
                            <option [ngValue]="slownik.id" *ngFor="let slownik of slowniki">{{slownik.nazwa}}</option>
                        </select>
                        <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('osobaSlownikId')">
                            {{pobierzBladWalidacyjny("osobaSlownikId") }}</div>
                    </div>

                    <div class="f-input mb-3">
                        <label _ngcontent-ajo-c128="" for="" class="ft__label mb-1 text-left w-100">Inne</label>
                    </div>
                    <div class="input_container mb-3">
                        <select [(ngModel)]="model.inneRodzajPola" name="inneRodzajPola">
                            <option [ngValue]="undefined" [disabled]="true">Rodzaj pola</option>
                            <option [ngValue]="0">Tekst</option>
                            <option [ngValue]="1">Słownik</option>
                        </select>
                    </div>
                    <div class="input_container mb-3" *ngIf="model.inneRodzajPola==1"
                        [ngClass]="czyBladWalidacyjny('inneSlownikId')?'error':''">
                        <select [(ngModel)]="model.inneSlownikId" name="inneSlownikId">
                            <option [ngValue]="null" [disabled]="true">Słownik</option>
                            <option [ngValue]="slownik.id" *ngFor="let slownik of slowniki">{{slownik.nazwa}}</option>
                        </select>
                        <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('inneSlownikId')">
                            {{pobierzBladWalidacyjny("inneSlownikId") }}</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="bootom__buttons px-4">
        <div class="container--mw600 flex-column flex-sm-row d-flex justify-content-between align-items-center">
            <button class="btn__link order-1 order-sm-0" (click)="pobierzDaneDoEkranu()">Anuluj</button>
            <button class="btn__blue order-0 order-sm-1" (click)="zapisz()">Zapisz</button>
        </div>
    </div>
</div>