<div class="modal-content">
    <div class="modal-body">
        <button aria-label="Close" class="close" data-dismiss="modal" type="button"
            (click)="activeModal.dismiss()"></button>
        <div class="ft__01 text-center pt-0 pb-3 ">Mapowanie ewidencji</div>
        <div class="mb-2 mt-2">Kod lokalizacji</div>


        <div class="input_container mb-3 " [ngClass]="czyBladWalidacyjny('lokalizacjaKolumnaEwidencji')?'error':''">
            <select [(ngModel)]="model.lokalizacjaKolumnaEwidencji" name="lokalizacjaKolumnaEwidencji">
                <option [ngValue]="undefined" [disabled]="true">Kolumna z ewidencji</option>
                <option [ngValue]="kolumna" *ngFor="let kolumna of dane.listaKolumn">{{kolumna}}</option>
            </select>
            <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('lokalizacjaKolumnaEwidencji')">
                {{pobierzBladWalidacyjny("lokalizacjaKolumnaEwidencji") }}</div>
        </div>
        <div class="input_container mb-3" [ngClass]="czyBladWalidacyjny('lokalizacjaKolumnaLokalizacja')?'error':''">
            <select [(ngModel)]="model.lokalizacjaKolumnaLokalizacja" name="lokalizacjaKolumnaLokalizacja">
                <option [ngValue]="undefined" [disabled]="true">Kolumna lokalizacji</option>
                <option [ngValue]="kolumna" *ngFor="let kolumna of dane.listaKolumnLokalizacji">{{kolumna}}</option>
            </select>
            <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('lokalizacjaKolumnaLokalizacja')">
                {{pobierzBladWalidacyjny("lokalizacjaKolumnaLokalizacja") }}</div>
        </div>

        <div *ngFor="let kolumnaImportu of model.kolumnyImportu">
            <div class="chb por mb-3 mt-4">
                <input id="{{kolumnaImportu.kolumnaEwidencji}}" type="checkbox"
                    [(ngModel)]="kolumnaImportu.czyImportowac">
                <label class="fs__chb"
                    for="{{kolumnaImportu.kolumnaEwidencji}}">{{kolumnaImportu.kolumnaEwidencji}}</label>
            </div>

            <div class="input_container mb-3">
                <select [(ngModel)]="kolumnaImportu.poleSpisuId">
                    <option [ngValue]="undefined">Pole spisu</option>
                    <option [ngValue]="pole.id" *ngFor="let pole of dane.listaPolSpisu">{{pole.nazwa}}</option>
                </select>
            </div>
            <div class="input_container pb-3" *ngIf="kolumnaImportu.poleSpisuId">
                <input type="number" placeholder="Priorytet parowania" [(ngModel)]="kolumnaImportu.priorytetParowania"
                    name="priorytetParowania" appTylkoPelneLiczby>
            </div>
        </div>


        <div class="input_container pb-3 pt-3">
            <button class="btn__primary " (click)="importuj()">Importuj</button>
        </div>
        <div class="input_container pb-3">
            <button class="btn__link" (click)="activeModal.dismiss()">Anuluj</button>
        </div>

    </div>
</div>