import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { PotwierdzKonto } from 'src/app/modele/logowanie/daneZalogowanegoUzytkownika';
import { PotwierdzKontoModel } from 'src/app/modele/uzytkownik/potwierdzKontoModel';
import { Routing } from 'src/app/modele/wspolne/routing';
import { UzytkownikService } from 'src/app/serwisy/konto/uzytkownik.service';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';

@Component({
  selector: 'app-potwierdz-konto',
  templateUrl: './potwierdz-konto.component.html'
})
export class PotwierdzKontoComponent extends BazowyComponent implements OnInit {

  public przeniesNaEkranZmianyHasla: boolean;
  public kodDoZmianyHasla: string;
  public kod: string;
  private potwierdzKontoModel: PotwierdzKonto;

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private uzytkownikSerwis: UzytkownikService,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    private komunikatSerwis: ToastrService,
    public authService: AuthService,
    public translate: TranslateService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translate);

  }

  ngOnInit(): void {
    this.przeniesNaEkranZmianyHasla = false;
    this.kod = this.activatedRoute.snapshot.paramMap.get('kod');
    this.inicjujJezyk();
    if (!this.kod) {
      throw new Error(this.pobierzTlumaczenie("potwierdzenieKonta.nieprawidlowyKod"));
    }
    setTimeout(() => {
      this.potwierdzKonto();
    });
  }

  private potwierdzKonto() {
    try {
      var model = new PotwierdzKontoModel();
      model.kod = this.kod;
      this.uzytkownikSerwis.potwierdzKonto(model).subscribe(
        (rezultat) => {
          if (this.czyRezultatOk(rezultat)) {
            this.przeniesNaEkranZmianyHasla = true;
            this.kodDoZmianyHasla = rezultat.element;
            return;

          }
          this.wyswietlKomunikat(rezultat.wiadomosc, true);
          this.router.navigate([Routing.Zaloguj]);
        }
      );
    } catch (blad) {
    }
  }

  public ustawHaslo() {
    this.router.navigate([Routing.NoweHaslo, this.kodDoZmianyHasla]);
  }
}
