<div *ngIf="czyZaladowanoDane">

    <div class="container--main d-none d-md-block">

        <div class="d-flex py-4">
            <div class="project__menu">
                <a routerLink='/users'>Użytkownicy</a>
                <a class="active" routerLink='/default-dictionaries'>Słowniki</a>
                <a routerLink='/admin/settings'>Ustawienia</a>
            </div>
        </div>
    </div>
    <div class="container ">

        <div class="d-fledx py-3 d-md-none">
            <div class="project__menu">
                <button class="btn__left"></button>
                <ngx-slick-carousel class="carousel menu_slider" #slickModal="slick-carousel" [config]="slideConfig">

                    <div ngxSlickItem class="slide"><a routerLink="/users">Użytkownicy</a></div>
                    <div ngxSlickItem class="slide"><a class="active" routerLink="/default-dictionaries">Słowniki</a>
                    </div>
                    <div ngxSlickItem class="slide"><a routerLink="/admin/settings">Ustawienia</a> </div>
                </ngx-slick-carousel>
                <button class="btn__right"></button>
            </div>
        </div>

        <div class="md-white-box md-white-box--2 px-md-5 py-4">
            <div class="pb-4">

                <div class="input_container mt-3">
                    <input type="text" placeholder="Szukaj słownik" [(ngModel)]="wyszukiwanie.wartoscSzukaj"
                        (keyup)="filtrujPoTekscie()">
                    <button class="btn__input--search"></button>
                </div>

            </div>
            <button class="btn__border mb-5" (click)="dodajSlownik()">Dodaj słownik</button>


            <button class="btn__slownik mb-3" *ngFor="let slownik of slowniki"
                (click)="pokazSzczegolySlownika(slownik.id)">{{slownik.nazwa}}</button>

            <div class="ft__03 text-gray text-center py-5" style="color: #757575 !important;"
                *ngIf="!czySaWynikiNaLiscie(slowniki)">
                Brak słowników<br>dla wybranych filtrów
            </div>
        </div>

    </div>
</div>