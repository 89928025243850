<div *ngIf="czyZaladowanoDane">
  <div class="container--main d-none d-md-block">
    <div class="ft__sort text-center pt-4">
      Generowanie raportu {{ pobierzNazweRaportu() }} - {{ adresLokalizacji }}
    </div>
  </div>
  <div class="container pt-4">
    <div
      class="f-input mb-3"
      [ngClass]="czyBladWalidacyjny('lokalizacja') ? 'error' : ''"
    >
      <label for="">Lokalizacja:</label>
      <input type="text" [(ngModel)]="model.lokalizacja" />
      <div
        class="error-info fs__error pt-2"
        *ngIf="czyBladWalidacyjny('lokalizacja')"
      >
        {{ pobierzBladWalidacyjny("lokalizacja") }}
      </div>
    </div>
    <div
      class="f-input mb-3"
      [ngClass]="czyBladWalidacyjny('przewodniczacyZespolu') ? 'error' : ''"
    >
      <label for="">Przewodniczący zespołu IPRO:</label>
      <input type="text" [(ngModel)]="model.przewodniczacyZespolu" />
      <div
        class="error-info fs__error pt-2"
        *ngIf="czyBladWalidacyjny('przewodniczacyZespolu')"
      >
        {{ pobierzBladWalidacyjny("przewodniczacyZespolu") }}
      </div>
    </div>
    <div
      class="f-input mb-3"
      [ngClass]="czyBladWalidacyjny('czlonekZespolu') ? 'error' : ''"
    >
      <label for="">Członek zespołu:</label>
      <input type="text" [(ngModel)]="model.czlonekZespolu" />
      <div
        class="error-info fs__error pt-2"
        *ngIf="czyBladWalidacyjny('czlonekZespolu')"
      >
        {{ pobierzBladWalidacyjny("czlonekZespolu") }}
      </div>
    </div>
    <div
      class="f-input mb-3"
      [ngClass]="czyBladWalidacyjny('klient') ? 'error' : ''"
    >
      <label for="">Klient:</label>
      <input type="text" [(ngModel)]="model.klient" />
      <div
        class="error-info fs__error pt-2"
        *ngIf="czyBladWalidacyjny('klient')"
      >
        {{ pobierzBladWalidacyjny("klient") }}
      </div>
    </div>
    <div
      class="f-input mb-3"
      [ngClass]="czyBladWalidacyjny('przedstawicielKlienta') ? 'error' : ''"
    >
      <label for="">Przedstawiciel Klienta:</label>
      <input type="text" [(ngModel)]="model.przedstawicielKlienta" />
      <div
        class="error-info fs__error pt-2"
        *ngIf="czyBladWalidacyjny('przedstawicielKlienta')"
      >
        {{ pobierzBladWalidacyjny("przedstawicielKlienta") }}
      </div>
    </div>

    <label class="ft__07 por" for="">Spis rozpoczęto:</label>
    <div class="d-flex">
      <div
        class="f-input mb-3 pr-2"
        [ngClass]="czyBladWalidacyjny('spisRozpoczetoData') ? 'error' : ''"
      >
        <input
          mask="00-00-0000"
          [specialCharacters]="['-']"
          [dropSpecialCharacters]="false"
          type="text"
          [(ngModel)]="model.spisRozpoczetoData"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('spisRozpoczetoData')"
        >
          {{ pobierzBladWalidacyjny("spisRozpoczetoData") }}
        </div>
      </div>
      <div
        class="f-input mb-3 pl-2"
        [ngClass]="czyBladWalidacyjny('spisRozpoczetoGodzina') ? 'error' : ''"
      >
        <input
          type="text"
          mask="Hh:m0"
          [specialCharacters]="[':']"
          [dropSpecialCharacters]="false"
          [(ngModel)]="model.spisRozpoczetoGodzina"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('spisRozpoczetoGodzina')"
        >
          {{ pobierzBladWalidacyjny("spisRozpoczetoGodzina") }}
        </div>
      </div>
    </div>

    <label class="ft__07 por" for="">Spis zakończono:</label>
    <div class="d-flex">
      <div
        class="f-input mb-3 pr-2"
        [ngClass]="czyBladWalidacyjny('spisZakonczonoData') ? 'error' : ''"
      >
        <input
          mask="00-00-0000"
          [specialCharacters]="['-']"
          [dropSpecialCharacters]="false"
          type="text"
          [(ngModel)]="model.spisZakonczonoData"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('spisZakonczonoData')"
        >
          {{ pobierzBladWalidacyjny("spisZakonczonoData") }}
        </div>
      </div>
      <div
        class="f-input mb-3 pl-2"
        [ngClass]="czyBladWalidacyjny('spisZakonczonoGodzina') ? 'error' : ''"
      >
        <input
          mask="Hh:m0"
          type="text"
          [specialCharacters]="[':']"
          [dropSpecialCharacters]="false"
          [(ngModel)]="model.spisZakonczonoGodzina"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('spisZakonczonoGodzina')"
        >
          {{ pobierzBladWalidacyjny("spisZakonczonoGodzina") }}
        </div>
      </div>
    </div>

    <div
      class="f-input mb-3 pb-1"
      [ngClass]="czyBladWalidacyjny('uwagi') ? 'error' : ''"
    >
      <label for="">Treść Uwagi i Zastrzeżenia :</label>
      <textarea class="big__textarea" [(ngModel)]="model.uwagi"></textarea>
      <div
        class="error-info fs__error pt-2"
        *ngIf="czyBladWalidacyjny('uwagi')"
      >
        {{ pobierzBladWalidacyjny("uwagi") }}
      </div>
    </div>

    <div
      class="chb por mb-3"
      *ngIf="this.idPomieszczenia || model.idPomieszczen.length == 1"
    >
      <input
        id="cb_nazwa_pomieszczenia"
        type="checkbox"
        name="czyDrukowacNazwePomieszczenia"
        [(ngModel)]="model.czyDrukowacNazwePomieszczenia"
      />
      <label class="fs__chb" for="cb_nazwa_pomieszczenia"
        >Widoczna nazwa pomieszczenia na arkuszu</label
      >
    </div>

    <div class="chb por mb-3">
      <input
        id="cb_czyDrukowacMpkLokalizacji"
        type="checkbox"
        name="czyDrukowacMpkLokalizacji"
        [(ngModel)]="model.czyDrukowacMpkLokalizacji"
      />
      <label class="fs__chb" for="cb_czyDrukowacMpkLokalizacji"
        >Widoczne MPK lokalizacji na arkuszu</label
      >
    </div>
  </div>
  <div class="container--main d-none d-md-block">
    <div class="ft__sort text-center pt-4">Kolumny raportu</div>
  </div>

  <div class="container pt-4 pb-md-5 mb-3">
    <div class="chb por mb-3" *ngFor="let kolumna of model.kolumny">
      <input
        id="cb_{{ kolumna.kolumna }}"
        type="checkbox"
        name="{{ kolumna.kolumna }}"
        [(ngModel)]="kolumna.czyWybrano"
      />
      <label class="fs__chb" for="cb_{{ kolumna.kolumna }}">{{
        kolumna.kolumna
      }}</label>
    </div>
    <div
      class="error-info fs__error pt-2"
      *ngIf="czyBladWalidacyjny('kolumny')"
    >
      {{ pobierzBladWalidacyjny("kolumny") }}
    </div>
  </div>

  <div class="bootom__buttons px-4 d-block">
    <div
      class="container--mw600 flex-column flex-md-row d-flex justify-content-between align-items-center"
    >
      <button class="btn__link order-1 order-md-0" (click)="anuluj()">
        Anuluj
      </button>
      <button
        class="btn__blue order-0 order-md-1 px-5 mb-md-0"
        (click)="generuj()"
      >
        Generuj
      </button>
    </div>
  </div>
</div>
