import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { EdytujProjektModel } from 'src/app/modele/projekty/edytujProjektModel';
import { StatusProjektu } from 'src/app/modele/projekty/statusProjektu';
import { SzczegolyProjektu } from 'src/app/modele/projekty/szczegolyProjektu';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { Routing } from 'src/app/modele/wspolne/routing';
import { Uprawnienie } from 'src/app/modele/wspolne/uprawnienie';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { ProjektyService } from 'src/app/serwisy/projekty/projekty.service';
import { BazowyComponent } from '../../bazowy.component';
import { KalendarzModalContent } from '../../kalendarz/kalendarz-modal';
import { Zalacznik } from 'src/app/modele/wspolne/zalacznik';

@Component({
  selector: 'app-edytuj-projekt',
  templateUrl: './edytuj-projekt.component.html',
  styleUrls: ['./edytuj-projekt.component.css'],
  providers: [DatePipe],
})
export class EdytujProjektComponent extends BazowyComponent implements OnInit {
  idProjektu: string;

  slideConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: document.getElementsByClassName('btn__left'),
    nextArrow: document.getElementsByClassName('btn__right'),
    infinite: false,
    variableWidth: true,
  };
  czyZaladowanoDane: boolean;
  model: EdytujProjektModel = new EdytujProjektModel();
  sformatowanaDataKoniecSpisuDo: string = '';
  sformatowanaDataRozliczenieJednoznaczneDo: string = '';
  sformatowanaDataRozliczenieAnalityczneDo: string = '';
  sformatowanaDataKoniecProjektu: string = '';

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    private datePipe: DatePipe,
    public projektySerwis: ProjektyService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translateSerwis
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.idProjektu = this.activatedRoute.snapshot.paramMap.get('id');
      if (!this.czyAdmin()) {
        this.router.navigate([Routing.SzczegolyProjektu, this.idProjektu]);
        return;
      }
      this.pobierzDaneDoEkranu();
    });
  }

  pobierzDaneDoEkranu() {
    var model = new IdModel();
    model.id = this.idProjektu;
    this.projektySerwis.pobierzSzczegoly(model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.model.id = rezultat.element.id;
        this.model.email = rezultat.element.email;
        this.model.nazwaKlienta = rezultat.element.nazwaKlienta;
        this.model.nazwaProjektu = rezultat.element.nazwaProjektu;
        this.model.notatka = rezultat.element.notatka;

        this.model.koniecProjektu =
          rezultat.element.koniecProjektu == undefined
            ? undefined
            : new Date(rezultat.element.koniecProjektu);
        this.model.koniecSpisuDo =
          rezultat.element.koniecSpisuDo == undefined
            ? undefined
            : new Date(rezultat.element.koniecSpisuDo);
        this.model.rozliczenieAnalityczneDo =
          rezultat.element.rozliczenieAnalityczneDo == undefined
            ? undefined
            : new Date(rezultat.element.rozliczenieAnalityczneDo);
        this.model.rozliczenieJednoznaczneDo =
          rezultat.element.rozliczenieJednoznaczneDo == undefined
            ? undefined
            : new Date(rezultat.element.rozliczenieJednoznaczneDo);

        this.model.status = this.ustalStatus(rezultat.element.status);

        this.model.czyWlaczycObslugeOswiadczen =
          rezultat.element.czyWlaczycObslugeOswiadczen;
        this.model.domenyKlienta = rezultat.element.domenyKlienta;
        this.model.dodatkowePolaNaDaneKlienta =
          rezultat.element.dodatkowePolaNaDaneKlienta;
        this.model.instrukcjaWypelnieniaOswiadczen =
          rezultat.element.instrukcjaWypelnieniaOswiadczen;

        this.model.czyNowyPlikZInstrukcja =
          !rezultat.element.instrukcjaWypelnieniaOswiadczen;

        this.sformatowanaDataKoniecProjektu = this.datePipe.transform(
          rezultat.element.koniecProjektu,
          'dd.MM.yyyy'
        );
        this.sformatowanaDataKoniecSpisuDo = this.datePipe.transform(
          rezultat.element.koniecSpisuDo,
          'dd.MM.yyyy'
        );
        this.sformatowanaDataRozliczenieAnalityczneDo = this.datePipe.transform(
          rezultat.element.rozliczenieAnalityczneDo,
          'dd.MM.yyyy'
        );
        this.sformatowanaDataRozliczenieJednoznaczneDo =
          this.datePipe.transform(
            rezultat.element.rozliczenieJednoznaczneDo,
            'dd.MM.yyyy'
          );

        this.czyZaladowanoDane = true;

        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  zapisz() {
    this.projektySerwis.edytujProjekt(this.model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.wyswietlKomunikat('Projekt został zapisany');
        this.router.navigate([Routing.SzczegolyProjektu, this.idProjektu]);
        return;
      }
      if (this.bladModelu(rezultat)) {
        this.bledyWalidacji = rezultat.bledyModelu;
        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  anuluj() {
    this.router.navigate([Routing.SzczegolyProjektu, this.idProjektu]);
  }

  wybierzDateKoniecSpisuDo() {
    this.modalReference = this.modalService.open(KalendarzModalContent, {
      centered: true,
    });
    this.modalReference.componentInstance.data = this.model.koniecSpisuDo;

    this.modalReference.result.then(
      (rezultat) => {
        if (rezultat != undefined) {
          this.model.koniecSpisuDo = rezultat.data;

          this.sformatowanaDataKoniecSpisuDo = this.datePipe.transform(
            rezultat.data,
            'dd.MM.yyyy'
          );
        }
      },
      (onRejected) => {}
    );
  }

  wybierzDateRozliczenieJednoznaczneDo() {
    this.modalReference = this.modalService.open(KalendarzModalContent, {
      centered: true,
    });
    this.modalReference.componentInstance.data =
      this.model.rozliczenieJednoznaczneDo;

    this.modalReference.result.then(
      (rezultat) => {
        if (rezultat != undefined) {
          this.model.rozliczenieJednoznaczneDo = rezultat.data;

          this.sformatowanaDataRozliczenieJednoznaczneDo =
            this.datePipe.transform(rezultat.data, 'dd.MM.yyyy');
        }
      },
      (onRejected) => {}
    );
  }

  wybierzDateRozliczenieAnalityczneDo() {
    this.modalReference = this.modalService.open(KalendarzModalContent, {
      centered: true,
    });
    this.modalReference.componentInstance.data =
      this.model.rozliczenieAnalityczneDo;

    this.modalReference.result.then(
      (rezultat) => {
        if (rezultat != undefined) {
          this.model.rozliczenieAnalityczneDo = rezultat.data;

          this.sformatowanaDataRozliczenieAnalityczneDo =
            this.datePipe.transform(rezultat.data, 'dd.MM.yyyy');
        }
      },
      (onRejected) => {}
    );
  }

  wybierzDateKoniecProjektu() {
    this.modalReference = this.modalService.open(KalendarzModalContent, {
      centered: true,
    });
    this.modalReference.componentInstance.data = this.model.koniecProjektu;

    this.modalReference.result.then(
      (rezultat) => {
        if (rezultat != undefined) {
          this.model.koniecProjektu = rezultat.data;

          this.sformatowanaDataKoniecProjektu = this.datePipe.transform(
            rezultat.data,
            'dd.MM.yyyy'
          );
        }
      },
      (onRejected) => {}
    );
  }

  wyczyscDate(rodzaj: string) {
    switch (rodzaj) {
      case 'KoniecSpisuDo':
        this.sformatowanaDataKoniecSpisuDo = '';
        this.model.koniecSpisuDo = null;
        break;
      case 'RozliczenieJednoznaczneDo':
        this.sformatowanaDataRozliczenieJednoznaczneDo = '';
        this.model.rozliczenieJednoznaczneDo = null;
        break;
      case 'RozliczenieAnalityczneDo':
        this.sformatowanaDataRozliczenieAnalityczneDo = '';
        this.model.rozliczenieAnalityczneDo = null;
        break;
      case 'KoniecProjektu':
        this.sformatowanaDataKoniecProjektu = '';
        this.model.koniecProjektu = null;
        break;
    }
  }

  czyAdmin() {
    return (
      this.authService.pobierzDaneZalogowanegoUzytkownika().uprawnienie ==
      Uprawnienie.administrator
    );
  }

  ustalStatus(status: StatusProjektu) {
    switch (status) {
      case StatusProjektu.przygotowanie:
        return 'Przygotowanie';
      case StatusProjektu.realizacja:
        return 'Realizacja';
      case StatusProjektu.zakonczony:
        return 'Zakonczony';
      case StatusProjektu.zarchiwizowany:
        return 'Zarchiwizowany';
    }
  }

  wybierzInstrukcjeZDysku(event: any) {
    var plik = event.target.files[0];
    this.wybierzInstrukcje(plik);

    event.target.value = null;
    return false;
  }

  wybierzInstrukcje(plik: File) {
    if (plik) {
      var reader = new FileReader();
      reader.readAsDataURL(plik);
      reader.onload = () => {
        var zalacznik = new Zalacznik();
        zalacznik.nazwaPliku = plik.name;
        zalacznik.plik = this.usunZbedneZnakiZPliku(reader.result.toString());
        if (zalacznik.plik == 'data:') {
          return;
        }
        zalacznik.dataModyfikacji = new Date(plik.lastModified);
        zalacznik.format = plik.name.split('.').pop().toLowerCase();

        this.model.instrukcjaWypelnieniaOswiadczen = zalacznik;
      };
    }
  }

  usunZbedneZnakiZPliku(base64: string) {
    if (base64.includes(',')) {
      return base64.substring(base64.indexOf(',') + 1);
    }
    return base64;
  }

  usunInstrukcje() {
    this.model.instrukcjaWypelnieniaOswiadczen = undefined;
    this.model.czyNowyPlikZInstrukcja = true;
  }
  pobierzAdresOswiadczen() {
    var adresStrony = location.origin;
    var url = adresStrony + '/' + Routing.Oswiadczenia + '/' + this.idProjektu;

    navigator.clipboard.writeText(url).then(() => {
      this.wyswietlKomunikat('Adres został skopiowany do schowka');
    });
  }
  przejdzDoOswiadczenJakoAdmin() {
    var model = new IdModel();
    model.id = this.idProjektu;
    this.projektySerwis
      .przejdzDoOswiadczenJakoAdmin(model)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          rezultat.element.potwierdzonoZapoznanieZInstrukcja = true;
          localStorage.setItem('uzytkownik', JSON.stringify(rezultat.element));
          var adresStrony = location.origin;
          var url =
            adresStrony + '/' + Routing.Oswiadczenia + '/' + this.idProjektu;
          window.open(url, '_blank');

          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }
}
