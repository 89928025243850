import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { KonfiguracjaPomieszczen } from 'src/app/modele/projekty/konfiguracjaPomieszczen/konfiguracjaPomieszczen';
import { PobierzProjektyFiltr } from 'src/app/modele/projekty/pobierzProjektyFiltr';
import { Projekt } from 'src/app/modele/projekty/projekt';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { PojedynczyElementResponse } from 'src/app/modele/wspolne/pojedynczyElement';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { ResponseBazowy } from 'src/app/modele/wspolne/responseBazowy';
import { StronicowanaListaWynikowResponse } from 'src/app/modele/wspolne/stronicowanaListaWynikowResponse';
import { SerwisBazowy } from '../bazowy.service';
import { AuthService } from '../ogolne/auth.service';
import { ErrorService } from '../ogolne/error.service';
import { LoadingService } from '../ogolne/loading.service';

@Injectable({
  providedIn: 'root'
})
export class KonfiguracjaPomieszczenService extends SerwisBazowy {

  constructor(
    httpClient: HttpClient,
    authService: AuthService,
    errorService: ErrorService,
    loaderService: LoadingService,
    translate: TranslateService) {
    super(httpClient, authService, errorService, loaderService, translate);
  }

  public pobierzKonfiguracje(model: IdModel) {
    return this.wykonajAkcje<IdModel, PojedynczyElementResponse<KonfiguracjaPomieszczen>>
      (this.AdresApi + "KonfiguracjaPomieszczen/PobierzKonfiguracje", model);
  }

  public zapisz(model: KonfiguracjaPomieszczen) {
    return this.wykonajAkcje<KonfiguracjaPomieszczen, ResponseBazowy>
      (this.AdresApi + "KonfiguracjaPomieszczen/Zapisz", model);
  }
}
