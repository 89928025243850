<div class="modal-content">
    <div class="modal-body">
        <button aria-label="Close" class="close" data-dismiss="modal" type="button"
            (click)="activeModal.dismiss()"></button>
        <div class="ft__01 text-center py-4 ">Import lokalizacji</div>
        <div class="text-center mb-2"></div>
        <form action="">

            <div class="input_container mb-4">
                <select [(ngModel)]="rodzajImportu" name="rodzajImportu">
                    <option [ngValue]="undefined" [disabled]="true">Rodzaj importu</option>
                    <option [ngValue]="0">Nowy import z pliku</option>
                    <option [ngValue]="1">Dodatkowy import z pliku</option>
                    <option [ngValue]="2">Dodanie lokalizacji ręcznie</option>
                </select>
            </div>

            <div *ngIf="rodzajImportu==0||rodzajImportu==1">
                <label class="btn__border pointer mb-4" for="file-upload" *ngIf="!zalacznik"
                    style="display: grid;align-items: center;">Wybierz
                    plik</label>
                <input id="file-upload" type="file" (change)="wybierzZalacznikZDysku($event)" hidden />

                <div class="d-flex justify-content-between align-items-center fs__imported mb-4 mt-4" *ngIf="zalacznik">
                    <div class="pt-1">{{zalacznik.nazwaPliku}}</div>
                    <div>
                        <button class="btn__delete" (click)="usunZalacznik()"></button>
                    </div>
                </div>
            </div>

            <div *ngIf="rodzajImportu==2">

                <div class="input_container pb-3  " [ngClass]="czyBladWalidacyjny('ulica')?'error':''">
                    <input type="text" placeholder="Ulica" [(ngModel)]="model.ulica" name="ulica">
                    <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('ulica')">
                        {{pobierzBladWalidacyjny("ulica") }}</div>
                </div>
                <div class="input_container pb-3" [ngClass]="czyBladWalidacyjny('miasto')?'error':''">
                    <input type="text" placeholder="Miasto" [(ngModel)]="model.miasto" name="miasto">
                    <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('miasto')">
                        {{pobierzBladWalidacyjny("miasto") }}</div>
                </div>
                <div class="input_container pb-3" [ngClass]="czyBladWalidacyjny('kodPocztowy')?'error':''">
                    <input type="text" placeholder="Kod pocztowy" [(ngModel)]="model.kodPocztowy" name="kodPocztowy">
                    <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('kodPocztowy')">
                        {{pobierzBladWalidacyjny("kodPocztowy") }}</div>
                </div>
                <div class="input_container pb-3" [ngClass]="czyBladWalidacyjny('rodzajLokalizacji')?'error':''">
                    <input type="text" placeholder="Rodzaj lokalizacji" [(ngModel)]="model.rodzajLokalizacji"
                        name="rodzajLokalizacji">
                    <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('rodzajLokalizacji')">
                        {{pobierzBladWalidacyjny("rodzajLokalizacji") }}</div>
                </div>
                <div class="input_container pb-3" [ngClass]="czyBladWalidacyjny('mpk')?'error':''">
                    <input type="text" placeholder="MPK" [(ngModel)]="model.mpk" name="mpk">
                    <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('mpk')">
                        {{pobierzBladWalidacyjny("mpk") }}</div>
                </div>
                <div class="input_container pb-3" [ngClass]="czyBladWalidacyjny('osobaOdpowiedzialna')?'error':''">
                    <input type="text" placeholder="Osoba odpowiedzialna" [(ngModel)]="model.osobaOdpowiedzialna"
                        name="osobaOdpowiedzialna">
                    <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('osobaOdpowiedzialna')">
                        {{pobierzBladWalidacyjny("osobaOdpowiedzialna") }}</div>
                </div>
                <div class="input_container pb-3" [ngClass]="czyBladWalidacyjny('numerTelefonu')?'error':''">
                    <input type="text" placeholder="Numer telefonu" [(ngModel)]="model.numerTelefonu"
                        name="numerTelefonu">
                    <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('numerTelefonu')">
                        {{pobierzBladWalidacyjny("numerTelefonu") }}</div>
                </div>
                <div class="input_container pb-3" [ngClass]="czyBladWalidacyjny('numerZespolu')?'error':''">
                    <input type="text" placeholder="Numer zespołu" [(ngModel)]="model.numerZespolu" name="numerZespolu">
                    <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('numerZespolu')">
                        {{pobierzBladWalidacyjny("numerZespolu") }}</div>
                </div>
            </div>


            <div class="input_container pb-3">
                <button class="btn__primary" (click)="zapisz()">Zapisz</button>
            </div>
            <div class="input_container pb-3">
                <button class="btn__link" (click)="activeModal.dismiss()">Anuluj</button>
            </div>
        </form>
    </div>
</div>