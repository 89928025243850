import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { KonfiguracjaPomieszczen } from 'src/app/modele/projekty/konfiguracjaPomieszczen/konfiguracjaPomieszczen';
import { DodajZespolModel } from 'src/app/modele/projekty/zespoly/dodajZespolModel';
import { EdytujZespolModel } from 'src/app/modele/projekty/zespoly/edytujZespolModel';
import { SzczegolyZespolu } from 'src/app/modele/projekty/zespoly/szczegolyZespolu';
import { WykonawcaZespolu } from 'src/app/modele/projekty/zespoly/wykonawcaZespolu';
import { ZespolProjektu } from 'src/app/modele/projekty/zespoly/zespolProjektu';
import { ZespolyProjektu } from 'src/app/modele/projekty/zespoly/zespolyProjektu';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { ListaWynikowResponse } from 'src/app/modele/wspolne/listaWynikowResponse';
import { PojedynczyElementResponse } from 'src/app/modele/wspolne/pojedynczyElement';
import { ResponseBazowy } from 'src/app/modele/wspolne/responseBazowy';
import { SerwisBazowy } from '../bazowy.service';
import { AuthService } from '../ogolne/auth.service';
import { ErrorService } from '../ogolne/error.service';
import { LoadingService } from '../ogolne/loading.service';

@Injectable({
  providedIn: 'root'
})
export class ZespolyService extends SerwisBazowy {

  constructor(
    httpClient: HttpClient,
    authService: AuthService,
    errorService: ErrorService,
    loaderService: LoadingService,
    translate: TranslateService) {
    super(httpClient, authService, errorService, loaderService, translate);
  }

  public pobierzZespoly(model: IdModel) {
    return this.wykonajAkcje<IdModel, PojedynczyElementResponse<ZespolyProjektu>>
      (this.AdresApi + "Zespoly/PobierzZespoly", model);
  }

  public dodajZespol(model: DodajZespolModel) {
    return this.wykonajAkcje<DodajZespolModel, ResponseBazowy>
      (this.AdresApi + "Zespoly/DodajZespol", model);
  }

  public edytujZespol(model: EdytujZespolModel) {
    return this.wykonajAkcje<EdytujZespolModel, ResponseBazowy>
      (this.AdresApi + "Zespoly/EdytujZespol", model);
  }

  public pobierzWykonawcowDoEdycjiZespolu() {
    return this.get<ListaWynikowResponse<WykonawcaZespolu>>
      (this.AdresApi + "Zespoly/PobierzWykonawcowDoEdycjiZespolu");
  }

  public usunZespol(model: IdModel) {
    return this.wykonajAkcje<IdModel, ResponseBazowy>
      (this.AdresApi + "Zespoly/UsunZespol", model);
  }

  public pobierzSzczegolyZespolu(model: IdModel) {
    return this.wykonajAkcje<IdModel, PojedynczyElementResponse<SzczegolyZespolu>>
      (this.AdresApi + "Zespoly/PobierzSzczegolyZespolu", model);
  }
}
