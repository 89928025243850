import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { TakNieModalComponent } from 'src/app/komponenty/ogolne/tak-nie-modal/tak-nie-modal.component';
import { PrzeniesPomieszczeniaModel } from 'src/app/modele/projekty/pomieszczenia/przeniesPomieszczeniaModel';
import { LokalizacjaDoSpisu } from 'src/app/modele/projekty/spis/lokalizacjaDoSpisu';
import { PobierzLokalizacjeDoSpisuFiltr } from 'src/app/modele/projekty/spis/pobierzLokalizacjeDoSpisuFiltr';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { Sortowanie, KierunekSortowania } from 'src/app/modele/wspolne/sortowanie';
import { Stronicowanie } from 'src/app/modele/wspolne/stronicowanie';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { EksportDanychService } from 'src/app/serwisy/projekty/eksport-danych.service';
import { LokalizacjeService } from 'src/app/serwisy/projekty/lokalizacje.service';
import { PomieszczeniaService } from 'src/app/serwisy/projekty/pomieszczenia.service';
import { ProjektyService } from 'src/app/serwisy/projekty/projekty.service';

@Component({
  selector: 'app-przenies-pomieszczenia-lokalizacje-modal',
  templateUrl: './przenies-pomieszczenia-lokalizacje-modal.component.html',
  styleUrls: ['./przenies-pomieszczenia-lokalizacje-modal.component.css']
})
export class PrzeniesPomieszczeniaLokalizacjeModalComponent extends BazowyComponent implements OnInit {

  idProjektu: string;
  czyZaladowanoDane: boolean;
  calkowitaLiczbaElementow: BigInteger;
  rozmiarTabeli: number = 25;
  czyJestWiecejRekordow: boolean = true;
  czyPokazacMenuSortowanie: boolean;
  wyszukiwanie: RejestrFiltrowanyRequest<PobierzLokalizacjeDoSpisuFiltr> = new RejestrFiltrowanyRequest<PobierzLokalizacjeDoSpisuFiltr>();
  lokalizacje: Array<LokalizacjaDoSpisu> = new Array();
  nazwaProjektu: string;
  czyPokazanoWyszukiwarkeMobile: boolean;
  idPomieszczen: Array<string>
  lokalizacjaAktualnaId: string

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    public activeModal: NgbActiveModal,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public lokalizacjeSerwis: LokalizacjeService,
    public projektySerwis: ProjektyService,
    public translateSerwis: TranslateService,
    public pomieszczeniaSerwis: PomieszczeniaService,
    private activatedRoute: ActivatedRoute,
    public eksportDanychSerwis: EksportDanychService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translateSerwis);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.wyszukiwanie.filtry = new PobierzLokalizacjeDoSpisuFiltr();
      this.wyszukiwanie.filtry.projektId = this.idProjektu;
      this.wyszukiwanie.sortowanie = new Sortowanie("lp", KierunekSortowania.asc);
      this.wyszukiwanie.stronicowanie = new Stronicowanie(false);
      this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
      this.wyszukiwanie.stronicowanie.strona = 1;

      this.pobierzDaneDoEkranu(true);
    });
  }

  pobierzDaneDoEkranu(zLadowaczem: boolean) {
    this.lokalizacjeSerwis.pobierzLokalizacjeDoSpisu(this.wyszukiwanie, zLadowaczem).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.lokalizacje = rezultat.element.lokalizacje;
          this.calkowitaLiczbaElementow = rezultat.element.calkowitaIloscRekordow;
          this.czyJestWiecejRekordow = rezultat.element.czyJestWiecejRekordow;
          this.nazwaProjektu = rezultat.element.nazwaProjektu;

          this.czyZaladowanoDane = true;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }

  zmienRozmiarTabeli() {
    this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
    this.pobierzDaneDoEkranu(true);
  }

  pobierzNumerRekorduPoczatek() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return this.wyszukiwanie.stronicowanie.rekordPoczatkowy + 1;
  }
  pobierzNumerRekorduKoniec() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return this.wyszukiwanie.stronicowanie.rekordPoczatkowy + this.lokalizacje.length;
  }

  zmienStrone(oIle: number) {
    this.wyszukiwanie.stronicowanie.strona += oIle;
    this.wyszukiwanie.stronicowanie.rekordPoczatkowy = (this.wyszukiwanie.stronicowanie.strona - 1) * this.wyszukiwanie.stronicowanie.iloscRekordow;
    this.pobierzDaneDoEkranu(true);
  }

  pokazMenuSortowania() {
    this.czyPokazacMenuSortowanie = !this.czyPokazacMenuSortowanie;
  }

  pobierzKierunekSortowania(kolumna: string) {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find(m => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      return "";
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      return "up";
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      return "down";
    }
  }

  zmienKierunekSortowania(kolumna: string) {
    this.czyPokazacMenuSortowanie = false;
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find(m => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      this.wyszukiwanie.sortowanie = new Sortowanie(kolumna, KierunekSortowania.asc);
      this.pobierzDaneDoEkranu(true);
      return;
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(kolumna, KierunekSortowania.desc);
      this.pobierzDaneDoEkranu(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanie.sortowanie = new Sortowanie("Id", KierunekSortowania.asc);
      this.pobierzDaneDoEkranu(true);
      return;
    }
  }

  pobierzKolumneSortowania() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    switch (kierunki[0].nazwaKolumny) {
      case "lp":
        return "Lp.";
      case "ulica":
        return "Ulica";
      case "miasto":
        return "Miasto";
      case "kodPocztowy":
        return "Kod pocztowy";
      case "numerZespolu":
        return "Numer zespołu";
      case "sumaSpisanychSrodkow":
        return "Spisane środki";
      case "sparowaneSrodki":
        return "Sparowane";
      case "srodkiZEwidencji":
        return "Środki z ewidencji";
    }
  }

  pobierzKierunekSortowaniaMobile() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    if (kierunki[0].kierunekSortowania == KierunekSortowania.asc) {
      return "up";
    }
    if (kierunki[0].kierunekSortowania == KierunekSortowania.desc) {
      return "";
    }
  }

  zmienKierunekSortowaniaMobile() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki[0];

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(kierunek.nazwaKolumny, KierunekSortowania.desc);
      this.pobierzDaneDoEkranu(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(kierunek.nazwaKolumny, KierunekSortowania.asc);
      this.pobierzDaneDoEkranu(true);
      return;
    }
  }
  wyczyscSortowanieMobile() {
    this.wyszukiwanie.sortowanie = new Sortowanie("Id", KierunekSortowania.asc);
    this.pobierzDaneDoEkranu(true);
  }

  filtrujPoTekscie() {
    this.pobierzDaneDoEkranu(false);
  }

  czyPokazacWyczyscFiltry() {
    return this.wyszukiwanie.filtry.lp || this.wyszukiwanie.filtry.kodPocztowy || this.wyszukiwanie.filtry.sparowaneSrodki
      || this.wyszukiwanie.filtry.ulica || this.wyszukiwanie.filtry.miasto || this.wyszukiwanie.filtry.srodkiZEwidencji
      || this.wyszukiwanie.filtry.sumaSpisanychSrodkow || this.wyszukiwanie.filtry.numerZespolu
  }

  wyczyscFiltry() {
    this.wyszukiwanie.filtry.lp = "";
    this.wyszukiwanie.filtry.kodPocztowy = "";
    this.wyszukiwanie.filtry.sparowaneSrodki = null;
    this.wyszukiwanie.filtry.ulica = "";
    this.wyszukiwanie.filtry.miasto = "";
    this.wyszukiwanie.filtry.srodkiZEwidencji = null;
    this.wyszukiwanie.filtry.numerZespolu = "";
    this.wyszukiwanie.filtry.sumaSpisanychSrodkow = null;

    this.pobierzDaneDoEkranu(true);
  }

  pokazWyszukiwarkeMobile(czyPokazac: boolean) {
    this.czyPokazanoWyszukiwarkeMobile = czyPokazac;
  }

  wybierzLokalizacje(id: string) {
    var lokalizacja = this.lokalizacje.filter(m => m.id == id)[0]
    var adresLokalizacji = `${lokalizacja.ulica}, ${lokalizacja.kodPocztowy} ${lokalizacja.miasto}`
    var komunikat = this.czyAdmin() ? `Czy na pewno chcesz przenieść pomieszczenia do lokalizacji ${adresLokalizacji}?` :
      `Czy na pewno chcesz przenieść swoje środki do ${adresLokalizacji}?`

    this.modalReference = this.modalService.open(TakNieModalComponent, new ModalOpcje(true, 'static'));
    this.modalReference.componentInstance.pytanie = komunikat;
    this.modalReference.result.then((result) => {
      if (result) {
        this.przeniesPomieszczenia(id)
      }
    }, onRejected => { });
  }

  przeniesPomieszczenia(idLokalizacji: string) {
    var model = new PrzeniesPomieszczeniaModel()
    model.lokalizacjaAktualnaId = this.lokalizacjaAktualnaId
    model.lokalizacjaDocelowaId = idLokalizacji
    model.pomieszczeniaId = this.idPomieszczen

    this.pomieszczeniaSerwis.przeniesPomieszczenia(model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.wyswietlKomunikat("Środki zostały przeniesione do nowej lokalizacji")
          this.activeModal.close(true)
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );

  }

}
