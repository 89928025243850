import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';

// https://angular.io/guide/http#handling-request-errors
// https://pusher.com/tutorials/error-handling-angular-part-1

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private injector: Injector,
    //, private komunikatySerwis: ToastrService // todo: circular dependency do rozwiazania
  ) { }

  handleError(error: any) {
    const router = this.injector.get(Router);
    const msg = `Wystąpił błąd: ${error}`;
    if (Error instanceof HttpErrorResponse) {
      console.log(error.status);
    }
    else {
      console.error(msg);

    }
    return throwError(msg);
  }
}
