import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { WyslijKodWeryfikacyjnyPonownieModel } from 'src/app/modele/uzytkownik/wyslijKodWeryfikacyjnyPonownieModel';
import { DezaktywujUzytkownikaModel } from 'src/app/modele/uzytkownik/dezaktywujUzytkownikaModel';
import { DodajUzytkownikaModel } from 'src/app/modele/uzytkownik/dodajUzytkownikaModel';
import { EdytujUzytkownikaModel } from 'src/app/modele/uzytkownik/edytujUzytkownikaModel';
import { Uzytkownik } from 'src/app/modele/uzytkownik/uzytkownik';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { UzytkownikService } from 'src/app/serwisy/konto/uzytkownik.service';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { BazowyComponent } from '../../bazowy.component';
import { DezaktywujUzytkownikaModalComponent } from '../dezaktywuj-uzytkownika-modal/dezaktywuj-uzytkownika-modal.component';
import { PobierzProjektyFiltr } from 'src/app/modele/projekty/pobierzProjektyFiltr';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import {
  Sortowanie,
  KierunekSortowania,
} from 'src/app/modele/wspolne/sortowanie';
import { Stronicowanie } from 'src/app/modele/wspolne/stronicowanie';
import { Uprawnienie } from 'src/app/modele/wspolne/uprawnienie';
import { ComboBox } from 'src/app/modele/wspolne/comboBox';
import { ProjektyService } from 'src/app/serwisy/projekty/projekty.service';

@Component({
  selector: 'app-edytuj-uzytkownika-modal',
  templateUrl: './edytuj-uzytkownika-modal.component.html',
  styleUrls: ['./edytuj-uzytkownika-modal.component.css'],
})
export class EdytujUzytkownikaModalComponent
  extends BazowyComponent
  implements OnInit
{
  id: string;
  model: EdytujUzytkownikaModel = new EdytujUzytkownikaModel();
  dezaktywujUzytkownikaModel = new DezaktywujUzytkownikaModel();
  czyZaladowanoDane: boolean;
  szczegoly: Uzytkownik;
  projekty: ComboBox[];

  constructor(
    public router: Router,
    modalService: NgbModal,
    public activeModal: NgbActiveModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public uzytkownikSerwis: UzytkownikService,
    public authService: AuthService,
    public translate: TranslateService,
    private projektySerwis: ProjektyService
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translate
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.pobierzSzczegoly();
    });
  }
  private pobierzSzczegoly(): void {
    var idModel = new IdModel();
    idModel.id = this.id;
    this.uzytkownikSerwis.pobierzSzczegoly(idModel).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.szczegoly = rezultat.element;
        this.model.imie = rezultat.element.imie;
        this.model.nazwisko = rezultat.element.nazwisko;
        this.model.email = rezultat.element.email;
        this.model.numerTelefonu = rezultat.element.numerTelefonu;
        this.model.uprawnienie = rezultat.element.uprawnienie;
        this.model.id = this.id;
        this.model.projektId = rezultat.element.projektId;

        if (this.model.projektId) {
          this.pobierzProjekty();
        }
        this.czyZaladowanoDane = true;
        return;
      }
      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      this.activeModal.close();
      return;
    });
  }

  zapisz() {
    this.uzytkownikSerwis
      .edytujUzytkownika(this.model)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.wyswietlKomunikat(
            this.pobierzTlumaczenie('edycjaUzytkownika.komunikatZapisz')
          );
          this.activeModal.close(true);
          return;
        }
        if (this.bladModelu(rezultat)) {
          this.bledyWalidacji = rezultat.bledyModelu;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  public wyslijLinkAktywacyjnyPonownie(): void {
    var model = new WyslijKodWeryfikacyjnyPonownieModel();
    model.email = this.szczegoly.email;
    this.uzytkownikSerwis.wyslijLinkPonownie(model).subscribe((result) => {
      if (this.czyRezultatOk(result)) {
        this.wyswietlKomunikat(
          this.pobierzTlumaczenie('komunikatWyslijLinkAktywacyjnyPonownie')
        );
        this.activeModal.close(true);
      } else {
        this.wyswietlKomunikat(result.wiadomosc, true);
      }
    });
  }

  dezaktywuj() {
    this.dezaktywujUzytkownikaModel.idUzytkownika = this.id;

    this.uzytkownikSerwis
      .dezaktywuj(this.dezaktywujUzytkownikaModel)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          if (rezultat.element.czyMaProjekty) {
            this.modalReference = this.modalService.open(
              DezaktywujUzytkownikaModalComponent,
              { centered: true }
            );

            this.modalReference.result.then(
              (tak) => {
                if (tak) {
                  this.dezaktywujUzytkownikaModel.potwierdzonoDezaktywacje =
                    true;
                  this.dezaktywuj();
                }
              },
              (onRejected) => {}
            );
            return;
          }
          this.wyswietlKomunikat(
            this.pobierzTlumaczenie('edycjaUzytkownika.komunikatDezaktywuj')
          );
          this.activeModal.close(true);
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  cofnijDezaktywacje() {
    var model = new IdModel();
    model.id = this.id;

    this.uzytkownikSerwis.aktywuj(model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.wyswietlKomunikat(
          this.pobierzTlumaczenie(
            'edycjaUzytkownika.komunikatCofnijDezaktywacje'
          )
        );
        this.activeModal.close(true);
        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  usun() {
    var model = new IdModel();
    model.id = this.id;

    this.uzytkownikSerwis.usun(model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.wyswietlKomunikat(
          this.pobierzTlumaczenie('edycjaUzytkownika.komunikatUsun')
        );
        this.activeModal.close(true);
        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  pobierzProjekty() {
    var model: RejestrFiltrowanyRequest<PobierzProjektyFiltr> =
      new RejestrFiltrowanyRequest<PobierzProjektyFiltr>();

    model.stronicowanie = new Stronicowanie(true);
    model.sortowanie = new Sortowanie('NazwaProjektu', KierunekSortowania.asc);
    model.filtry = new PobierzProjektyFiltr();
    model.filtry.aktywne = true;
    this.projektySerwis.PobierzProjekty(model, true).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.projekty = rezultat.listaElementow.map((m) => ({
          id: m.id,
          nazwa: m.nazwaProjektu,
        }));

        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  wybranoUprawnienie() {
    if (this.model.uprawnienie == Uprawnienie.pracownikKlienta) {
      this.pobierzProjekty();
    } else {
      this.model.projektId = null;
    }
  }
}
