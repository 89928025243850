import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { TakNieModalComponent } from 'src/app/komponenty/ogolne/tak-nie-modal/tak-nie-modal.component';
import { DodajZespolModel } from 'src/app/modele/projekty/zespoly/dodajZespolModel';
import { EdytujZespolModel } from 'src/app/modele/projekty/zespoly/edytujZespolModel';
import { SzczegolyZespolu } from 'src/app/modele/projekty/zespoly/szczegolyZespolu';
import { WykonawcaZespolu } from 'src/app/modele/projekty/zespoly/wykonawcaZespolu';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { ZespolyService } from 'src/app/serwisy/projekty/zespoly.service';

@Component({
  selector: 'app-edytuj-zespol-modal',
  templateUrl: './edytuj-zespol-modal.component.html',
  styleUrls: ['./edytuj-zespol-modal.component.css']
})
export class EdytujZespolModalComponent extends BazowyComponent implements OnInit {

  idProjektu: string;
  szczegolyZespolu: SzczegolyZespolu = new SzczegolyZespolu();
  idZespolu: string;
  model: EdytujZespolModel = new EdytujZespolModel();
  wykonawcy: Array<WykonawcaZespolu>;
  wykonawcyDoListy: Array<WykonawcaZespolu>;
  czyZaladowanoDane: boolean;
  idWykonawcy: string;
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

  constructor(
    public router: Router,
    modalService: NgbModal,
    public activeModal: NgbActiveModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public zespolySerwis: ZespolyService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translateSerwis);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.pobierzDaneDoEkranu();
    });
  }

  pobierzDaneDoEkranu() {
    var model = new IdModel();
    model.id = this.idZespolu;

    this.zespolySerwis.pobierzSzczegolyZespolu(model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.szczegolyZespolu = rezultat.element;

          this.model.nazwaZespolu = this.szczegolyZespolu.nazwa;
          this.model.id = this.szczegolyZespolu.id;
          this.model.idProjektu = this.szczegolyZespolu.projektId;
          this.model.idWykonawcow = this.szczegolyZespolu.idWykonawcow;

          this.pobierzWykonawcow();
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }
  pobierzWykonawcow() {
    this.zespolySerwis.pobierzWykonawcowDoEdycjiZespolu().subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.wykonawcy = rezultat.listaElementow;

          this.szczegolyZespolu.idWykonawcow.forEach(m => {
            this.wykonawcy.find(z => z.id == m).czyWybrany = true;
          })

          this.wykonawcyDoListy = [...this.wykonawcy.filter(m => !m.czyWybrany)];

          this.czyZaladowanoDane = true;

          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }


  zapisz() {
    this.model.idProjektu = this.idProjektu;
    this.model.id = this.idZespolu;
    this.model.idWykonawcow = new Array();
    this.wykonawcy.filter(m => m.czyWybrany).forEach(m => {
      this.model.idWykonawcow.push(m.id);
    })

    this.zespolySerwis.edytujZespol(this.model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.wyswietlKomunikat("Zespół został zapisany");
          this.activeModal.close(true);
          return;
        }
        if (this.bladModelu(rezultat)) {
          this.bledyWalidacji = rezultat.bledyModelu;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }

  wybierzWykonawce() {
    var wykonawca = this.wykonawcy.find(m => m.id == this.idWykonawcy);
    wykonawca.czyWybrany = true;
    this.ngSelectComponent.clearModel();
    this.ngSelectComponent.handleClearClick();
    this.ngSelectComponent.blur();
    this.idWykonawcy = null;

    this.wykonawcyDoListy = [...this.wykonawcy.filter(m => !m.czyWybrany)];
  }

  pobierzWybranychWykonawcow() {
    return this.wykonawcy.filter(m => m.czyWybrany)
  }

  customSearchFn(term: string, item: WykonawcaZespolu) {
    term = term.toLocaleLowerCase();
    return item.imie.toLocaleLowerCase().indexOf(term) > -1 ||
      item.nazwisko.toLocaleLowerCase().indexOf(term) > -1 ||
      item.telefon.toLocaleLowerCase().indexOf(term) > -1 ||
      (item.imie + " " + item.nazwisko).toLocaleLowerCase().indexOf(term) > -1 ||
      (item.nazwisko + " " + item.imie).toLocaleLowerCase().indexOf(term) > -1
      ;
  }

  usunWybranegoWykonawce(id: string) {
    var wykonawca = this.wykonawcy.find(m => m.id == id);
    wykonawca.czyWybrany = false;

    this.wykonawcyDoListy = [...this.wykonawcy.filter(m => !m.czyWybrany)];
  }

  usun() {
    var model = new IdModel();
    model.id = this.idZespolu;

    this.modalReference = this.modalService.open(TakNieModalComponent, new ModalOpcje(true, 'static'));
    this.modalReference.componentInstance.pytanie = "Czy chcesz usunąć zespół " + this.szczegolyZespolu.nazwa + "?";


    this.modalReference.result.then((result) => {
      if (result) {
        this.zespolySerwis.usunZespol(model).subscribe(
          (rezultat) => {
            if (this.czyRezultatOk(rezultat)) {
              this.wyswietlKomunikat("Zespół został usunięty");
              this.activeModal.close(true);
              return;
            }

            this.wyswietlKomunikat(rezultat.wiadomosc, true);
            return;
          }
        );
      }
    }, onRejected => { });
  }
}
