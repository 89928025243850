<nav class="navbar">
    <div class="navbar__container">
        <div class="navbrand d-flex align-items-center justify-content-center">
            <a class="brand" href="#">
                <img src="assets/img/logo.svg" alt="">
            </a>
        </div>
    </div>
</nav>

<div class="container container--login">
    <div class="ft__01 text-center py-4 mt-2">{{ 'logowanie.naglowek' | translate}}</div>

    <div class="input_container pb-3" [ngClass]="czyBladWalidacyjny('email')?'error':''">
        <input placeholder="E-mail" type="text" name="email" [(ngModel)]="zalogujModel.email">
        <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('email')">{{ pobierzBladWalidacyjny("email") }}
        </div>
    </div>
    <form (ngSubmit)="zaloguj()">
        <div class="input_container pb-2" [ngClass]="czyBladWalidacyjny('haslo')?'error':''">
            <input [type]="czyPokazacHaslo ? 'text' : 'password'" name="haslo" [(ngModel)]="zalogujModel.haslo"
                placeholder="{{ 'logowanie.haslo' | translate}}">
            <button type="button" class="btn__input--eye" [ngClass]="czyPokazacHaslo? 'active':''"
                (click)="pokazHaslo()"></button>
            <div class="error-info fs__error pt-2"
                *ngIf="czyBladWalidacyjny('haslo')&&!czyBladWalidacyjnyZLinkiem('haslo')">{{
                pobierzBladWalidacyjny("haslo")
                }}
            </div>
            <div class="login_info fs__login--info mt-2 mb-3" *ngIf="czyBladWalidacyjnyZLinkiem('haslo')"
                style="color: #AD1D2B">
                {{ 'logowanie.kontoNieaktywneCzesc1' | translate}} <a routerLink="" (click)="wyslijLinkPonownie()">{{
                    'logowanie.kontoNieaktywneCzesc2' | translate}}</a>
            </div>

        </div>
    </form>
    <div class="text-right pb-3">
        <a class="link" routerLink="/change-password">{{ 'logowanie.niePamietamHasla' | translate}}</a>
    </div>
    <div class="input_container pb-3">
        <button class="btn__primary" (click)="zaloguj()">{{ 'logowanie.zaloguj' | translate}}</button>
    </div>
</div>