import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { KonfiguracjaPomieszczen } from 'src/app/modele/projekty/konfiguracjaPomieszczen/konfiguracjaPomieszczen';
import { ComboBox } from 'src/app/modele/wspolne/comboBox';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { KonfiguracjaPomieszczenService } from 'src/app/serwisy/projekty/konfiguracja-pomieszczen.service';
import { PolaSpisuService } from 'src/app/serwisy/projekty/pola-spisu.service';
import { SlownikiService } from 'src/app/serwisy/projekty/slowniki.service';
import { BazowyComponent } from '../../bazowy.component';

@Component({
  selector: 'app-konfiguracja-pomieszczen',
  templateUrl: './konfiguracja-pomieszczen.component.html',
  styleUrls: ['./konfiguracja-pomieszczen.component.css']
})
export class KonfiguracjaPomieszczenComponent extends BazowyComponent implements OnInit {

  idProjektu: string;
  czyZaladowanoDane: boolean;
  model: KonfiguracjaPomieszczen = new KonfiguracjaPomieszczen();
  slowniki: Array<ComboBox> = new Array();

  slideConfig = {
    "slidesToShow": 2,
    "slidesToScroll": 1,
    "prevArrow": document.getElementsByClassName('btn__left'),
    "nextArrow": document.getElementsByClassName('btn__right'),
    "infinite": false,
    "variableWidth": true,
  };
  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public konfiguracjaPomieszczenSerwis: KonfiguracjaPomieszczenService,
    public slownikiSerwis: SlownikiService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translateSerwis);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.idProjektu = this.activatedRoute.snapshot.paramMap.get('id');
      this.pobierzDaneDoEkranu();
    });
  }

  pobierzDaneDoEkranu() {
    var model = new IdModel();
    model.id = this.idProjektu;

    this.konfiguracjaPomieszczenSerwis.pobierzKonfiguracje(model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.model = rezultat.element;

          this.pobierzSlownikiJednokolumnowe();
          this.czyZaladowanoDane = true;

          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }

  pobierzSlownikiJednokolumnowe() {
    var model = new IdModel();
    model.id = this.idProjektu;
    this.slownikiSerwis.pobierzSlownikiJednokolumnowe(model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.slowniki = rezultat.listaElementow;
          return;
        }
        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }

  zapisz() {

    this.konfiguracjaPomieszczenSerwis.zapisz(this.model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.wyswietlKomunikat("Zapisano poprawnie");
          return;
        }
        if (this.bladModelu(rezultat)) {
          this.bledyWalidacji = rezultat.bledyModelu;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }
}
