import { Zalacznik } from '../wspolne/zalacznik';

export class DodajProjektModel {
  nazwaProjektu: string;
  nazwaKlienta: string;
  email: string;
  status: string;
  koniecSpisuDo?: Date;
  rozliczenieJednoznaczneDo?: Date;
  rozliczenieAnalityczneDo?: Date;
  koniecProjektu?: Date;
  notatka: string;
  czyWlaczycObslugeOswiadczen: boolean;
  domenyKlienta: string;
  dodatkowePolaNaDaneKlienta: string;
  instrukcjaWypelnieniaOswiadczen?: Zalacznik;
}
