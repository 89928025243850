<div class="modal-content" *ngIf="czyZaladowanoDane">
  <div class="modal-body">
    <button
      aria-label="Close"
      class="close"
      data-dismiss="modal"
      type="button"
      (click)="activeModal.dismiss()"
    ></button>
    <div class="ft__01 text-center py-4 mb-3">
      {{ id ? "Edytuj środek" : "Dodaj środek" }}
    </div>

    <div
      class="container"
      [ngClass]="
        czyJakiekolwiekPoleMaWyszukiwanieWEwidencji() ? 'jest-wyszukiwanie' : ''
      "
    >
      <div *ngFor="let pole of model.pola">
        <div
          class="f-input mb-1"
          *ngIf="
            pole.poleSpisu.rodzajPolaSpisu != 0 &&
            pole.poleSpisu.rodzajPolaSpisu != 7
          "
        >
          <label
            _ngcontent-ajo-c128=""
            for=""
            class="ft__label mb-1 text-left w-100"
            >{{ pole.poleSpisu.nazwaPola }}</label
          >
        </div>
        <div
          class="f-input mb-3"
          [ngClass]="
            czyBladWalidacyjny(pole.poleSpisu.nazwaPola) ? 'error' : ''
          "
        >
          <!-- ckeckbox -->
          <!-- gotowe -->
          <div *ngIf="pole.poleSpisu.rodzajPolaSpisu == 0" class="chb por mb-3">
            <input
              id="cb_{{ pole.poleSpisu.id }}"
              type="checkbox"
              [checked]="czyCheckboxZaznaczonyDomyslnie(pole.wartosc)"
              name="{{ pole.poleSpisu.id }}"
              (change)="zaznaczCheckbox($event, pole.poleSpisu.id)"
              [disabled]="pole.poleSpisu.tylkoDoOdczytu || !czyMoznaEdytowac"
              [ngModel]="czyCheckboxZaznaczonyDomyslnie(pole.wartosc)"
            />
            <label class="fs__chb" for="cb_{{ pole.poleSpisu.id }}">{{
              pole.poleSpisu.nazwaPola
            }}</label>
          </div>

          <!-- data -->
          <!-- gotowe -->
          <div
            *ngIf="pole.poleSpisu.rodzajPolaSpisu == 1"
            class="f-input d-flex"
          >
            <input
              type="text"
              value="{{ pole.wartosc }}"
              [readonly]="pole.poleSpisu.tylkoDoOdczytu || !czyMoznaEdytowac"
              [imask]="dateMask"
              [(ngModel)]="pole.wartosc"
            />
            <button
              *ngIf="czyMoznaWyszukiwacWEwidencji(pole.poleSpisu.id)"
              class="btn__input--search"
              (click)="szukajWEwidencjiKrzyzowo()"
            ></button>
            <div
              class="btn__input--search"
              *ngIf="!czyMoznaWyszukiwacWEwidencji(pole.poleSpisu.id)"
              style="visibility: hidden"
            ></div>
          </div>

          <!-- liczba calkowita -->
          <!-- gotowe -->
          <div
            *ngIf="pole.poleSpisu.rodzajPolaSpisu == 2"
            class="f-input d-flex"
          >
            <input
              type="text"
              value="{{ pole.wartosc }}"
              [readonly]="pole.poleSpisu.tylkoDoOdczytu || !czyMoznaEdytowac"
              appTylkoPelneLiczby
              [maxLength]="
                pobierzMaksymalnaDlugoscPola(
                  pole.poleSpisu.maksymalnaLiczbaZnakow
                )
              "
              (focusout)="onFocusOutEvent($event, pole.poleSpisu.id)"
              [(ngModel)]="pole.wartosc"
            />

            <button
              *ngIf="czyMoznaWyszukiwacWEwidencji(pole.poleSpisu.id)"
              class="btn__input--search"
              (click)="szukajWEwidencjiKrzyzowo()"
            ></button>
            <div
              class="btn__input--search"
              *ngIf="!czyMoznaWyszukiwacWEwidencji(pole.poleSpisu.id)"
              style="visibility: hidden"
            ></div>
          </div>

          <!-- liczba zmiennoprzecinkowa -->
          <!-- gotowe -->
          <div
            *ngIf="pole.poleSpisu.rodzajPolaSpisu == 3"
            class="f-input d-flex"
          >
            <input
              type="text"
              value="{{ pole.wartosc }}"
              [readonly]="pole.poleSpisu.tylkoDoOdczytu || !czyMoznaEdytowac"
              digitOnly
              decimal="true"
              decimalSeparator="."
              [(ngModel)]="pole.wartosc"
              [maxLength]="
                pobierzMaksymalnaDlugoscPola(
                  pole.poleSpisu.maksymalnaLiczbaZnakow
                )
              "
            />
            <button
              *ngIf="czyMoznaWyszukiwacWEwidencji(pole.poleSpisu.id)"
              class="btn__input--search"
              (click)="szukajWEwidencjiKrzyzowo()"
            ></button>
            <div
              class="btn__input--search"
              *ngIf="!czyMoznaWyszukiwacWEwidencji(pole.poleSpisu.id)"
              style="visibility: hidden"
            ></div>
          </div>

          <!-- slownik jednokolumnowy-->
          <!-- gotowe -->
          <div
            *ngIf="pole.poleSpisu.rodzajPolaSpisu == 4"
            class="f-input d-flex"
          >
            <ng-autocomplete
              *ngIf="!pole.poleSpisu.tylkoDoOdczytu && czyMoznaEdytowac"
              [data]="pole.wartosciSlownika"
              (focusout)="onFocusOutEvent($event, pole.poleSpisu.id)"
              [(ngModel)]="pole.wartosc"
              [itemTemplate]="itemTemplate"
              initialValue="test"
              [maxLength]="
                pobierzMaksymalnaDlugoscPola(
                  pole.poleSpisu.maksymalnaLiczbaZnakow
                )
              "
            >
            </ng-autocomplete>
            <ng-template #itemTemplate let-item>
              <a [innerHTML]="item"></a>
            </ng-template>
            <input
              *ngIf="pole.poleSpisu.tylkoDoOdczytu || !czyMoznaEdytowac"
              type="text"
              value="{{ pole.wartosc }}"
              [readonly]="true"
              [maxLength]="
                pobierzMaksymalnaDlugoscPola(
                  pole.poleSpisu.maksymalnaLiczbaZnakow
                )
              "
              [(ngModel)]="pole.wartosc"
            />
            <button
              *ngIf="czyMoznaWyszukiwacWEwidencji(pole.poleSpisu.id)"
              class="btn__input--search"
              (click)="szukajWEwidencjiKrzyzowo()"
            ></button>
            <div
              class="btn__input--search"
              *ngIf="!czyMoznaWyszukiwacWEwidencji(pole.poleSpisu.id)"
              style="visibility: hidden"
            ></div>
          </div>

          <!-- slownik wielokolumnowy -->
          <!-- gotowe -->
          <div
            *ngIf="pole.poleSpisu.rodzajPolaSpisu == 5"
            class="f-input d-flex"
          >
            <ng-autocomplete
              *ngIf="!pole.poleSpisu.tylkoDoOdczytu && czyMoznaEdytowac"
              [data]="pole.wartosciSlownika"
              (focusout)="onFocusOutEvent($event, pole.poleSpisu.id)"
              [(ngModel)]="pole.wartosc"
              (selected)="selectEvent($event, pole.poleSpisu.id)"
              [itemTemplate]="itemTemplate"
              (inputCleared)="clearedEvent($event, pole.poleSpisu.id)"
              initialValue="{{ pole.wartosc }}"
              [maxLength]="
                pobierzMaksymalnaDlugoscPola(
                  pole.poleSpisu.maksymalnaLiczbaZnakow
                )
              "
            >
            </ng-autocomplete>
            <ng-template #itemTemplate let-item>
              <a [innerHTML]="item"></a>
            </ng-template>
            <input
              *ngIf="pole.poleSpisu.tylkoDoOdczytu || !czyMoznaEdytowac"
              type="text"
              value="{{ pole.wartosc }}"
              [readonly]="true"
              [maxLength]="
                pobierzMaksymalnaDlugoscPola(
                  pole.poleSpisu.maksymalnaLiczbaZnakow
                )
              "
              [(ngModel)]="pole.wartosc"
            />
            <button
              *ngIf="czyMoznaWyszukiwacWEwidencji(pole.poleSpisu.id)"
              class="btn__input--search"
              (click)="szukajWEwidencjiKrzyzowo()"
            ></button>
            <div
              class="btn__input--search"
              *ngIf="!czyMoznaWyszukiwacWEwidencji(pole.poleSpisu.id)"
              style="visibility: hidden"
            ></div>
          </div>

          <!-- tekst -->
          <!-- gotowe -->
          <div
            *ngIf="pole.poleSpisu.rodzajPolaSpisu == 6"
            class="f-input d-flex"
          >
            <input
              type="text"
              value="{{ pole.wartosc }}"
              [readonly]="pole.poleSpisu.tylkoDoOdczytu || !czyMoznaEdytowac"
              [maxLength]="
                pobierzMaksymalnaDlugoscPola(
                  pole.poleSpisu.maksymalnaLiczbaZnakow
                )
              "
              (focusout)="onFocusOutEvent($event, pole.poleSpisu.id)"
              [(ngModel)]="pole.wartosc"
            />
            <button
              *ngIf="czyMoznaWyszukiwacWEwidencji(pole.poleSpisu.id)"
              class="btn__input--search"
              (click)="szukajWEwidencjiKrzyzowo()"
            ></button>
            <div
              class="btn__input--search"
              *ngIf="!czyMoznaWyszukiwacWEwidencji(pole.poleSpisu.id)"
              style="visibility: hidden"
            ></div>
          </div>

          <!-- zdjecie -->
          <div
            *ngIf="pole.poleSpisu.rodzajPolaSpisu == 7"
            class="f-input d-flex"
          >
            <div style="width: 100%">
              <div
                class="f-input por"
                [ngClass]="czySaWynikiNaLiscie(model.zdjecia) ? 'mb-4' : ''"
              >
                <input
                  type="text"
                  value="{{ pole.poleSpisu.nazwaPola }}"
                  readonly
                />

                <button
                  class="btn__input--gallery"
                  (click)="zdjecie.click()"
                  *ngIf="czyMoznaEdytowac"
                ></button>
                <input
                  id="file-upload"
                  type="file"
                  (change)="wybierzZalacznikZDysku($event)"
                  hidden
                  #zdjecie
                  accept="image/jpeg, image/png"
                />
              </div>
              <div
                class="box__scroll px-md-5"
                *ngIf="czySaWynikiNaLiscie(model.zdjecia)"
              >
                <div class="gallery__images">
                  <div
                    class="gallery__images--image"
                    *ngFor="let zdjecie of model.zdjecia; index as i"
                  >
                    <img
                      [src]="budujZdjecieDoWyswietlenia(zdjecie)"
                      *ngIf="zdjecie"
                      style="height: 100%; width: 100%; object-fit: cover"
                      (click)="pokazGalerie(i)"
                    />
                    <button
                      class="btn__gallery--remove"
                      (click)="usunZdjecie(i)"
                      *ngIf="czyMoznaEdytowac"
                    ></button>
                  </div>
                </div>
                <ng-image-fullscreen-view
                  [images]="fullScrennZdjecia"
                  [imageIndex]="wybraneZdjecie"
                  [show]="pokazGalerieZdjec"
                  (close)="zamknijGalerie()"
                  [animationSpeed]="0.1"
                >
                </ng-image-fullscreen-view>
              </div>
            </div>

            <div class="btn__input--search" style="visibility: hidden"></div>
          </div>

          <!-- inne -->
          <!-- gotowe -->
          <div
            *ngIf="pole.poleSpisu.rodzajPolaSpisu == 8"
            class="f-input d-flex"
          >
            <input
              type="text"
              value="{{ pole.wartosc }}"
              [readonly]="pole.poleSpisu.tylkoDoOdczytu || !czyMoznaEdytowac"
              [maxLength]="
                pobierzMaksymalnaDlugoscPola(
                  pole.poleSpisu.maksymalnaLiczbaZnakow
                )
              "
              (focusout)="onFocusOutEvent($event, pole.poleSpisu.id)"
              [(ngModel)]="pole.wartosc"
            />
            <button
              *ngIf="czyMoznaWyszukiwacWEwidencji(pole.poleSpisu.id)"
              class="btn__input--search"
              (click)="szukajWEwidencjiKrzyzowo()"
            ></button>
            <div
              class="btn__input--search"
              *ngIf="!czyMoznaWyszukiwacWEwidencji(pole.poleSpisu.id)"
              style="visibility: hidden"
            ></div>
          </div>

          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny(pole.poleSpisu.nazwaPola)"
          >
            {{ pobierzBladWalidacyjny(pole.poleSpisu.nazwaPola) }}
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-buttons">
      <div class="input_container pb-2 mt-4">
        <button class="btn__primary" (click)="zapisz()">
          {{ "zapisz" | translate }}
        </button>
      </div>
      <div class="f-item pt-2">
        <button
          *ngIf="this.id"
          type="button"
          [routerLink]=""
          class="btn__deactivate mb-3 btn__empty"
          (click)="usun()"
        >
          {{ "usun" | translate }}
        </button>
      </div>
      <div class="input_container pb-3">
        <button class="btn__link" (click)="activeModal.dismiss()">
          {{ "anuluj" | translate }}
        </button>
      </div>
    </div>

    <!-- <div class="bootom__buttons px-4 mt-4">
      <div
        class="container--mw800 flex-column flex-sm-row d-flex justify-content-between align-items-center"
      >
        <button
          class="btn__link order-2 order-sm-0 px-lg-5"
          (click)="cofnij()"
          *ngIf="czyMoznaEdytowac"
        >
          Anuluj</button
        ><button
          class="btn__link order-2 order-sm-0 px-lg-5"
          (click)="cofnij()"
          *ngIf="!czyMoznaEdytowac"
        >
          Cofnij
        </button>
        <button
          class="btn__link order-2 order-sm-0 px-lg-5"
          (click)="pobierzHistorieZmianSrodka()"
          *ngIf="czyMoznaEdytowac"
        >
          Historia
        </button>
        <button
          class="btn__link order-1 btn__link--red px-5 mb-0"
          (click)="usun()"
          *ngIf="czyMoznaEdytowac"
        >
          Usuń
        </button>
        <button
          class="btn__blue order-0 order-sm-1 px-5 mb-3 mb-sm-0"
          (click)="zapisz()"
          *ngIf="czyMoznaEdytowac"
        >
          Zapisz
        </button>
      </div>
    </div> -->
  </div>
</div>
