<div class="modal-content" *ngIf="czyZaladowanoDane">
    <div class="modal-body">
        <button aria-label="Close" class="close" data-dismiss="modal" type="button"
            (click)="activeModal.dismiss()"></button>
        <div class="text-center mb-2"></div>


        <div class="container-fluid d-none d-md-block pt-4">
            <div class="box__scroll py-3">
                <div class="box__scroll--top d-flex justify-content-between align-items-center mb-3">
                    <span class="ft__03 pt-1 mt-1">Wybierz pomieszczenie</span>

                    <div class="input_container">
                        <button class="btn__clear--filter mr-3" (click)="wyczyscFiltry()"
                            *ngIf="czyPokazacWyczyscFiltry()">Wyczyść
                            filtry</button>
                    </div>
                </div>
                <div class="tableFixHead tableFixHead--3">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>
                                    Nazwa
                                    <button class="btn__input--sort--bottom--gray"
                                        [ngClass]="pobierzKierunekSortowania('nazwa')"
                                        (click)="zmienKierunekSortowania('nazwa')"></button>
                                    <div class="input_container input_container--mini">
                                        <input class=" pr-4" type="text" placeholder="Szukaj"
                                            [(ngModel)]="wyszukiwanie.filtry.nazwa"
                                            (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.nazwa)">
                                        <button class="btn__input--search" *ngIf="!wyszukiwanie.filtry.nazwa"></button>
                                        <button class="btn__input--clear" *ngIf="wyszukiwanie.filtry.nazwa"
                                            (click)="this.wyszukiwanie.filtry.nazwa='';pobierzDaneDoEkranu(false)"></button>
                                    </div>
                                </th>
                                <th>
                                    Piętro
                                    <button class="btn__input--sort--bottom--gray"
                                        [ngClass]="pobierzKierunekSortowania('pietro')"
                                        (click)="zmienKierunekSortowania('pietro')"></button>

                                    <div class="input_container input_container--mini">
                                        <input class=" pr-4" type="text" placeholder="Szukaj"
                                            [(ngModel)]="wyszukiwanie.filtry.pietro"
                                            (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.pietro)">
                                        <button class="btn__input--search" *ngIf="!wyszukiwanie.filtry.pietro"></button>
                                        <button class="btn__input--clear" *ngIf="wyszukiwanie.filtry.pietro"
                                            (click)="this.wyszukiwanie.filtry.pietro='';pobierzDaneDoEkranu(false)"></button>
                                    </div>
                                </th>

                                <th>
                                    Spisane środki
                                    <button class="btn__input--sort--bottom--gray"
                                        [ngClass]="pobierzKierunekSortowania('sumaSpisanychSrodkow')"
                                        (click)="zmienKierunekSortowania('sumaSpisanychSrodkow')"></button>

                                    <div class="input_container input_container--mini">
                                        <input class=" pr-4" type="text" placeholder="Szukaj"
                                            [(ngModel)]="wyszukiwanie.filtry.sumaSpisanychSrodkow"
                                            (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.sumaSpisanychSrodkow)">
                                        <button class="btn__input--search"
                                            *ngIf="!wyszukiwanie.filtry.sumaSpisanychSrodkow"></button>
                                        <button class="btn__input--clear"
                                            *ngIf="wyszukiwanie.filtry.sumaSpisanychSrodkow"
                                            (click)="this.wyszukiwanie.filtry.sumaSpisanychSrodkow='';pobierzDaneDoEkranu(false)"></button>
                                    </div>
                                </th>
                                <th>
                                    Sparowane
                                    <button class="btn__input--sort--bottom--gray"
                                        [ngClass]="pobierzKierunekSortowania('sparowaneSrodki')"
                                        (click)="zmienKierunekSortowania('sparowaneSrodki')"></button>
                                    <div class="input_container input_container--mini">
                                        <input class=" pr-4" type="text" placeholder="Szukaj"
                                            [(ngModel)]="wyszukiwanie.filtry.sparowaneSrodki"
                                            (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.sparowaneSrodki)">
                                        <button class="btn__input--search"
                                            *ngIf="!wyszukiwanie.filtry.sparowaneSrodki"></button>
                                        <button class="btn__input--clear" *ngIf="wyszukiwanie.filtry.sparowaneSrodki"
                                            (click)="this.wyszukiwanie.filtry.sparowaneSrodki='';pobierzDaneDoEkranu(false)"></button>
                                    </div>
                                </th>
                                <th>
                                    Utworzył
                                    <button class="btn__input--sort--bottom--gray"
                                        [ngClass]="pobierzKierunekSortowania('utworzyl')"
                                        (click)="zmienKierunekSortowania('utworzyl')"></button>
                                    <div class="input_container input_container--mini">
                                        <input class=" pr-4" type="text" placeholder="Szukaj"
                                            [(ngModel)]="wyszukiwanie.filtry.utworzyl"
                                            (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.utworzyl)">
                                        <button class="btn__input--search"
                                            *ngIf="!wyszukiwanie.filtry.utworzyl"></button>
                                        <button class="btn__input--clear" *ngIf="wyszukiwanie.filtry.utworzyl"
                                            (click)="this.wyszukiwanie.filtry.utworzyl='';pobierzDaneDoEkranu(false)"></button>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="fz12" *ngIf="czySaWynikiNaLiscie(pomieszczenia)">
                            <tr *ngFor="let pomieszczenie of pomieszczenia" class="pointer project-row"
                                (click)="wybierzPomieszczenie(pomieszczenie.id)">
                                <td>{{pomieszczenie.nazwa}}</td>
                                <td>{{pomieszczenie.pietro}}</td>
                                <td>{{pomieszczenie.sumaSpisanychSrodkow}}</td>
                                <td>{{pomieszczenie.sparowaneSrodki}}</td>
                                <td>{{pomieszczenie.utworzyl}}</td>

                            </tr>

                        </tbody>
                    </table>
                    <div class="ft__03 text-gray text-center my-5 py-5" *ngIf="!czySaWynikiNaLiscie(pomieszczenia)">
                        <div>Brak pomieszczeń dla wybranych filtrów</div>
                    </div>
                </div>

            </div>

            <div class="paginate float-right d-flex align-items-center pt-4 pb-2 pt-lg-2 mt-1"
                *ngIf="czySaWynikiNaLiscie(pomieszczenia)">
                <div>Pokaż wyniki</div>
                <select class="paginate__select mx-2" name="" id="" (change)="zmienRozmiarTabeli()"
                    [(ngModel)]="rozmiarTabeli">
                    <option [ngValue]="25">25</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                </select>
                <div class="px-2">{{pobierzNumerRekorduPoczatek()}}-{{pobierzNumerRekorduKoniec()}} z
                    {{calkowitaLiczbaElementow}}</div>
                <div class="d-flex">
                    <button class="btn__left" *ngIf="this.wyszukiwanie.stronicowanie.strona>1"
                        (click)="zmienStrone(-1)"></button>
                    <div class="px-2">{{this.wyszukiwanie.stronicowanie.strona}}</div>
                    <button class="btn__right" *ngIf="czyJestWiecejRekordow" (click)="zmienStrone(1)"></button>
                </div>
            </div>
        </div>
        <!-- mobile -->
        <div class="container-fluid d-md-none pb-5 ">
            <div class="filter-box d-flex justify-content-between pt-3 mb-3">
                <span class="ft__03 mr-3">Wybierz pomieszczenie</span>
                <div class="dropdown person_desktop text-right">
                    <a class="btn__input--sort dropdown-toggle" [routerLink]="" (click)="pokazMenuSortowania()"
                        role="button" id="dropdownSort" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false" (clickOutside)="czyPokazacMenuSortowanie=false">
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownSort"
                        *ngIf="czyPokazacMenuSortowanie" style="display: block;position: absolute;">
                        <a class="dropdown-item " [routerLink]="" (click)="zmienKierunekSortowania('nazwa')">Nazwa</a>
                        <a class="dropdown-item " [routerLink]="" (click)="zmienKierunekSortowania('pietro')">Pietro</a>

                        <a class="dropdown-item " [routerLink]=""
                            (click)="zmienKierunekSortowania('sumaSpisanychSrodkow')">Spisane środki</a>
                        <a class="dropdown-item " [routerLink]=""
                            (click)="zmienKierunekSortowania('sparowaneSrodki')">Sparowane</a>
                        <a class="dropdown-item " [routerLink]=""
                            (click)="zmienKierunekSortowania('utworzyl')">Utworzył</a>
                    </div>
                </div>
            </div>

            <div class="filter-select pt-3 pb-2 d-flex flex-column mb-3" *ngIf="pobierzKolumneSortowania()">
                <div class="d-flex pb-2">
                    <div class="filter_item">
                        <button class="btn__x" (click)="wyczyscSortowanieMobile()"></button>
                        <span class="fs__sort">Sortowanie: {{pobierzKolumneSortowania()}}</span>
                    </div>
                    <button class="btn__input--sort--bottom ml-3" [ngClass]="pobierzKierunekSortowaniaMobile()"
                        (click)="zmienKierunekSortowaniaMobile()"></button>
                </div>

            </div>

            <div class="input_container mb-4 text-right" *ngIf="!czyPokazanoWyszukiwarkeMobile">
                <button class="btn__search mx-2" (click)="pokazWyszukiwarkeMobile(true)"></button>
            </div>
            <div class="searchBox d-flex flex-wrap por pb-5" *ngIf="czyPokazanoWyszukiwarkeMobile">
                <button class="btn__input--clear" (click)="pokazWyszukiwarkeMobile(false)"></button>
            </div>

            <div class="box__mobile box__mobile--form mb-3" *ngIf="czyPokazanoWyszukiwarkeMobile">

                <div class="input_container mb-3">
                    <input class=" pr-4" type="text" placeholder="Nazwa" [(ngModel)]="wyszukiwanie.filtry.nazwa">
                    <button class="btn__input--search" *ngIf="!wyszukiwanie.filtry.nazwa"></button>
                    <button class="btn__input--clear" *ngIf="wyszukiwanie.filtry.nazwa"
                        (click)="this.wyszukiwanie.filtry.nazwa=''"></button>
                </div>
                <div class="input_container mb-3">
                    <input class=" pr-4" type="text" placeholder="Piętro" [(ngModel)]="wyszukiwanie.filtry.pietro">
                    <button class="btn__input--search" *ngIf="!wyszukiwanie.filtry.pietro"></button>
                    <button class="btn__input--clear" *ngIf="wyszukiwanie.filtry.pietro"
                        (click)="this.wyszukiwanie.filtry.pietro=''"></button>
                </div>

                <div class="input_container mb-3">
                    <input class=" pr-4" type="text" placeholder="Spisane środki"
                        [(ngModel)]="wyszukiwanie.filtry.sumaSpisanychSrodkow">
                    <button class="btn__input--search" *ngIf="!wyszukiwanie.filtry.sumaSpisanychSrodkow"></button>
                    <button class="btn__input--clear" *ngIf="wyszukiwanie.filtry.sumaSpisanychSrodkow"
                        (click)="this.wyszukiwanie.filtry.sumaSpisanychSrodkow=''"></button>
                </div>
                <div class="input_container mb-3">
                    <input class=" pr-4" type="text" placeholder="Sparowane"
                        [(ngModel)]="wyszukiwanie.filtry.sparowaneSrodki">
                    <button class="btn__input--search" *ngIf="!wyszukiwanie.filtry.sparowaneSrodki"></button>
                    <button class="btn__input--clear" *ngIf="wyszukiwanie.filtry.sparowaneSrodki"
                        (click)="this.wyszukiwanie.filtry.sparowaneSrodki=''"></button>
                </div>

                <div class="input_container mb-3">
                    <input class=" pr-4" type="text" placeholder="Utworzył" [(ngModel)]="wyszukiwanie.filtry.utworzyl">
                    <button class="btn__input--search" *ngIf="!wyszukiwanie.filtry.utworzyl"></button>
                    <button class="btn__input--clear" *ngIf="wyszukiwanie.filtry.utworzyl"
                        (click)="this.wyszukiwanie.filtry.utworzyl=''"></button>
                </div>
            </div>

            <button class="btn__border mt-4 mb-4" (click)="pobierzDaneDoEkranu(true);pokazWyszukiwarkeMobile(false)"
                *ngIf="czyPokazanoWyszukiwarkeMobile">Szukaj</button>


            <button class="btn__clear--filter mt-2 mb-4" (click)="wyczyscFiltry();pokazWyszukiwarkeMobile(false)"
                *ngIf="czyPokazacWyczyscFiltry()">Wyczyść
                filtry</button>


            <div class="no-results text-center ft__no-results" *ngIf="!czySaWynikiNaLiscie(pomieszczenia)">
                <div>Brak pomieszczeń<br>dla wybranych filtrów</div>

            </div>

            <div class="box__mobile mb-3" *ngFor="let pomieszczenie of pomieszczenia"
                (click)="wybierzPomieszczenie(pomieszczenie.id)">
                <div class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border">
                    <span class="fs__pname--2">Nazwa</span>
                    <span class="fs__pname--3 text-lg2">{{pomieszczenie.nazwa}}</span>
                </div>
                <div class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border">
                    <span class="fs__pname--2">Piętro</span>
                    <span class="fs__pname--3 text-lg2">{{pomieszczenie.pietro}}</span>
                </div>

                <div class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border">
                    <span class="fs__pname--2">Spisane środki</span>
                    <span class="fs__pname--3 text-lg2">{{pomieszczenie.sumaSpisanychSrodkow}}</span>
                </div>
                <div class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border">
                    <span class="fs__pname--2">Sparowane</span>
                    <span class="fs__pname--3 text-lg2">{{pomieszczenie.sparowaneSrodki}}</span>
                </div>
                <div class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border">
                    <span class="fs__pname--2">Utworzył</span>
                    <span class="fs__pname--3 text-lg2">{{pomieszczenie.utworzyl}}</span>
                </div>
            </div>


            <div class="paginate float-right d-flex align-items-center pb-2 pt-lg-4"
                *ngIf="czySaWynikiNaLiscie(pomieszczenia)">
                <div>Pokaż wyniki</div>
                <select class="paginate__select mx-2" name="" id="" (change)="zmienRozmiarTabeli()"
                    [(ngModel)]="rozmiarTabeli">
                    <option [ngValue]="25">25</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                </select>
                <div class="px-2">{{pobierzNumerRekorduPoczatek()}}-{{pobierzNumerRekorduKoniec()}} z
                    {{calkowitaLiczbaElementow}}</div>
                <div class="d-flex">
                    <button class="btn__left" *ngIf="this.wyszukiwanie.stronicowanie.strona>1"
                        (click)="zmienStrone(-1)"></button>
                    <div class="px-2">{{this.wyszukiwanie.stronicowanie.strona}}</div>
                    <button class="btn__right" *ngIf="czyJestWiecejRekordow" (click)="zmienStrone(1)"></button>
                </div>
            </div>
        </div>

        <div class="input_container pb-3" style="margin-top: 65px;">
            <button class="btn__border btn__border--mini" (click)="dodajPomieszczenie()" style="width: auto;
    margin: 0 auto;
    display: block;">Dodaj pomieszczenie</button>
        </div>
        <div class="input_container pb-3">
            <button class="btn__border btn__border--mini" (click)="activeModal.dismiss()" style="width: auto;
    margin: 0 auto;
    display: block;">Anuluj</button>
        </div>
    </div>
</div>