import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Routing } from 'src/app/modele/wspolne/routing';
import { ZmienHasloModel } from 'src/app/modele/zmianaHasla/zmienHasloModel';
import { ZmienHasloService } from 'src/app/serwisy/konto/zmien-haslo.service';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { BazowyComponent } from '../../bazowy.component';

@Component({
  selector: 'app-zmiana-hasla-nowe-haslo',
  templateUrl: './zmiana-hasla-nowe-haslo.component.html'
})
export class ZmianaHaslaNoweHasloComponent extends BazowyComponent implements OnInit {

  public token: string;
  public zmienHasloModel = new ZmienHasloModel();
  public czyPokazacHaslo = false;

  constructor(
    public authService: AuthService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private zmienHasloSerwis: ZmienHasloService,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    private komunikatSerwis: ToastrService,
    public translate: TranslateService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translate);

  }

  ngOnInit(): void {
    setTimeout(() => {
      this.token = this.activatedRoute.snapshot.paramMap.get('token');
      if (!this.token) {
        this.router.navigate([Routing.Zaloguj]);
      }
      this.inicjujJezyk();
    });
  }

  public zmienHaslo() {
    this.zmienHasloModel.kod = this.token;
    this.zmienHasloSerwis.zmienHaslo(this.zmienHasloModel).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        if (this.czyZalogowany()) {
          this.wyswietlKomunikat(this.pobierzTlumaczenie("zmianaHasla.hasloZostaloZmienione"));
        }
        else {
          this.wyswietlKomunikat(this.pobierzTlumaczenie("zmianaHasla.hasloZostaloZmienioneZalogujSie"));
        }
        this.router.navigate([Routing.Zaloguj]);
        return;
      } else {
        this.bledyWalidacji = rezultat.bledyModelu;
        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    });
  }
  pokazHaslo() {
    this.czyPokazacHaslo = !this.czyPokazacHaslo;
  }
}
