import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tak-nie-modal',
  templateUrl: './tak-nie-modal.component.html',
  styleUrls: ['./tak-nie-modal.component.css']
})
export class TakNieModalComponent implements OnInit {

  naglowek: string;
  pytanie: string;

  constructor(
    public router: Router,
    public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  tak() {
    this.activeModal.close(true);
  }
  nie() {
    this.activeModal.close();
  }
}
