import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Lokalizacja } from 'src/app/modele/projekty/lokalizacje/lokalizacja';
import { PobierzLokalizacjeFiltr } from 'src/app/modele/projekty/lokalizacje/pobierzLokalizacjeFiltr';
import { StatusProjektu } from 'src/app/modele/projekty/statusProjektu';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { Routing } from 'src/app/modele/wspolne/routing';
import {
  KierunekSortowania,
  Sortowanie,
} from 'src/app/modele/wspolne/sortowanie';
import { Stronicowanie } from 'src/app/modele/wspolne/stronicowanie';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { LokalizacjeService } from 'src/app/serwisy/projekty/lokalizacje.service';
import { ProjektyService } from 'src/app/serwisy/projekty/projekty.service';
import { BazowyComponent } from '../../bazowy.component';
import { ImportLokalizacjiModalComponent } from './import-lokalizacji-modal/import-lokalizacji-modal.component';
import { Subscription } from 'rxjs';
import { EdytujLokalizacjeModalComponent } from './edytuj-lokalizacje-modal/edytuj-lokalizacje-modal.component';

@Component({
  selector: 'app-lokalizacje',
  templateUrl: './lokalizacje.component.html',
  styleUrls: ['./lokalizacje.component.css'],
  providers: [DatePipe],
})
export class LokalizacjeComponent extends BazowyComponent implements OnInit {
  idProjektu: string;
  czyZaladowanoDane: boolean;
  calkowitaLiczbaElementow: BigInteger;
  rozmiarTabeli: number = 25;
  czyJestWiecejRekordow: boolean = true;
  czyPokazacMenuSortowanie: boolean;
  wyszukiwanie: RejestrFiltrowanyRequest<PobierzLokalizacjeFiltr> =
    new RejestrFiltrowanyRequest<PobierzLokalizacjeFiltr>();
  lokalizacje: Array<Lokalizacja> = new Array();
  nazwaProjektu: string;
  czyPokazanoWyszukiwarkeMobile: boolean;
  statusProjektu: StatusProjektu;
  subsription: Subscription | undefined;

  slideConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: document.getElementsByClassName('btn__left'),
    nextArrow: document.getElementsByClassName('btn__right'),
    infinite: false,
    variableWidth: true,
  };

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    private datePipe: DatePipe,
    public lokalizacjeSerwis: LokalizacjeService,
    public projektySerwis: ProjektyService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translateSerwis
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.idProjektu = this.activatedRoute.snapshot.paramMap.get('id');
      this.wyszukiwanie.filtry = new PobierzLokalizacjeFiltr();
      this.wyszukiwanie.filtry.projektId = this.idProjektu;
      this.wyszukiwanie.sortowanie = new Sortowanie(
        'lp',
        KierunekSortowania.asc
      );
      this.wyszukiwanie.stronicowanie = new Stronicowanie(false);
      this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
      this.wyszukiwanie.stronicowanie.strona = 1;

      this.pobierzDaneDoEkranu(true);
    });
  }

  pobierzDaneDoEkranu(zLadowaczem: boolean) {
    this.subsription = this.lokalizacjeSerwis
      .pobierzLokalizacje(this.wyszukiwanie, zLadowaczem)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.lokalizacje = rezultat.element.lokalizacje;
          this.calkowitaLiczbaElementow =
            rezultat.element.calkowitaIloscRekordow;
          this.czyJestWiecejRekordow = rezultat.element.czyJestWiecejRekordow;
          this.nazwaProjektu = rezultat.element.nazwaProjektu;
          this.statusProjektu = rezultat.element.statusProjektu;

          this.czyZaladowanoDane = true;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  zmienRozmiarTabeli() {
    this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
    this.pobierzDaneDoEkranu(true);
  }

  pobierzNumerRekorduPoczatek() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return this.wyszukiwanie.stronicowanie.rekordPoczatkowy + 1;
  }
  pobierzNumerRekorduKoniec() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return (
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy + this.lokalizacje.length
    );
  }

  zmienStrone(oIle: number) {
    this.wyszukiwanie.stronicowanie.strona += oIle;
    this.wyszukiwanie.stronicowanie.rekordPoczatkowy =
      (this.wyszukiwanie.stronicowanie.strona - 1) *
      this.wyszukiwanie.stronicowanie.iloscRekordow;
    this.pobierzDaneDoEkranu(true);
  }

  pokazMenuSortowania() {
    this.czyPokazacMenuSortowanie = !this.czyPokazacMenuSortowanie;
  }

  pobierzKierunekSortowania(kolumna: string) {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      return '';
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      return 'up';
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      return 'down';
    }
  }

  zmienKierunekSortowania(kolumna: string) {
    this.czyPokazacMenuSortowanie = false;
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.desc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        'Id',
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
  }

  pobierzKolumneSortowania() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    switch (kierunki[0].nazwaKolumny) {
      case 'lp':
        return 'Lp.';
      case 'ulica':
        return 'Ulica';
      case 'miasto':
        return 'Miasto';
      case 'kodPocztowy':
        return 'Kod pocztowy';
      case 'mpk':
        return 'MPK';
      case 'osobaOdpowiedzialna':
        return 'Osoba odpowiedzialna';
      case 'numerTelefonu':
        return 'Numer telefonu';
      case 'rodzajLokalizacji':
        return 'Rodzaj lokalizacji';
      case 'numerZespolu':
        return 'Numer zespołu';
    }
  }

  pobierzKierunekSortowaniaMobile() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    if (kierunki[0].kierunekSortowania == KierunekSortowania.asc) {
      return 'up';
    }
    if (kierunki[0].kierunekSortowania == KierunekSortowania.desc) {
      return '';
    }
  }

  zmienKierunekSortowaniaMobile() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki[0];

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kierunek.nazwaKolumny,
        KierunekSortowania.desc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kierunek.nazwaKolumny,
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
  }
  wyczyscSortowanieMobile() {
    this.wyszukiwanie.sortowanie = new Sortowanie('Id', KierunekSortowania.asc);
    this.pobierzDaneDoEkranu(true);
  }

  cofnij() {
    this.router.navigate([Routing.Spis, this.idProjektu]);
  }

  pokazModalImportu() {
    this.modalReference = this.modalService.open(
      ImportLokalizacjiModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.idProjektu = this.idProjektu;
    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.pobierzDaneDoEkranu(true);
        }
      },
      (onRejected) => {}
    );
  }

  filtrujPoTekscie(wartosc: string) {
    this.subsription?.unsubscribe();
    this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
    this.wyszukiwanie.stronicowanie.strona = 1;
    this.pobierzDaneDoEkranu(false);
  }

  czyPokazacWyczyscFiltry() {
    return (
      this.wyszukiwanie.filtry.lp ||
      this.wyszukiwanie.filtry.kodPocztowy ||
      this.wyszukiwanie.filtry.mPK ||
      this.wyszukiwanie.filtry.ulica ||
      this.wyszukiwanie.filtry.miasto ||
      this.wyszukiwanie.filtry.osobaOdpowiedzialna ||
      this.wyszukiwanie.filtry.numerTelefonu ||
      this.wyszukiwanie.filtry.numerZespolu ||
      this.wyszukiwanie.filtry.rodzajLokalizacji
    );
  }

  wyczyscFiltry() {
    this.wyszukiwanie.filtry.lp = '';
    this.wyszukiwanie.filtry.kodPocztowy = '';
    this.wyszukiwanie.filtry.mPK = '';
    this.wyszukiwanie.filtry.ulica = '';
    this.wyszukiwanie.filtry.miasto = '';
    this.wyszukiwanie.filtry.osobaOdpowiedzialna = '';
    this.wyszukiwanie.filtry.numerTelefonu = '';
    this.wyszukiwanie.filtry.numerZespolu = '';
    this.wyszukiwanie.filtry.rodzajLokalizacji = '';

    this.pobierzDaneDoEkranu(true);
  }

  pokazWyszukiwarkeMobile(czyPokazac: boolean) {
    this.czyPokazanoWyszukiwarkeMobile = czyPokazac;
  }

  czyMoznaEdytowacLokalizacje() {
    return this.statusProjektu != StatusProjektu.zarchiwizowany;
  }

  edytujLokalizacje(id: string) {
    this.modalReference = this.modalService.open(
      EdytujLokalizacjeModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.id = id;
    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.pobierzDaneDoEkranu(true);
        }
      },
      (onRejected) => {}
    );
  }
}
