<div *ngIf="czyZaladowanoDane">
    <div class="container--main d-none d-md-block">
        <div class="ft__sort text-center pt-4">{{dane.nazwaProjektu}}</div>

        <div class="d-flex py-3">
            <div class="project__menu">
                <a routerLink="/project-details/{{idProjektu}}">Projekt</a>
                <a routerLink="/inventory/{{idProjektu}}">Spis</a>
                <a routerLink="/locations/{{idProjektu}}">Lokalizacje</a>
                <a routerLink="/client-data/{{idProjektu}}">Ewidencja klienta</a>
                <a class="active" routerLink="/project-dictionaries/{{idProjektu}}" *ngIf="czyAdmin()">Słowniki</a>
                <a routerLink="/teams/{{idProjektu}}" *ngIf="czyAdmin()">Zespoły</a>
                <a routerLink="/inventory-fields/{{idProjektu}}" *ngIf="czyAdmin()">Pola spisu</a>
            </div>
        </div>
    </div>
    <div class="container ">

        <div class="pt-3 mt-1 d-md-none">
            <div class="ft__sort text-center">{{dane.nazwaProjektu}}</div>
        </div>



        <div class="d-fledx py-3 d-md-none">
            <div class="project__menu">
                <button class="btn__left"></button>
                <ngx-slick-carousel class="carousel menu_slider" #slickModal="slick-carousel" [config]="slideConfig">

                    <div ngxSlickItem class="slide"><a routerLink="/project-details/{{idProjektu}}">Projekt</a></div>
                    <div ngxSlickItem class="slide"><a routerLink="/inventory/{{idProjektu}}">Spis</a></div>
                    <div ngxSlickItem class="slide"><a routerLink="/locations/{{idProjektu}}">Lokalizacje</a></div>
                    <div ngxSlickItem class="slide"><a routerLink="/client-data/{{idProjektu}}">Ewidencja klienta</a>
                    </div>
                    <div ngxSlickItem class="slide" *ngIf="czyAdmin()"><a class="active"
                            routerLink="/project-dictionaries/{{idProjektu}}">Słowniki</a></div>
                    <div ngxSlickItem class="slide" *ngIf="czyAdmin()"> <a
                            routerLink="/teams/{{idProjektu}}">Zespoły</a></div>
                    <div ngxSlickItem class="slide" *ngIf="czyAdmin()"><a
                            routerLink="/inventory-fields/{{idProjektu}}">Pola
                            spisu</a></div>
                </ngx-slick-carousel>
                <button class="btn__right"></button>
            </div>
        </div>

        <div class="md-white-box" *ngIf="czySaWynikiNaLiscie(dane.slowniki)">
            <button class="btn__border mb-5" (click)="dodajSlownik()">Dodaj słownik</button>


            <button class="btn__slownik mb-3" *ngFor="let slownik of dane.slowniki"
                (click)="pokazSzczegolySlownika(slownik.id)">{{slownik.nazwa}}</button>
        </div>
        <button class="btn__border mb-3 mt-3" (click)="dodajSlownik()" *ngIf="!czySaWynikiNaLiscie(dane.slowniki)">Dodaj
            słownik</button>

        <div class="ft__03 text-gray text-center py-5" style="color: #757575 !important;"
            *ngIf="!czySaWynikiNaLiscie(dane.slowniki)">
            Brak zapisanych<br>słowników
        </div>



    </div>
    <div class="bootom__buttons px-4">
        <div class="container--mw600 flex-column flex-sm-row d-flex justify-content-between align-items-center">
            <button class="btn__link order-1 order-sm-0" (click)="cofnij()">Cofnij</button>
            <button class="btn__blue order-0 order-sm-1" (click)="dalej()">Dalej</button>
        </div>
    </div>

</div>