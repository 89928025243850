import { RodzajPolaPomieszczenia } from "./rodzajPolaPomieszczenia";

export class KonfiguracjaPomieszczen {
    projektId: string;
    nazwaRodzajPola: RodzajPolaPomieszczenia;
    pietroRodzajPola: RodzajPolaPomieszczenia;
    mpkRodzajPola: RodzajPolaPomieszczenia;
    opisDodatkowyRodzajPola: RodzajPolaPomieszczenia;
    osobaRodzajPola: RodzajPolaPomieszczenia;
    inneRodzajPola: RodzajPolaPomieszczenia;
    nazwaSlownikId: string;
    pietroSlownikId: string;
    mpkSlownikId: string;
    opisDodatkowySlownikId: string;
    osobaSlownikId: string;
    inneSlownikId: string;
    nazwaProjektu: string;

}