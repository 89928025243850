import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appTylkoPelneLiczby]'
})
export class TylkoPelneLiczbyDirective {

  public text;

  private specialKeys: Array<string> = ['Backspace', 'Tab'];

  constructor(private el: ElementRef) {
  }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('keypress', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);

    if (parseInt(next) <= 0) {
      event.preventDefault();
    }

    if (event.charCode < 48 || event.charCode > 57) {
      event.preventDefault();
    }
  }

}
