import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor(private loadingService: LoadingService) { }

  //public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  pokazLadowacz(czyPokazac: boolean) {
    this.loadingService.loadingSub.next(czyPokazac);
    //this.status.next(czyPokazac);
  }
}
