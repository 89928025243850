export class PobierzPomieszczeniaFiltr {
  nazwa: string;
  pietro: string;
  lokalizacjaId: string;
  sumaSpisanychSrodkow: string;
  sparowaneSrodki: string;
  utworzyl: string;
  opis: string;
  osoba: string;
  inne: string;
  mpk: string;
  czyPobracPomieszczeniaOswiadczen: boolean = true;
}
