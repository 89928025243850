import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { PobierzPomieszczeniaFiltr } from 'src/app/modele/projekty/pomieszczenia/pobierzPomieszczeniaFiltr';
import { Pomieszczenie } from 'src/app/modele/projekty/pomieszczenia/pomieszczenie';
import { StatusProjektu } from 'src/app/modele/projekty/statusProjektu';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import {
  Sortowanie,
  KierunekSortowania,
} from 'src/app/modele/wspolne/sortowanie';
import { Stronicowanie } from 'src/app/modele/wspolne/stronicowanie';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { EksportDanychService } from 'src/app/serwisy/projekty/eksport-danych.service';
import { PomieszczeniaService } from 'src/app/serwisy/projekty/pomieszczenia.service';
import { DodajPomieszczenieModalComponent } from '../dodaj-pomieszczenie-modal/dodaj-pomieszczenie-modal.component';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { TakNieModalComponent } from 'src/app/komponenty/ogolne/tak-nie-modal/tak-nie-modal.component';
import { PrzeniesSrodkiModel } from 'src/app/modele/projekty/spis/przeniesSrodkiModel';
import { SpisService } from 'src/app/serwisy/projekty/spis.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-przenies-srodki-pomieszczenia-modal',
  templateUrl: './przenies-srodki-pomieszczenia-modal.component.html',
  styleUrls: ['./przenies-srodki-pomieszczenia-modal.component.css'],
})
export class PrzeniesSrodkiPomieszczeniaModalComponent
  extends BazowyComponent
  implements OnInit
{
  idProjektu: string;
  idLokalizacji: string;
  czyZaladowanoDane: boolean;
  calkowitaLiczbaElementow: BigInteger;
  rozmiarTabeli: number = 25;
  czyJestWiecejRekordow: boolean = true;
  czyPokazacMenuSortowanie: boolean;
  wyszukiwanie: RejestrFiltrowanyRequest<PobierzPomieszczeniaFiltr> =
    new RejestrFiltrowanyRequest<PobierzPomieszczeniaFiltr>();
  pomieszczenia: Array<Pomieszczenie> = new Array();
  adresLokalizacji: string;
  czyPokazanoWyszukiwarkeMobile: boolean;
  nazwaProjektu: string;
  czyPokazanoPodmenu: boolean;
  statusProjektu: StatusProjektu;
  czyPrzenoszeniePomieszczen: boolean;
  czyZaznaczonoWszystko: boolean;
  pomieszczenieAktualne: Pomieszczenie;
  idSrodkow: Array<string>;
  subsription: Subscription | undefined;

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public pomieszczeniaSerwis: PomieszczeniaService,
    public translateSerwis: TranslateService,
    private spisSerwis: SpisService,
    public activeModal: NgbActiveModal,
    public eksportDanychSerwis: EksportDanychService
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translateSerwis
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.wyszukiwanie.filtry = new PobierzPomieszczeniaFiltr();
      this.wyszukiwanie.filtry.lokalizacjaId = this.idLokalizacji;
      this.wyszukiwanie.filtry.czyPobracPomieszczeniaOswiadczen = false;
      this.wyszukiwanie.sortowanie = new Sortowanie(
        'nazwa',
        KierunekSortowania.asc
      );
      this.wyszukiwanie.stronicowanie = new Stronicowanie(false);
      this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
      this.wyszukiwanie.stronicowanie.strona = 1;

      this.pobierzDaneDoEkranu(true);
    });
  }

  pobierzDaneDoEkranu(zLadowaczem: boolean) {
    this.subsription = this.pomieszczeniaSerwis
      .pobierzPomieszczeniaLokalizacji(this.wyszukiwanie, zLadowaczem)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.pomieszczenia = rezultat.element.pomieszczenia;
          this.calkowitaLiczbaElementow =
            rezultat.element.calkowitaIloscRekordow;
          this.czyJestWiecejRekordow = rezultat.element.czyJestWiecejRekordow;
          this.adresLokalizacji = rezultat.element.adresLokalizacji;
          this.nazwaProjektu = rezultat.element.nazwaProjektu;
          this.statusProjektu = rezultat.element.statusProjektu;

          this.czyZaladowanoDane = true;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  zmienRozmiarTabeli() {
    this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
    this.pobierzDaneDoEkranu(true);
  }

  pobierzNumerRekorduPoczatek() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return this.wyszukiwanie.stronicowanie.rekordPoczatkowy + 1;
  }
  pobierzNumerRekorduKoniec() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return (
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy +
      this.pomieszczenia.length
    );
  }

  zmienStrone(oIle: number) {
    this.wyszukiwanie.stronicowanie.strona += oIle;
    this.wyszukiwanie.stronicowanie.rekordPoczatkowy =
      (this.wyszukiwanie.stronicowanie.strona - 1) *
      this.wyszukiwanie.stronicowanie.iloscRekordow;
    this.pobierzDaneDoEkranu(true);
  }

  pokazMenuSortowania() {
    this.czyPokazacMenuSortowanie = !this.czyPokazacMenuSortowanie;
  }

  pobierzKierunekSortowania(kolumna: string) {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      return '';
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      return 'up';
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      return 'down';
    }
  }

  zmienKierunekSortowania(kolumna: string) {
    this.czyPokazacMenuSortowanie = false;
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.desc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        'Id',
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
  }

  pobierzKolumneSortowania() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    switch (kierunki[0].nazwaKolumny) {
      case 'nazwa':
        return 'Nazwa';
      case 'pietro':
        return 'Piętro';
      case 'sumaSpisanychSrodkow':
        return 'Spisane środki';
      case 'sparowaneSrodki':
        return 'Sparowane';
      case 'utworzyl':
        return 'Utworzył';
    }
  }

  pobierzKierunekSortowaniaMobile() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    if (kierunki[0].kierunekSortowania == KierunekSortowania.asc) {
      return 'up';
    }
    if (kierunki[0].kierunekSortowania == KierunekSortowania.desc) {
      return '';
    }
  }

  zmienKierunekSortowaniaMobile() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki[0];

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kierunek.nazwaKolumny,
        KierunekSortowania.desc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kierunek.nazwaKolumny,
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
  }
  wyczyscSortowanieMobile() {
    this.wyszukiwanie.sortowanie = new Sortowanie('Id', KierunekSortowania.asc);
    this.pobierzDaneDoEkranu(true);
  }

  filtrujPoTekscie() {
    this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
    this.wyszukiwanie.stronicowanie.strona = 1;
    this.subsription?.unsubscribe();
    this.pobierzDaneDoEkranu(false);
  }

  czyPokazacWyczyscFiltry() {
    return (
      this.wyszukiwanie.filtry.nazwa ||
      this.wyszukiwanie.filtry.pietro ||
      this.wyszukiwanie.filtry.sparowaneSrodki ||
      this.wyszukiwanie.filtry.sumaSpisanychSrodkow ||
      this.wyszukiwanie.filtry.utworzyl
    );
  }

  wyczyscFiltry() {
    this.wyszukiwanie.filtry.nazwa = '';
    this.wyszukiwanie.filtry.pietro = '';
    this.wyszukiwanie.filtry.sparowaneSrodki = null;
    this.wyszukiwanie.filtry.sumaSpisanychSrodkow = null;
    this.wyszukiwanie.filtry.utworzyl = '';

    this.pobierzDaneDoEkranu(true);
  }

  pokazWyszukiwarkeMobile(czyPokazac: boolean) {
    this.czyPokazanoWyszukiwarkeMobile = czyPokazac;
    this.czyPokazanoPodmenu = false;
  }

  dodajPomieszczenie() {
    var modalReference = this.modalService.open(
      DodajPomieszczenieModalComponent,
      new ModalOpcje(true, 'static')
    );
    modalReference.componentInstance.idLokalizacji = this.idLokalizacji;
    modalReference.componentInstance.czyPrzenoszenieSrodkow = true;
    modalReference.componentInstance.pomieszczenieAktualneNazwa =
      this.pomieszczenieAktualne.nazwa;
    modalReference.result.then(
      (pomieszczenie) => {
        if (pomieszczenie) {
          this.przeniesSrodki(pomieszczenie.id, pomieszczenie.nazwa);
        }
      },
      (onRejected) => {}
    );
  }

  czyProjektZarchiwizowany() {
    return this.statusProjektu == StatusProjektu.zarchiwizowany;
  }

  wybierzPomieszczenie(id: string) {
    var pomieszczenieDocelowe = this.pomieszczenia.filter((m) => m.id == id)[0];
    var komunikat = `Czy na pewno chcesz przenieść środki z ${this.pomieszczenieAktualne.nazwa} do ${pomieszczenieDocelowe.nazwa}?`;

    var modalReference = this.modalService.open(
      TakNieModalComponent,
      new ModalOpcje(true, 'static')
    );
    modalReference.componentInstance.pytanie = komunikat;
    modalReference.result.then(
      (result) => {
        if (result) {
          this.przeniesSrodki(id);
        }
      },
      (onRejected) => {}
    );
  }

  przeniesSrodki(idPomieszczeniaDocelowego: string, nazwaNowego?: string) {
    var nazwaDocelowego = nazwaNowego
      ? nazwaNowego
      : this.pomieszczenia.filter((m) => m.id == idPomieszczeniaDocelowego)[0]
          .nazwa;
    var model = new PrzeniesSrodkiModel();
    model.pomieszczenieAktualneId = this.pomieszczenieAktualne.id;
    model.pomieszczenieDoceloweId = idPomieszczeniaDocelowego;
    model.srodkiId = this.idSrodkow;

    this.spisSerwis.przeniesSrodki(model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.wyswietlKomunikat(
          `Środki zostały przeniesione do pomieszczenia ${nazwaDocelowego}`
        );
        this.activeModal.close(true);
        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }
}
