import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RequestBazowy } from 'src/app/modele/wspolne/requestBazowy';
import { ResponseBazowy } from 'src/app/modele/wspolne/responseBazowy';
import { ZmienHasloMailModel } from 'src/app/modele/zmianaHasla/zmienHasloMailModel';
import { ZmienHasloModel } from 'src/app/modele/zmianaHasla/zmienHasloModel';
import { SerwisBazowy } from '../bazowy.service';
import { AuthService } from '../ogolne/auth.service';
import { ErrorService } from '../ogolne/error.service';
import { LoadingService } from '../ogolne/loading.service';

@Injectable({
  providedIn: 'root'
})
export class ZmienHasloService extends SerwisBazowy {

  constructor(
    httpClient: HttpClient,
    authService: AuthService,
    errorService: ErrorService,
    loaderService: LoadingService,
    translate: TranslateService) {
    super(httpClient, authService, errorService, loaderService, translate);
  }

  zmienHasloMail(model: ZmienHasloMailModel) {
    return this.wykonajAkcje<ZmienHasloMailModel, ResponseBazowy>(this.AdresApi + "ZmienHaslo/ZmienHasloMail", model);

  }

  zmienHaslo(model: ZmienHasloModel) {
    return this.wykonajAkcje<ZmienHasloModel, ResponseBazowy>(this.AdresApi + "ZmienHaslo/ZmienHaslo", model);

  }
  zmienHasloZalogowanyMail() {
    return this.wykonajAkcjeBezModelu<ResponseBazowy>(this.AdresApi + "ZmienHaslo/ZmienHasloZalogowanyMail");

  }
}
