import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UstawieniaSystemu } from 'src/app/modele/administracja/ustawieniaSystemu';
import { ZmienHasloDoSkrzynkiModel } from 'src/app/modele/administracja/zmienHasloDoSkrzynkiModel';
import { PobierzUzytkownikowFiltr } from 'src/app/modele/uzytkownik/pobierzUzytkownikowFiltr';
import { Uzytkownik } from 'src/app/modele/uzytkownik/uzytkownik';
import { PojedynczyElementResponse } from 'src/app/modele/wspolne/pojedynczyElement';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { ResponseBazowy } from 'src/app/modele/wspolne/responseBazowy';
import { StronicowanaListaWynikowResponse } from 'src/app/modele/wspolne/stronicowanaListaWynikowResponse';
import { SerwisBazowy } from '../bazowy.service';
import { AuthService } from '../ogolne/auth.service';
import { ErrorService } from '../ogolne/error.service';
import { LoadingService } from '../ogolne/loading.service';

@Injectable({
  providedIn: 'root'
})
export class UstawieniaService extends SerwisBazowy {


  constructor(
    httpClient: HttpClient,
    authService: AuthService,
    errorService: ErrorService,
    loaderService: LoadingService,
    translate: TranslateService) {
    super(httpClient, authService, errorService, loaderService, translate);
  }

  pobierzUstawienia() {
    return this.get<PojedynczyElementResponse<UstawieniaSystemu>>(this.AdresApi + "Ustawienia/PobierzUstawienia");
  }

  zapiszUstawienia(model: UstawieniaSystemu) {
    return this.wykonajAkcje<UstawieniaSystemu, ResponseBazowy>(this.AdresApi + "Ustawienia/ZapiszUstawienia", model);

  }

  zmienHasloDoSkrzynki(model: ZmienHasloDoSkrzynkiModel) {
    return this.wykonajAkcje<ZmienHasloDoSkrzynkiModel, ResponseBazowy>(this.AdresApi + "Ustawienia/ZmienHasloDoSkrzynki", model);

  }
}
