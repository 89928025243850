export class Pomieszczenie {
  nazwa: string;
  pietro: string;
  id: string;
  sumaSpisanychSrodkow: number;
  sparowaneSrodki: number;
  utworzyl: string;
  czyWybrano: boolean;
  opis: string;
  osoba: string;
  inne: string;
  mpk: string;
  czyPomieszczenieOswiadczen: boolean;
}
