export enum WymagalnoscPolaSpisu {
  niewymagane,
  wymagane,
  wymaganeJezeliInnePolePuste,
  wymaganeJezeliWInnymPoluWartoscZeSlownika,
  wymaganeJezeliInnePoleNieJestPuste,
}

export enum WymagalnoscPolaSpisuOperator {
  brak,
  i,
  lub,
}
