<div *ngIf="czyZaladowanoDane">
  <div class="container--main d-none d-md-block">
    <div class="ft__sort text-center pt-4">{{ nazwaProjektu }}</div>

    <div class="d-flex pt-3">
      <div class="project__menu" *ngIf="!czyPracownikKlienta()">
        <a routerLink="/project-details/{{ idProjektu }}">Projekt</a>
        <a class="active" routerLink="/inventory/{{ idProjektu }}">Spis</a>
        <a routerLink="/locations/{{ idProjektu }}">Lokalizacje</a>
        <a routerLink="/client-data/{{ idProjektu }}">Ewidencja klienta</a>
        <a
          routerLink="/project-dictionaries/{{ idProjektu }}"
          *ngIf="czyAdmin()"
          >Słowniki</a
        >
        <a routerLink="/teams/{{ idProjektu }}" *ngIf="czyAdmin()">Zespoły</a>
        <a routerLink="/inventory-fields/{{ idProjektu }}" *ngIf="czyAdmin()"
          >Pola spisu</a
        >
      </div>
    </div>
  </div>

  <div class="container-fluid d-none d-md-block pt-4">
    <div class="box__scroll py-3">
      <div
        class="box__scroll--top d-flex justify-content-between align-items-center mb-3"
      >
        <span class="ft__03">{{ adresLokalizacji }}</span>
        <div class="mt-2">
          <button class="btn__badge" (click)="pokazEkranPomieszczen()">
            Pomieszczenia
          </button>
          <button class="btn__badge" (click)="pokazEkranSpisanychSrodkow()">
            Lista spisanych środków
          </button>
          <button class="btn__badge" (click)="pokazEkranNadwyzek()">
            Nadwyżki
          </button>
          <button class="btn__badge active" (click)="pokazEkranNiedoborow()">
            Niedobory
          </button>
        </div>

        <div class="input_container">
          <button
            class="btn__clear--filter"
            (click)="wyczyscFiltry()"
            *ngIf="czyPokazacWyczyscFiltry()"
          >
            Wyczyść filtry
          </button>
          <button
            class="btn__paruj"
            (click)="paruj()"
            *ngIf="czyMoznaParowac()"
          >
            Paruj
          </button>
        </div>
      </div>
      <div class="tableFixHead tableFixHead--3">
        <table class="table">
          <thead>
            <tr>
              <th
                *ngFor="let kolumna of wyszukiwanie.filtry.kolumnyFiltrowania"
                [ngClass]="czyKolumnaLp(kolumna.kolumna) ? 'lp' : ''"
              >
                {{ kolumna.kolumna }}
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania(kolumna.kolumna)"
                  (click)="zmienKierunekSortowania(kolumna.kolumna)"
                ></button>
                <div
                  class="input_container input_container--mini"
                  [ngClass]="czyKolumnaLp(kolumna.kolumna) ? 'lp' : ''"
                >
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="kolumna.wartoscSzukaj"
                    [ngClass]="czyKolumnaLp(kolumna.kolumna) ? 'lp' : ''"
                    (keyup)="filtrujPoTekscie($event, kolumna.kolumna)"
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="
                      !czyPokazacPrzyciskCzyszczeniaFiltru(kolumna.kolumna)
                    "
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="czyPokazacPrzyciskCzyszczeniaFiltru(kolumna.kolumna)"
                    (click)="wyczyscFiltrKolumny(kolumna.kolumna)"
                  ></button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody class="fz12" *ngIf="czySaWynikiNaLiscie(rekordyEwidencji)">
            <tr *ngFor="let wiersz of rekordyEwidencji">
              <td
                *ngFor="let wartosc of wiersz; index as i"
                [ngClass]="i == kolumnaId ? 'id' : ''"
              >
                <div *ngIf="i == kolumnaKomentarza; else elseBlock">
                  <div class="d-inline-block">{{ wartosc }}</div>
                  <button
                    *ngIf="!czyPracownikKlienta()"
                    class="btn__edit btn__edit--min"
                    (click)="dodajKomentarz(wiersz, wartosc)"
                  ></button>
                </div>

                <ng-template #elseBlock>{{ wartosc }}</ng-template>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="ft__03 text-gray text-center my-5 py-5"
          *ngIf="!czySaWynikiNaLiscie(rekordyEwidencji)"
        >
          Brak rekordów dla wybranych filtrów.
        </div>
      </div>
    </div>
    <div
      class="paginate float-right d-flex align-items-center pt-4 pb-2 pt-lg-2 mt-1"
      *ngIf="czySaWynikiNaLiscie(rekordyEwidencji)"
    >
      <div>Pokaż wyniki</div>
      <select
        class="paginate__select mx-2"
        name=""
        id=""
        (change)="zmienRozmiarTabeli()"
        [(ngModel)]="rozmiarTabeli"
      >
        <option [ngValue]="25">25</option>
        <option [ngValue]="50">50</option>
        <option [ngValue]="100">100</option>
      </select>
      <div class="px-2">
        {{ pobierzNumerRekorduPoczatek() }}-{{ pobierzNumerRekorduKoniec() }} z
        {{ calkowitaLiczbaElementow }}
      </div>
      <div class="d-flex">
        <button
          class="btn__left"
          *ngIf="this.wyszukiwanie.stronicowanie.strona > 1"
          (click)="zmienStrone(-1)"
        ></button>
        <div class="px-2">{{ this.wyszukiwanie.stronicowanie.strona }}</div>
        <button
          class="btn__right"
          *ngIf="czyJestWiecejRekordow"
          (click)="zmienStrone(1)"
        ></button>
      </div>
    </div>
  </div>
  <div class="container-fluid d-md-none pb-5">
    <div class="pt-3 mt-1">
      <div class="ft__sort text-center">{{ nazwaProjektu }}</div>
    </div>

    <div class="d-fledx py-3">
      <div class="project__menu" *ngIf="!czyPracownikKlienta()">
        <button class="btn__left"></button>
        <ngx-slick-carousel
          class="carousel menu_slider"
          #slickModal="slick-carousel"
          [config]="slideConfig"
        >
          <div ngxSlickItem class="slide">
            <a routerLink="/project-details/{{ idProjektu }}">Projekt</a>
          </div>
          <div ngxSlickItem class="slide">
            <a class="active" routerLink="/inventory/{{ idProjektu }}">Spis</a>
          </div>
          <div ngxSlickItem class="slide">
            <a routerLink="/locations/{{ idProjektu }}">Lokalizacje</a>
          </div>
          <div ngxSlickItem class="slide">
            <a routerLink="/client-data/{{ idProjektu }}">Ewidencja klienta</a>
          </div>
          <div ngxSlickItem class="slide" *ngIf="czyAdmin()">
            <a routerLink="/project-dictionaries/{{ idProjektu }}">Słowniki</a>
          </div>
          <div ngxSlickItem class="slide" *ngIf="czyAdmin()">
            <a routerLink="/teams/{{ idProjektu }}">Zespoły</a>
          </div>
          <div ngxSlickItem class="slide" *ngIf="czyAdmin()">
            <a routerLink="/inventory-fields/{{ idProjektu }}">Pola spisu</a>
          </div>
        </ngx-slick-carousel>
        <button class="btn__right"></button>
      </div>
    </div>

    <div class="filter-box d-flex justify-content-between pt-3 mb-3">
      <span class="ft__03 mr-3">{{ adresLokalizacji }}</span>
    </div>

    <div
      class="input_container mb-3 text-right"
      *ngIf="!czyPokazanoWyszukiwarkeMobile"
    >
      <button class="btn__hamburger" (click)="pokazPodmenu()"></button>
      <button
        class="btn__search mx-2"
        (click)="pokazWyszukiwarkeMobile(true)"
      ></button>
    </div>

    <div
      class="box__mobile box__mobile--form mb-3 py-5 d-flex flex-column"
      *ngIf="czyPokazanoPodmenu"
    >
      <button class="btn__badge mb-4 py-2" (click)="pokazEkranPomieszczen()">
        Pomieszczenia
      </button>
      <button
        class="btn__badge mb-4 py-2"
        (click)="pokazEkranSpisanychSrodkow()"
      >
        Lista spisanych środków
      </button>
      <button class="btn__badge mb-4 py-2" (click)="pokazEkranNadwyzek()">
        Nadwyżki
      </button>
      <button
        class="btn__badge mb-4 py-2 active"
        (click)="pokazEkranNiedoborow()"
      >
        Niedobory
      </button>
      <button
        class="btn__badge mb-4 py-2"
        (click)="paruj()"
        *ngIf="czyMoznaParowac()"
      >
        Paruj
      </button>
    </div>
    <div
      class="searchBox d-flex flex-wrap por pb-5"
      *ngIf="czyPokazanoWyszukiwarkeMobile"
    >
      <button
        class="btn__input--clear"
        (click)="pokazWyszukiwarkeMobile(false)"
      ></button>
    </div>

    <div
      class="box__mobile box__mobile--form mb-3"
      *ngIf="czyPokazanoWyszukiwarkeMobile"
    >
      <div
        class="input_container mb-3"
        *ngFor="let kolumna of wyszukiwanie.filtry.kolumnyFiltrowania"
      >
        <input
          class="pr-4"
          type="text"
          placeholder="{{ kolumna.kolumna }}"
          [(ngModel)]="kolumna.wartoscSzukaj"
        />
        <button
          class="btn__input--search"
          *ngIf="!czyPokazacPrzyciskCzyszczeniaFiltru(kolumna.kolumna)"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="czyPokazacPrzyciskCzyszczeniaFiltru(kolumna.kolumna)"
          (click)="wyczyscFiltrKolumny(kolumna.kolumna)"
        ></button>
      </div>
    </div>

    <button
      class="btn__border mt-4 mb-4"
      (click)="pobierzDaneDoEkranu(true); pokazWyszukiwarkeMobile(false)"
      *ngIf="czyPokazanoWyszukiwarkeMobile"
    >
      Szukaj
    </button>

    <button
      class="btn__clear--filter mt-2 mb-4"
      (click)="wyczyscFiltry(); pokazWyszukiwarkeMobile(false)"
      *ngIf="czyPokazacWyczyscFiltry()"
    >
      Wyczyść filtry
    </button>
    <div
      class="box__mobile box__mobile--table mb-3"
      *ngIf="czySaWynikiNaLiscie(rekordyEwidencji)"
    >
      <div class="fixed_table_container">
        <table>
          <tr>
            <th *ngFor="let kolumna of wyszukiwanie.filtry.kolumnyFiltrowania">
              <div class="d-flex">
                {{ kolumna.kolumna
                }}<button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania(kolumna.kolumna)"
                  (click)="zmienKierunekSortowania(kolumna.kolumna)"
                ></button>
              </div>
            </th>
          </tr>
          <tr *ngFor="let wiersz of rekordyEwidencji">
            <!-- <td *ngFor="let wartosc of wiersz; index as i" [ngClass]="i==kolumnaId?'id':''">
                            {{wartosc}}
                        </td> -->

            <td
              *ngFor="let wartosc of wiersz; index as i"
              [ngClass]="i == kolumnaId ? 'id' : ''"
            >
              <div *ngIf="i == kolumnaKomentarza; else elseBlock">
                <div class="d-inline-block">{{ wartosc }}</div>
                <button
                  class="btn__edit btn__edit--min"
                  (click)="dodajKomentarz(wiersz, wartosc)"
                ></button>
              </div>

              <ng-template #elseBlock>{{ wartosc }}</ng-template>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div
      class="paginate float-right d-flex align-items-center pb-2 pt-lg-4"
      *ngIf="czySaWynikiNaLiscie(rekordyEwidencji)"
    >
      <div>Pokaż wyniki</div>
      <select
        class="paginate__select mx-2"
        name=""
        id=""
        (change)="zmienRozmiarTabeli()"
        [(ngModel)]="rozmiarTabeli"
      >
        <option [ngValue]="25">25</option>
        <option [ngValue]="50">50</option>
        <option [ngValue]="100">100</option>
      </select>
      <div class="px-2">
        {{ pobierzNumerRekorduPoczatek() }}-{{ pobierzNumerRekorduKoniec() }} z
        {{ calkowitaLiczbaElementow }}
      </div>
      <div class="d-flex">
        <button
          class="btn__left"
          *ngIf="this.wyszukiwanie.stronicowanie.strona > 1"
          (click)="zmienStrone(-1)"
        ></button>
        <div class="px-2">{{ this.wyszukiwanie.stronicowanie.strona }}</div>
        <button
          class="btn__right"
          *ngIf="czyJestWiecejRekordow"
          (click)="zmienStrone(1)"
        ></button>
      </div>
    </div>
    <div
      class="ft__03 text-gray text-center py-5"
      *ngIf="!czySaWynikiNaLiscie(rekordyEwidencji)"
    >
      Brak rekordów <br />dla wybranych filtrów.
    </div>
    <button
      class="btn__primary mt-3"
      (click)="wygenerujArkusz()"
      *ngIf="!czyPracownikKlienta()"
    >
      Generuj raport
    </button>
    <button
      class="btn__border btn__border--mini px-3 mt-3"
      (click)="eksportujDane()"
    >
      Eksportuj dane
    </button>
    <button class="btn__link order-1 order-sm-0" (click)="cofnij()">
      Cofnij
    </button>
  </div>

  <div class="bootom__buttons px-4 d-none d-md-block">
    <div
      class="container--mw600 flex-column flex-sm-row d-flex justify-content-between align-items-center"
    >
      <button class="btn__link order-1 order-sm-0" (click)="cofnij()">
        Cofnij
      </button>
      <button
        class="btn__border btn__border--mini px-3"
        (click)="eksportujDane()"
      >
        Eksportuj dane</button
      ><button
        class="btn__blue order-0 order-sm-1"
        (click)="wygenerujArkusz()"
        *ngIf="!czyPracownikKlienta()"
      >
        Generuj raport
      </button>
    </div>
  </div>
</div>
