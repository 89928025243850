import { KolumnaEwidencjiFiltrowanie } from '../ewidencja/kolumnaEwidencjiFiltrowanie';

export class SzukajWEwidencjiKrzyzowoModel {
  projektId: string;
  wartosci: Array<WartoscDoSzukaniaKrzyzowego> = new Array();
}

export class WartoscDoSzukaniaKrzyzowego {
  poleSpisuId: string;
  wartoscSzukaj: string;
}
