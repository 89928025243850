import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { SzczegolySlownika } from 'src/app/modele/projekty/slowniki/szczegolySlownika';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { SlownikiService } from 'src/app/serwisy/projekty/slowniki.service';

@Component({
  selector: 'app-tabela-slownika-modal',
  templateUrl: './tabela-slownika-modal.component.html',
  styleUrls: ['./tabela-slownika-modal.component.css']
})
export class TabelaSlownikaModalComponent extends BazowyComponent implements OnInit {

  idSlownika: string;
  czyZaladowanoDane: boolean;
  szczegolySlownika: SzczegolySlownika;

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    public activeModal: NgbActiveModal,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public slownikiSerwis: SlownikiService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translateSerwis);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.szczegolySlownika.listaKolumn.length
    });
  }


}
