<div class="modal-content" *ngIf="czyZaladowanoDane">
  <div class="modal-body">
    <button
      aria-label="Close"
      class="close"
      data-dismiss="modal"
      type="button"
      (click)="activeModal.dismiss()"
    ></button>
    <div class="ft__01 text-center py-4 mb-3">
      {{ "edycjaUzytkownika.naglowek" | translate }}
    </div>

    <form action="">
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('imie') ? 'error' : ''"
      >
        <input
          placeholder="{{ 'imie' | translate }}"
          type="text"
          name="imie"
          [(ngModel)]="model.imie"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('imie')"
        >
          {{ pobierzBladWalidacyjny("imie") }}
        </div>
      </div>
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('nazwisko') ? 'error' : ''"
      >
        <input
          placeholder="{{ 'nazwisko' | translate }}"
          type="text"
          name="nazwisko"
          [(ngModel)]="model.nazwisko"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('nazwisko')"
        >
          {{ pobierzBladWalidacyjny("nazwisko") }}
        </div>
      </div>
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('email') ? 'error' : ''"
      >
        <input
          placeholder="E-mail"
          type="text"
          name="email"
          [(ngModel)]="model.email"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('email')"
        >
          {{ pobierzBladWalidacyjny("email") }}
        </div>
      </div>
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('numerTelefonu') ? 'error' : ''"
      >
        <input
          placeholder="{{ 'numerTelefonu' | translate }}"
          type="text"
          name="numerTelefonu"
          [(ngModel)]="model.numerTelefonu"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('numerTelefonu')"
        >
          {{ pobierzBladWalidacyjny("numerTelefonu") }}
        </div>
      </div>
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('uprawnienie') ? 'error' : ''"
      >
        <select
          [(ngModel)]="model.uprawnienie"
          name="uprawnienie"
          (change)="wybranoUprawnienie()"
        >
          <option [ngValue]="undefined" [disabled]="true">
            {{ "rola" | translate }}
          </option>
          <option [ngValue]="0">{{ "wykonawca" | translate }}</option>
          <option [ngValue]="1" selected>
            {{ "administrator" | translate }}
          </option>
          <option [ngValue]="3" selected>Pracownik klienta</option>
        </select>
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('uprawnienie')"
        >
          {{ pobierzBladWalidacyjny("uprawnienie") }}
        </div>
      </div>

      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('projektId') ? 'error' : ''"
        *ngIf="model.uprawnienie == 3"
      >
        <ng-select
          placeholder="Projekt"
          [searchable]="true"
          [clearable]="true"
          [multiple]="false"
          [items]="projekty"
          [(ngModel)]="model.projektId"
          bindValue="id"
          bindLabel="nazwa"
          [ngModelOptions]="{ standalone: true }"
          [searchFn]="customSearchFn"
          [editableSearchTerm]="false"
          notFoundText="Brak projektu"
        >
        </ng-select>

        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('projektId')"
        >
          {{ pobierzBladWalidacyjny("projektId") }}
        </div>
      </div>

      <div class="input_container pb-2 mt-4">
        <button class="btn__primary" (click)="zapisz()">
          {{ "zapisz" | translate }}
        </button>
      </div>
      <div class="f-item pt-2">
        <button
          *ngIf="szczegoly.czyAktywny"
          class="btn__deactivate btn__empty mb-3"
          (click)="dezaktywuj()"
        >
          {{ "dezaktywuj" | translate }}
        </button>
        <button
          *ngIf="!szczegoly.czyAktywny"
          type="button"
          class="btn__activate mb-3 btn__empty"
          (click)="cofnijDezaktywacje()"
        >
          {{ "cofnijDezaktywacje" | translate }}
        </button>
        <button
          *ngIf="!szczegoly.czyAktywny"
          type="button"
          [routerLink]=""
          class="btn__deactivate mb-3 btn__empty"
          (click)="usun()"
        >
          {{ "usun" | translate }}
        </button>
        <button
          class="btn__send_link btn__empty mb-3"
          *ngIf="!szczegoly.czyKontoPotwierdzone"
          (click)="wyslijLinkAktywacyjnyPonownie()"
        >
          {{ "wyslijLinkAktywacyjny" | translate }}
        </button>
      </div>
      <div class="input_container pb-3">
        <button class="btn__link" (click)="activeModal.dismiss()">
          {{ "anuluj" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

<!-- <div class="modal-content" *ngIf="czyZaladowanoDane">
    <div class="modal_box">

        <div class="close" (click)="activeModal.dismiss()"></div>

        <div class="text-center">
            <p class="heading">{{'edycjaUzytkownika.naglowek' | translate}}</p>
        </div>

        <form action="">
            <div class="f-item pb-3" [ngClass]="czyBladWalidacyjny('imie')?'error':''">
                <input placeholder="{{'imie' | translate}}" type="text" name="imie" [(ngModel)]="model.imie" />
                <div class="error_info text-right" [hidden]="!czyBladWalidacyjny('imie')">
                    {{ pobierzBladWalidacyjny("imie") }}
                </div>
            </div>
            <div class="f-item pb-3" [ngClass]="czyBladWalidacyjny('nazwisko')?'error':''">
                <input placeholder="{{'nazwisko' | translate}}" type="text" name="nazwisko"
                    [(ngModel)]="model.nazwisko" />
                <div class="error_info text-right" [hidden]="!czyBladWalidacyjny('nazwisko')">
                    {{ pobierzBladWalidacyjny("nazwisko") }}
                </div>
            </div>
            <div class="f-item pb-3" [ngClass]="czyBladWalidacyjny('email')?'error':''">
                <input placeholder="E-mail" type="text" name="email" [(ngModel)]="model.email" />
                <div class="error_info text-right" [hidden]="!czyBladWalidacyjny('email')">
                    {{ pobierzBladWalidacyjny("email") }}
                </div>
            </div>
            <div class="f-item pb-3" [ngClass]="czyBladWalidacyjny('numerTelefonu')?'error':''">
                <input placeholder="{{'numerTelefonu' | translate}}" type="text" name="numerTelefonu"
                    [(ngModel)]="model.numerTelefonu" />
                <div class="error_info text-right" [hidden]="!czyBladWalidacyjny('numerTelefonu')">
                    {{ pobierzBladWalidacyjny("numerTelefonu") }}
                </div>
            </div>
            <div class="f-item pb-3" [ngClass]="czyBladWalidacyjny('uprawnienie')?'error':''">
                <select [(ngModel)]="model.uprawnienie" name="uprawnienie">
                    <option [ngValue]="undefined" [disabled]="true">{{'rola' | translate}}</option>
                    <option [ngValue]="1">{{'projektant'|translate}}</option>
                    <option [ngValue]="2">{{'wsparcieProjektanta'|translate}}</option>
                    <option [ngValue]="3" selected>{{'administrator'|translate}}</option>
                </select>
                <div class="error_info text-right" [hidden]="!czyBladWalidacyjny('uprawnienie')">
                    {{ pobierzBladWalidacyjny("uprawnienie") }}
                </div>
            </div>
            <div class="f-item pt-2 center">
                <button class="btn__primary" (click)="zapisz()">{{'zapisz' | translate}}</button>
            </div>
            <div class="f-item pt-2">
                <button *ngIf="szczegoly.czyAktywny" class="btn__primary btn__empty mb-2"
                    (click)="dezaktywuj()">{{'dezaktywuj' | translate}}</button>
                <button *ngIf="!szczegoly.czyAktywny" type="button" class="btn__primary mb-2 btn__empty"
                    (click)="cofnijDezaktywacje()">{{'cofnijDezaktywacje' | translate}}</button>
                <button *ngIf="!szczegoly.czyAktywny" type="button" [routerLink]="" class="btn__primary mb-2 btn__empty"
                    (click)="usun()">{{'usun' | translate}}</button>
                <button class="btn__primary btn__empty" *ngIf="!szczegoly.czyKontoPotwierdzone"
                    (click)="wyslijLinkAktywacyjnyPonownie()">{{'wyslijLinkAktywacyjny' | translate}}</button>
            </div>
            <div class="f-item pt-2 center">
                <button class="btn__primary btn__empty" (click)="activeModal.dismiss()">{{'anuluj' |
                    translate}}</button>
            </div>
        </form>

    </div>
</div> -->
