import { AfterViewInit, Component, OnInit } from '@angular/core';
import { JitCompilerFactory } from '@angular/platform-browser-dynamic';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ZmienJezykModel } from 'src/app/modele/profil/zmienJezykModel';
import { Ciasteczko } from 'src/app/modele/wspolne/ciasteczka';
import { Jezyk } from 'src/app/modele/wspolne/jezyk';
import { Routing } from 'src/app/modele/wspolne/routing';
import { Uprawnienie } from 'src/app/modele/wspolne/uprawnienie';
import { LogowanieService } from 'src/app/serwisy/konto/logowanie.service';
import { ProfilService } from 'src/app/serwisy/konto/profil.service';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { BazowyComponent } from '../bazowy.component';

@Component({
  selector: 'app-nawigacja',
  templateUrl: './nawigacja.component.html'
})
export class NawigacjaComponent extends BazowyComponent implements OnInit, AfterViewInit {

  czyPokazacMenuMobile: boolean;
  czyPokazacMenuKontekstowe: boolean;

  constructor(
    private logowanieSerwis: LogowanieService,
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    private komunikatSerwis: ToastrService,
    public authService: AuthService,
    public translate: TranslateService,
    public kontoSerwis: ProfilService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translate);
  }


  ngOnInit(): void {
    setTimeout(() => {
      this.pobierzDaneUzytkownika();
    });
  }

  public pobierzDaneUzytkownika(): void {
    this.logowanieSerwis.pobierzDaneZalogowanegoUzytkownika().subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.authService.ustawCiasteczko(Ciasteczko.DaneZalogowanegoUzytkownika, rezultat.element);
          if (rezultat.element.jezyk == Jezyk.pl) {
            this.translate.use('pl-PL');
          }
          if (rezultat.element.jezyk == Jezyk.en) {
            this.translate.use('en-US');
          }
          return;
        }
      }
    );
  }

  pobierzInicjalyUzytkownika() {
    var imie = this.authService.getUserName();
    var nazwisko = this.authService.getUserLastName();

    return imie.slice(0, 1) + nazwisko.slice(0, 1);
  }

  wyloguj() {
    this.authService.logOut();
  }

  pokazMenuMobile() {
    this.czyPokazacMenuMobile = true;
  }
  schowajMenuMobile() {
    this.czyPokazacMenuMobile = false;
  }

  pokazMenuKontekstowe() {
    this.czyPokazacMenuKontekstowe = !this.czyPokazacMenuKontekstowe;
  }

  czyWykonawca() {
    var daneUzytkownika = this.authService.pobierzDaneZalogowanegoUzytkownika();
    return daneUzytkownika.uprawnienie == Uprawnienie.wykonawca;
  }

  czyPokazacZakladkeAdministracja() {
    var daneUzytkownika = this.authService.pobierzDaneZalogowanegoUzytkownika();
    return daneUzytkownika.uprawnienie == Uprawnienie.administrator;
  }
  kliknijPozaMenu(e: Event) {
    if (!this.czyPokazacMenuKontekstowe) {
      return;
    }
    this.czyPokazacMenuKontekstowe = false;
  }
}
