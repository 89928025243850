import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { DodajPoleSpisuModel } from 'src/app/modele/projekty/polaSpisu/dodajPoleSpisuModel';
import { RodzajPolaSpisu } from 'src/app/modele/projekty/polaSpisu/rodzajPolaSpisu';
import { WidocznoscPolaNaOswiadczeniu } from 'src/app/modele/projekty/polaSpisu/widocznoscPolaNaOswiadczeniu';
import { WymagalnoscPolaSpisu } from 'src/app/modele/projekty/polaSpisu/wymagalnoscPolaSpisu';
import { ComboBox } from 'src/app/modele/wspolne/comboBox';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { PolaSpisuService } from 'src/app/serwisy/projekty/pola-spisu.service';
import { SlownikiService } from 'src/app/serwisy/projekty/slowniki.service';

@Component({
  selector: 'app-nowe-pole-modal',
  templateUrl: './nowe-pole-modal.component.html',
  styleUrls: ['./nowe-pole-modal.component.css'],
})
export class NowePoleModalComponent extends BazowyComponent implements OnInit {
  idProjektu: string;
  model: DodajPoleSpisuModel = new DodajPoleSpisuModel();
  slowniki: Array<ComboBox> = new Array();
  kolumny: Array<ComboBox> = new Array();
  slownikiWymagalnosci: Array<ComboBox> = new Array();
  polaSpisuWymagalnosci: Array<ComboBox> = new Array();
  separatoryKoduQr = [',', ';', '.', '-', '/'];

  constructor(
    public router: Router,
    modalService: NgbModal,
    public activeModal: NgbActiveModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public polaSpisuSerwis: PolaSpisuService,
    public slownikiSerwis: SlownikiService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translateSerwis
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.model.projektId = this.idProjektu;
      this.model.widocznoscPolaNaOswiadczeniu =
        WidocznoscPolaNaOswiadczeniu.widoczneNaOswiadczeniuIProjekcie;
    });
  }

  zapisz() {
    this.polaSpisuSerwis.dodajPole(this.model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.wyswietlKomunikat('Pole zostało dodane');
        this.activeModal.close(true);
        return;
      }
      if (this.bladModelu(rezultat)) {
        this.bledyWalidacji = rezultat.bledyModelu;
        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  wybranoRodzajPola() {
    this.model.slownikId = undefined;
    this.model.kolumnaSlownika = undefined;
    if (this.model.rodzajPolaSpisu == RodzajPolaSpisu.slownikJednokolumnowy) {
      this.pobierzSlownikiJednokolumnowe();
    }
    if (this.model.rodzajPolaSpisu == RodzajPolaSpisu.slownikWielokolumnowy) {
      this.pobierzSlownikiWielokolumnowe();
    }
    if (this.model.rodzajPolaSpisu == RodzajPolaSpisu.checkbox) {
      this.model.wymagalnosc = WymagalnoscPolaSpisu.niewymagane;
      this.model.wymagalnoscPolaSpisuId = Array();
      this.model.wymagalnoscSlownikId = undefined;
      this.model.wymagalnoscPolaSpisuOperator = 0;
    }
    if (this.model.rodzajPolaSpisu == RodzajPolaSpisu.inne) {
      this.model.czyMaskaWlaczonaNaOswiadczeniu = true;
    }
  }

  pobierzSlownikiJednokolumnowe(czyDoWymagalnosci: boolean = false) {
    var model = new IdModel();
    model.id = this.model.projektId;
    this.slownikiSerwis
      .pobierzSlownikiJednokolumnowe(model)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          if (czyDoWymagalnosci) {
            this.slownikiWymagalnosci = rezultat.listaElementow;
          } else {
            this.slowniki = rezultat.listaElementow;
          }
          return;
        }
        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  pobierzSlownikiWielokolumnowe() {
    var model = new IdModel();
    model.id = this.idProjektu;
    this.slownikiSerwis
      .pobierzSlownikiWielokolumnowe(model)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.slowniki = rezultat.listaElementow;
          return;
        }
        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  pobierzKolumnySlownika() {
    var model = new IdModel();
    model.id = this.model.slownikId;
    this.polaSpisuSerwis.pobierzKolumnySlownika(model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.kolumny = rezultat.listaElementow;
        return;
      }
      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  wybranoSlownik() {
    this.model.kolumnaSlownika = undefined;
    if (this.model.rodzajPolaSpisu != RodzajPolaSpisu.slownikWielokolumnowy) {
      return;
    }
    this.pobierzKolumnySlownika();
  }

  wybranoWymagalnosc() {
    if ([0, 1].includes(this.model.wymagalnosc)) {
      return;
    }
    if ([2, 3, 4].includes(this.model.wymagalnosc)) {
      this.model.wymagalnoscPolaSpisuId = Array();
      this.model.wymagalnoscPolaSpisuOperator = 0;
      this.pobierzPolaSpisuDoWymagalnosci();
    }
    if (this.model.wymagalnosc == 3) {
      this.model.wymagalnoscSlownikId = undefined;
      this.pobierzSlownikiJednokolumnowe(true);
    }
  }

  pobierzPolaSpisuDoWymagalnosci() {
    var model = new IdModel();
    model.id = this.model.projektId;
    this.polaSpisuSerwis.pobierzPola(model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.polaSpisuWymagalnosci = rezultat.element.pola
          .filter((m) => ![0, 7].includes(m.rodzajPola))
          .map((m) => ({
            id: m.id,
            nazwa: m.nazwaPola,
          }));
        return;
      }
      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }
}
