import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ZespolyProjektu } from 'src/app/modele/projekty/zespoly/zespolyProjektu';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { Routing } from 'src/app/modele/wspolne/routing';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { ZespolyService } from 'src/app/serwisy/projekty/zespoly.service';
import { BazowyComponent } from '../../bazowy.component';
import { DodajZespolModalComponent } from './dodaj-zespol-modal/dodaj-zespol-modal.component';
import { EdytujZespolModalComponent } from './edytuj-zespol-modal/edytuj-zespol-modal.component';

@Component({
  selector: 'app-zespoly',
  templateUrl: './zespoly.component.html',
  styleUrls: ['./zespoly.component.css']
})
export class ZespolyComponent extends BazowyComponent implements OnInit {

  idProjektu: string;
  czyZaladowanoDane: boolean;
  dane: ZespolyProjektu = new ZespolyProjektu();

  slideConfig = {
    "slidesToShow": 2,
    "slidesToScroll": 1,
    "prevArrow": document.getElementsByClassName('btn__left'),
    "nextArrow": document.getElementsByClassName('btn__right'),
    "infinite": false,
    "variableWidth": true,
  };
  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public zespolySerwis: ZespolyService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translateSerwis);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.idProjektu = this.activatedRoute.snapshot.paramMap.get('id');
      this.pobierzDaneDoEkranu();
    });
  }

  pobierzDaneDoEkranu() {
    var model = new IdModel();
    model.id = this.idProjektu;

    this.zespolySerwis.pobierzZespoly(model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.dane = rezultat.element;

          this.czyZaladowanoDane = true;

          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }

  dodajZespol() {
    this.modalReference = this.modalService.open(DodajZespolModalComponent, new ModalOpcje(true, 'static'));
    this.modalReference.componentInstance.idProjektu = this.idProjektu;
    this.modalReference.result.then((result) => {
      if (result) {
        this.pobierzDaneDoEkranu();
      }
    }, onRejected => { });
  }

  cofnij() {
    this.router.navigate([Routing.SlownikiProjektu, this.idProjektu]);
  }

  dalej() {
    this.router.navigate([Routing.PolaSpisu, this.idProjektu]);
  }

  pokazSzczegoly(id: string) {
    this.modalReference = this.modalService.open(EdytujZespolModalComponent, new ModalOpcje(true, 'static'));
    this.modalReference.componentInstance.idZespolu = id;
    this.modalReference.componentInstance.idProjektu = this.idProjektu;
    this.modalReference.result.then((result) => {
      if (result) {
        this.pobierzDaneDoEkranu();
      }
    }, onRejected => { });
  }

  pobierzLiczbeWykonawcowZespolu(liczbaWykonawcow: number) {
    return liczbaWykonawcow == 0 ? "" : "(" + liczbaWykonawcow + ")";
  }

}
