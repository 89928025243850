<div class="modal-content">
  <div class="modal-body">
    <button
      aria-label="Close"
      class="close"
      data-dismiss="modal"
      type="button"
      (click)="activeModal.dismiss()"
    ></button>
    <div class="text-center mb-2"></div>

    <div class="fixed_table_container">
      <table>
        <tr>
          <th>Data edycji</th>
          <th>Edytował</th>
          <th>Akcja</th>
          <th>Poprzednie dane</th>
          <th>Dane po edycji</th>
        </tr>
        <tr *ngFor="let wiersz of historia" class="pointer project-row">
          <td>
            {{ wiersz.dataEdycji }}
          </td>
          <td>
            {{ wiersz.edytowal }}
          </td>
          <td>
            {{ wiersz.akcja }}
          </td>
          <td>
            <div [innerText]="wiersz.poprzednieDane"></div>
          </td>
          <td>
            <div [innerText]="wiersz.danePoZmianie"></div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
