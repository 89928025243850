<nav class="navbar">
  <div class="navbar__container">
    <div class="navbrand d-flex align-items-center justify-content-center">
      <a class="brand" [routerLink]="">
        <img src="assets/img/logo.svg" alt="" />
      </a>
    </div>
  </div>
</nav>

<div
  class="container container--login"
  *ngIf="czyMoznaDodawacOswiadczenia && zaladowanoDane"
>
  <div class="ft__01 text-center py-4 mt-3">
    Oświadczenia {{ nazwaKlienta }}
  </div>
  <div class="text-center pb-4 mb-2">Wprowadź swój adres e-mail</div>
  <form (ngSubmit)="potwierdz()">
    <div
      class="input_container pb-3"
      [ngClass]="czyBladWalidacyjny('email') ? 'error' : ''"
    >
      <input
        placeholder="E-mail"
        type="email"
        name="email"
        [(ngModel)]="model.email"
      />
      <div
        class="error-info fs__error pt-2"
        *ngIf="czyBladWalidacyjny('email')"
      >
        {{ pobierzBladWalidacyjny("email") }}
      </div>
    </div>
    <div class="input_container pb-3">
      <button class="btn__primary">Potwierdź</button>
    </div>
    <hr />
  </form>
</div>
<div
  class="container container--login"
  *ngIf="!czyMoznaDodawacOswiadczenia && zaladowanoDane"
>
  <div class="ft__01 text-center py-4 mt-3">
    Wypełnianie oświadczeń nie jest możliwe
  </div>
</div>
