import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DodatkowyImportLokalizacjiModel } from 'src/app/modele/projekty/lokalizacje/dodatkowyImportLokalizacjiModel';
import { DodatkowyImportLokalizacjiRezultat } from 'src/app/modele/projekty/lokalizacje/dodatkowyImportLokalizacjiRezultat';
import { ImportujLokalizacjeModel } from 'src/app/modele/projekty/lokalizacje/importujLokalizacjeModel';
import { ImportujLokalizacjeRezultat } from 'src/app/modele/projekty/lokalizacje/importujLokalizacjeRezultat';
import { LokalizacjeProjektu } from 'src/app/modele/projekty/lokalizacje/lokalizacjeProjektu';
import { NowaLokalizacjaModel } from 'src/app/modele/projekty/lokalizacje/nowaLokalizacjaModel';
import { PobierzLokalizacjeFiltr } from 'src/app/modele/projekty/lokalizacje/pobierzLokalizacjeFiltr';
import { LokalizacjeDoSpisu } from 'src/app/modele/projekty/spis/lokalizacjeDoSpisu';
import { PobierzLokalizacjeDoSpisuFiltr } from 'src/app/modele/projekty/spis/pobierzLokalizacjeDoSpisuFiltr';
import { PojedynczyElementResponse } from 'src/app/modele/wspolne/pojedynczyElement';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { ResponseBazowy } from 'src/app/modele/wspolne/responseBazowy';
import { SerwisBazowy } from '../bazowy.service';
import { AuthService } from '../ogolne/auth.service';
import { ErrorService } from '../ogolne/error.service';
import { LoadingService } from '../ogolne/loading.service';
import { EdytujLokalizacjeModel } from 'src/app/modele/projekty/lokalizacje/edytujLokalizacjeModel';
import { IdModel } from 'src/app/modele/wspolne/id.model';

@Injectable({
  providedIn: 'root',
})
export class LokalizacjeService extends SerwisBazowy {
  constructor(
    httpClient: HttpClient,
    authService: AuthService,
    errorService: ErrorService,
    loaderService: LoadingService,
    translate: TranslateService
  ) {
    super(httpClient, authService, errorService, loaderService, translate);
  }

  public pobierzLokalizacje(
    model: RejestrFiltrowanyRequest<PobierzLokalizacjeFiltr>,
    zLadowaczem: boolean
  ) {
    if (zLadowaczem) {
      return this.wykonajAkcje<
        RejestrFiltrowanyRequest<PobierzLokalizacjeFiltr>,
        PojedynczyElementResponse<LokalizacjeProjektu>
      >(this.AdresApi + 'Lokalizacje/PobierzLokalizacje', model);
    } else {
      return this.wykonajAkcjeBezLadowacza<
        RejestrFiltrowanyRequest<PobierzLokalizacjeFiltr>,
        PojedynczyElementResponse<LokalizacjeProjektu>
      >(this.AdresApi + 'Lokalizacje/PobierzLokalizacje', model);
    }
  }

  public importujLokalizacje(model: ImportujLokalizacjeModel) {
    return this.wykonajAkcje<
      ImportujLokalizacjeModel,
      PojedynczyElementResponse<ImportujLokalizacjeRezultat>
    >(this.AdresApi + 'Lokalizacje/ImportujLokalizacje', model);
  }

  public dodatkowyImportLokalizacji(model: DodatkowyImportLokalizacjiModel) {
    return this.wykonajAkcje<
      DodatkowyImportLokalizacjiModel,
      PojedynczyElementResponse<DodatkowyImportLokalizacjiRezultat>
    >(this.AdresApi + 'Lokalizacje/DodatkowyImportLokalizacji', model);
  }

  public dodajLokalizacjeRecznie(model: NowaLokalizacjaModel) {
    return this.wykonajAkcje<NowaLokalizacjaModel, ResponseBazowy>(
      this.AdresApi + 'Lokalizacje/DodajLokalizacjeRecznie',
      model
    );
  }

  public pobierzLokalizacjeDoSpisu(
    model: RejestrFiltrowanyRequest<PobierzLokalizacjeDoSpisuFiltr>,
    zLadowaczem: boolean
  ) {
    if (zLadowaczem) {
      return this.wykonajAkcje<
        RejestrFiltrowanyRequest<PobierzLokalizacjeDoSpisuFiltr>,
        PojedynczyElementResponse<LokalizacjeDoSpisu>
      >(this.AdresApi + 'Lokalizacje/PobierzLokalizacjeDoSpisu', model);
    } else {
      return this.wykonajAkcjeBezLadowacza<
        RejestrFiltrowanyRequest<PobierzLokalizacjeDoSpisuFiltr>,
        PojedynczyElementResponse<LokalizacjeDoSpisu>
      >(this.AdresApi + 'Lokalizacje/PobierzLokalizacjeDoSpisu', model);
    }
  }

  edytujLokalizacje(model: EdytujLokalizacjeModel) {
    return this.wykonajAkcje<EdytujLokalizacjeModel, ResponseBazowy>(
      this.AdresApi + 'Lokalizacje/EdytujLokalizacje',
      model
    );
  }

  pobierzSzczegolyLokalizacji(model: IdModel) {
    return this.wykonajAkcje<
      IdModel,
      PojedynczyElementResponse<EdytujLokalizacjeModel>
    >(this.AdresApi + 'Lokalizacje/PobierzSzczegolyDoEdycji', model);
  }

  usun(model: IdModel) {
    return this.wykonajAkcje<IdModel, ResponseBazowy>(
      this.AdresApi + 'Lokalizacje/UsunLokalizacje',
      model
    );
  }
}
