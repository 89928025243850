<nav class="navbar">
    <div class="navbar__container">
        <div class="navbrand d-flex align-items-center justify-content-center">
            <a class="brand" href="#">
                <img src="assets/img/logo.svg" alt="">
            </a>
        </div>
    </div>
</nav>

<div class="container container--login">
    <div class="ft__01 text-center py-4 mt-3">{{ 'zmianaHasla.naglowek' | translate}}</div>
    <div class="text-center pb-4 mb-2">{{ 'zmianaHasla.naglowek2' | translate}}</div>

    <form (ngSubmit)="zmienHaslo()">
        <div class="input_container pb-3" [ngClass]="czyBladWalidacyjny('noweHaslo')?'error':''">
            <input [type]="czyPokazacHaslo ? 'text' : 'password'" name="haslo" [(ngModel)]="zmienHasloModel.noweHaslo"
                placeholder="{{ 'zmianaHasla.noweHasloPlaceholder' | translate}}" />
            <button type="button" class="btn__input--eye" [ngClass]="czyPokazacHaslo? 'active':''"
                (click)=" pokazHaslo()"></button>
        </div>
        <div class="text-sm-center">
            <div class="login_info d-sm-inline-block pt-sm-1 pb-sm-1 fs__login--info mt-2 mb-3"
                [ngClass]="czyBladWalidacyjny('noweHaslo')?'error':''">
                {{ 'zmianaHasla.noweHasloWalidacja' |
                translate}}
            </div>
        </div>
    </form>
    <div class="input_container pb-3">
        <button class="btn__primary" (click)="zmienHaslo()">{{ 'zmianaHasla.zmienHaslo' | translate}}</button>
    </div>
    <hr>
    <div class="text-center pt-2 mt-1">
        {{ 'zmianaHasla.pamietaszHaslo' | translate}} <a class="" routerLink="/login"> {{
            'zmianaHasla.zalogujSie' | translate}}</a>
    </div>

</div>