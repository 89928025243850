import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DaneZalogowanegoUzytkownika } from 'src/app/modele/logowanie/daneZalogowanegoUzytkownika';
import { ZalogujModel } from 'src/app/modele/logowanie/zalogujModel';
import { ZalogujPrzezFbModel } from 'src/app/modele/logowanie/zalogujPrzezFbModel';
import { ZalogujPrzezFbRezultat } from 'src/app/modele/logowanie/zalogujPrzezFbRezultat';
import { ZalogujPrzezGoogleModel } from 'src/app/modele/logowanie/zalogujPrzezGoogleModel';
import { PojedynczyElementResponse } from 'src/app/modele/wspolne/pojedynczyElement';
import { RequestBazowy } from 'src/app/modele/wspolne/requestBazowy';
import { SerwisBazowy } from '../bazowy.service';
import { AuthService } from '../ogolne/auth.service';
import { ErrorService } from '../ogolne/error.service';
import { LoadingService } from '../ogolne/loading.service';

@Injectable({
  providedIn: 'root'
})
export class LogowanieService extends SerwisBazowy {

  constructor(
    httpClient: HttpClient,
    authService: AuthService,
    errorService: ErrorService,
    loaderService: LoadingService,
    translate: TranslateService) {
    super(httpClient, authService, errorService, loaderService, translate);
  }


  zalogujPrzezEmail(model: ZalogujModel) {
    return this.wykonajAkcje<ZalogujModel, PojedynczyElementResponse<DaneZalogowanegoUzytkownika>>(this.AdresApi + "logowanie/zaloguj", model);

  }

  pobierzDaneZalogowanegoUzytkownika() {
    return this.getBezLoadera<PojedynczyElementResponse<DaneZalogowanegoUzytkownika>>
      (this.AdresApi + "logowanie/PobierzDaneZalogowanegoUzytkownika");

  }
}
