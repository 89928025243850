import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { KolumnaSpisuFiltrowanie } from 'src/app/modele/projekty/spis/kolumnaSpisuFiltrowanie';
import { PobierzSpisaneSrodkiPomieszczeniaFiltry } from 'src/app/modele/projekty/spis/pobierzSpisaneSrodkiPomieszczeniaFiltry';
import { StatusProjektu } from 'src/app/modele/projekty/statusProjektu';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { Routing } from 'src/app/modele/wspolne/routing';
import {
  Sortowanie,
  KierunekSortowania,
} from 'src/app/modele/wspolne/sortowanie';
import { Stronicowanie } from 'src/app/modele/wspolne/stronicowanie';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { SpisService } from 'src/app/serwisy/projekty/spis.service';
import { PrzeniesSrodkiPomieszczeniaModalComponent } from '../przenies-srodki-pomieszczenia-modal/przenies-srodki-pomieszczenia-modal.component';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { Pomieszczenie } from 'src/app/modele/projekty/pomieszczenia/pomieszczenie';
import { Subscription } from 'rxjs';
import { TakNieModalComponent } from 'src/app/komponenty/ogolne/tak-nie-modal/tak-nie-modal.component';
import { UsunSrodkiModel } from 'src/app/modele/projekty/spis/usunSrodkiModel';

@Component({
  selector: 'app-spisane-srodki-pomieszczenia',
  templateUrl: './spisane-srodki-pomieszczenia.component.html',
  styleUrls: ['./spisane-srodki-pomieszczenia.component.css'],
})
export class SpisaneSrodkiPomieszczeniaComponent
  extends BazowyComponent
  implements OnInit
{
  idLokalizacji: string;
  idProjektu: string;
  idPomieszczenia: string;
  czyZaladowanoDane: boolean;
  calkowitaLiczbaElementow: bigint;
  rozmiarTabeli: number = 25;
  czyJestWiecejRekordow: boolean = true;
  czyPokazacMenuSortowanie: boolean;
  wyszukiwanie: RejestrFiltrowanyRequest<PobierzSpisaneSrodkiPomieszczeniaFiltry> =
    new RejestrFiltrowanyRequest<PobierzSpisaneSrodkiPomieszczeniaFiltry>();
  spisaneSrodki: Array<Array<string>> = new Array();
  kolumnyTabeli: Array<string> = new Array();
  czyPierwszeUruchomienie: boolean = true;
  nazwaProjektu: string;
  adresLokalizacji: string;
  czyPokazanoWyszukiwarkeMobile: boolean;
  czyPokazanoPodmenu: boolean;
  nazwaPomieszczenia: string;
  kolumnaId: number;
  kolumnaCzyMojSrodek: number;
  statusProjektu: StatusProjektu;
  czyPrzenoszenieSrodkow: boolean;
  czyUsuwanieSrodkow: boolean;
  czyZaznaczonoWszystko: boolean;
  wybraneSrodki: Array<string> = new Array();
  czyAdministrator: boolean;
  filtryZUrl: KolumnaSpisuFiltrowanie[] = new Array();
  czyPomieszczenieOswiadczen: boolean;

  slideConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: document.getElementsByClassName('btn__left'),
    nextArrow: document.getElementsByClassName('btn__right'),
    infinite: false,
    variableWidth: true,
    initialSlide: 3,
  };

  subsription: Subscription | undefined;

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public spisSerwis: SpisService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translateSerwis
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.czyAdministrator = this.czyAdmin();
      this.idLokalizacji =
        this.activatedRoute.snapshot.paramMap.get('idLokalizacji');
      this.idProjektu = this.activatedRoute.snapshot.paramMap.get('idProjektu');
      this.idPomieszczenia =
        this.activatedRoute.snapshot.paramMap.get('idPomieszczenia');
      this.wyszukiwanie.filtry = new PobierzSpisaneSrodkiPomieszczeniaFiltry();
      this.wyszukiwanie.sortowanie = new Sortowanie(
        'data_utworzenia',
        KierunekSortowania.asc
      );
      this.wyszukiwanie.stronicowanie = new Stronicowanie(false);
      this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
      this.wyszukiwanie.stronicowanie.strona = 1;

      this.pobierzFiltryZUrl();
      this.pobierzDaneDoEkranu(true);
    });
  }

  pobierzDaneDoEkranu(zLadowaczem: boolean) {
    this.zapiszFiltryWUrl();
    this.wyszukiwanie.filtry.idLokalizacji = this.idLokalizacji;
    this.wyszukiwanie.filtry.idPomieszczenia = this.idPomieszczenia;
    this.subsription = this.spisSerwis
      .pobierzSpisaneSrodkiPomieszczenia(this.wyszukiwanie, zLadowaczem)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.spisaneSrodki = rezultat.element.dane;
          this.calkowitaLiczbaElementow =
            rezultat.element.calkowitaIloscRekordow;
          this.czyJestWiecejRekordow = rezultat.element.czyJestWiecejRekordow;
          this.kolumnyTabeli = rezultat.element.kolumny;
          this.nazwaProjektu = rezultat.element.nazwaProjektu;
          this.adresLokalizacji = rezultat.element.adresLokalizacji;
          this.nazwaPomieszczenia = rezultat.element.nazwaPomieszczenia;
          this.czyPomieszczenieOswiadczen =
            rezultat.element.czyPomieszczenieOswiadczen;
          this.kolumnaId = this.kolumnyTabeli.indexOf('id');
          this.kolumnaCzyMojSrodek =
            this.kolumnyTabeli.indexOf('czy_moj_srodek');
          this.statusProjektu = rezultat.element.statusProjektu;

          if (
            this.spisaneSrodki.length == 0 &&
            !this.czyJestWiecejRekordow &&
            this.calkowitaLiczbaElementow > 0n
          ) {
            this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
            this.wyszukiwanie.stronicowanie.strona = 1;
            this.pobierzDaneDoEkranu(true);
            return;
          }
          if (this.czyPierwszeUruchomienie) {
            this.przygotujFiltry();
            this.czyPierwszeUruchomienie = false;
          }

          this.czyZaladowanoDane = true;
          this.anulujPrzenoszenieSrodkow();
          this.anulujUsuwanieSrodkow();
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  zmienRozmiarTabeli() {
    this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
    this.pobierzDaneDoEkranu(true);
  }

  pobierzNumerRekorduPoczatek() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return this.wyszukiwanie.stronicowanie.rekordPoczatkowy + 1;
  }
  pobierzNumerRekorduKoniec() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return (
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy +
      this.spisaneSrodki.length
    );
  }

  zmienStrone(oIle: number) {
    this.wyszukiwanie.stronicowanie.strona += oIle;
    this.wyszukiwanie.stronicowanie.rekordPoczatkowy =
      (this.wyszukiwanie.stronicowanie.strona - 1) *
      this.wyszukiwanie.stronicowanie.iloscRekordow;
    this.pobierzDaneDoEkranu(true);
  }

  cofnij() {
    this.router.navigate([
      Routing.PomieszczeniaLokalizacji,
      this.idProjektu,
      this.idLokalizacji,
    ]);
  }

  pobierzKierunekSortowania(kolumna: string) {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      return '';
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      return 'up';
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      return 'down';
    }
  }

  zmienKierunekSortowania(kolumna: string) {
    this.czyPokazacMenuSortowanie = false;
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.desc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        'id',
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
  }

  wyczyscFiltry() {
    this.wyszukiwanie.filtry.kolumnyFiltrowania.forEach((k) => {
      k.wartoscSzukaj = '';
    });
    this.pobierzDaneDoEkranu(true);
  }

  filtrujPoTekscie(event: any, kolumna: string) {
    this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
    this.wyszukiwanie.stronicowanie.strona = 1;
    var wartosc = event.target.value.toString();

    this.ustawWartoscFiltrowania(kolumna, wartosc);
    this.subsription?.unsubscribe();
    this.pobierzDaneDoEkranu(false);
  }

  ustawWartoscFiltrowania(kolumna: string, wartosc: string) {
    var kolumnaFiltrowania = this.wyszukiwanie.filtry.kolumnyFiltrowania.find(
      (m) => m.kolumna == kolumna
    );

    if (kolumnaFiltrowania == undefined) {
      kolumnaFiltrowania = new KolumnaSpisuFiltrowanie();
      kolumnaFiltrowania.kolumna = kolumna;
      kolumnaFiltrowania.wartoscSzukaj = wartosc;
      this.wyszukiwanie.filtry.kolumnyFiltrowania.push(kolumnaFiltrowania);
    } else {
      this.wyszukiwanie.filtry.kolumnyFiltrowania.forEach((k) => {
        if (k.kolumna == kolumna) {
          k.wartoscSzukaj = wartosc;
        }
      });
    }
  }

  czyPokazacPrzyciskCzyszczeniaFiltru(kolumna: string) {
    var kolumnaFiltrowania = this.wyszukiwanie.filtry.kolumnyFiltrowania.find(
      (m) => m.kolumna == kolumna
    );
    return kolumnaFiltrowania != undefined && kolumnaFiltrowania.wartoscSzukaj;
  }
  wyczyscFiltrKolumny(kolumna: string) {
    this.wyszukiwanie.filtry.kolumnyFiltrowania.forEach((k) => {
      if (k.kolumna == kolumna) {
        k.wartoscSzukaj = '';
      }
    });
    this.pobierzDaneDoEkranu(false);
  }

  przygotujFiltry() {
    this.wyszukiwanie.filtry.kolumnyFiltrowania = new Array();
    this.kolumnyTabeli.forEach((kolumna) => {
      if (kolumna == 'id' || kolumna == 'czy_moj_srodek') {
        return;
      }
      var kolumnaFiltrowania = new KolumnaSpisuFiltrowanie();
      kolumnaFiltrowania.kolumna = kolumna;
      kolumnaFiltrowania.wartoscSzukaj =
        this.filtryZUrl.find((m) => m.kolumna == kolumna)?.wartoscSzukaj || '';
      this.wyszukiwanie.filtry.kolumnyFiltrowania.push(kolumnaFiltrowania);
    });
  }

  czyPokazacWyczyscFiltry() {
    return (
      this.wyszukiwanie.filtry.kolumnyFiltrowania.filter((m) => m.wartoscSzukaj)
        .length > 0
    );
  }
  pokazWyszukiwarkeMobile(czyPokazac: boolean) {
    this.czyPokazanoWyszukiwarkeMobile = czyPokazac;
    this.czyPokazanoPodmenu = false;
  }

  pokazEkranNadwyzek() {
    this.router.navigate([
      Routing.NadwyzkiPomieszczenia,
      this.idProjektu,
      this.idLokalizacji,
      this.idPomieszczenia,
    ]);
  }

  pokazEkranSpisanychSrodkow() {
    this.router.navigate([
      Routing.SpisaneSrodkiPomieszczenia,
      this.idProjektu,
      this.idLokalizacji,
      this.idPomieszczenia,
    ]);
  }

  pokazPodmenu() {
    this.czyPokazanoPodmenu = !this.czyPokazanoPodmenu;
    this.czyPokazanoWyszukiwarkeMobile = false;
  }

  rozpocznijSpis() {
    this.router.navigate([
      Routing.SpisSrodka,
      this.idProjektu,
      this.idLokalizacji,
      this.idPomieszczenia,
    ]);
  }

  edytujSrodek(rekord: Array<string>) {
    var id = rekord[this.kolumnaId];
    if (this.czyPrzenoszenieSrodkow || this.czyUsuwanieSrodkow) {
      if (!this.czyMoznaZaznaczycSrodek(rekord)) {
        return;
      }
      if (this.wybraneSrodki.filter((m) => m == id).length) {
        this.czyZaznaczonoWszystko = false;
        this.wybraneSrodki = this.wybraneSrodki.filter((m) => m != id);
      } else {
        this.wybraneSrodki.push(id);
      }
      return;
    }
    this.router.navigate(
      [
        Routing.EdycjaSrodka,
        this.idProjektu,
        this.idLokalizacji,
        this.idPomieszczenia,
        id,
      ],
      {
        queryParams: this.budujQueryParams(),
        queryParamsHandling: '',
      }
    );
  }

  wygenerujArkusz() {
    this.router.navigate([
      '5',
      Routing.WyborRaportu,
      this.idProjektu,
      this.idLokalizacji,
      this.idPomieszczenia,
    ]);
  }
  czyProjektZarchiwizowany() {
    return this.statusProjektu == StatusProjektu.zarchiwizowany;
  }

  przeniesSrodki() {
    if (this.wybraneSrodki.length == 0) {
      this.wyswietlKomunikat('Wybierz środki do przeniesienia', true);
      return;
    }

    var aktualnePomieszczenie = new Pomieszczenie();
    aktualnePomieszczenie.id = this.idPomieszczenia;
    aktualnePomieszczenie.nazwa = this.nazwaPomieszczenia;

    this.modalReference = this.modalService.open(
      PrzeniesSrodkiPomieszczeniaModalComponent,
      new ModalOpcje(true, 'static', null, 'full-screen-width-modal')
    );
    this.modalReference.componentInstance.idProjektu = this.idProjektu;
    this.modalReference.componentInstance.idSrodkow = this.wybraneSrodki;
    this.modalReference.componentInstance.idLokalizacji = this.idLokalizacji;
    this.modalReference.componentInstance.pomieszczenieAktualne =
      aktualnePomieszczenie;
    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.czyPrzenoszenieSrodkow = false;
          this.pobierzDaneDoEkranu(true);
        }
      },
      (onRejected) => {}
    );
  }

  usunSrodki() {
    if (this.wybraneSrodki.length == 0) {
      this.wyswietlKomunikat('Wybierz środki do usunięcia', true);
      return;
    }

    var model = new UsunSrodkiModel();
    model.srodkiId = this.wybraneSrodki;
    model.idProjektu = this.idProjektu;

    this.modalReference = this.modalService.open(
      TakNieModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.pytanie =
      'Czy na pewno chcesz usunąć wybrane środki?';

    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.spisSerwis.usunSrodki(model).subscribe((rezultat) => {
            if (this.czyRezultatOk(rezultat)) {
              this.wyswietlKomunikat('Środki zostały usunięte');
              this.pobierzDaneDoEkranu(true);
              return;
            }

            this.wyswietlKomunikat(rezultat.wiadomosc, true);
            return;
          });
        }
      },
      (onRejected) => {}
    );
  }

  anulujPrzenoszenieSrodkow() {
    this.czyPrzenoszenieSrodkow = false;
    this.odznaczWszystko();
  }
  rozpocznijPrzenoszenieSrodkow() {
    this.czyPrzenoszenieSrodkow = true;
    this.odznaczWszystko();
  }

  anulujUsuwanieSrodkow() {
    this.czyUsuwanieSrodkow = false;
    this.odznaczWszystko();
  }
  rozpocznijUsuwanieSrodkow() {
    this.czyUsuwanieSrodkow = true;
    this.odznaczWszystko();
  }

  zaznaczWszystko() {
    this.czyZaznaczonoWszystko = true;
    this.wybraneSrodki = new Array();
    this.spisaneSrodki.forEach((srodek) => {
      if (this.czyMoznaZaznaczycSrodek(srodek)) {
        this.wybraneSrodki.push(srodek[this.kolumnaId]);
      }
    });
  }

  odznaczWszystko() {
    this.czyZaznaczonoWszystko = false;
    this.wybraneSrodki = new Array();
  }

  srodekZmiana(e, id: string) {
    if (!e.target.checked) {
      this.czyZaznaczonoWszystko = false;
      this.wybraneSrodki = this.wybraneSrodki.filter((m) => m != id);
    } else {
      this.wybraneSrodki.push(id);
    }
  }

  czyWybranoSrodek(id: string) {
    return this.wybraneSrodki.filter((m) => m == id).length != 0;
  }

  pobierzId(rekord: Array<string>) {
    return rekord[this.kolumnaId];
  }

  czyMoznaZaznaczycSrodek(rekord: Array<string>) {
    if (this.czyAdministrator) {
      return true;
    }
    var czyMojSrodek = rekord[this.kolumnaCzyMojSrodek] == '1';

    return czyMojSrodek;
  }

  budujQueryParams() {
    var filtry = this.wyszukiwanie?.filtry?.kolumnyFiltrowania
      ?.filter((m) => m.wartoscSzukaj)
      .map((m) => ({ [m.kolumna]: m.wartoscSzukaj }));

    return Object.assign(
      {},
      ...[
        { ['strona']: this.wyszukiwanie.stronicowanie.strona },
        { ['rozmiarStrony']: this.rozmiarTabeli },
        {
          ['sortowanieKolumna']:
            this.wyszukiwanie.sortowanie.listaSortowania[0].nazwaKolumny,
        },
        {
          ['sortowanieKierunek']:
            this.wyszukiwanie.sortowanie.listaSortowania[0].kierunekSortowania,
        },
        ...filtry,
      ]
    );
  }
  zapiszFiltryWUrl() {
    this.router.navigate([], {
      queryParams: this.budujQueryParams(),
      queryParamsHandling: '',
      replaceUrl: true,
      relativeTo: this.activatedRoute,
    });
  }

  pobierzFiltryZUrl() {
    var filtry = this.activatedRoute.snapshot.queryParamMap;

    this.wyszukiwanie.stronicowanie.rekordPoczatkowy =
      (this.wyszukiwanie.stronicowanie.strona - 1) *
      this.wyszukiwanie.stronicowanie.iloscRekordow;

    filtry.keys.forEach((k) => {
      if (
        [
          'strona',
          'rozmiarStrony',
          'sortowanieKolumna',
          'sortowanieKierunek',
        ].includes(k)
      ) {
        return;
      }
      var filtr = {
        kolumna: k,
        wartoscSzukaj: filtry.get(k),
      } as KolumnaSpisuFiltrowanie;
      this.wyszukiwanie.filtry.kolumnyFiltrowania.push(filtr);
      this.filtryZUrl.push(filtr);
    });

    this.wyszukiwanie.stronicowanie.strona =
      parseInt(filtry.get('strona')) || 1;
    this.rozmiarTabeli = parseInt(filtry.get('rozmiarStrony')) || 25;
    this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;

    this.wyszukiwanie.stronicowanie.rekordPoczatkowy =
      (this.wyszukiwanie.stronicowanie.strona - 1) *
      this.wyszukiwanie.stronicowanie.iloscRekordow;

    this.wyszukiwanie.sortowanie.listaSortowania = [
      {
        kierunekSortowania: parseInt(filtry.get('sortowanieKierunek')) || 0,
        nazwaKolumny: filtry.get('sortowanieKolumna') || 'data_utworzenia',
      },
    ];
  }
}
