<div class="modal-content">
    <div class="modal-body">
        <button aria-label="Close" class="close" data-dismiss="modal" type="button"
            (click)="activeModal.dismiss()"></button>
        <div class="ft__01 text-center py-4 ">Dodaj słownik</div>
        <div class="text-center mb-2"></div>
        <form action="">
            <div class="input_container pb-4 mb-1 " [ngClass]="czyBladWalidacyjny('nazwa')?'error':''">
                <input type="text" placeholder="Nazwa słownika" name="nazwa" [(ngModel)]="model.nazwa">
                <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('nazwa')">
                    {{pobierzBladWalidacyjny("nazwa") }}
                </div>
            </div>

            <div class="input_container pb-3" *ngIf="!model.zalacznik">
                <label class="btn__border pointer" for="file-upload" style="display: grid;align-items: center;">Importuj
                    słownik</label>
                <input id="file-upload" type="file" (change)="wybierzZalacznikZDysku($event)" hidden />
                <div class=" d-sm-inline-block pt-sm-1 pb-sm-1 fs__login--info   mb-2">
                    Wymagany format pliku to xls/xlsx
                </div>
            </div>

            <div class="chb por mb-3" *ngIf="model.zalacznik&&czyWielokolumnowy">
                <input id="cb_1" type="checkbox" [(ngModel)]="model.czyMoznaEdytowac" name="czyMoznaEdytowac">
                <label class="fs__chb" for="cb_1">Czy słownik edytowalny</label>
            </div>

            <div class="d-flex justify-content-between align-items-center fs__imported mb-4 mt-2"
                *ngIf="model.zalacznik">
                <div style="word-break: break-all;">{{model.zalacznik.nazwaPliku}}</div>
                <div>
                    <button class="btn__delete" (click)="usunZalacznik()"></button>
                    <!-- <button class="btn__download ml-2"></button> -->
                </div>
            </div>

            <div class="input_container pb-3">
                <button class="btn__primary" (click)="zapisz()">Zapisz słownik</button>
            </div>
            <div class="input_container pb-3">
                <button class="btn__link" (click)="activeModal.dismiss()">Anuluj</button>
            </div>
        </form>
    </div>
</div>