import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { startWith, map } from 'rxjs/operators';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { DodajKomentarzDoEwidencji } from 'src/app/modele/projekty/ewidencja/dodajKomentarzDoEwidencji';
import { DodajPomieszczenieModel } from 'src/app/modele/projekty/pomieszczenia/dodajPomieszczenieModel';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { EwidencjaService } from 'src/app/serwisy/projekty/ewidencja.service';

@Component({
  selector: 'app-dodaj-komentarz-do-ewidencji-modal',
  templateUrl: './dodaj-komentarz-do-ewidencji-modal.component.html',
  styleUrls: ['./dodaj-komentarz-do-ewidencji-modal.component.css']
})
export class DodajKomentarzDoEwidencjiModalComponent extends BazowyComponent implements OnInit {

  idProjektu: string;
  tresc: string;
  id: string;
  model: DodajKomentarzDoEwidencji = new DodajKomentarzDoEwidencji();
  czyZaladowanoDane: boolean;

  constructor(
    public router: Router,
    modalService: NgbModal,
    public activeModal: NgbActiveModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public ewidencjaSerwis: EwidencjaService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translateSerwis);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.model.id = this.id;
      this.model.idProjektu = this.idProjektu;
      this.model.tresc = this.tresc;

      this.czyZaladowanoDane = true;
    });
  }




  zapisz() {
    this.ewidencjaSerwis.dodajKomentarzDoRekorduEwidencji(this.model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.wyswietlKomunikat("Komentarz został dodany");
          this.activeModal.close(true);
          return;
        }
        if (this.bladModelu(rezultat)) {
          this.bledyWalidacji = rezultat.bledyModelu;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }

}
