<div class="modal-content">
    <div class="modal-body">
        <button aria-label="Close" class="close" data-dismiss="modal" type="button"
            (click)="activeModal.dismiss()"></button>
        <div class="ft__01 text-center pb-3 ">Lista błędów</div>
        <div class="text-center mb-2"></div>
        <ul>
            <li *ngFor="let blad of listaBledow">{{blad}}</li>
        </ul>
        <div class="input_container pb-3">
            <button class="btn__link" (click)="activeModal.dismiss()">OK</button>
        </div>

    </div>
</div>