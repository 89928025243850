<div *ngIf="czyZaladowanoDane">

    <div class="container">


        <div class="row  pt-4">
            <div class="container-fluid d-none d-md-block"
                [ngClass]="{'order-1':miejsceWywolania=='1','order-2':miejsceWywolania!='1','mb-5':!czySaWynikiNaLiscie(nadwyzki)}">

                <div class="box__scroll py-2">

                    <div class="box__scroll--top d-flex justify-content-between align-items-center ">
                        <span class="ft__03 pt-1 mt-1">Nadwyżki</span>
                        <!-- <button class="btn__paruj_x">Przerwij parowanie</button> -->
                        <div class="input_container">
                            <button class="btn__clear--filter" (click)="wyczyscFiltryNadwyzki()"
                                *ngIf="czyPokazacWyczyscFiltryNadwyzki()">Wyczyść
                                filtry</button>
                        </div>
                    </div>



                    <div class="tableFixHead tableFixHead--3">
                        <table class="table table--fix2">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th *ngFor="let kolumna of wyszukiwanieNadwyzki.filtry.kolumnyFiltrowania">
                                        {{kolumna.kolumna}}
                                        <button class="btn__input--sort--bottom--gray"
                                            [ngClass]="pobierzKierunekSortowaniaNadwyzki(kolumna.kolumna)"
                                            (click)="zmienKierunekSortowaniaNadwyzki(kolumna.kolumna)"></button>
                                        <div class="input_container input_container--mini">
                                            <input class=" pr-4" type="text" placeholder="Szukaj"
                                                [(ngModel)]="kolumna.wartoscSzukaj"
                                                (keyup)="filtrujPoTekscieNadwyzki($event,kolumna.kolumna)">
                                            <button class="btn__input--search"
                                                *ngIf="!czyPokazacPrzyciskCzyszczeniaFiltruNadwyzki(kolumna.kolumna)"></button>
                                            <button class="btn__input--clear"
                                                *ngIf="czyPokazacPrzyciskCzyszczeniaFiltruNadwyzki(kolumna.kolumna)"
                                                (click)="wyczyscFiltrKolumnyNadwyzki(kolumna.kolumna)"></button>
                                        </div>
                                    </th>

                                </tr>
                            </thead>
                            <tbody class="fz12" *ngIf="czySaWynikiNaLiscie(nadwyzki)">
                                <tr *ngFor="let wiersz of nadwyzki" class="pointer project-row"
                                    (click)="kliknijWWierszNadwyzek(wiersz.id)">
                                    <td class="text-left">
                                        <div class="chb por mt-1" (click)="$event.stopPropagation();">
                                            <input id="cb_nadwyzki_{{wiersz.id}}" type="checkbox" name="czyWybrany"
                                                (change)="zaznaczCheckboxNadwyzek($event,wiersz.id)"
                                                [ngModel]="wiersz.czyWybrany">
                                            <label class="pt-1" for="cb_nadwyzki_{{wiersz.id}}"></label>
                                        </div>
                                    </td>
                                    <td *ngFor="let wartosc of wiersz.wartosci; index as i"
                                        [ngClass]="i==kolumnaIdNadwyzki?'id':''">
                                        {{wartosc}}
                                    </td>

                                </tr>

                            </tbody>
                        </table>
                        <div class="ft__03 text-gray text-center my-5 py-5" *ngIf="!czySaWynikiNaLiscie(nadwyzki)">
                            Brak rekordów dla wybranych filtrów.
                        </div>
                    </div>

                </div>
                <div class="paginate float-right d-flex align-items-center pt-3 pb-2 pt-lg-2 mt-1"
                    style="margin-top:-10px !important;" *ngIf="czySaWynikiNaLiscie(nadwyzki)">
                    <div>Pokaż wyniki</div>
                    <select class="paginate__select mx-2" name="" id="" (change)="zmienRozmiarTabeliNadwyzki()"
                        [(ngModel)]="rozmiarTabeliNadwyzki">
                        <option [ngValue]="25">25</option>
                        <option [ngValue]="50">50</option>
                        <option [ngValue]="100">100</option>
                    </select>
                    <div class="px-2">{{pobierzNumerRekorduPoczatekNadwyzki()}}-{{pobierzNumerRekorduKoniecNadwyzki()}}
                        z
                        {{calkowitaLiczbaElementowNadwyzki}}</div>
                    <div class="d-flex">
                        <button class="btn__left" *ngIf="this.wyszukiwanieNadwyzki.stronicowanie.strona>1"
                            (click)="zmienStroneNadwyzki(-1)"></button>
                        <div class="px-2">{{this.wyszukiwanieNadwyzki.stronicowanie.strona}}</div>
                        <button class="btn__right" *ngIf="czyJestWiecejRekordowNadwyzki"
                            (click)="zmienStroneNadwyzki(1)"></button>
                    </div>
                </div>
            </div>


            <div class="container-fluid d-none d-md-block"
                [ngClass]="{'order-1':miejsceWywolania=='2','order-2':miejsceWywolania!='2','mb-5':!czySaWynikiNaLiscie(niedobory)}">

                <div class="box__scroll py-2">

                    <div class="box__scroll--top d-flex justify-content-between align-items-center ">
                        <span class="ft__03 pt-1 mt-1">Niedobory</span>
                        <!-- <button class="btn__paruj_x">Przerwij parowanie</button> -->
                        <div class="input_container">
                            <button class="btn__clear--filter" (click)="wyczyscFiltryNiedobory()"
                                *ngIf="czyPokazacWyczyscFiltryNiedobory()">Wyczyść
                                filtry</button>
                        </div>
                    </div>



                    <div class="tableFixHead tableFixHead--3">
                        <table class="table table--fix2">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th *ngFor="let kolumna of wyszukiwanieNiedobory.filtry.kolumnyFiltrowania"
                                        [ngClass]="czyKolumnaLp(kolumna.kolumna)?'lp':''">{{kolumna.kolumna}}
                                        <button class="btn__input--sort--bottom--gray"
                                            [ngClass]="pobierzKierunekSortowaniaNiedobory(kolumna.kolumna)"
                                            (click)="zmienKierunekSortowaniaNiedobory(kolumna.kolumna)"></button>
                                        <div class="input_container input_container--mini"
                                            [ngClass]="czyKolumnaLp(kolumna.kolumna)?'lp':''">
                                            <input class=" pr-4" type="text" placeholder="Szukaj"
                                                [(ngModel)]="kolumna.wartoscSzukaj"
                                                [ngClass]="czyKolumnaLp(kolumna.kolumna)?'lp':''"
                                                (keyup)="filtrujPoTekscieNiedobory($event,kolumna.kolumna)">
                                            <button class="btn__input--search"
                                                *ngIf="!czyPokazacPrzyciskCzyszczeniaFiltruNiedobory(kolumna.kolumna)"></button>
                                            <button class="btn__input--clear"
                                                *ngIf="czyPokazacPrzyciskCzyszczeniaFiltruNiedobory(kolumna.kolumna)"
                                                (click)="wyczyscFiltrKolumnyNiedobory(kolumna.kolumna)"></button>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="fz12" *ngIf="czySaWynikiNaLiscie(niedobory)">
                                <tr *ngFor="let wiersz of niedobory" class="pointer project-row"
                                    (click)="kliknijWWierszNiedoborow(wiersz.id)">
                                    <td class="text-left">
                                        <div class="chb por mt-1" (click)="$event.stopPropagation();">
                                            <input id="cb_niedobory_{{wiersz.id}}" type="checkbox" name="czyWybrany"
                                                (change)="zaznaczCheckboxNiedoborow($event,wiersz.id)"
                                                [ngModel]="wiersz.czyWybrany">
                                            <label class="pt-1" for="cb_niedobory_{{wiersz.id}}"></label>
                                        </div>
                                    </td>
                                    <td *ngFor="let wartosc of wiersz.wartosci; index as i"
                                        [ngClass]="i==kolumnaIdNiedobory?'id':''">
                                        <div *ngIf="i==kolumnaKomentarza; else elseBlock">
                                            <div class="d-inline-block">{{wartosc}}</div>
                                            <button class="btn__edit btn__edit--min"
                                                (click)="dodajKomentarz(wiersz,wartosc)"></button>
                                        </div>

                                        <ng-template #elseBlock>{{wartosc}}</ng-template>
                                    </td>

                                </tr>

                            </tbody>
                        </table>
                        <div class="ft__03 text-gray text-center my-5 py-5" *ngIf="!czySaWynikiNaLiscie(niedobory)">
                            Brak rekordów dla wybranych filtrów.
                        </div>
                    </div>
                </div>
                <div class="paginate float-right d-flex align-items-center pt-2 pb-2 pt-lg-2 mt-1"
                    style="margin-top:-10px !important;" *ngIf="czySaWynikiNaLiscie(niedobory)">
                    <div>Pokaż wyniki</div>
                    <select class="paginate__select mx-2" name="" id="" (change)="zmienRozmiarTabeliNiedobory()"
                        [(ngModel)]="rozmiarTabeliNiedobory">
                        <option [ngValue]="25">25</option>
                        <option [ngValue]="50">50</option>
                        <option [ngValue]="100">100</option>
                    </select>
                    <div class="px-2">
                        {{pobierzNumerRekorduPoczatekNiedobory()}}-{{pobierzNumerRekorduKoniecNiedobory()}} z
                        {{calkowitaLiczbaElementowNiedobory}}</div>
                    <div class="d-flex">
                        <button class="btn__left" *ngIf="this.wyszukiwanieNiedobory.stronicowanie.strona>1"
                            (click)="zmienStroneNiedobory(-1)"></button>
                        <div class="px-2">{{this.wyszukiwanieNiedobory.stronicowanie.strona}}</div>
                        <button class="btn__right" *ngIf="czyJestWiecejRekordowNiedobory"
                            (click)="zmienStroneNiedobory(1)"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- mobile -->
    <div class="container-fluid d-md-none pb-4 ">
        <div class="container">
            <div class="row">

                <div style="width:100% ;" [ngClass]="miejsceWywolania=='1'?'order-3':'order-4'">
                    <div class="d-flex justify-content-between pt-4">
                        <div class="input_container mb-3 text-right d-flex ">
                            <div class="ft__04">Nadwyżki</div>
                            <button class="btn__search btn__search--mini ml-1"
                                *ngIf="!czyPokazanoWyszukiwarkeNadwyzekMobile"
                                (click)="pokazWyszukiwarkeNadwyzekMobile(true)"></button>
                            <button class="btn__input--clear btn__input--clear-search ml-1"
                                *ngIf="czyPokazanoWyszukiwarkeNadwyzekMobile"
                                (click)="pokazWyszukiwarkeNadwyzekMobile(false)"></button>
                        </div>
                        <div class="input_container mb-3 text-right " *ngIf="miejsceWywolania=='1'">
                            <button class="btn__hamburger" (click)="pokazPodmenu()"></button>
                            <!-- <button class="btn__x btn__x--blue ml-2"></button> -->
                            <!-- <button class="btn__input--clear" *ngIf="czyPokazanoPodmenu"></button> -->
                        </div>


                    </div>
                    <div class="box__mobile box__mobile--form mb-3 pt-4 pb-3 d-flex flex-column"
                        *ngIf="czyPokazanoPodmenu">

                        <div class="chb por mb-3 mt-3 ml-sm-3">
                            <input id="c2" type="checkbox" name="czySugerowacPodobne"
                                (change)="zaznaczSugerujPodobne($event)" [ngModel]="czySugerowacPodobne">
                            <label class="ft__05" for="c2">Sugeruj podobne pozycje</label>
                        </div>
                        <div class="chb por mb-3 ml-sm-3">
                            <input id="cb_03" type="checkbox" name="czyPokazacSparowane"
                                (change)="zaznaczPokazSparowane($event)" [ngModel]="czyPokazacSparowane">
                            <label class="ft__05" for="cb_03">Pokaż sparowane pozycje</label>
                        </div>
                        <div class="chb por mb-3 ml-sm-3">
                            <input id="cb_04" type="checkbox" name="czySzukacWSparowanych"
                                (change)="zaznaczSzukajWSparowanych($event)" [ngModel]="czySzukacWSparowanych">
                            <label class="ft__05" for="cb_04">Szukaj w sparowanych</label>
                        </div>

                    </div>
                    <div class="box__mobile box__mobile--form mb-3" *ngIf="czyPokazanoWyszukiwarkeNadwyzekMobile">

                        <div class="input_container mb-3"
                            *ngFor="let kolumna of wyszukiwanieNadwyzki.filtry.kolumnyFiltrowania">
                            <input class=" pr-4" type="text" placeholder="{{kolumna.kolumna}}"
                                [(ngModel)]="kolumna.wartoscSzukaj">
                            <button class="btn__input--search"
                                *ngIf="!czyPokazacPrzyciskCzyszczeniaFiltruNadwyzki(kolumna.kolumna)"></button>
                            <button class="btn__input--clear"
                                *ngIf="czyPokazacPrzyciskCzyszczeniaFiltruNadwyzki(kolumna.kolumna)"
                                (click)="wyczyscFiltrKolumnyNadwyzki(kolumna.kolumna)"></button>
                        </div>
                    </div>
                    <button class="btn__border mt-4 mb-4"
                        (click)="pobierzNadwyzki(true);pokazWyszukiwarkeNadwyzekMobile(false)"
                        *ngIf="czyPokazanoWyszukiwarkeNadwyzekMobile">Szukaj</button>


                    <button class="btn__clear--filter mt-2 mb-4"
                        (click)="wyczyscFiltryNadwyzki();pokazWyszukiwarkeNadwyzekMobile(false)"
                        *ngIf="czyPokazacWyczyscFiltryNadwyzki()">Wyczyść
                        filtry</button>
                    <div class="box__mobile box__mobile--table mb-3" *ngIf="czySaWynikiNaLiscie(nadwyzki)">
                        <div class="fixed_table_container">
                            <table>
                                <tr>
                                    <th bgcolor="#BFBFC1"></th>
                                    <th *ngFor="let kolumna of wyszukiwanieNadwyzki.filtry.kolumnyFiltrowania">
                                        <div class="d-flex">{{kolumna.kolumna}}<button
                                                class="btn__input--sort--bottom--gray d-inline-block"
                                                [ngClass]="pobierzKierunekSortowaniaNadwyzki(kolumna.kolumna)"
                                                (click)="zmienKierunekSortowaniaNadwyzki(kolumna.kolumna)"></button>
                                        </div>
                                    </th>

                                </tr>
                                <tr *ngFor="let wiersz of nadwyzki" class="pointer project-row"
                                    (click)="kliknijWWierszNadwyzek(wiersz.id)">
                                    <td>
                                        <div class="chb por mx-1 mr-4 ml-1" (click)="$event.stopPropagation();">
                                            <input id="cb_nadwyzki_{{wiersz.id}}" type="checkbox" name="czyWybrany"
                                                (change)="zaznaczCheckboxNadwyzek($event,wiersz.id)"
                                                [ngModel]="wiersz.czyWybrany">
                                            <label class="pt-1" for="cb_nadwyzki_{{wiersz.id}}"></label>
                                        </div>
                                    </td>
                                    <td *ngFor="let wartosc of wiersz.wartosci; index as i"
                                        [ngClass]="i==kolumnaIdNadwyzki?'id':''">
                                        {{wartosc}}
                                    </td>
                                </tr>

                            </table>
                        </div>
                    </div>
                    <div class="paginate float-right d-flex align-items-center pb-2 pt-lg-4"
                        *ngIf="czySaWynikiNaLiscie(nadwyzki)">
                        <div>Pokaż wyniki</div>
                        <select class="paginate__select mx-2" name="" id="" (change)="zmienRozmiarTabeliNadwyzki()"
                            [(ngModel)]="rozmiarTabeliNadwyzki">
                            <option [ngValue]="25">25</option>
                            <option [ngValue]="50">50</option>
                            <option [ngValue]="100">100</option>
                        </select>
                        <div class="px-2">
                            {{pobierzNumerRekorduPoczatekNadwyzki()}}-{{pobierzNumerRekorduKoniecNadwyzki()}}
                            z
                            {{calkowitaLiczbaElementowNadwyzki}}</div>
                        <div class="d-flex">
                            <button class="btn__left" *ngIf="this.wyszukiwanieNadwyzki.stronicowanie.strona>1"
                                (click)="zmienStroneNadwyzki(-1)"></button>
                            <div class="px-2">{{this.wyszukiwanieNadwyzki.stronicowanie.strona}}</div>
                            <button class="btn__right" *ngIf="czyJestWiecejRekordowNadwyzki"
                                (click)="zmienStroneNadwyzki(1)"></button>
                        </div>
                    </div>
                    <div class="ft__03 text-gray text-center py-5" *ngIf="!czySaWynikiNaLiscie(nadwyzki) ">
                        Brak rekordów <br>dla wybranych filtrów.
                    </div>
                </div>


                <div style="width:100% ;" [ngClass]="miejsceWywolania=='2'?'order-3':'order-4'">
                    <div class="d-flex justify-content-between pt-3">
                        <div class="input_container mb-3 text-right d-flex ">
                            <div class="ft__04">Niedobory</div>
                            <button class="btn__search btn__search--mini ml-1"
                                (click)="pokazWyszukiwarkeNiedoborowMobile(true)"></button>
                        </div>
                        <div class="input_container mb-3 text-right " *ngIf="miejsceWywolania=='2'">
                            <button class="btn__hamburger" (click)="pokazPodmenu()"></button>
                            <!-- <button class="btn__x btn__x--blue ml-2"></button> -->
                            <!-- <button class="btn__input--clear" *ngIf="czyPokazanoPodmenu"></button> -->
                        </div>
                    </div>
                    <div class="box__mobile box__mobile--form mb-3 pt-4 pb-3 d-flex flex-column"
                        *ngIf="czyPokazanoPodmenu">

                        <div class="chb por mb-3 mt-3 ml-sm-3">
                            <input id="c2" type="checkbox" name="czySugerowacPodobne"
                                (change)="zaznaczSugerujPodobne($event)" [ngModel]="czySugerowacPodobne">
                            <label class="ft__05" for="c2">Sugeruj podobne pozycje</label>
                        </div>
                        <div class="chb por mb-3 ml-sm-3">
                            <input id="cb_03" type="checkbox" name="czyPokazacSparowane"
                                (change)="zaznaczPokazSparowane($event)" [ngModel]="czyPokazacSparowane">
                            <label class="ft__05" for="cb_03">Pokaż sparowane pozycje</label>
                        </div>
                        <div class="chb por mb-3 ml-sm-3">
                            <input id="cb_04" type="checkbox" name="czySzukacWSparowanych"
                                (change)="zaznaczSzukajWSparowanych($event)" [ngModel]="czySzukacWSparowanych">
                            <label class="ft__05" for="cb_04">Szukaj w sparowanych</label>
                        </div>

                    </div>
                    <div class="box__mobile box__mobile--form mb-3" *ngIf="czyPokazanoWyszukiwarkeNiedoborowMobile">

                        <div class="input_container mb-3"
                            *ngFor="let kolumna of wyszukiwanieNiedobory.filtry.kolumnyFiltrowania">
                            <input class=" pr-4" type="text" placeholder="{{kolumna.kolumna}}"
                                [(ngModel)]="kolumna.wartoscSzukaj">
                            <button class="btn__input--search"
                                *ngIf="!czyPokazacPrzyciskCzyszczeniaFiltruNiedobory(kolumna.kolumna)"></button>
                            <button class="btn__input--clear"
                                *ngIf="czyPokazacPrzyciskCzyszczeniaFiltruNiedobory(kolumna.kolumna)"
                                (click)="wyczyscFiltrKolumnyNiedobory(kolumna.kolumna)"></button>
                        </div>
                    </div>
                    <button class="btn__border mt-4 mb-4"
                        (click)="pobierzNiedobory(true);pokazWyszukiwarkeNiedoborowMobile(false)"
                        *ngIf="czyPokazanoWyszukiwarkeNiedoborowMobile">Szukaj</button>


                    <button class="btn__clear--filter mt-2 mb-4"
                        (click)="wyczyscFiltryNiedobory();pokazWyszukiwarkeNiedoborowMobile(false)"
                        *ngIf="czyPokazacWyczyscFiltryNiedobory()">Wyczyść
                        filtry</button>


                    <div class="box__mobile box__mobile--table mb-3" *ngIf="czySaWynikiNaLiscie(niedobory)">
                        <div class="fixed_table_container">
                            <table>
                                <tr>
                                    <th bgcolor="#BFBFC1"></th>
                                    <th *ngFor="let kolumna of wyszukiwanieNiedobory.filtry.kolumnyFiltrowania"
                                        [ngClass]="czyKolumnaLp(kolumna.kolumna)?'lp':''">
                                        <div class="d-flex">{{kolumna.kolumna}}<button
                                                class="btn__input--sort--bottom--gray d-inline-block"
                                                [ngClass]="pobierzKierunekSortowaniaNiedobory(kolumna.kolumna)"
                                                (click)="zmienKierunekSortowaniaNiedobory(kolumna.kolumna)"></button>
                                        </div>
                                    </th>

                                </tr>
                                <tr *ngFor="let wiersz of niedobory" (click)="kliknijWWierszNiedoborow(wiersz.id)">
                                    <td>
                                        <div class="chb por mx-1 mr-4 ml-1" (click)="$event.stopPropagation();">
                                            <input id="cb_niedobory_{{wiersz.id}}" type="checkbox" name="czyWybrany"
                                                (change)="zaznaczCheckboxNiedoborow($event,wiersz.id)"
                                                [ngModel]="wiersz.czyWybrany">
                                            <label class="pt-1" for="cb_niedobory_{{wiersz.id}}"></label>
                                        </div>
                                    </td>
                                    <td *ngFor="let wartosc of wiersz.wartosci; index as i"
                                        [ngClass]="i==kolumnaIdNiedobory?'id':''">
                                        <div *ngIf="i==kolumnaKomentarza; else elseBlock">
                                            <div class="d-inline-block">{{wartosc}}</div>
                                            <button class="btn__edit btn__edit--min"
                                                (click)="dodajKomentarz(wiersz,wartosc)"></button>
                                        </div>

                                        <ng-template #elseBlock>{{wartosc}}</ng-template>
                                    </td>
                                </tr>

                            </table>
                        </div>
                    </div>

                    <div class="paginate float-right d-flex align-items-center pb-2 pt-lg-4"
                        *ngIf="czySaWynikiNaLiscie(niedobory)">
                        <div>Pokaż wyniki</div>
                        <select class="paginate__select mx-2" name="" id="" (change)="zmienRozmiarTabeliNiedobory()"
                            [(ngModel)]="rozmiarTabeliNiedobory">
                            <option [ngValue]="25">25</option>
                            <option [ngValue]="50">50</option>
                            <option [ngValue]="100">100</option>
                        </select>
                        <div class="px-2">
                            {{pobierzNumerRekorduPoczatekNiedobory()}}-{{pobierzNumerRekorduKoniecNiedobory()}} z
                            {{calkowitaLiczbaElementowNiedobory}}</div>
                        <div class="d-flex">
                            <button class="btn__left" *ngIf="this.wyszukiwanieNiedobory.stronicowanie.strona>1"
                                (click)="zmienStroneNiedobory(-1)"></button>
                            <div class="px-2">{{this.wyszukiwanieNiedobory.stronicowanie.strona}}</div>
                            <button class="btn__right" *ngIf="czyJestWiecejRekordowNiedobory"
                                (click)="zmienStroneNiedobory(1)"></button>
                        </div>
                    </div>
                    <div class="ft__03 text-gray text-center py-5" *ngIf="!czySaWynikiNaLiscie(niedobory) ">
                        Brak rekordów <br>dla wybranych filtrów.
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="bootom__buttons px-4 d-md-none bg-white" *ngIf="czyPokazacPrzyciskRozlacz()||czyPokazacPrzyciskParuj()">
        <div class="d-flex justify-content-between align-content-around">
            <button class="btn__border btn__border--mini order-0 order-sm-1 mr-2" *ngIf="czyPokazacPrzyciskRozlacz()"
                (click)="rozlacz()">Rozłącz</button>
            <button class="btn__blue order-0 order-sm-1 px-4 ml-2" *ngIf="czyPokazacPrzyciskParuj()"
                (click)="rozpocznijParowanie()">Paruj</button>
        </div>
    </div>

    <div class="py-md-4"></div>
    <div class="bootom__buttons px-4 d-none d-md-block">
        <div class="container-fluid flex-column flex-sm-row d-flex justify-content-between align-items-center">
            <button class="btn__link order-1 order-sm-0" (click)="anuluj()">Anuluj</button>
            <div class="chb por mt-1">
                <input id="c2" type="checkbox" name="czySugerowacPodobne" (change)="zaznaczSugerujPodobne($event)"
                    [ngModel]="czySugerowacPodobne">
                <label class="ft__05" for="c2">Sugeruj podobne pozycje</label>
            </div>

            <div class="chb por mt-1">
                <input id="cb_03" type="checkbox" name="czyPokazacSparowane" (change)="zaznaczPokazSparowane($event)"
                    [ngModel]="czyPokazacSparowane">
                <label class="ft__05" for="cb_03">Pokaż sparowane pozycje</label>
            </div>

            <div class="chb por mt-1">
                <input id="cb_04" type="checkbox" name="czySzukacWSparowanych"
                    (change)="zaznaczSzukajWSparowanych($event)" [ngModel]="czySzukacWSparowanych">
                <label class="ft__05" for="cb_04">Szukaj w sparowanych</label>
            </div>
            <button class="btn__border btn__border--mini order-0 order-sm-1" style="width: 140px;"
                *ngIf="czyPokazacPrzyciskRozlacz()" (click)="rozlacz()">Rozłącz</button>
            <button class="btn__blue order-0 order-sm-1 px-5" *ngIf="czyPokazacPrzyciskParuj()" style="width: 140px;"
                (click)="rozpocznijParowanie()">Paruj</button>
            <div style="width: 140px;" *ngIf="!czyPokazacPrzyciskRozlacz()&&!czyPokazacPrzyciskParuj()"></div>
        </div>
    </div>
</div>