<nav class="navbar">
  <div class="navbar__container">
    <div class="navbrand d-flex align-items-center justify-content-center">
      <a class="brand" [routerLink]="">
        <img src="assets/img/logo.svg" alt="" />
      </a>
    </div>
  </div>
</nav>

<div
  class="container container--login"
  *ngIf="czyMoznaDodawacOswiadczenia && zaladowanoDane"
>
  <div class="ft__01 text-center py-4 mt-3">
    Oświadczenia {{ nazwaKlienta }}
  </div>
  <div class="text-center pb-4 mb-2">Uzupełnij swoje dane</div>
  <form (ngSubmit)="potwierdz()">
    <div
      class="input_container pb-3"
      [ngClass]="czyBladWalidacyjny('imie') ? 'error' : ''"
    >
      <input
        placeholder="Imię (Name)"
        type="text"
        name="imie"
        [(ngModel)]="model.imie"
      />
      <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('imie')">
        {{ pobierzBladWalidacyjny("imie") }}
      </div>
    </div>
    <div
      class="input_container pb-3"
      [ngClass]="czyBladWalidacyjny('nazwisko') ? 'error' : ''"
    >
      <input
        placeholder="Nazwisko (Last name)"
        type="text"
        name="nazwisko"
        [(ngModel)]="model.nazwisko"
      />
      <div
        class="error-info fs__error pt-2"
        *ngIf="czyBladWalidacyjny('nazwisko')"
      >
        {{ pobierzBladWalidacyjny("nazwisko") }}
      </div>
    </div>
    <div
      class="input_container pb-3"
      [ngClass]="czyBladWalidacyjny('telefon') ? 'error' : ''"
    >
      <input
        placeholder="Numer telefonu (Phone number)"
        type="text"
        name="telefon"
        [(ngModel)]="model.telefon"
      />
      <div
        class="error-info fs__error pt-2"
        *ngIf="czyBladWalidacyjny('telefon')"
      >
        {{ pobierzBladWalidacyjny("telefon") }}
      </div>
    </div>

    <div
      *ngFor="let pole of model.polaDynamiczne"
      class="input_container pb-3"
      [ngClass]="czyBladWalidacyjny(pole.nazwaPola) ? 'error' : ''"
    >
      <input
        placeholder="{{ pole.nazwaPola }}"
        type="text"
        name="{{ pole.nazwaPola }}"
        value="{{ pole.wartosc }}"
        [(ngModel)]="pole.wartosc"
      />
      <div
        class="error-info fs__error pt-2"
        *ngIf="czyBladWalidacyjny(pole.nazwaPola)"
      >
        {{ pobierzBladWalidacyjny(pole.nazwaPola) }}
      </div>
    </div>

    <div
      class="input_container pb-3"
      [ngClass]="czyBladWalidacyjny('lokalizacjaId') ? 'error' : ''"
    >
      <ng-select
        placeholder="Lokalizacja (Location)"
        [searchable]="true"
        [clearable]="true"
        [multiple]="false"
        [items]="przefiltrowaneLokalizacje"
        [(ngModel)]="model.lokalizacjaId"
        bindValue="id"
        bindLabel="nazwa"
        [ngModelOptions]="{ standalone: true }"
        [searchFn]="customSearchFn"
        [editableSearchTerm]="false"
        notFoundText="Brak lokalizacji (wybierz inną)"
      >
      </ng-select>

      <div
        class="error-info fs__error pt-2"
        *ngIf="czyBladWalidacyjny('lokalizacjaId')"
      >
        {{ pobierzBladWalidacyjny("lokalizacjaId") }}
      </div>
    </div>

    <div
      *ngIf="model.lokalizacjaId == 'inna'"
      class="input_container pb-3"
      [ngClass]="czyBladWalidacyjny('adresLokalizacji') ? 'error' : ''"
    >
      <input
        placeholder="Adres lokalizacji (Location address)"
        type="text"
        name="adresLokalizacji"
        [(ngModel)]="model.adresLokalizacji"
      />
      <div
        class="error-info fs__error pt-2"
        *ngIf="czyBladWalidacyjny('adresLokalizacji')"
      >
        {{ pobierzBladWalidacyjny("adresLokalizacji") }}
      </div>
    </div>

    <div class="input_container pb-3">
      <button class="btn__primary">Potwierdź</button>
    </div>

    <hr />
  </form>
</div>
<div
  class="container container--login"
  *ngIf="!czyMoznaDodawacOswiadczenia && zaladowanoDane"
>
  <div class="ft__01 text-center py-4 mt-3">
    Wypełnianie oświadczeń nie jest możliwe
  </div>
</div>
