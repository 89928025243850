import { WartoscPolaDynamicznego } from './pobierzDaneDoUzupelnieniaDanychPodstawowychUzytkownikaRezultat';

export class ZapiszDanePodstawoweUzytkownikaDoOswiadczenia {
  polaDynamiczne: Array<WartoscPolaDynamicznego>;
  imie: string;
  nazwisko: string;
  telefon: string;
  adresLokalizacji: string;
  lokalizacjaId: string;
  idUzytkownika: string;
  idProjektu: string;
}
