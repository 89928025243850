<div *ngIf="czyZaladowanoDane">
  <div class="container--main d-none d-md-block">
    <div class="ft__sort text-center pt-4">Oświadczenia {{ nazwaKlienta }}</div>
  </div>

  <div class="container-fluid d-none d-md-block pt-4">
    <div class="box__scroll py-3">
      <div
        class="box__scroll--top d-flex justify-content-between align-items-center mb-3"
      >
        <span class="ft__03">{{ adresLokalizacji }}</span>
      </div>
      <div class="tableFixHead tableFixHead--3">
        <table class="table">
          <thead>
            <tr>
              <th></th>
              <th *ngFor="let kolumna of kolumnyTabeli">
                {{ kolumna }}
                <!-- <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania(kolumna.kolumna)"
                  (click)="zmienKierunekSortowania(kolumna.kolumna)"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="kolumna.wartoscSzukaj"
                    (keyup)="filtrujPoTekscie($event, kolumna.kolumna)"
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="
                      !czyPokazacPrzyciskCzyszczeniaFiltru(kolumna.kolumna)
                    "
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="czyPokazacPrzyciskCzyszczeniaFiltru(kolumna.kolumna)"
                    (click)="wyczyscFiltrKolumny(kolumna.kolumna)"
                  ></button>
                </div> -->
              </th>
            </tr>
          </thead>
          <tbody class="fz12" *ngIf="czySaWynikiNaLiscie(spisaneSrodki)">
            <tr
              *ngFor="let wiersz of spisaneSrodki"
              class="pointer project-row"
              (click)="edytujSrodek(wiersz); $event.stopPropagation()"
            >
              <td>
                <button
                  class="btn__edit"
                  (click)="edytujSrodek(wiersz); $event.stopPropagation()"
                ></button>
              </td>
              <td
                *ngFor="let wartosc of wiersz; index as i"
                [ngClass]="i == kolumnaId ? 'id' : ''"
              >
                {{ wartosc }}
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="ft__03 text-gray text-center my-5 py-5"
          *ngIf="!czySaWynikiNaLiscie(spisaneSrodki)"
        >
          Brak rekordów
        </div>
      </div>
    </div>

    <div class="clearfix"></div>
    <div
      class="pb-2 d-flex mt-3 justify-content-between"
      style="align-items: center"
    >
      <div class="d-flex" style="gap: 5px">
        Wygaśnięcie sesji za:
        <p
          style="margin-top: initial; margin-bottom: initial"
          [ngClass]="pozostalyCzasSesji < 300000 ? 'czas-sesji-koniec' : ''"
        >
          {{ pozostalyCzasSesji | date : "mm:ss" }}
        </p>
      </div>

      <button class="btn__add" (click)="dodaj()"></button>
    </div>
  </div>
  <div class="container-fluid d-md-none pb-5">
    <div class="pt-3 mt-1">
      <div class="ft__sort text-center">Oświadczenia {{ nazwaKlienta }}</div>
    </div>

    <div class="filter-box d-flex justify-content-between pt-3 mb-3">
      <span class="ft__03 mr-3">{{ adresLokalizacji }}</span>
    </div>

    <!-- <div
      class="input_container mb-3 text-right"
      *ngIf="!czyPokazanoWyszukiwarkeMobile"
    >
      <button class="btn__hamburger" (click)="pokazPodmenu()"></button>
      <button
        class="btn__search mx-2"
        (click)="pokazWyszukiwarkeMobile(true)"
      ></button>
    </div> -->
    <!-- <div
      class="searchBox d-flex flex-wrap por pb-5"
      *ngIf="czyPokazanoWyszukiwarkeMobile"
    >
      <button
        class="btn__input--clear"
        (click)="pokazWyszukiwarkeMobile(false)"
      ></button>
    </div> -->

    <!-- <div
      class="box__mobile box__mobile--form mb-3"
      *ngIf="czyPokazanoWyszukiwarkeMobile"
    >
      <div
        class="input_container mb-3"
        *ngFor="let kolumna of wyszukiwanie.filtry.kolumnyFiltrowania"
      >
        <input
          class="pr-4"
          type="text"
          placeholder="{{ kolumna.kolumna }}"
          [(ngModel)]="kolumna.wartoscSzukaj"
        />
        <button
          class="btn__input--search"
          *ngIf="!czyPokazacPrzyciskCzyszczeniaFiltru(kolumna.kolumna)"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="czyPokazacPrzyciskCzyszczeniaFiltru(kolumna.kolumna)"
          (click)="wyczyscFiltrKolumny(kolumna.kolumna)"
        ></button>
      </div>
    </div> -->

    <!-- <button
      class="btn__border mt-4 mb-4"
      (click)="pobierzDaneDoEkranu(true); pokazWyszukiwarkeMobile(false)"
      *ngIf="czyPokazanoWyszukiwarkeMobile"
    >
      Szukaj
    </button> -->

    <!-- <button
      class="btn__clear--filter mt-2 mb-4"
      (click)="wyczyscFiltry(); pokazWyszukiwarkeMobile(false)"
      *ngIf="czyPokazacWyczyscFiltry()"
    >
      Wyczyść filtry
    </button> -->
    <div
      class="box__mobile box__mobile--table mb-3"
      *ngIf="czySaWynikiNaLiscie(spisaneSrodki)"
    >
      <div class="fixed_table_container">
        <table>
          <tr>
            <th bgcolor="BFBFC1" width="42"></th>
            <th *ngFor="let kolumna of kolumnyTabeli">
              <div class="d-flex">
                {{ kolumna }}
                <!-- <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania(kolumna.kolumna)"
                  (click)="zmienKierunekSortowania(kolumna.kolumna)"
                ></button> -->
              </div>
            </th>
          </tr>
          <tr *ngFor="let wiersz of spisaneSrodki">
            <td
              *ngFor="let wartosc of wiersz; index as i"
              [ngClass]="i == kolumnaId ? 'id' : ''"
            >
              {{ wartosc }}
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div
      class="ft__03 text-gray text-center py-5"
      *ngIf="!czySaWynikiNaLiscie(spisaneSrodki)"
    >
      Brak rekordów
    </div>

    <button class="btn__primary mt-3" (click)="dodaj()">Dodaj środek</button>
    <button
      class="btn__border btn__border--mini px-3 mt-3 button-green"
      (click)="wyslij()"
    >
      Wyślij oświadczenie
    </button>

    <button
      class="btn__border btn__border--mini px-3 mt-3"
      (click)="pobierzInstrukcje()"
    >
      Pobierz instrukcję
    </button>

    <button class="btn__link order-1 order-sm-0" (click)="wyjdz()">
      Wyjdz
    </button>
  </div>

  <div class="bootom__buttons px-4 d-none d-md-block">
    <div
      class="flex-column flex-sm-row d-flex justify-content-between align-items-center"
      style="justify-content: space-evenly !important"
      [ngClass]="container - -mw800"
    >
      <button class="btn__link order-1 order-sm-0" (click)="wyjdz()">
        Wyjdz
      </button>

      <button
        class="btn__border btn__border--mini px-3"
        (click)="pobierzInstrukcje()"
      >
        Pobierz instrukcję
      </button>

      <button
        class="btn__border btn__border--mini px-3 button-green"
        (click)="wyslij()"
      >
        Wyślij oświadczenie
      </button>
      <button class="btn__blue order-0 order-sm-1" (click)="dodaj()">
        Dodaj środek
      </button>
    </div>
  </div>
</div>
