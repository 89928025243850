<nav class="navbar">
    <div class="navbar__container">
        <div class="navbrand d-flex align-items-center justify-content-center">
            <a class="brand" href="#">
                <img src="assets/img/logo.svg" alt="">
            </a>
        </div>
    </div>
</nav>

<div class="container container--login">
    <div class="ft__01 text-center py-4 mt-3">{{ 'zmianaHasla.naglowek' | translate}}</div>
    <div class="text-center pb-4 mb-2">{{ 'zmianaHasla.naglowek3' | translate}}</div>
    <form (ngSubmit)="zmienHaslo()">
        <div class="input_container pb-3" [ngClass]="czyBladWalidacyjny('email')?'error':''">
            <input placeholder="E-mail" type="email" name="email" [(ngModel)]="zmienHasloModel.email" />
            <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('email')">
                {{pobierzBladWalidacyjny("email")}}
            </div>
        </div>
        <div class="input_container pb-3">
            <button class="btn__primary">{{ 'zmianaHasla.zmienHaslo' | translate}}</button>
        </div>
        <hr>
        <div class="text-center pt-2 mt-1">
            {{ 'zmianaHasla.pamietaszHaslo' | translate}} <a class="" routerLink="/login"> {{
                'zmianaHasla.zalogujSie' | translate}}</a>
        </div>
    </form>
</div>