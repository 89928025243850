import { WartoscSpisanegoSrodka } from '../projekty/spis/wartoscSpisanegoSrodka';
import { ComboBox } from '../wspolne/comboBox';
import { DanePodstawoweDoOswiadczenia } from './danePodstawoweDoOswiadczenia';

export class PobierzDaneDoUzupelnieniaDanychPodstawowychUzytkownikaRezultat extends DanePodstawoweDoOswiadczenia {
  lokalizacje: Array<ComboBox>;
  polaDynamiczne: Array<WartoscPolaDynamicznego>;
  imie: string;
  nazwisko: string;
  telefon: string;
  adresLokalizacji: string;
  lokalizacjaId: string;
}

export class WartoscPolaDynamicznego {
  nazwaPola: string;
  wartosc: string;
}
