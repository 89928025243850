import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { DodatkowyImportLokalizacjiModel } from 'src/app/modele/projekty/lokalizacje/dodatkowyImportLokalizacjiModel';
import { ImportujLokalizacjeModel } from 'src/app/modele/projekty/lokalizacje/importujLokalizacjeModel';
import { NowaLokalizacjaModel } from 'src/app/modele/projekty/lokalizacje/nowaLokalizacjaModel';
import { EksportujDaneModel } from 'src/app/modele/projekty/spis/eksportujDaneModel';
import { Zalacznik } from 'src/app/modele/wspolne/zalacznik';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { EksportDanychService } from 'src/app/serwisy/projekty/eksport-danych.service';
import { LokalizacjeService } from 'src/app/serwisy/projekty/lokalizacje.service';

@Component({
  selector: 'app-rodzaj-eksportu-modal',
  templateUrl: './rodzaj-eksportu-modal.component.html',
  styleUrls: ['./rodzaj-eksportu-modal.component.css']
})
export class RodzajEksportuModalComponent extends BazowyComponent implements OnInit {

  idProjektu: string;
  idLokalizacji: string;
  zakresEksportu: number = 0;
  rodzajEksportu: string;

  constructor(
    public router: Router,
    modalService: NgbModal,
    public activeModal: NgbActiveModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute,
    public eksportDanychSerwis: EksportDanychService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translateSerwis);
  }

  ngOnInit(): void {
    setTimeout(() => {
    });
  }

  wybierz() {
    if (this.rodzajEksportu == "nadwyzki") {
      this.eksportujNadwyzki();
    }
    if (this.rodzajEksportu == "niedobory") {
      this.eksportujNiedobory();
    }
  }

  eksportujNadwyzki() {
    var model = new EksportujDaneModel();
    model.idProjektu = this.idProjektu;
    model.idLokalizacji = this.zakresEksportu == 0 ? this.idLokalizacji : null;

    this.eksportDanychSerwis.eksportujNadwyzki(model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.bledyWalidacji = null;
          var a = document.createElement("a");
          a.target = "_blank";
          a.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + rezultat.element.plik;
          a.download = rezultat.element.nazwaPliku;
          a.click();
          this.activeModal.dismiss();
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }

  eksportujNiedobory() {
    var model = new EksportujDaneModel();
    model.idProjektu = this.idProjektu;
    model.idLokalizacji = this.zakresEksportu == 0 ? this.idLokalizacji : null;

    this.eksportDanychSerwis.eksportujNiedobory(model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.bledyWalidacji = null;
          var a = document.createElement("a");
          a.target = "_blank";
          a.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + rezultat.element.plik;
          a.download = rezultat.element.nazwaPliku;
          a.click();
          this.activeModal.dismiss();
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }
}
