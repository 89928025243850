import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";

export class ModalOpcje implements NgbModalOptions {
  constructor(centered?: boolean, backdrop?: boolean | 'static', keyboard?: boolean, modalDialogClass?: string) {
    this.centered = centered;
    this.backdrop = backdrop;
    this.keyboard = keyboard;
    this.modalDialogClass = modalDialogClass;
  }
  centered?: boolean;
  backdrop?: boolean | 'static';
  keyboard?: boolean;
  modalDialogClass?: string;
}