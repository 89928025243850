import { KolumnaEwidencjiFiltrowanie } from "../ewidencja/kolumnaEwidencjiFiltrowanie";

export class PobierzNiedoboryDoParowaniaFiltry {
    kolumnyFiltrowania: Array<KolumnaEwidencjiFiltrowanie> = new Array();
    idLokalizacji: string;
    idProjektu: string;
    spisanySrodekDoSparowaniaId: string;
    czySugerowacPodobne: boolean;
    czyPokazacSparowaneSrodki: boolean;
    czySzukacWSparowanych: boolean;
}