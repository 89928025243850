import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { ZmienHasloDoSkrzynkiModel } from 'src/app/modele/administracja/zmienHasloDoSkrzynkiModel';
import { UstawieniaService } from 'src/app/serwisy/administracja/ustawienia.service';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';

@Component({
  selector: 'app-zmien-haslo-do-skrzynki-modal',
  templateUrl: './zmien-haslo-do-skrzynki-modal.component.html',
  styleUrls: ['./zmien-haslo-do-skrzynki-modal.component.css']
})
export class ZmienHasloDoSkrzynkiModalComponent extends BazowyComponent implements OnInit {

  public model = new ZmienHasloDoSkrzynkiModel();

  constructor(
    public ustawieniaSerwis: UstawieniaService,
    public router: Router,
    modalService: NgbModal,
    public activeModal: NgbActiveModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public translate: TranslateService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translate);
  }

  ngOnInit(): void {
  }


  public zapisz(): void {
    this.ustawieniaSerwis.zmienHasloDoSkrzynki(this.model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.activeModal.close();
          return;
        }
        else {
          if (this.bladModelu(rezultat)) {
            this.bledyWalidacji = rezultat.bledyModelu;
          }
          if (rezultat.wiadomosc) {
            this.wyswietlKomunikat(rezultat.wiadomosc, true);
          }
        }
      }
    );
  }

}
