import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DodajUzytkownikaModel } from 'src/app/modele/uzytkownik/dodajUzytkownikaModel';
import { UzytkownikService } from 'src/app/serwisy/konto/uzytkownik.service';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { BazowyComponent } from '../../bazowy.component';
import { ProjektyService } from 'src/app/serwisy/projekty/projekty.service';
import { PobierzProjektyFiltr } from 'src/app/modele/projekty/pobierzProjektyFiltr';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import {
  Sortowanie,
  KierunekSortowania,
} from 'src/app/modele/wspolne/sortowanie';
import { Stronicowanie } from 'src/app/modele/wspolne/stronicowanie';
import { ComboBox } from 'src/app/modele/wspolne/comboBox';
import { Uprawnienie } from 'src/app/modele/wspolne/uprawnienie';

@Component({
  selector: 'app-nowy-uzytkownik-modal',
  templateUrl: './nowy-uzytkownik-modal.component.html',
  styleUrls: ['./nowy-uzytkownik-modal.component.css'],
})
export class NowyUzytkownikModalComponent
  extends BazowyComponent
  implements OnInit
{
  model: DodajUzytkownikaModel = new DodajUzytkownikaModel();
  projekty: ComboBox[];

  constructor(
    public router: Router,
    modalService: NgbModal,
    public activeModal: NgbActiveModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public uzytkownikSerwis: UzytkownikService,
    public authService: AuthService,
    public translate: TranslateService,
    private projektySerwis: ProjektyService
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translate
    );
  }

  ngOnInit(): void {}

  zapisz() {
    this.uzytkownikSerwis.dodajUzytkownika(this.model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.wyswietlKomunikat(
          this.pobierzTlumaczenie('uzytkownikZostalDodany')
        );
        this.activeModal.close(true);
        return;
      }
      if (this.bladModelu(rezultat)) {
        this.bledyWalidacji = rezultat.bledyModelu;
        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  pobierzProjekty() {
    var model: RejestrFiltrowanyRequest<PobierzProjektyFiltr> =
      new RejestrFiltrowanyRequest<PobierzProjektyFiltr>();

    model.stronicowanie = new Stronicowanie(true);
    model.sortowanie = new Sortowanie('NazwaProjektu', KierunekSortowania.asc);
    model.filtry = new PobierzProjektyFiltr();
    model.filtry.aktywne = true;
    this.projektySerwis.PobierzProjekty(model, true).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.projekty = rezultat.listaElementow.map((m) => ({
          id: m.id,
          nazwa: m.nazwaProjektu,
        }));

        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  wybranoUprawnienie() {
    if (this.model.uprawnienie == Uprawnienie.pracownikKlienta) {
      this.pobierzProjekty();
    } else {
      this.model.projektId = null;
    }
  }
}
