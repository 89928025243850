export class HistoriaZmianSrodkaRezultat {
  historia: Array<HistoriaZmianSrodka> = new Array();
}

export class PobierzHistorieZmianSrodkaModel {
  idSrodka: string;
  idProjektu: string;
}

export class HistoriaZmianSrodka {
  dataEdycji: string;
  edytowal: string;
  akcja: string;
  poprzednieDane: string;
  danePoZmianie: string;
}
