import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { ZmienAdresEmailFormularz } from 'src/app/modele/profil/zmien-adres-email-formularz.model';
import { ProfilService } from 'src/app/serwisy/konto/profil.service';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';

@Component({
  selector: 'app-zmiana-email-modal',
  templateUrl: './zmiana-email-modal.component.html'
})
export class ZmianaEmailModalComponent extends BazowyComponent implements OnInit {

  public zmienAdresEmailFormularz = new ZmienAdresEmailFormularz();

  constructor(
    public profilSerwis: ProfilService,
    public router: Router,
    modalService: NgbModal,
    public activeModal: NgbActiveModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public translate: TranslateService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translate);
  }

  ngOnInit(): void {
  }

  public onZapiszNowyEmailClick(): void {
    this._zmienEmail();
  }

  private _zmienEmail(): void {
    this.profilSerwis.zmienAdresEmailIWyslijPotwierdzenie(this.zmienAdresEmailFormularz).subscribe(
      (rezultat) => {
        this.schowajLadowacz();
        if (this.czyRezultatOk(rezultat)) {
          this.activeModal.close();
          return;
        }
        else {
          if (this.bladModelu(rezultat)) {
            this.bledyWalidacji = rezultat.bledyModelu;
          }
          if (rezultat.wiadomosc) {
            this.wyswietlKomunikat(rezultat.wiadomosc, true);
          }
        }
      }
    );
  }

}
