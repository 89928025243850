import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { TakNieModalComponent } from 'src/app/komponenty/ogolne/tak-nie-modal/tak-nie-modal.component';
import { DodatkowyImportLokalizacjiModel } from 'src/app/modele/projekty/lokalizacje/dodatkowyImportLokalizacjiModel';
import { EdytujLokalizacjeModel } from 'src/app/modele/projekty/lokalizacje/edytujLokalizacjeModel';
import { ImportujLokalizacjeModel } from 'src/app/modele/projekty/lokalizacje/importujLokalizacjeModel';
import { NowaLokalizacjaModel } from 'src/app/modele/projekty/lokalizacje/nowaLokalizacjaModel';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { Zalacznik } from 'src/app/modele/wspolne/zalacznik';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { LokalizacjeService } from 'src/app/serwisy/projekty/lokalizacje.service';

@Component({
  selector: 'app-edytuj-lokalizacje-modal',
  templateUrl: './edytuj-lokalizacje-modal.component.html',
  styleUrls: ['./edytuj-lokalizacje-modal.component.css'],
})
export class EdytujLokalizacjeModalComponent
  extends BazowyComponent
  implements OnInit
{
  id: string;
  model: EdytujLokalizacjeModel = new EdytujLokalizacjeModel();

  constructor(
    public router: Router,
    modalService: NgbModal,
    public activeModal: NgbActiveModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public lokalizacjeSerwis: LokalizacjeService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translateSerwis
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.pobierzSzczegoly();
    });
  }

  pobierzSzczegoly() {
    var model = new IdModel();
    model.id = this.id;

    this.lokalizacjeSerwis
      .pobierzSzczegolyLokalizacji(model)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.model.id = this.id;
          this.model.kodPocztowy = rezultat.element.kodPocztowy;
          this.model.mpk = rezultat.element.mpk;
          this.model.miasto = rezultat.element.miasto;
          this.model.numerTelefonu = rezultat.element.numerTelefonu;
          this.model.numerZespolu = rezultat.element.numerZespolu;
          this.model.osobaOdpowiedzialna = rezultat.element.osobaOdpowiedzialna;
          this.model.rodzajLokalizacji = rezultat.element.rodzajLokalizacji;
          this.model.ulica = rezultat.element.ulica;

          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  zapisz() {
    this.lokalizacjeSerwis
      .edytujLokalizacje(this.model)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.wyswietlKomunikat('Lokalizacja została zapisana');
          this.activeModal.close(true);
          return;
        }
        if (this.bladModelu(rezultat)) {
          this.bledyWalidacji = rezultat.bledyModelu;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  usun() {
    var model = new IdModel();
    model.id = this.id;

    this.modalReference = this.modalService.open(
      TakNieModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.pytanie =
      'Czy na pewno chcesz usunąć lokalizację?';

    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.lokalizacjeSerwis.usun(model).subscribe((rezultat) => {
            if (this.czyRezultatOk(rezultat)) {
              this.wyswietlKomunikat('Lokalizacja została usunięta');
              this.activeModal.close(true);
              return;
            }

            this.wyswietlKomunikat(rezultat.wiadomosc, true);
            return;
          });
        }
      },
      (onRejected) => {}
    );
  }
}
