<div class="modal-content">
    <div class="modal-body">
        <button aria-label="Close" class="close" data-dismiss="modal" type="button"
            (click)="activeModal.dismiss()"></button>
        <div class="ft__01 text-center py-4 ">Zmiana hasła do skrzynki</div>
        <div class="text-center pb-4 mb-2">Wprowadź nowe hasło do skrzynki</div>
        <form action="">
            <div class="input_container pb-3" [ngClass]="czyBladWalidacyjny('haslo')?'error':''">
                <input placeholder="Hasło" type="password" name="haslo" [(ngModel)]="model.haslo" />
                <div class="error-info fs__error pt-2" [hidden]="!czyBladWalidacyjny('haslo')">
                    {{pobierzBladWalidacyjny("haslo")}}</div>
            </div>
            <div class="input_container pb-3">
                <button class="btn__primary" (click)="zapisz()">{{ 'zapisz' | translate}}</button>
            </div>
            <div class="input_container pb-3">
                <button class="btn__link" (click)="activeModal.dismiss()">{{ 'zamknij' | translate}}</button>
            </div>
        </form>
    </div>
</div>