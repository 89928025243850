<div class="modal-content">
    <div class="modal-body">
        <button aria-label="Close" class="close" data-dismiss="modal" type="button"
            (click)="activeModal.dismiss()"></button>
        <div class="ft__01 text-center py-4 ">{{szczegolySlownika.nazwa}}</div>
        <div class="text-center mb-2"></div>

        <div class="fixed_table_container">
            <table>
                <tr>
                    <th *ngFor="let kolumna of szczegolySlownika.listaKolumn">{{kolumna}}</th>
                </tr>
                <tr *ngFor="let wartoscSlownika of szczegolySlownika.dane">
                    <td>
                        {{wartoscSlownika.kolumna1}}
                    </td>
                    <td *ngIf="szczegolySlownika.listaKolumn.length>1">
                        {{wartoscSlownika.kolumna2}}
                    </td>
                    <td *ngIf="szczegolySlownika.listaKolumn.length>2">
                        {{wartoscSlownika.kolumna3}}
                    </td>
                    <td *ngIf="szczegolySlownika.listaKolumn.length>3">
                        {{wartoscSlownika.kolumna4}}
                    </td>
                    <td *ngIf="szczegolySlownika.listaKolumn.length==5">
                        {{wartoscSlownika.kolumna5}}
                    </td>
                </tr>
            </table>

        </div>

    </div>
</div>