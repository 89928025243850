import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DodajProjektModel } from 'src/app/modele/projekty/dodajProjektModel';
import { EdytujProjektModel } from 'src/app/modele/projekty/edytujProjektModel';
import { PrzygotujDaneDoEksportuRezultat } from 'src/app/modele/projekty/eksportDanych/przygotujDaneDoEksportuRezultat';
import { PobierzProjektyFiltr } from 'src/app/modele/projekty/pobierzProjektyFiltr';
import { Projekt } from 'src/app/modele/projekty/projekt';
import { SzczegolyProjektu } from 'src/app/modele/projekty/szczegolyProjektu';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { PojedynczyElementResponse } from 'src/app/modele/wspolne/pojedynczyElement';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { ResponseBazowy } from 'src/app/modele/wspolne/responseBazowy';
import { StronicowanaListaWynikowResponse } from 'src/app/modele/wspolne/stronicowanaListaWynikowResponse';
import { SerwisBazowy } from '../bazowy.service';
import { AuthService } from '../ogolne/auth.service';
import { ErrorService } from '../ogolne/error.service';
import { LoadingService } from '../ogolne/loading.service';
import { Zalacznik } from 'src/app/modele/wspolne/zalacznik';
import { AutoryzujDoOswiadczeniaRezultat } from 'src/app/modele/oswiadczenia/autoryzujDoOswiadczeniaRezultat';

@Injectable({
  providedIn: 'root',
})
export class ProjektyService extends SerwisBazowy {
  constructor(
    httpClient: HttpClient,
    authService: AuthService,
    errorService: ErrorService,
    loaderService: LoadingService,
    translate: TranslateService
  ) {
    super(httpClient, authService, errorService, loaderService, translate);
  }

  public PobierzProjekty(
    model: RejestrFiltrowanyRequest<PobierzProjektyFiltr>,
    zLadowaczem: boolean
  ) {
    if (zLadowaczem) {
      return this.wykonajAkcje<
        RejestrFiltrowanyRequest<PobierzProjektyFiltr>,
        StronicowanaListaWynikowResponse<Projekt>
      >(this.AdresApi + 'Projekty/PobierzProjekty', model);
    } else {
      return this.wykonajAkcjeBezLadowacza<
        RejestrFiltrowanyRequest<PobierzProjektyFiltr>,
        StronicowanaListaWynikowResponse<Projekt>
      >(this.AdresApi + 'Projekty/PobierzProjekty', model);
    }
  }

  public dodajProjekt(model: DodajProjektModel) {
    return this.wykonajAkcje<
      DodajProjektModel,
      PojedynczyElementResponse<string>
    >(this.AdresApi + 'Projekty/DodajProjekt', model);
  }

  public pobierzSzczegoly(model: IdModel) {
    return this.wykonajAkcje<
      IdModel,
      PojedynczyElementResponse<SzczegolyProjektu>
    >(this.AdresApi + 'Projekty/PobierzSzczegoly', model);
  }

  public edytujProjekt(model: EdytujProjektModel) {
    return this.wykonajAkcje<EdytujProjektModel, ResponseBazowy>(
      this.AdresApi + 'Projekty/EdytujProjekt',
      model
    );
  }

  usunProjekt(model: IdModel) {
    return this.wykonajAkcje<IdModel, ResponseBazowy>(
      this.AdresApi + 'Projekty/UsunProjekt',
      model
    );
  }

  public pobierzInstrukcje(model: IdModel) {
    return this.wykonajAkcje<IdModel, PojedynczyElementResponse<Zalacznik>>(
      this.AdresApi + 'Projekty/PobierzInstrukcjeWypelnianiaOswiadczen',
      model
    );
  }

  przejdzDoOswiadczenJakoAdmin(model: IdModel) {
    return this.wykonajAkcje<
      IdModel,
      PojedynczyElementResponse<AutoryzujDoOswiadczeniaRezultat>
    >(this.AdresApi + 'Oswiadczenia/AutoryzujJakoAdmin', model);
  }
}
