import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { TakNieModalComponent } from 'src/app/komponenty/ogolne/tak-nie-modal/tak-nie-modal.component';
import { DodatkowyImportLokalizacjiModel } from 'src/app/modele/projekty/lokalizacje/dodatkowyImportLokalizacjiModel';
import { ImportujLokalizacjeModel } from 'src/app/modele/projekty/lokalizacje/importujLokalizacjeModel';
import { NowaLokalizacjaModel } from 'src/app/modele/projekty/lokalizacje/nowaLokalizacjaModel';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { Zalacznik } from 'src/app/modele/wspolne/zalacznik';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { LokalizacjeService } from 'src/app/serwisy/projekty/lokalizacje.service';
import { ImportLokalizacjiBledyModalComponent } from '../import-lokalizacji-bledy-modal/import-lokalizacji-bledy-modal.component';

@Component({
  selector: 'app-import-lokalizacji-modal',
  templateUrl: './import-lokalizacji-modal.component.html',
  styleUrls: ['./import-lokalizacji-modal.component.css']
})
export class ImportLokalizacjiModalComponent extends BazowyComponent implements OnInit {

  idProjektu: string;
  importModel: ImportujLokalizacjeModel = new ImportujLokalizacjeModel();
  importDodatkowyModel: DodatkowyImportLokalizacjiModel = new DodatkowyImportLokalizacjiModel();
  model: NowaLokalizacjaModel = new NowaLokalizacjaModel();
  rodzajImportu: number;
  zalacznik: Zalacznik;

  constructor(
    public router: Router,
    modalService: NgbModal,
    public activeModal: NgbActiveModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public lokalizacjeSerwis: LokalizacjeService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translateSerwis);
  }

  ngOnInit(): void {
    setTimeout(() => {
    });
  }

  zapisz() {
    if (this.rodzajImportu == 0) {
      this.importuj();

    }
    if (this.rodzajImportu == 1) {

      this.dodatkowyImport();
    }
    if (this.rodzajImportu == 2) {
      this.dodajLokalizacjeRecznie();
    }
  }

  usunZalacznik() {
    this.zalacznik = undefined;
  }

  wybierzZalacznikZDysku(event: any) {
    var plik = event.target.files[0];
    this.wybierzZalacznik(plik);

    event.target.value = null;
    return false;
  }

  wybierzZalacznik(plik: File) {
    if (plik) {

      var reader = new FileReader();
      reader.readAsDataURL(plik);
      reader.onload = () => {
        var zalacznik = new Zalacznik();
        zalacznik.nazwaPliku = plik.name;
        zalacznik.plik = this.usunZbedneZnakiZPliku(reader.result.toString());
        if (zalacznik.plik == "data:") {
          return;
        }
        zalacznik.dataModyfikacji = new Date(plik.lastModified);
        zalacznik.format = plik.name.split('.').pop().toLowerCase();

        this.zalacznik = zalacznik;
      };

    }
  }

  usunZbedneZnakiZPliku(base64: string) {
    if (base64.includes(",")) {
      return base64.substring(base64.indexOf(",") + 1);
    }
    return base64;
  }


  importuj() {
    this.importModel.zalacznik = this.zalacznik;
    this.importModel.projektId = this.idProjektu;
    this.lokalizacjeSerwis.importujLokalizacje(this.importModel).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {

          if (this.czySaWynikiNaLiscie(rezultat.element.listaBledow)) {
            this.pokazModalZBledami(rezultat.element.listaBledow)
            return;
          }
          if (rezultat.element.czySaPomieszczenia) {
            this.modalReference = this.modalService.open(TakNieModalComponent, new ModalOpcje(true, 'static'));
            this.modalReference.componentInstance.naglowek = "Lokalizacje posiadają już przypisane pomieszczenia."
            this.modalReference.componentInstance.pytanie = "Czy na pewno chcesz usunąć lokalizacje wraz z danymi?";
            this.modalReference.result.then((result) => {
              if (result) {
                this.importModel.czyNadpisac = true;
                this.importuj();
              }
            }, onRejected => { });

            return;
          }

          this.wyswietlKomunikat("Lokalizacje zostały zaimportowane");
          this.activeModal.close(true);
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }
  dodatkowyImport() {
    this.importDodatkowyModel.zalacznik = this.zalacznik;
    this.importDodatkowyModel.projektId = this.idProjektu;
    this.lokalizacjeSerwis.dodatkowyImportLokalizacji(this.importDodatkowyModel).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {

          if (this.czySaWynikiNaLiscie(rezultat.element.listaBledow)) {
            this.pokazModalZBledami(rezultat.element.listaBledow)
            return;
          }

          this.wyswietlKomunikat("Lokalizacje zostały zaimportowane");
          this.activeModal.close(true);
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }

  dodajLokalizacjeRecznie() {
    this.model.projektId = this.idProjektu;
    this.lokalizacjeSerwis.dodajLokalizacjeRecznie(this.model).subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.wyswietlKomunikat("Lokalizacja została dodana");
          this.activeModal.close(true);
          return;
        }
        if (this.bladModelu(rezultat)) {
          this.bledyWalidacji = rezultat.bledyModelu;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      }
    );
  }

  pokazModalZBledami(bledy: Array<string>) {
    this.modalReference = this.modalService.open(ImportLokalizacjiBledyModalComponent, new ModalOpcje(true, 'static'));
    this.modalReference.componentInstance.listaBledow = bledy;
  }
}
