import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LogowanieComponent } from './komponenty/konto/logowanie/logowanie.component';
import { PotwierdzEmailComponent } from './komponenty/konto/profil/potwierdz-email/potwierdz-email.component';
import { UstawieniaProfiluComponent } from './komponenty/konto/profil/ustawienia-profilu/ustawienia-profilu.component';
import { PotwierdzKontoComponent } from './komponenty/konto/potwierdz-konto/potwierdz-konto.component';
import { ZmianaHaslaEmailComponent } from './komponenty/konto/zmiana-hasla-email/zmiana-hasla-email.component';
import { ZmianaHaslaNoweHasloComponent } from './komponenty/konto/zmiana-hasla-nowe-haslo/zmiana-hasla-nowe-haslo.component';
import { UzytkownicyComponent } from './komponenty/uzytkownicy/uzytkownicy/uzytkownicy.component';
import { Routing } from './modele/wspolne/routing';
import { AuthGuardService } from './serwisy/ogolne/auth.service';
import { ListaProjektowComponent } from './komponenty/projekty/lista-projektow/lista-projektow.component';
import { SzczegolyProjektuComponent } from './komponenty/projekty/szczegoly-projektu/szczegoly-projektu.component';
import { NowyProjektComponent } from './komponenty/projekty/nowy-projekt/nowy-projekt.component';
import { EwidencjaComponent } from './komponenty/projekty/ewidencja/ewidencja.component';
import { EdytujProjektComponent } from './komponenty/projekty/edytuj-projekt/edytuj-projekt.component';
import { LokalizacjeComponent } from './komponenty/projekty/lokalizacje/lokalizacje.component';
import { SlownikiComponent } from './komponenty/projekty/slowniki/slowniki.component';
import { SlownikiDomyslneComponent } from './komponenty/administracja/slowniki-domyslne/slowniki-domyslne.component';
import { ZespolyComponent } from './komponenty/projekty/zespoly/zespoly.component';
import { PolaSpisuComponent } from './komponenty/projekty/pola-spisu/pola-spisu.component';
import { SpisComponent } from './komponenty/projekty/spis/spis.component';
import { KonfiguracjaPomieszczenComponent } from './komponenty/projekty/konfiguracja-pomieszczen/konfiguracja-pomieszczen.component';
import { PomieszczeniaLokalizacjiComponent } from './komponenty/projekty/spis/pomieszczenia-lokalizacji/pomieszczenia-lokalizacji.component';
import { SpisaneSrodkiLokalizacjiComponent } from './komponenty/projekty/spis/spisane-srodki-lokalizacji/spisane-srodki-lokalizacji.component';
import { NadwyzkiSrodkowLokalizacjiComponent } from './komponenty/projekty/spis/nadwyzki-srodkow-lokalizacji/nadwyzki-srodkow-lokalizacji.component';
import { NiedoborySrodkowLokalizacjiComponent } from './komponenty/projekty/spis/niedobory-srodkow-lokalizacji/niedobory-srodkow-lokalizacji.component';
import { SpisaneSrodkiPomieszczeniaComponent } from './komponenty/projekty/spis/spisane-srodki-pomieszczenia/spisane-srodki-pomieszczenia.component';
import { NadwyzkiSrodkowPomieszczeniaComponent } from './komponenty/projekty/spis/nadwyzki-srodkow-pomieszczenia/nadwyzki-srodkow-pomieszczenia.component';
import { SpisSrodkaComponent } from './komponenty/projekty/spis/spis-srodka/spis-srodka.component';
import { EdycjaSrodkaComponent } from './komponenty/projekty/spis/edycja-srodka/edycja-srodka.component';
import { WyborRaportuComponent } from './komponenty/projekty/spis/wybor-raportu/wybor-raportu.component';
import { GenerowanieArkuszaSpisuComponent } from './komponenty/projekty/spis/generowanie-arkusza-spisu/generowanie-arkusza-spisu.component';
import { ParowanieSrodkowComponent } from './komponenty/projekty/spis/parowanie-srodkow/parowanie-srodkow.component';
import { UstawieniaComponent } from './komponenty/administracja/ustawienia/ustawienia.component';
import { AutoryzacjaEmailComponent } from './komponenty/oswiadczenia/autoryzacja-email/autoryzacja-email.component';
import { OswiadczeniaComponent } from './komponenty/oswiadczenia/oswiadczenia/oswiadczenia.component';
import { AutoryzacjaKodComponent } from './komponenty/oswiadczenia/autoryzacja-kod/autoryzacja-kod.component';
import { InstrukcjaWypelnianiaOswiadczeniaComponent } from './komponenty/oswiadczenia/instrukcja-wypelniania-oswiadczenia/instrukcja-wypelniania-oswiadczenia.component';
import { DaneUzytkownikaOswiadczeniaComponent } from './komponenty/oswiadczenia/dane-uzytkownika-oswiadczenia/dane-uzytkownika-oswiadczenia.component';

const routes: Routes = [
  { path: '', redirectTo: Routing.Projekty, pathMatch: 'full' },
  {
    path: Routing.Zaloguj,
    component: LogowanieComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.OswiadczeniaInstrukcja + '/:id',
    component: InstrukcjaWypelnianiaOswiadczeniaComponent,
    // canActivate: [AuthGuardService],
  },
  {
    path: Routing.OswiadczeniaDanePodstawowe + '/:id',
    component: DaneUzytkownikaOswiadczeniaComponent,
    // canActivate: [AuthGuardService],
  },
  {
    path: Routing.Oswiadczenia + '/:id',
    component: OswiadczeniaComponent,
    //canActivate: [AuthGuardService],
  },
  {
    path: Routing.OswiadczeniaAutoryzacja + '/:id',
    component: AutoryzacjaEmailComponent,
    //canActivate: [AuthGuardService],
  },
  {
    path: Routing.OswiadczeniaAutoryzacjaKod + '/:id',
    component: AutoryzacjaKodComponent,
    //canActivate: [AuthGuardService],
  },
  {
    path: Routing.RejestracjaPotwierdzenie + '/:kod',
    component: PotwierdzKontoComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.ZmienHaslo,
    component: ZmianaHaslaEmailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.NoweHaslo + '/:token',
    component: ZmianaHaslaNoweHasloComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.Profil,
    component: UstawieniaProfiluComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.ZmianaEmail + '/:token',
    component: PotwierdzEmailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.Uzytkownicy,
    component: UzytkownicyComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.Projekty,
    component: ListaProjektowComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.NowyProjekt,
    component: NowyProjektComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.SzczegolyProjektu + '/:id',
    component: SzczegolyProjektuComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.SzczegolyProjektu + '/:id/:akcja',
    component: SzczegolyProjektuComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.EdytujProjekt + '/:id',
    component: EdytujProjektComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.Ewidencja + '/:id',
    component: EwidencjaComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.Lokalizacje + '/:id',
    component: LokalizacjeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.SlownikiProjektu + '/:id',
    component: SlownikiComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.Zespoly + '/:id',
    component: ZespolyComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.PolaSpisu + '/:id',
    component: PolaSpisuComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.Spis + '/:id',
    component: SpisComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.KonfiguracjaPomieszczen + '/:id',
    component: KonfiguracjaPomieszczenComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.SlownikiDomyslne,
    component: SlownikiDomyslneComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.PomieszczeniaLokalizacji + '/:idProjektu/:idLokalizacji',
    component: PomieszczeniaLokalizacjiComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.SpisaneSrodkiLokalizacji + '/:idProjektu/:idLokalizacji',
    component: SpisaneSrodkiLokalizacjiComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.NadwyzkiLokalizacji + '/:idProjektu/:idLokalizacji',
    component: NadwyzkiSrodkowLokalizacjiComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.NiedoboryLokalizacji + '/:idProjektu/:idLokalizacji',
    component: NiedoborySrodkowLokalizacjiComponent,
    canActivate: [AuthGuardService],
  },
  {
    path:
      Routing.SpisaneSrodkiPomieszczenia +
      '/:idProjektu/:idLokalizacji/:idPomieszczenia',
    component: SpisaneSrodkiPomieszczeniaComponent,
    canActivate: [AuthGuardService],
  },
  {
    path:
      Routing.NadwyzkiPomieszczenia +
      '/:idProjektu/:idLokalizacji/:idPomieszczenia',
    component: NadwyzkiSrodkowPomieszczeniaComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.SpisSrodka + '/:idProjektu/:idLokalizacji/:idPomieszczenia',
    component: SpisSrodkaComponent,
    canActivate: [AuthGuardService],
  },
  {
    path:
      Routing.EdycjaSrodka + '/:idProjektu/:idLokalizacji/:idPomieszczenia/:id',
    component: EdycjaSrodkaComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.EdycjaSrodka + '/:idProjektu/:idLokalizacji/:id',
    component: EdycjaSrodkaComponent,
    canActivate: [AuthGuardService],
  },
  {
    path:
      ':miejsceWywolania/' +
      Routing.WyborRaportu +
      '/:idProjektu/:idLokalizacji',
    component: WyborRaportuComponent,
    canActivate: [AuthGuardService],
  },
  {
    path:
      ':miejsceWywolania/' +
      Routing.WyborRaportu +
      '/:idProjektu/:idLokalizacji/:idPomieszczenia',
    component: WyborRaportuComponent,
    canActivate: [AuthGuardService],
  },
  {
    path:
      ':miejsceWywolania/' +
      Routing.ArkuszSpisu +
      '/:idProjektu/:idLokalizacji',
    component: GenerowanieArkuszaSpisuComponent,
    canActivate: [AuthGuardService],
  },
  {
    path:
      ':miejsceWywolania/' +
      Routing.ArkuszSpisu +
      '/:idProjektu/:idLokalizacji/:idPomieszczenia',
    component: GenerowanieArkuszaSpisuComponent,
    canActivate: [AuthGuardService],
  },
  {
    path:
      ':miejsceWywolania/' + Routing.Parowanie + '/:idProjektu/:idLokalizacji',
    component: ParowanieSrodkowComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.Ustawienia,
    component: UstawieniaComponent,
    canActivate: [AuthGuardService],
  },

  { path: '**', redirectTo: Routing.Zaloguj, pathMatch: 'full' }, // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [AuthGuardService],
  exports: [RouterModule],
})
export class AppRoutingModule {}
