export class PobierzLokalizacjeDoSpisuFiltr {
    lp: string;
    ulica: string;
    miasto: string;
    kodPocztowy: string;
    projektId: string;
    numerZespolu: string;
    sumaSpisanychSrodkow: string;
    sparowaneSrodki: string
    srodkiZEwidencji: string;
}