import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { EdytujDaneKontaModel } from 'src/app/modele/profil/edytujDaneKontaModel';
import { PobierzDaneKontaRezultat } from 'src/app/modele/profil/pobierzDaneKontaRezultat';
import { UzupelnijDaneKontaModel } from 'src/app/modele/profil/uzupelnijDaneKontaModel';
import { ZmienAdresEmailFormularz } from 'src/app/modele/profil/zmien-adres-email-formularz.model';
import { Ciasteczko } from 'src/app/modele/wspolne/ciasteczka';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { Uprawnienie } from 'src/app/modele/wspolne/uprawnienie';
import { ProfilService } from 'src/app/serwisy/konto/profil.service';
import { ZmienHasloService } from 'src/app/serwisy/konto/zmien-haslo.service';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { ZmianaEmailModalComponent } from '../zmiana-email-modal/zmiana-email-modal.component';

@Component({
  selector: 'app-ustawienia-profilu',
  templateUrl: './ustawienia-profilu.component.html'

})
export class UstawieniaProfiluComponent extends BazowyComponent implements OnInit {

  public model: EdytujDaneKontaModel = new EdytujDaneKontaModel();
  public daneKonta: PobierzDaneKontaRezultat = new PobierzDaneKontaRezultat();
  public zmienAdresEmailFormularz = new ZmienAdresEmailFormularz();
  public czyZaladowanoDane: boolean = false;

  constructor(
    private profilService: ProfilService,
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    private komunikatSerwis: ToastrService,
    public authService: AuthService,
    private zmienHasloSerwis: ZmienHasloService,
    private activeModal: NgbModal,
    public translate: TranslateService) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router, translate);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.pobierzDaneDoEkranu();
      this.activeModal.dismissAll();
    });
  }

  private pobierzDaneDoEkranu() {
    this.profilService.pobierzDaneKonta().subscribe((rezultat) => {
      this.czyZaladowanoDane = true;
      if (this.czyRezultatOk(rezultat)) {
        this.daneKonta = rezultat.element;
        this.model.imie = rezultat.element.imie;
        this.model.nazwisko = rezultat.element.nazwisko;
        this.model.numerTelefonu = rezultat.element.numerTelefonu;

        this.zmienAdresEmailFormularz.adresEmail = rezultat.element.email;
        return;
      } else {
        this.wyswietlKomunikat(rezultat.wiadomosc, true);
      }
    }
    );
  }

  public zapisz() {
    this.profilService.edytujDaneKonta(this.model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.wyswietlKomunikat(this.pobierzTlumaczenie("daneZostalyZapisane"));
        var daneUzytkownika = this.pobierzDaneZalogowanegoUzytkownika();
        daneUzytkownika.imie = rezultat.element.imie;
        daneUzytkownika.nazwisko = rezultat.element.nazwisko;
        this.ustawCiasteczko(Ciasteczko.DaneZalogowanegoUzytkownika, daneUzytkownika);
        this.bledyWalidacji = null;
        return;
      } else if (this.bladModelu(rezultat)) {
        this.bledyWalidacji = rezultat.bledyModelu;
        return;
      }
      this.wyswietlKomunikat(rezultat.wiadomosc, true);
    }
    );
  }


  public onZmienEmailBtnClick(): void {

    const zmianaEmailModalRef = this.modalService.open(ZmianaEmailModalComponent, new ModalOpcje(true, 'static'));
    zmianaEmailModalRef.result.then((result) => {
      this.wyswietlKomunikat(this.pobierzTlumaczenie("zmienEmail.komunikat"));
    }, onRejected => { });

  }

  public onZmienHasloClick() {
    this.zmienHasloSerwis.zmienHasloZalogowanyMail().subscribe(
      (rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.wyswietlInformacje(this.pobierzTlumaczenie("zmianaHasla.wyslanoEmailKomunikat"));
          return;
        }
        else {
          this.wyswietlKomunikat(rezultat.wiadomosc, true);
        }
      }
    );
  }
  pobierzInicjalyUzytkownika() {
    var imie = this.daneKonta.imie;
    var nazwisko = this.daneKonta.nazwisko;

    return imie.slice(0, 1) + nazwisko.slice(0, 1);
  }

  pobierzRole() {
    if (this.daneKonta.rola == Uprawnienie.wykonawca) {
      return this.pobierzTlumaczenie("wykonawca");
    }
    if (this.daneKonta.rola == Uprawnienie.administrator) {
      return this.pobierzTlumaczenie("administrator");
    }
  }

}
