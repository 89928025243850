import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AutoryzujDoOswiadczeniaRezultat } from 'src/app/modele/oswiadczenia/autoryzujDoOswiadczeniaRezultat';
import { Routing } from 'src/app/modele/wspolne/routing';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { MenuService } from 'src/app/serwisy/ogolne/menu.service';
import { OswiadczeniaService } from 'src/app/serwisy/oswiadczenia/oswiadczenia.service';
import { BazowyComponent } from '../../bazowy.component';
import { ComboBox } from 'src/app/modele/wspolne/comboBox';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import {
  PobierzDaneDoUzupelnieniaDanychPodstawowychUzytkownikaRezultat,
  WartoscPolaDynamicznego,
} from 'src/app/modele/oswiadczenia/pobierzDaneDoUzupelnieniaDanychPodstawowychUzytkownikaRezultat';
import { ZapiszDanePodstawoweUzytkownikaDoOswiadczenia } from 'src/app/modele/oswiadczenia/zapiszDanePodstawoweUzytkownikaDoOswiadczenia';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { OswiadczeniaSesjaService } from 'src/app/serwisy/oswiadczenia/oswiadczenia-sesja.service';

@Component({
  selector: 'app-dane-uzytkownika-oswiadczenia',
  templateUrl: './dane-uzytkownika-oswiadczenia.component.html',
  styleUrls: ['./dane-uzytkownika-oswiadczenia.component.css'],
})
export class DaneUzytkownikaOswiadczeniaComponent
  extends BazowyComponent
  implements OnInit
{
  id: string;
  nazwaKlienta: string;
  czyMoznaDodawacOswiadczenia: boolean = false;
  uzytkownik: AutoryzujDoOswiadczeniaRezultat;
  zaladowanoDane: boolean;
  lokalizacje: Array<ComboBox> = new Array();
  model: ZapiszDanePodstawoweUzytkownikaDoOswiadczenia =
    new ZapiszDanePodstawoweUzytkownikaDoOswiadczenia();
  keyword = 'nazwa';
  przefiltrowaneLokalizacje: Array<ComboBox> = new Array();
  @ViewChild('lokalizacjaAutocomplete') lokalizacjaAutocomplete;

  lokalizacjaObs: Observable<ComboBox[]>;
  lokalizacjaFC = new UntypedFormControl();

  constructor(
    public authService: AuthService,
    public router: Router,
    private oswiadczeniaSerwis: OswiadczeniaService,
    modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public translate: TranslateService,
    public menuService: MenuService,
    private sesjaSerwis: OswiadczeniaSesjaService
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translate
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.menuService.schowajMenu();
      this.id = this.activatedRoute.snapshot.paramMap.get('id');

      this.inicjujJezyk();
      this.uzytkownik = JSON.parse(localStorage.getItem('uzytkownik'));
      if (!this.uzytkownik || this.uzytkownik.idProjektu != this.id) {
        this.sesjaSerwis.wyczyscSesjeBezZmianyStrony();
        this.router.navigate([Routing.OswiadczeniaAutoryzacja, this.id]);
      }
      if (!this.uzytkownik.potwierdzonoZapoznanieZInstrukcja) {
        this.router.navigate([Routing.OswiadczeniaInstrukcja, this.id]);
      }
      this.pobierzDaneDoEkranu();
    });
  }

  public pobierzDaneDoEkranu() {
    try {
      this.oswiadczeniaSerwis
        .pobierzDaneDoUzupelnieniaDanychPodstawowychUzytkownika()
        .subscribe((rezultat) => {
          if (this.czyRezultatOk(rezultat)) {
            this.nazwaKlienta = rezultat.element.nazwaKlienta;
            this.czyMoznaDodawacOswiadczenia = rezultat.element.czyWlaczone;
            this.lokalizacje.push({ id: 'inna', nazwa: 'Inna' });
            this.lokalizacje.push(...rezultat.element.lokalizacje);

            this.przefiltrowaneLokalizacje = this.lokalizacje;

            this.uzupelnijWartosciStartowe(rezultat.element);
            this.zaladowanoDane = true;
            return;
          }

          this.wyswietlKomunikat(rezultat.wiadomosc, true);
        });
    } catch (blad) {}
  }

  public potwierdz() {
    try {
      this.model.idUzytkownika = this.uzytkownik.idUzytkownika;
      this.model.idProjektu = this.id;
      this.oswiadczeniaSerwis
        .zapiszDanePodstawoweUzytkownika(this.model)
        .subscribe((rezultat) => {
          if (this.czyRezultatOk(rezultat)) {
            this.uzytkownik.danePodstawowe = this.model;
            localStorage.removeItem('uzytkownik');
            localStorage.setItem('uzytkownik', JSON.stringify(this.uzytkownik));
            this.sesjaSerwis.zapiszOstatniaAktywnosc();
            this.router.navigate([Routing.Oswiadczenia, this.id]);
            return;
          }
          if (this.bladModelu(rezultat)) {
            this.bledyWalidacji = rezultat.bledyModelu;
            return;
          }
          this.wyswietlKomunikat(rezultat.wiadomosc, true);
        });
    } catch (blad) {}
  }

  uzupelnijWartosciStartowe(
    element: PobierzDaneDoUzupelnieniaDanychPodstawowychUzytkownikaRezultat
  ) {
    this.model.imie = element.imie;
    this.model.nazwisko = element.nazwisko;
    this.model.telefon = element.telefon;
    this.model.adresLokalizacji = element.adresLokalizacji;
    this.model.lokalizacjaId = !element.lokalizacjaId
      ? undefined
      : element.lokalizacjaId;
    this.model.polaDynamiczne = element.polaDynamiczne;
  }

  onFocusOutEvent($event: any) {
    if (!this.model.lokalizacjaId && this.lokalizacjaAutocomplete.query) {
      this.lokalizacjaAutocomplete.clear();
    }
  }

  wybranoLokalizacje(e) {
    debugger;
    alert(e);
  }

  customSearchFn(term: string, item: ComboBox) {
    term = term.toLocaleLowerCase();
    return item.nazwa.toLocaleLowerCase().indexOf(term) > -1;
  }
}
