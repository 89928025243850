<div class="modal-content">
  <div class="modal-body">
    <button
      aria-label="Close"
      class="close"
      data-dismiss="modal"
      type="button"
      (click)="activeModal.dismiss()"
    ></button>
    <div class="ft__01 text-center py-4 mb-3">
      {{ "dodawanieUzytkownika" | translate }}
    </div>

    <form action="">
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('imie') ? 'error' : ''"
      >
        <input
          placeholder="{{ 'imie' | translate }}"
          type="text"
          name="imie"
          [(ngModel)]="model.imie"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('imie')"
        >
          {{ pobierzBladWalidacyjny("imie") }}
        </div>
      </div>
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('nazwisko') ? 'error' : ''"
      >
        <input
          placeholder="{{ 'nazwisko' | translate }}"
          type="text"
          name="nazwisko"
          [(ngModel)]="model.nazwisko"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('nazwisko')"
        >
          {{ pobierzBladWalidacyjny("nazwisko") }}
        </div>
      </div>
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('email') ? 'error' : ''"
      >
        <input
          placeholder="E-mail"
          type="text"
          name="email"
          [(ngModel)]="model.email"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('email')"
        >
          {{ pobierzBladWalidacyjny("email") }}
        </div>
      </div>
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('numerTelefonu') ? 'error' : ''"
      >
        <input
          placeholder="{{ 'numerTelefonu' | translate }}"
          type="text"
          name="numerTelefonu"
          [(ngModel)]="model.numerTelefonu"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('numerTelefonu')"
        >
          {{ pobierzBladWalidacyjny("numerTelefonu") }}
        </div>
      </div>
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('uprawnienie') ? 'error' : ''"
      >
        <select
          [(ngModel)]="model.uprawnienie"
          name="uprawnienie"
          (change)="wybranoUprawnienie()"
        >
          <option [ngValue]="undefined" [disabled]="true">
            {{ "rola" | translate }}
          </option>
          <option [ngValue]="0">{{ "wykonawca" | translate }}</option>
          <option [ngValue]="1" selected>
            {{ "administrator" | translate }}
          </option>
          <option [ngValue]="3" selected>Pracownik klienta</option>
        </select>
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('uprawnienie')"
        >
          {{ pobierzBladWalidacyjny("uprawnienie") }}
        </div>
      </div>

      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('projektId') ? 'error' : ''"
        *ngIf="model.uprawnienie == 3"
      >
        <ng-select
          placeholder="Projekt"
          [searchable]="true"
          [clearable]="true"
          [multiple]="false"
          [items]="projekty"
          [(ngModel)]="model.projektId"
          bindValue="id"
          bindLabel="nazwa"
          [ngModelOptions]="{ standalone: true }"
          [searchFn]="customSearchFn"
          [editableSearchTerm]="false"
          notFoundText="Brak projektu"
        >
        </ng-select>

        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('projektId')"
        >
          {{ pobierzBladWalidacyjny("projektId") }}
        </div>
      </div>

      <div class="input_container pb-3 mt-4">
        <button class="btn__primary" (click)="zapisz()">
          {{ "zapisz" | translate }}
        </button>
      </div>
      <div class="input_container pb-3">
        <button class="btn__link" (click)="activeModal.dismiss()">
          {{ "anuluj" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
