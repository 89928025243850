import { Uprawnienie } from '../wspolne/uprawnienie';

export class DodajUzytkownikaModel {
  imie: string;
  nazwisko: string;
  email: string;
  numerTelefonu: string;
  uprawnienie?: Uprawnienie;
  projektId: string;
}
