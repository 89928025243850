import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { KolumnaEwidencjiFiltrowanie } from 'src/app/modele/projekty/ewidencja/kolumnaEwidencjiFiltrowanie';
import { PobierzNiedoboryLokalizacjiFiltry } from 'src/app/modele/projekty/spis/pobierzNiedoboryLokalizacjiFiltry';
import { PobierzSpisaneSrodkiLokalizacjiFiltry } from 'src/app/modele/projekty/spis/pobierzSpisaneSrodkiLokalizacjiFiltry';
import { StatusProjektu } from 'src/app/modele/projekty/statusProjektu';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { Routing } from 'src/app/modele/wspolne/routing';
import {
  Sortowanie,
  KierunekSortowania,
} from 'src/app/modele/wspolne/sortowanie';
import { Stronicowanie } from 'src/app/modele/wspolne/stronicowanie';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { SpisService } from 'src/app/serwisy/projekty/spis.service';
import { DodajKomentarzDoEwidencjiModalComponent } from '../../ewidencja/dodaj-komentarz-do-ewidencji-modal/dodaj-komentarz-do-ewidencji-modal.component';
import { RodzajEksportuModalComponent } from '../eksport-danych/rodzaj-eksportu-modal/rodzaj-eksportu-modal.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-niedobory-srodkow-lokalizacji',
  templateUrl: './niedobory-srodkow-lokalizacji.component.html',
  styleUrls: ['./niedobory-srodkow-lokalizacji.component.css'],
})
export class NiedoborySrodkowLokalizacjiComponent
  extends BazowyComponent
  implements OnInit
{
  idLokalizacji: string;
  idProjektu: string;
  czyZaladowanoDane: boolean;
  calkowitaLiczbaElementow: BigInteger;
  rozmiarTabeli: number = 25;
  czyJestWiecejRekordow: boolean = true;
  czyPokazacMenuSortowanie: boolean;
  wyszukiwanie: RejestrFiltrowanyRequest<PobierzNiedoboryLokalizacjiFiltry> =
    new RejestrFiltrowanyRequest<PobierzNiedoboryLokalizacjiFiltry>();
  rekordyEwidencji: Array<Array<string>> = new Array();
  kolumnyTabeli: Array<string> = new Array();
  czyPierwszeUruchomienie: boolean = true;
  nazwaProjektu: string;
  adresLokalizacji: string;
  czyPokazanoWyszukiwarkeMobile: boolean;
  czyPokazanoPodmenu: boolean;
  kolumnaKomentarza: number;
  kolumnaId: number;
  statusProjektu: StatusProjektu;
  czyZaimportowanoEwidencje: boolean;
  subsription: Subscription | undefined;

  slideConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: document.getElementsByClassName('btn__left'),
    nextArrow: document.getElementsByClassName('btn__right'),
    infinite: false,
    variableWidth: true,
    initialSlide: 3,
  };

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public spisSerwis: SpisService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translateSerwis
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.idLokalizacji =
        this.activatedRoute.snapshot.paramMap.get('idLokalizacji');
      this.idProjektu = this.activatedRoute.snapshot.paramMap.get('idProjektu');
      this.wyszukiwanie.filtry = new PobierzNiedoboryLokalizacjiFiltry();
      this.wyszukiwanie.sortowanie = new Sortowanie(
        'lp',
        KierunekSortowania.asc
      );
      this.wyszukiwanie.stronicowanie = new Stronicowanie(false);
      this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
      this.wyszukiwanie.stronicowanie.strona = 1;

      this.pobierzDaneDoEkranu(true);
    });
  }

  pobierzDaneDoEkranu(zLadowaczem: boolean) {
    this.wyszukiwanie.filtry.idLokalizacji = this.idLokalizacji;
    this.subsription = this.spisSerwis
      .pobierzNiedoboryLokalizacji(this.wyszukiwanie, zLadowaczem)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.rekordyEwidencji = rezultat.element.dane;
          this.calkowitaLiczbaElementow =
            rezultat.element.calkowitaIloscRekordow;
          this.czyJestWiecejRekordow = rezultat.element.czyJestWiecejRekordow;
          this.kolumnyTabeli = rezultat.element.kolumny;
          this.nazwaProjektu = rezultat.element.nazwaProjektu;
          this.adresLokalizacji = rezultat.element.adresLokalizacji;
          this.statusProjektu = rezultat.element.statusProjektu;
          this.czyZaimportowanoEwidencje =
            rezultat.element.czyZaimportowanoEwidencje;

          this.kolumnaKomentarza = this.kolumnyTabeli.indexOf('Komentarz');
          this.kolumnaId = this.kolumnyTabeli.indexOf('id');

          if (this.czyPierwszeUruchomienie) {
            this.przygotujFiltry();
            this.czyPierwszeUruchomienie = false;
          }

          this.czyZaladowanoDane = true;

          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  zmienRozmiarTabeli() {
    this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
    this.pobierzDaneDoEkranu(true);
  }

  pobierzNumerRekorduPoczatek() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return this.wyszukiwanie.stronicowanie.rekordPoczatkowy + 1;
  }
  pobierzNumerRekorduKoniec() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return (
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy +
      this.rekordyEwidencji.length
    );
  }

  zmienStrone(oIle: number) {
    this.wyszukiwanie.stronicowanie.strona += oIle;
    this.wyszukiwanie.stronicowanie.rekordPoczatkowy =
      (this.wyszukiwanie.stronicowanie.strona - 1) *
      this.wyszukiwanie.stronicowanie.iloscRekordow;
    this.pobierzDaneDoEkranu(true);
  }

  cofnij() {
    this.router.navigate([Routing.Spis, this.idProjektu]);
  }

  pobierzKierunekSortowania(kolumna: string) {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      return '';
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      return 'up';
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      return 'down';
    }
  }

  zmienKierunekSortowania(kolumna: string) {
    this.czyPokazacMenuSortowanie = false;
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.desc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        'id',
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
  }

  wyczyscFiltry() {
    this.wyszukiwanie.filtry.kolumnyFiltrowania.forEach((k) => {
      k.wartoscSzukaj = '';
    });
    this.pobierzDaneDoEkranu(true);
  }

  filtrujPoTekscie(event: any, kolumna: string) {
    this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
    this.wyszukiwanie.stronicowanie.strona = 1;
    var wartosc = event.target.value.toString();

    this.ustawWartoscFiltrowania(kolumna, wartosc);
    this.subsription?.unsubscribe();
    this.pobierzDaneDoEkranu(false);
  }

  ustawWartoscFiltrowania(kolumna: string, wartosc: string) {
    var kolumnaFiltrowania = this.wyszukiwanie.filtry.kolumnyFiltrowania.find(
      (m) => m.kolumna == kolumna
    );

    if (kolumnaFiltrowania == undefined) {
      kolumnaFiltrowania = new KolumnaEwidencjiFiltrowanie();
      kolumnaFiltrowania.kolumna = kolumna;
      kolumnaFiltrowania.wartoscSzukaj = wartosc;
      this.wyszukiwanie.filtry.kolumnyFiltrowania.push(kolumnaFiltrowania);
    } else {
      this.wyszukiwanie.filtry.kolumnyFiltrowania.forEach((k) => {
        if (k.kolumna == kolumna) {
          k.wartoscSzukaj = wartosc;
        }
      });
    }
  }

  czyPokazacPrzyciskCzyszczeniaFiltru(kolumna: string) {
    var kolumnaFiltrowania = this.wyszukiwanie.filtry.kolumnyFiltrowania.find(
      (m) => m.kolumna == kolumna
    );
    return kolumnaFiltrowania != undefined && kolumnaFiltrowania.wartoscSzukaj;
  }
  wyczyscFiltrKolumny(kolumna: string) {
    this.wyszukiwanie.filtry.kolumnyFiltrowania.forEach((k) => {
      if (k.kolumna == kolumna) {
        k.wartoscSzukaj = '';
      }
    });
    this.pobierzDaneDoEkranu(false);
  }

  przygotujFiltry() {
    this.wyszukiwanie.filtry.kolumnyFiltrowania = new Array();
    this.kolumnyTabeli.forEach((kolumna) => {
      if (kolumna == 'id') {
        return;
      }
      var kolumnaFiltrowania = new KolumnaEwidencjiFiltrowanie();
      kolumnaFiltrowania.kolumna = kolumna;
      kolumnaFiltrowania.wartoscSzukaj = '';
      this.wyszukiwanie.filtry.kolumnyFiltrowania.push(kolumnaFiltrowania);
    });
  }

  czyPokazacWyczyscFiltry() {
    return (
      this.wyszukiwanie.filtry.kolumnyFiltrowania.filter((m) => m.wartoscSzukaj)
        .length > 0
    );
  }
  pokazWyszukiwarkeMobile(czyPokazac: boolean) {
    this.czyPokazanoWyszukiwarkeMobile = czyPokazac;
    this.czyPokazanoPodmenu = false;
  }

  pokazEkranPomieszczen() {
    this.router.navigate([
      Routing.PomieszczeniaLokalizacji,
      this.idProjektu,
      this.idLokalizacji,
    ]);
  }

  pokazEkranNadwyzek() {
    this.router.navigate([
      Routing.NadwyzkiLokalizacji,
      this.idProjektu,
      this.idLokalizacji,
    ]);
  }

  pokazEkranSpisanychSrodkow() {
    this.router.navigate([
      Routing.SpisaneSrodkiLokalizacji,
      this.idProjektu,
      this.idLokalizacji,
    ]);
  }

  pokazEkranNiedoborow() {
    this.router.navigate([
      Routing.NiedoboryLokalizacji,
      this.idProjektu,
      this.idLokalizacji,
    ]);
  }

  pokazPodmenu() {
    this.czyPokazanoPodmenu = !this.czyPokazanoPodmenu;
    this.czyPokazanoWyszukiwarkeMobile = false;
  }

  czyKolumnaLp(kolumna: string) {
    return kolumna.toLowerCase().includes('lp');
  }

  dodajKomentarz(rekord: Array<string>, tresc: string) {
    var id = rekord[this.kolumnaId];

    this.modalReference = this.modalService.open(
      DodajKomentarzDoEwidencjiModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.idProjektu = this.idProjektu;
    this.modalReference.componentInstance.id = id;
    this.modalReference.componentInstance.tresc = tresc;
    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.pobierzDaneDoEkranu(true);
        }
      },
      (onRejected) => {}
    );
  }

  wygenerujArkusz() {
    this.router.navigate([
      '4',
      Routing.WyborRaportu,
      this.idProjektu,
      this.idLokalizacji,
    ]);
  }
  paruj() {
    this.router.navigate([
      '2',
      Routing.Parowanie,
      this.idProjektu,
      this.idLokalizacji,
    ]);
  }

  czyProjektZarchiwizowany() {
    return this.statusProjektu == StatusProjektu.zarchiwizowany;
  }
  czyMoznaParowac() {
    return (
      this.statusProjektu != StatusProjektu.zarchiwizowany &&
      this.czyZaimportowanoEwidencje &&
      !this.czyPracownikKlienta()
    );
  }

  eksportujDane() {
    this.modalReference = this.modalService.open(
      RodzajEksportuModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.idProjektu = this.idProjektu;
    this.modalReference.componentInstance.idLokalizacji = this.idLokalizacji;
    this.modalReference.componentInstance.rodzajEksportu = 'niedobory';
  }
}
