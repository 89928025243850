import { Zalacznik } from "../../wspolne/zalacznik";
import { RodzajSlownika } from "./rodzajSlownika";
import { WartoscSlownika } from "./wartoscSlownika";

export class SzczegolySlownika {
    projektId: string;
    id: string;
    nazwa: string;
    listaKolumn: Array<string> = new Array();
    dane: Array<WartoscSlownika> = new Array();
    zalacznik: Zalacznik;
    czyMoznaEdytowac: boolean;
    rodzajSlownika: RodzajSlownika;
}