<div class="modal-content">
    <div class="modal-body">
        <button aria-label="Close" class="close" data-dismiss="modal" type="button"
            (click)="activeModal.dismiss()"></button>
        <div class="ft__01 text-center py-4 ">Wybierz zakres eksportu:</div>
        <div class="text-center mb-2"></div>
        <form action="">

            <div class="input_container mb-4">
                <select [(ngModel)]="zakresEksportu" name="zakresEksportu">
                    <option [ngValue]="0">Bieżąca lokalizacja</option>
                    <option [ngValue]="1">Cały projekt</option>
                </select>
            </div>

            <div class="input_container pb-3">
                <button class="btn__primary" (click)="wybierz()">Wybierz</button>
            </div>
            <div class="input_container pb-3">
                <button class="btn__link" (click)="activeModal.dismiss()">Anuluj</button>
            </div>
        </form>
    </div>
</div>