<div class="container--main d-none d-md-block" *ngIf="czyZaladowanoDane">

    <div class="d-flex py-4">
        <div class="project__menu">
            <a routerLink='/users' class="active">Użytkownicy</a>
            <a routerLink='/default-dictionaries'>Słowniki</a>
            <a routerLink='/admin/settings'>Ustawienia</a>
        </div>
        <div class="input_container" style=" right: 48px;">
            <input class="pr-4" type="text" placeholder="imię, nazwisko lub e-mail" style="width: 290px;"
                [(ngModel)]="wyszukiwanie.filtry.wartoscSzukaj" (keyup)="filtrujPoTekscie()">
            <button class="btn__input--search"></button>
        </div>
    </div>

</div>
<div class="container-fluid d-none d-md-block" *ngIf="czyZaladowanoDane">


    <div class="box__scroll">
        <div class="tableFixHead tableFixHead--2" *ngIf="czySaWynikiNaLiscie(uzytkownicy)">
            <table class="table">
                <thead>
                    <tr>
                        <th>
                            Imię
                            <button class="btn__input--sort--bottom--gray" [ngClass]="pobierzKierunekSortowania('Imie')"
                                (click)="zmienKierunekSortowania('Imie')"></button>
                        </th>
                        <th>
                            Nazwisko
                            <button class="btn__input--sort--bottom--gray"
                                [ngClass]="pobierzKierunekSortowania('Nazwisko')"
                                (click)="zmienKierunekSortowania('Nazwisko')"></button>
                        </th>
                        <th>
                            Email
                            <button class="btn__input--sort--bottom--gray"
                                [ngClass]="pobierzKierunekSortowania('Email')"
                                (click)="zmienKierunekSortowania('Email')"></button>
                        </th>
                        <th>
                            Telefon
                            <button class="btn__input--sort--bottom--gray"
                                [ngClass]="pobierzKierunekSortowania('NumerTelefonu')"
                                (click)="zmienKierunekSortowania('NumerTelefonu')"></button>
                        </th>
                        <th>
                            Rola
                            <button class="btn__input--sort--bottom--gray" [ngClass]="pobierzKierunekSortowania('Rola')"
                                (click)="zmienKierunekSortowania('Rola')"></button>
                        </th>
                        <th width="140">
                            Akcje
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let uzytkownik of uzytkownicy">
                        <td>
                            <div class="ico__person">{{uzytkownik.imie}}</div>
                        </td>
                        <td>
                            {{uzytkownik.nazwisko}}
                        </td>
                        <td>
                            {{uzytkownik.email}}
                        </td>
                        <td>
                            {{uzytkownik.numerTelefonu}}
                        </td>
                        <td>
                            {{pobierzRole(uzytkownik)}}
                        </td>
                        <td>
                            <button class="btn__edit" (click)="edytujUzytkownika(uzytkownik)"></button>
                            <button class="btn__play" (click)="wyslijLinkAktywacyjny(uzytkownik)"
                                *ngIf="!uzytkownik.czyKontoPotwierdzone"></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="no-results text-center ft__no-results" *ngIf="!czySaWynikiNaLiscie(uzytkownicy)">
            Brak użytkowników<br>dla wybranych filtrów
        </div>
    </div>

    <div class="paginate float-right d-flex align-items-center pt-2 pb-2 pt-lg-2">
        <div *ngIf="czySaWynikiNaLiscie(uzytkownicy)" class="paginate float-right d-flex align-items-center">
            <div>Pokaż wyniki</div>
            <select class="paginate__select mx-2" name="" id="" (change)="zmienRozmiarTabeli()"
                [(ngModel)]="rozmiarTabeli">
                <option [ngValue]="25">25</option>
                <option [ngValue]="50">50</option>
                <option [ngValue]="100">100</option>
            </select>
            <div class="px-2">{{pobierzNumerRekorduPoczatek()}}-{{pobierzNumerRekorduKoniec()}} z
                {{calkowitaLiczbaElementow}}</div>
            <div class="d-flex">
                <button class="btn__left" *ngIf="this.wyszukiwanie.stronicowanie.strona>1"
                    (click)="zmienStrone(-1)"></button>
                <div class="px-2">{{this.wyszukiwanie.stronicowanie.strona}}</div>
                <button class="btn__right" *ngIf="czyJestWiecejRekordow" (click)="zmienStrone(1)"></button>
            </div>
        </div>

    </div>
    <div class="clearfix"></div>
    <div class="pb-4 text-right">
        <button class="btn__add" (click)="dodajUzytkownika()"></button>
    </div>
</div>


<div class="container-fluid d-md-none pb-5 mb-5" *ngIf="czyZaladowanoDane">

    <div class="d-fledx py-3 d-md-none">
        <div class="project__menu" style="width: 320px !important;">
            <button class="btn__left"></button>
            <ngx-slick-carousel class="carousel menu_slider" #slickModal="slick-carousel" [config]="slideConfig">

                <div ngxSlickItem class="slide"><a class="active" routerLink="/users">Użytkownicy</a></div>
                <div ngxSlickItem class="slide"><a routerLink="/default-dictionaries">Słowniki</a></div>
                <div ngxSlickItem class="slide"><a routerLink="/admin/settings">Ustawienia</a> </div>
            </ngx-slick-carousel>

            <button class="btn__right"></button>
        </div>
    </div>


    <div class="filter-box d-flex justify-content-between pt-3">
        <div class="fs__04 pt-1">{{ 'uzytkownicy' | translate }}</div>
        <div class="d-flex">
            <div class="dropdown person_desktop text-right">
                <a class="btn__input--sort dropdown-toggle" [routerLink]="" (click)="pokazMenuSortowania()"
                    (clickOutside)="czyPokazacMenuSortowanie=false" role="button" id="dropdownSort"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                </a>



                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownSort"
                    *ngIf="czyPokazacMenuSortowanie" style="display: block;position: absolute;">
                    <a class="dropdown-item ico__account" [routerLink]=""
                        (click)="zmienKierunekSortowania('Imie')">Imię</a>
                    <a class="dropdown-item ico__account" [routerLink]=""
                        (click)="zmienKierunekSortowania('Nazwisko')">Nazwisko</a>
                    <a class="dropdown-item ico__email" [routerLink]="" (click)="zmienKierunekSortowania('Email')"
                        style="margin-left: 23px;margin-right: 23px;">E-mail</a>
                    <a class="dropdown-item ico__phone" [routerLink]=""
                        (click)="zmienKierunekSortowania('NumerTelefonu')"
                        style="margin-left: 23px;margin-right: 23px;">Telefon</a>
                    <a class="dropdown-item ico__role" style="margin-left: 23px;margin-right: 23px;" [routerLink]=""
                        (click)="zmienKierunekSortowania('Rola')">Rola</a>
                </div>
            </div>
        </div>
    </div>
    <div class="filter-select pt-3 pb-2 d-flex flex-column" *ngIf="pobierzKolumneSortowania()">
        <div class="d-flex pb-2">
            <div class="filter_item">
                <button class="btn__x" (click)="wyczyscSortowanieMobile()"></button>
                <span class="fs__sort">Sortowanie: {{pobierzKolumneSortowania()}}</span>
            </div>
            <button class="btn__input--sort--bottom ml-3" [ngClass]="pobierzKierunekSortowaniaMobile()"
                (click)="zmienKierunekSortowaniaMobile()"></button>
        </div>
    </div>
    <div class="input_container mb-3 mt-2">
        <input class="pr-4" type="text" placeholder="imię, nazwisko lub e-mail"
            [(ngModel)]="wyszukiwanie.filtry.wartoscSzukaj" (keyup)="filtrujPoTekscie()">

        <button class="btn__input--clear" (click)="wyczyscFiltr()" *ngIf="wyszukiwanie.filtry.wartoscSzukaj"></button>
    </div>
    <div class="no-results text-center ft__no-results" *ngIf="!czySaWynikiNaLiscie(uzytkownicy)">
        Brak użytkowników<br>dla wybranych filtrów
    </div>

    <div class="box__mobile mb-3" *ngFor="let uzytkownik of uzytkownicy">
        <div class="d-flex justify-content-between align-items-center pb-2">
            <span class="fs__pname">{{uzytkownik.imie}} {{uzytkownik.nazwisko}}</span>
            <button class="btn__edit" (click)="edytujUzytkownika(uzytkownik)"></button>
        </div>
        <div class="d-flex justify-content-between align-items-center pb-2 pr-3 mb-2">
            <span class="ico__email fs__pname--2">Email</span>
            <span class="fs__pname--3" style="word-break: break-all;">{{uzytkownik.email}}</span>
        </div>
        <div class="d-flex justify-content-between align-items-center pb-2 pr-3 mb-2">
            <span class="ico__phone fs__pname--2">Telefon</span>
            <span class="fs__pname--3">{{uzytkownik.numerTelefonu}}</span>
        </div>
        <div class="d-flex justify-content-between align-items-center pb-2 pr-3 mb-2">
            <span class="ico__role fs__pname--2">Rola</span>
            <span class="fs__pname--3">{{pobierzRole(uzytkownik)}}</span>
        </div>
        <hr class="mr-3">
        <div class="d-flex justify-content-center align-items-center mt-3">
            <button class="btn__active ft__btnactive" (click)="wyslijLinkAktywacyjny(uzytkownik)"
                *ngIf="!uzytkownik.czyKontoPotwierdzone">Aktywuj konto</button>
        </div>
    </div>

    <div class="paginate float-right d-flex align-items-center pb-2 pt-lg-4">
        <div *ngIf="czySaWynikiNaLiscie(uzytkownicy)" class="paginate float-right d-flex align-items-center">
            <div>Pokaż wyniki</div>
            <select class="paginate__select mx-2" name="" id="" (change)="zmienRozmiarTabeli()"
                [(ngModel)]="rozmiarTabeli">
                <option [ngValue]="25">25</option>
                <option [ngValue]="50">50</option>
                <option [ngValue]="100">100</option>
            </select>
            <div class="px-2">{{pobierzNumerRekorduPoczatek()}}-{{pobierzNumerRekorduKoniec()}} z
                {{calkowitaLiczbaElementow}}</div>
            <div class="d-flex">
                <button class="btn__left" *ngIf="this.wyszukiwanie.stronicowanie.strona>1"
                    (click)="zmienStrone(-1)"></button>
                <div class="px-2">{{this.wyszukiwanie.stronicowanie.strona}}</div>
                <button class="btn__right" *ngIf="czyJestWiecejRekordow" (click)="zmienStrone(1)"></button>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
    <div class="pb-4 text-right">
        <button class="btn__add" (click)="dodajUzytkownika()"></button>
    </div>
</div>
<div class="fixed_tabs" *ngIf="czyZaladowanoDane">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
            <a class="nav-link fs__tab   ico__tab--user pointer" id="home-tab" data-toggle="tab" [routerLink]=""
                role="tab" [ngClass]="wyszukiwanie.filtry.aktywni?'active':''" aria-controls="home" aria-selected="true"
                (click)="wybierzAktywnychUzytkownikow(true)">Aktywni</a>
        </li>
        <li class="nav-item">
            <a class="nav-link fs__tab ico__tab--dez pointer" id="profile-tab" data-toggle="tab" [routerLink]=""
                role="tab" [ngClass]="!wyszukiwanie.filtry.aktywni?'active':''" aria-controls="profile"
                aria-selected="false" (click)="wybierzAktywnychUzytkownikow(false)">Dezaktywowani</a>
        </li>
    </ul>
</div>